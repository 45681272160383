import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";

import activityFieldModel from "@app/util/fieldModel/ActivityFieldModel";

const isActivityRequiredRelationshipField = (schemaField: LayoutSchemaField) => {
  if (!schemaField.required) {
    return false;
  }
  const activityField = activityFieldModel.getByPlatformName(schemaField.field);
  return !!activityField?.hasFeature(FieldFeature.RELATIONSHIPS);
};

export default isActivityRequiredRelationshipField;

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { Button } from "antd";
import PopConfirm from "antd/es/popconfirm";
import { useIntl } from "react-intl";

import EmailLogStatus from "@mapmycustomers/shared/enum/EmailLogStatus";
import EmailLog from "@mapmycustomers/shared/types/email/EmailLog";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import { cancelQueuedEmail } from "@app/store/email/actions";

import messages from "./messages";

interface CancelEmailAction {
  emailLog: EmailLog;
  onCancel: typeof cancelQueuedEmail;
}

const EmailLogAction: React.FC<CancelEmailAction> = ({ emailLog, onCancel }) => {
  const intl = useIntl();
  const [cancelling, startCancelling, finishCancelling] = useBoolean();

  const handleCancelConfirm = useCallback(() => {
    startCancelling();
    onCancel({
      emailLogId: emailLog.id,
      onFinish: finishCancelling,
    });
  }, [emailLog, startCancelling, finishCancelling, onCancel]);

  const disabled = emailLog.status !== EmailLogStatus.SUBMITTED;

  return (
    <PopConfirm
      cancelText={intl.formatMessage(messages.goBackLabel)}
      disabled={disabled}
      okButtonProps={{
        danger: true,
        type: "text",
      }}
      okText={intl.formatMessage(messages.cancelSendLabel)}
      onConfirm={handleCancelConfirm}
      placement="left"
      title={intl.formatMessage(messages.cancelSendWarning)}
    >
      <Button danger disabled={disabled} loading={cancelling} type="link">
        {intl.formatMessage(messages.cancelSendLabel)}
      </Button>
    </PopConfirm>
  );
};

const mapDispatchToProps = {
  onCancel: cancelQueuedEmail,
};

export default connect(null, mapDispatchToProps)(EmailLogAction);

import { createReducer } from "typesafe-actions";

import Visibility from "@mapmycustomers/shared/enum/Visibility";
import { EntityType } from "@mapmycustomers/shared/types/entity";

import { isDisplayFieldOnRecordPreviewByDefault } from "@app/util/fieldAssertions";
import companyFieldModel from "@app/util/fieldModel/CompanyFieldModel";
import dealFieldModel from "@app/util/fieldModel/DealFieldModel";
import personFieldModel from "@app/util/fieldModel/PersonFieldModel";

import {
  Actions,
  setRecordsPreviewConfiguration,
  updateRecordsPreviewConfiguration,
} from "./actions";
import EntityColumnsConfiguration from "./EntityColumnsConfiguration";

export interface RecordPreviewState {
  configuration: EntityColumnsConfiguration;
  loading: boolean;
}

const initialState: RecordPreviewState = {
  configuration: {
    actions: { visibility: Visibility.PRIVATE },
    engagement: { visibility: Visibility.PRIVATE },
    [EntityType.COMPANY]: companyFieldModel.sortedFields
      .filter(isDisplayFieldOnRecordPreviewByDefault)
      .map((field, index) => ({
        disabled: false,
        displayOrder: index,
        field,
        visibility: Visibility.PRIVATE,
      })),
    [EntityType.DEAL]: dealFieldModel.sortedFields
      .filter(isDisplayFieldOnRecordPreviewByDefault)
      .map((field, index) => ({
        disabled: false,
        displayOrder: index,
        field,
        visibility: Visibility.PRIVATE,
      })),
    [EntityType.PERSON]: personFieldModel.sortedFields
      .filter(isDisplayFieldOnRecordPreviewByDefault)
      .map((field, index) => ({
        disabled: false,
        displayOrder: index,
        field,
        visibility: Visibility.PRIVATE,
      })),
  },
  loading: false,
};

const recordPreview = createReducer<RecordPreviewState, Actions>(initialState)
  .handleAction(updateRecordsPreviewConfiguration.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(updateRecordsPreviewConfiguration.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(setRecordsPreviewConfiguration, (state, { payload }) => ({
    ...state,
    configuration: {
      ...state.configuration,
      ...payload,
    },
  }))
  .handleAction(updateRecordsPreviewConfiguration.failure, (state) => ({
    ...state,
    loading: false,
  }));

export type RecordPreviewActions = Actions;
export default recordPreview;

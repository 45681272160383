import React, { useCallback } from "react";

import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Checkbox, { CheckboxChangeEvent } from "antd/es/checkbox";
import Space from "antd/es/space";
import Tooltip from "antd/es/tooltip";

import { useConfigProvider } from "../../../../ConfigProvider";

import styles from "./EmailUnsubscribeLink.module.scss";

interface UnsubscribeLinkProps {
  onUnsubscribeLinkIncludedChange: (value: boolean) => void;
  unsubscribeLinkIncluded: boolean;
}

const UnsubscribeLink: React.FC<UnsubscribeLinkProps> = ({
  onUnsubscribeLinkIncludedChange,
  unsubscribeLinkIncluded,
}) => {
  const configProvider = useConfigProvider();

  const handleCheckboxChange = useCallback(
    (e: CheckboxChangeEvent) => {
      onUnsubscribeLinkIncludedChange(e.target.checked);
    },
    [onUnsubscribeLinkIncludedChange]
  );

  return (
    <div>
      {unsubscribeLinkIncluded && (
        <>
          <div className={styles.separator}>--</div>
          <div className={styles.unsubscribeLink}>
            {configProvider.formatMessage("ui.emailBody.emailUnsubscribeLink.linkLabel")}
          </div>
        </>
      )}
      <Checkbox onChange={handleCheckboxChange} value={unsubscribeLinkIncluded}>
        <Space size="small">
          {configProvider.formatMessage("ui.emailBody.emailUnsubscribeLink.checkboxLabel")}
          <Tooltip
            title={configProvider.formatMessage(
              "ui.emailBody.emailUnsubscribeLink.checkboxTooltip"
            )}
          >
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        </Space>
      </Checkbox>
    </div>
  );
};

export default UnsubscribeLink;

import React from "react";

import { bem } from "@react-md/utils";
import cn from "classnames";

import InlineButtons from "../InlineButtons";

interface Props {
  caption?: string;
  className?: string;
  disabled?: boolean;
  editing: boolean;
  onCancel?: () => void;
  onSave?: () => void;
}

const block = bem("mmc-inline-input-footer");

const Footer: React.FC<Props> = ({ caption, className, disabled, editing, onCancel, onSave }) => {
  const buttonsVisible = !disabled && editing;
  if (!buttonsVisible && !caption) {
    return null;
  }

  return (
    <div className={cn(block(), className)}>
      <div className={block("column")}>
        <div className={block("caption")}>{caption ?? ""}</div>
        {buttonsVisible && <InlineButtons onCancel={onCancel} onSave={onSave} />}
      </div>
    </div>
  );
};

export default Footer;

import { PlatformCombinedCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

const getParentActivitiesFilter = (): PlatformCombinedCondition => {
  return {
    $or: [
      { emailLogId: { $eq: null } },
      { $and: [{ emailLogId: { $ne: null } }, { parentId: { $eq: null } }] },
    ],
  };
};

export default getParentActivitiesFilter;

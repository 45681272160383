import React from "react";

import Form from "antd/es/form";
import { useIntl } from "react-intl";

import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import { SelectField } from "@mapmycustomers/ui";

import ActivityTypeIcon from "@app/component/activity/ActivityTypeIcon";

import messages from "./messages";

interface Props {
  activityType: ActivityType;
  isAutoEmail?: boolean;
}

const LockedActivityType: React.FC<Props> = ({ activityType, isAutoEmail }) => {
  const intl = useIntl();

  return (
    <>
      <SelectField
        disabled
        locked
        lockTooltip={intl.formatMessage(messages.activityTypeLockTooltip)}
        placeholder={
          isAutoEmail ? intl.formatMessage(messages.activityTypeAutoEmailName) : activityType.name
        }
        prefixIcon={
          <ActivityTypeIcon activityType={activityType} isAutoEmail={isAutoEmail} size="sm" />
        }
      />
      <Form.Item hidden name="activityTypeId" />
    </>
  );
};

export default LockedActivityType;

import React from "react";

import { ensureGoogleMapsApiIsLoaded } from "@app/util/mapping";

// All scenes wrapped into React.lazy and import to split them into separate chunks.
// The only exceptions are pages which should be available all the time, like auth/* pages

// utility function to guarantee that the google maps api is loaded for the given scene
const loadWithMaps =
  <T,>(componentImport: Promise<T>) =>
  async (): Promise<T> => {
    const [component] = await Promise.all([componentImport, ensureGoogleMapsApiIsLoaded()]);
    return component;
  };

export const ActivityPage = React.lazy(
  loadWithMaps(
    import(
      /* webpackPrefetch: true */
      "./activity"
    )
  )
);

export const DashboardPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./dashboard"
    )
);

export const OnboardingPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./onboarding"
    )
);

export const BillingPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./billing"
    )
);

export const CompanyPage = React.lazy(
  loadWithMaps(
    import(
      /* webpackPrefetch: true */
      "./company"
    )
  )
);

export const PeoplePage = React.lazy(
  loadWithMaps(
    import(
      /* webpackPrefetch: true */
      "./people"
    )
  )
);
export const SettingsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./settings"
    )
);

export const ReportsPage = React.lazy(
  // need maps on various report cards & for export preview grid
  loadWithMaps(
    import(
      /* webpackPrefetch: true */
      "./reports"
    )
  )
);

export const DealPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./deal"
    )
);

export const NotificationsPage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./notifications"
    )
);

export const Web1Page = React.lazy(
  loadWithMaps(
    import(
      /* webpackPrefetch: true */
      "./web1"
    )
  )
);

export const MapPage = React.lazy(
  loadWithMaps(
    import(
      /* webpackPrefetch: true */
      "./map"
    )
  )
);

export const EmailUnsubscribePage = React.lazy(
  () =>
    import(
      /* webpackPrefetch: true */
      "./emailUnsubscribe"
    )
);

import isEqual from "lodash/isEqual";
import { createReducer, getType } from "typesafe-actions";

import ViewType from "@app/scene/dashboard/enum/ViewType";
import { EVENTS_PAGE_SIZE } from "@app/scene/dashboard/store/const";
import EventFilters from "@app/scene/dashboard/store/EventFilters";
import getResultingScope from "@app/scene/dashboard/store/utils/getResultingScope";
import dashboardSettings from "@app/scene/dashboard/utils/dashboardSettings";
import Dashboard from "@app/types/dashboard/Dashboard";
import Scope from "@app/types/dashboard/Scope";
import TimelineEvent from "@app/types/events/TimelineEvent";

import {
  Actions,
  ActivitiesLoggedCardActions,
  ActivitiesOverdueCardActions,
  addCard,
  changeScope,
  CheckInsCardActions,
  ClosedStageDealAmountCardActions,
  ClosedStageDealCountCardActions,
  CustomerFaceTimeCardActions,
  fetchTimelineEvents,
  NewRecordsCardActions,
  NewRoutesCardActions,
  NoContactInCardActions,
  OpenStageDealAmountCardActions,
  OutOfFrequencyCardActions,
  overrideTeamDashboards,
  RecordsPastDueCardActions,
  removeCard,
  saveCard,
  saveDashboardLayout,
  saveDashboardSettings,
  UpcomingActivitiesCardActions,
} from "./actions";
import activitiesLoggedState, {
  ActivitiesLoggedCardState,
  activitiesLoggedInitialState,
} from "./cards/activitiesLogged/reducers";
import activitiesOverdueState, {
  ActivitiesOverdueCardState,
  activitiesOverdueInitialState,
} from "./cards/activitiesOverdue/reducers";
import checkInsState, { CheckInsCardState, checkInsInitialState } from "./cards/checkIns/reducers";
import closedStageDealAmountState, {
  ClosedStageDealAmountCardState,
  closedStageDealAmountInitialState,
} from "./cards/closedStageDealAmount/reducers";
import closedStageDealCountState, {
  ClosedStageDealCountCardState,
  closedStageDealCountInitialState,
} from "./cards/closedStageDealCount/reducers";
import customerFaceTimeState, {
  CustomerFaceTimeCardState,
  customerFaceTimeInitialState,
} from "./cards/customerFaceTime/reducers";
import newRecordsState, {
  NewRecordsCardState,
  newRecordsInitialState,
} from "./cards/newRecords/reducers";
import newRoutesState, {
  NewRoutesCardState,
  newRoutesInitialState,
} from "./cards/newRoutes/reducers";
import noContactInState, {
  NoContactInCardState,
  noContactInInitialState,
} from "./cards/noContactIn/reducers";
import openStageDealAmountState, {
  OpenStageDealAmountCardState,
  openStageDealAmountInitialState,
} from "./cards/openStageDealAmount/reducers";
import outOfFrequencyState, {
  OutOfFrequencyCardState,
  outOfFrequencyInitialState,
} from "./cards/outOfFrequency/reducers";
import recordsPastDueState, {
  RecordsPastDueCardState,
  recordsPastDueInitialState,
} from "./cards/recordsPastDue/reducers";
import upcomingActivitiesState, {
  UpcomingActivitiesCardState,
  upcomingActivitiesInitialState,
} from "./cards/upcomingActivities/reducers";
import { StackRankActions } from "./stackRank/actions";
import stackRank, { initialStackRankState } from "./stackRank/reducers";
import StackRankState from "./stackRank/StackRankState";

export interface DashboardState {
  activitiesLoggedState: ActivitiesLoggedCardState;
  activitiesOverdueState: ActivitiesOverdueCardState;
  boards: Dashboard[];
  checkInsState: CheckInsCardState;
  closedStageDealAmountState: ClosedStageDealAmountCardState;
  closedStageDealCountState: ClosedStageDealCountCardState;
  customerFaceTimeState: CustomerFaceTimeCardState;
  events: TimelineEvent[];
  eventsFilters: EventFilters;
  eventsTotal: number;
  loading?: boolean;
  newRecordsState: NewRecordsCardState;
  newRoutesState: NewRoutesCardState;
  noContactInState: NoContactInCardState;
  oldestEventDate?: Date;
  openStageDealAmountState: OpenStageDealAmountCardState;
  outOfFrequencyState: OutOfFrequencyCardState;
  previousView?: ViewType;
  recordsPastDueState: RecordsPastDueCardState;
  scope: Scope;
  stackRank: StackRankState;
  upcomingActivitiesState: UpcomingActivitiesCardState;
}

const initialState: DashboardState = {
  activitiesLoggedState: activitiesLoggedInitialState,
  activitiesOverdueState: activitiesOverdueInitialState,
  boards: [],
  checkInsState: checkInsInitialState,
  closedStageDealAmountState: closedStageDealAmountInitialState,
  closedStageDealCountState: closedStageDealCountInitialState,
  customerFaceTimeState: customerFaceTimeInitialState,
  events: [],
  eventsFilters: {
    eventTypes: dashboardSettings.getVisibleEventTypes(),
    offset: 0,
    query: "",
    startDate: new Date(),
  },
  eventsTotal: 0,
  newRecordsState: newRecordsInitialState,
  newRoutesState: newRoutesInitialState,
  noContactInState: noContactInInitialState,
  openStageDealAmountState: openStageDealAmountInitialState,
  outOfFrequencyState: outOfFrequencyInitialState,
  recordsPastDueState: recordsPastDueInitialState,
  scope: {},
  stackRank: initialStackRankState,
  upcomingActivitiesState: upcomingActivitiesInitialState,
};

const dashboard = createReducer<DashboardState, Actions>(initialState)
  .handleType(getType(changeScope.request), (state, { payload }) => ({
    ...state,
    loading: true,
    scope: payload.scope,
  }))
  .handleType(getType(changeScope.success), (state, { payload }) => ({
    ...state,
    boards: payload.boards ?? state.boards,
    events: payload.events ?? state.events,
    // keep loading true if just switched to the timeline, otherwise change to false
    // loading: payload.view === ViewType.TIMELINE && state.previousView !== ViewType.TIMELINE,
    oldestEventDate: payload.oldestEventDate,
    previousView: payload.view,
  }))
  .handleType(getType(changeScope.failure), (state) => ({ ...state, loading: false }))
  .handleType(getType(fetchTimelineEvents.request), (state, { payload }) => ({
    ...state,
    eventsFilters: {
      eventTypes: payload.eventTypes ?? state.eventsFilters.eventTypes,
      offset:
        "query" in payload ||
        "startDate" in payload ||
        payload.eventTypes ||
        "viewFilter" in payload
          ? 0
          : state.eventsFilters.offset + EVENTS_PAGE_SIZE,
      query: "query" in payload ? payload.query : state.eventsFilters.query,
      startDate: "startDate" in payload ? payload.startDate : state.eventsFilters.startDate,
      viewFilter: "viewFilter" in payload ? payload.viewFilter : state.eventsFilters.viewFilter,
    },
    loading: true,
  }))
  .handleType(getType(fetchTimelineEvents.success), (state, { payload }) => ({
    ...state,
    events: payload.append ? [...state.events, ...payload.events] : payload.events,
    eventsTotal: payload.total !== undefined ? payload.total : state.eventsTotal,
    loading: false,
  }))
  .handleType(getType(fetchTimelineEvents.failure), (state) => ({ ...state, loading: false }))
  .handleType(
    Object.keys(stackRank.handlers) as StackRankActions["type"][],
    (state: DashboardState, action: StackRankActions) => ({
      ...state,
      stackRank: stackRank(state.stackRank, action),
    })
  )
  .handleType(getType(addCard.request), (state, { payload }) => {
    const scope = getResultingScope(payload.scope);
    return {
      ...state,
      boards: state.boards.map((board) => {
        if (board.teamId === scope.teamId && board.userId === scope.userId) {
          return {
            ...board,
            settings: {
              ...board.settings,
              cards: [...board.settings.cards, payload.card],
            },
          };
        }
        return board;
      }),
    };
  })
  .handleType(getType(addCard.success), (state, { payload }) => ({
    ...state,
    boards: state.boards.map((board) => (payload.id === board.id ? payload : board)),
  }))
  .handleType(getType(saveCard.request), (state, { payload }) => {
    const isTeamUserScope = !!payload.scope.teamId && !!payload.scope.userId;

    // TODO: if saving goal changes, then this change might need to go to both team & user boards
    // For example, if user enabled the goal, the enabled flag should go to the team board
    // but if the target type was set to "individual", then the target value should be
    // stored to the user's board

    const scope = getResultingScope(payload.scope);
    const boardToUpdate = state.boards.find(
      (board) =>
        board.teamId === scope.teamId &&
        board.userId === scope.userId &&
        board.settings.cards.some(({ id }) => id === payload.cardId)
    );
    if (!boardToUpdate) {
      return state;
    }

    let updatedBoards = [...state.boards];

    // if team member modifies their own board and touched preferences or goal, we should save these
    // changes too, into user's own board.overrides
    if (isTeamUserScope && (payload.updatePreferencesOverrides || payload.updateGoalOverrides)) {
      const originalCard = boardToUpdate.settings.cards.find(({ id }) => id === payload.cardId)!;
      const preferencesChanged =
        payload.updatePreferencesOverrides &&
        !isEqual(originalCard.configuration.preferences, payload.configuration.preferences);
      const canChangeGoal =
        !originalCard.configuration.goal?.enabled && payload.updateGoalOverrides;
      const shouldUpdateOverrides = preferencesChanged || canChangeGoal;
      if (shouldUpdateOverrides) {
        const memberBoard = state.boards.find(
          (board) => board.teamId === payload.scope.teamId && board.userId === payload.scope.userId
        );
        if (memberBoard) {
          let overrides = [...(memberBoard.settings.overrides ?? [])];
          if (!overrides.some(({ id }) => id === payload.cardId)) {
            overrides.push({
              id: payload.cardId,
              configuration: {
                goal: canChangeGoal ? payload.configuration.goal : undefined,
                preferences: preferencesChanged ? payload.configuration.preferences : undefined,
              },
            });
          } else {
            overrides = overrides.map((card) => {
              if (card.id === payload.cardId) {
                return {
                  ...card,
                  configuration: {
                    goal: canChangeGoal ? payload.configuration.goal : card.configuration.goal,
                    preferences: preferencesChanged
                      ? payload.configuration.preferences
                      : card.configuration.preferences,
                  },
                };
              }
              return card;
            });
          }
          updatedBoards = updatedBoards.map((board) =>
            board.id === memberBoard.id
              ? { ...memberBoard, settings: { ...memberBoard.settings, overrides } }
              : board
          );
        }
      }
    }

    // finally, let's update the board we planned to update initially (boardToUpdate)
    const updatedCards = boardToUpdate.settings.cards.map((card) => {
      if (card.id === payload.cardId) {
        let updatedCard = {
          ...card,
          configuration: payload.configuration,
          fullWidth: payload.fullWidth ?? card.fullWidth,
        };
        if (payload.teamGoalConfiguration) {
          updatedCard.configuration = {
            ...updatedCard.configuration,
            goal: payload.teamGoalConfiguration,
          };
        }
        if (payload.updateMetaData) {
          updatedCard = { ...updatedCard, ...payload.updateMetaData };
        }
        return updatedCard;
      } else {
        return card;
      }
    });

    return {
      ...state,
      boards: updatedBoards.map((board) =>
        board.id === boardToUpdate.id
          ? {
              ...boardToUpdate,
              settings: { ...boardToUpdate.settings, cards: updatedCards },
            }
          : board
      ),
    };
  })
  .handleType(getType(saveCard.success), (state, { payload }) => {
    const newBoardsMap = payload.reduce(
      (result, board) => ({ ...result, [board.id]: board }),
      {} as Record<Dashboard["id"], Dashboard>
    );
    return {
      ...state,
      boards: state.boards.map((board) => newBoardsMap[board.id] ?? board),
    };
  })
  .handleType(getType(removeCard.request), (state, { payload }) => ({
    ...state,
    boards: state.boards.map((board) => {
      const updatedCards = board.settings.cards.filter(({ id }) => id !== payload.cardId);
      const updatedOverriddenCards = board.settings.overrides?.filter(
        ({ id }) => id !== payload.cardId
      );
      return updatedCards.length < board.settings.cards.length ||
        (board.settings.overrides?.length &&
          updatedOverriddenCards!.length < board.settings.overrides.length)
        ? {
            ...board,
            settings: { ...board.settings, cards: updatedCards, overrides: updatedOverriddenCards },
          }
        : board;
    }),
  }))
  .handleType(getType(removeCard.success), (state, { payload }) => {
    const newBoardsMap = payload.reduce(
      (result, board) => ({ ...result, [board.id]: board }),
      {} as Record<Dashboard["id"], Dashboard>
    );
    return {
      ...state,
      boards: state.boards.map((board) => newBoardsMap[board.id] ?? board),
    };
  })
  .handleType(getType(saveDashboardLayout.success), (state, { payload }) => {
    return {
      ...state,
      boards: state.boards.map((board) => (board.id === payload.id ? payload : board)),
    };
  })
  .handleType(getType(saveDashboardSettings.success), (state, { payload }) => {
    return {
      ...state,
      boards: state.boards.map((board) => (board.id === payload.id ? payload : board)),
    };
  })
  .handleType(getType(overrideTeamDashboards.success), (state, { payload }) => {
    const newBoardsMap = payload.reduce(
      (result, board) => ({ ...result, [board.id]: board }),
      {} as Record<Dashboard["id"], Dashboard>
    );
    return {
      ...state,
      boards: state.boards.map((board) => newBoardsMap[board.id] ?? board),
    };
  })
  .handleType(
    Object.keys(activitiesLoggedState.handlers) as ActivitiesLoggedCardActions["type"][],
    (state: DashboardState, action: ActivitiesLoggedCardActions) => ({
      ...state,
      activitiesLoggedState: activitiesLoggedState(state.activitiesLoggedState, action),
    })
  )
  .handleType(
    Object.keys(activitiesOverdueState.handlers) as ActivitiesOverdueCardActions["type"][],
    (state: DashboardState, action: ActivitiesOverdueCardActions) => ({
      ...state,
      activitiesOverdueState: activitiesOverdueState(state.activitiesOverdueState, action),
    })
  )
  .handleType(
    Object.keys(checkInsState.handlers) as CheckInsCardActions["type"][],
    (state: DashboardState, action: CheckInsCardActions) => ({
      ...state,
      checkInsState: checkInsState(state.checkInsState, action),
    })
  )
  .handleType(
    Object.keys(closedStageDealAmountState.handlers) as ClosedStageDealAmountCardActions["type"][],
    (state: DashboardState, action: ClosedStageDealAmountCardActions) => ({
      ...state,
      closedStageDealAmountState: closedStageDealAmountState(
        state.closedStageDealAmountState,
        action
      ),
    })
  )
  .handleType(
    Object.keys(closedStageDealCountState.handlers) as ClosedStageDealCountCardActions["type"][],
    (state: DashboardState, action: ClosedStageDealCountCardActions) => ({
      ...state,
      closedStageDealCountState: closedStageDealCountState(state.closedStageDealCountState, action),
    })
  )
  .handleType(
    Object.keys(customerFaceTimeState.handlers) as CustomerFaceTimeCardActions["type"][],
    (state: DashboardState, action: CustomerFaceTimeCardActions) => ({
      ...state,
      customerFaceTimeState: customerFaceTimeState(state.customerFaceTimeState, action),
    })
  )
  .handleType(
    Object.keys(newRecordsState.handlers) as NewRecordsCardActions["type"][],
    (state: DashboardState, action: NewRecordsCardActions) => ({
      ...state,
      newRecordsState: newRecordsState(state.newRecordsState, action),
    })
  )
  .handleType(
    Object.keys(newRoutesState.handlers) as NewRoutesCardActions["type"][],
    (state: DashboardState, action: NewRoutesCardActions) => ({
      ...state,
      newRoutesState: newRoutesState(state.newRoutesState, action),
    })
  )
  .handleType(
    Object.keys(noContactInState.handlers) as NoContactInCardActions["type"][],
    (state: DashboardState, action: NoContactInCardActions) => ({
      ...state,
      noContactInState: noContactInState(state.noContactInState, action),
    })
  )
  .handleType(
    Object.keys(recordsPastDueState.handlers) as RecordsPastDueCardActions["type"][],
    (state: DashboardState, action: RecordsPastDueCardActions) => ({
      ...state,
      recordsPastDueState: recordsPastDueState(state.recordsPastDueState, action),
    })
  )
  .handleType(
    Object.keys(openStageDealAmountState.handlers) as OpenStageDealAmountCardActions["type"][],
    (state: DashboardState, action: OpenStageDealAmountCardActions) => ({
      ...state,
      openStageDealAmountState: openStageDealAmountState(state.openStageDealAmountState, action),
    })
  )
  .handleType(
    Object.keys(outOfFrequencyState.handlers) as OutOfFrequencyCardActions["type"][],
    (state: DashboardState, action: OutOfFrequencyCardActions) => ({
      ...state,
      outOfFrequencyState: outOfFrequencyState(state.outOfFrequencyState, action),
    })
  )
  .handleType(
    Object.keys(upcomingActivitiesState.handlers) as UpcomingActivitiesCardActions["type"][],
    (state: DashboardState, action: UpcomingActivitiesCardActions) => ({
      ...state,
      upcomingActivitiesState: upcomingActivitiesState(state.upcomingActivitiesState, action),
    })
  );

export type DashboardActions = Actions;

export default dashboard;

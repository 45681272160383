import { defineMessages } from "react-intl";

const messages = defineMessages({
  actionColumnTitle: {
    id: "emailQueueModal.actionColumnTitle",
    defaultMessage: "Action",
    description: "Email queue modal action column title.",
  },
  cancelSendLabel: {
    id: "emailQueueModal.cancelSendLabel",
    defaultMessage: "Cancel Send",
    description: "Email queue modal cancel send label.",
  },
  cancelSendWarning: {
    id: "emailQueueModal.cancelSendConfirmation",
    defaultMessage: "Canceling a queued email cannot be undone.",
    description: "Email queue modal cancel send warning.",
  },
  clearQueueLabel: {
    id: "emailQueueModal.clearQueueLabel",
    defaultMessage: "Clear Queue",
    description: "Email queue modal clear label.",
  },
  clearQueueModalDescription: {
    id: "emailQueueModal.clearQueueModal.description",
    defaultMessage: "Clearing the queue cancels all unsent emails, leaving the queue empty.",
    description: "Email queue modal clear queue modal description.",
  },
  clearQueueModalTitle: {
    id: "emailQueueModal.clearQueueModal.title",
    defaultMessage: "This action cannot be undone",
    description: "Email queue modal clear queue modal title.",
  },
  emailColumnTitle: {
    id: "emailQueueModal.emailColumnTitle",
    defaultMessage: "Email",
    description: "Email queue modal email column title.",
  },
  emailsQueuedLabel: {
    id: "emailQueueModal.emailsQueuedLabel",
    defaultMessage: "{submitted} {submitted, plural, one {email} other {emails}} queued",
    description: "Email queue modal emails queued label.",
  },
  goBackLabel: {
    id: "emailQueueModal.goBackLabel",
    defaultMessage: "Go Back",
    description: "Email queue modal go back label.",
  },
  nameColumnTitle: {
    id: "emailQueueModal.nameColumnTitle",
    defaultMessage: "Name",
    description: "Email queue modal name column title.",
  },
  notSentTooltip: {
    id: "emailQueueModal.notSentTooltip",
    defaultMessage: "Email not sent, please try again",
    description: "Email queue modal not sent tooltip.",
  },
  progressCaption: {
    id: "emailQueueModal.progress.progressCaption",
    defaultMessage: "{sent}/{total} {sent, plural, one {email} other {emails}} sent",
    description: "Email queue modal emails progress caption.",
  },
  progressInfoTooltip: {
    id: "emailQueueModal.progress.progressInfoTooltip",
    defaultMessage:
      "To protect your email’s reputation, you are limited to {total} sends per day. Queued emails will resume the following day.",
    description: "Email queue modal progress emails limit tooltip.",
  },
  progressTooltip: {
    id: "emailQueueModal.progress.progressTooltip",
    defaultMessage:
      "{sent} {sent, plural, one {email} other {emails}} sent / {submitted} {submitted, plural, one {email} other {emails}} queued / {remaining} {remaining, plural, one {email} other {emails}} remaining",
    description: "Email queue modal emails progress tooltip.",
  },
  queueEmptyDescription: {
    id: "emailQueueModal.queueEmptyDescription",
    defaultMessage: "There’s nothing queued at the moment.",
    description: "Email queue modal queue empty description.",
  },
  queueEmptyLabel: {
    id: "emailQueueModal.queueEmptyLabel",
    defaultMessage: "Queue is empty",
    description: "Email queue modal queue empty label.",
  },
  redirectToSettingsTooltip: {
    id: "emailQueueModal.redirectToSettingsTooltip",
    defaultMessage: "Go to settings",
    description: "Email queue modal redirect to settings tooltip.",
  },
  refreshTooltip: {
    id: "emailQueueModal.refreshTooltip",
    defaultMessage: "Refresh",
    description: "Email queue modal refresh tooltip.",
  },
  sendingTooltip: {
    id: "emailQueueModal.sendingTooltip",
    defaultMessage: "Sending now",
    description: "Email queue modal sending tooltip.",
  },
  sendIntervalLabel: {
    id: "emailQueueModal.sendIntervalLabel",
    defaultMessage: "Sends between {start} - {end} {timezone}",
    description: "Email queue modal emails queued label.",
  },
  sentTodayTooltip: {
    id: "emailQueueModal.sentTodayTooltip",
    defaultMessage: "Sent today at {time}",
    description: "Email queue modal send today tooltip.",
  },
  statusColumnTitle: {
    id: "emailQueueModal.statusColumnTitle",
    defaultMessage: "Status",
    description: "Email queue modal status column title.",
  },
  subjectColumnTitle: {
    id: "emailQueueModal.subjectColumnTitle",
    defaultMessage: "Subject",
    description: "Email queue modal subject column title.",
  },
  title: {
    id: "emailQueueModal.title",
    defaultMessage: "Email Queue",
    description: "Email queue modal title.",
  },
});

export default messages;

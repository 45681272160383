import type { ActionType } from "typesafe-actions";
import { createAction, createAsyncAction } from "typesafe-actions";

import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import CustomField from "@mapmycustomers/shared/types/customField/CustomField";
import type CustomFieldValue from "@mapmycustomers/shared/types/customField/CustomFieldValue";
import { CustomFieldError } from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import type { Activity, Deal, Person, Route } from "@mapmycustomers/shared/types/entity";
import { Group } from "@mapmycustomers/shared/types/entity";
import type ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import type Note from "@mapmycustomers/shared/types/entity/common/Note";
import type Company from "@mapmycustomers/shared/types/entity/Company";
import type { RawFile } from "@mapmycustomers/shared/types/File";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import User from "@mapmycustomers/shared/types/User";
import SortModel from "@mapmycustomers/shared/types/viewModel/internalModel/SortModel";
import type { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";

import type RecapRange from "@app/enum/preview/RecapRange";
import RecordPaneTab from "@app/enum/preview/RecordPaneTab";
import type { CompanyPayload } from "@app/store/api/ApiService";
import type FileListItem from "@app/types/FileListItem";

import type CompanyRecordData from "./CompanyRecordData";

export const fetchPreviewData = createAsyncAction(
  "companyRecord/fetchPreviewData/request",
  "companyRecord/fetchPreviewData/success",
  "companyRecord/fetchPreviewData/failure"
)<
  Company["id"],
  {
    recordData: CompanyRecordData;
    selectedActivityTypes: ActivityType[];
  },
  void
>();

export const fetchCompanyActivitiesCompletedByType = createAsyncAction(
  "companyRecord/fetchCompanyActivitiesCompletedByType/request",
  "companyRecord/fetchCompanyActivitiesCompletedByType/success",
  "companyRecord/fetchCompanyActivitiesCompletedByType/failure"
)<
  {
    activityTypes: ActivityType[];
    company: Company;
    filter: ActivityStatusOption;
    order: SortModel;
    recapRange: RecapRange;
    selectedAssignees: User["id"][];
  },
  Activity[],
  void
>();

export const fetchCompanyActivities = createAsyncAction(
  "companyRecord/fetchCompanyActivities/request",
  "companyRecord/fetchCompanyActivities/success",
  "companyRecord/fetchCompanyActivities/failure"
)<
  {
    activityTypes: ActivityType[];
    company: Company;
    filter: ActivityStatusOption;
    order: SortModel;
    recapRange: RecapRange;
    search: string;
    selectedAssignees: User["id"][];
  },
  Activity[],
  void
>();

export const fetchCompanyActivitiesTotal = createAsyncAction(
  "companyRecord/fetchCompanyActivitiesTotal/request",
  "companyRecord/fetchCompanyActivitiesTotal/success",
  "companyRecord/fetchCompanyActivitiesTotal/failure"
)<Company, number, void>();

export const fetchCompanyRoutes = createAsyncAction(
  "companyRecord/fetchCompanyRoutes/request",
  "companyRecord/fetchCompanyRoutes/success",
  "companyRecord/fetchCompanyRoutes/failure"
)<Company["id"], Route[], void>();

export const createCompanyNote = createAsyncAction(
  "companyRecord/createCompanyNote/request",
  "companyRecord/createCompanyNote/success",
  "companyRecord/createCompanyNote/failure"
)<string, Note, void>();

export const updateCompanyNote = createAsyncAction(
  "companyRecord/updateCompanyNote/request",
  "companyRecord/updateCompanyNote/success",
  "companyRecord/updateCompanyNote/failure"
)<Note, Note, Note["id"]>();

export const deleteCompanyNote = createAsyncAction(
  "companyRecord/deleteCompanyNote/request",
  "companyRecord/deleteCompanyNote/success",
  "companyRecord/deleteCompanyNote/failure"
)<Note, Note["id"], Note["id"]>();

export const uploadCompanyFiles = createAsyncAction(
  "companyRecord/uploadCompanyFiles/request",
  "companyRecord/uploadCompanyFiles/success",
  "companyRecord/uploadCompanyFiles/failure"
)<
  {
    callback?: (filesList: FileListItem[]) => void;
    fileGroupId?: string;
    files: (Blob | File)[];
  },
  FileListItem[],
  void
>();

export const deleteCompanyFile = createAsyncAction(
  "companyRecord/deleteCompanyFile/request",
  "companyRecord/deleteCompanyFile/success",
  "companyRecord/deleteCompanyFile/failure"
)<RawFile, { file: RawFile; removed: boolean }, RawFile>();

export const clearAllUploadedCompanyFiles = createAction(
  "companyRecord/clearAllUploadedCompanyFiles"
)<void>();

export const fetchThumbnail = createAction("companyRecord/fetchThumbnail")<{
  callback: (data: Blob) => void;
  fileId: RawFile["id"];
}>();

export const downloadCompanyFile = createAction("companyRecord/downloadCompanyFile")<RawFile>();

export const updateCompany = createAsyncAction(
  "companyRecord/updateCompany/request",
  "companyRecord/updateCompany/success",
  "companyRecord/updateCompany/failure"
)<
  {
    callback?: (updatedCompany: Company) => void;
    company: OnlyRequiredFields<
      Pick<
        CompanyPayload,
        | "address"
        | "annualRevenue"
        | "city"
        | "color"
        | "country"
        | "countryCode"
        | "email"
        | "geoAddress"
        | "geoManagementState"
        | "geoPoint"
        | "id"
        | "name"
        | "numEmployees"
        | "phone"
        | "postalCode"
        | "region"
        | "user"
        | "website"
      >,
      "id"
    >;
    customFields?: Record<CustomField["esKey"], CustomFieldValue>;
    customFieldsValueValidateCallback?: (customFieldErrors: CustomFieldError[]) => void;
    groupIdsToAdd?: Group["id"][];
    groupIdsToRemove?: Group["id"][];
    layoutId?: FormLayout["id"];
  },
  Company,
  void
>();

export const updateCompanyFrequency = createAsyncAction(
  "companyRecord/updateCompanyFrequency/request",
  "companyRecord/updateCompanyFrequency/success",
  "companyRecord/updateCompanyFrequency/failure"
)<
  {
    companyId: Company["id"];
  },
  Company,
  void
>();

export const addCompanyParentCompany = createAsyncAction(
  "companyRecord/addCompanyParentCompany/request",
  "companyRecord/addCompanyParentCompany/success",
  "companyRecord/addCompanyParentCompany/failure"
)<{ id: Company["id"]; parentCompanyId: Company["id"] }, Company, void>();

export const removeCompanyParentCompany = createAsyncAction(
  "companyRecord/removeCompanyParentCompany/request",
  "companyRecord/removeCompanyParentCompany/success",
  "companyRecord/removeCompanyParentCompany/failure"
)<Company["id"], Company["id"], Company["id"]>();

export const addCompanyChildCompanies = createAsyncAction(
  "companyRecord/addCompanyChildCompanies/request",
  "companyRecord/addCompanyChildCompanies/success",
  "companyRecord/addCompanyChildCompanies/failure"
)<
  { companyIds?: Array<Company["id"]>; id: Company["id"]; removedCompaniesIds?: Company["id"][] },
  Company[],
  void
>();

export const removeCompanyChildCompany = createAsyncAction(
  "companyRecord/removeCompanyChildCompany/request",
  "companyRecord/removeCompanyChildCompany/success",
  "companyRecord/removeCompanyChildCompany/failure"
)<Company["id"], Company["id"], Company["id"]>();

export const addCompanyPeople = createAsyncAction(
  "companyRecord/addCompanyPeople/request",
  "companyRecord/addCompanyPeople/success",
  "companyRecord/addCompanyPeople/failure"
)<
  {
    id: Company["id"];
    peopleIds?: Array<Person["id"]>;
    primaryPersonId?: Person["id"];
    removedPeopleIds?: Person["id"][];
  },
  { people: Person[]; primaryPerson: Company["primaryContact"]; total: number },
  void
>();

export const removeCompanyPerson = createAsyncAction(
  "companyRecord/removeCompanyPerson/request",
  "companyRecord/removeCompanyPerson/success",
  "companyRecord/removeCompanyPerson/failure"
)<Person["id"], Person["id"], Person["id"]>();

export const addCompanyDeals = createAsyncAction(
  "companyRecord/addCompanyDeals/request",
  "companyRecord/addCompanyDeals/success",
  "companyRecord/addCompanyDeals/failure"
)<
  { dealIds?: Array<Deal["id"]>; id: Company["id"]; removedDealsIds?: Deal["id"][] },
  { deals: Deal[]; total: number },
  void
>();

export const removeCompanyDeal = createAsyncAction(
  "companyRecord/removeCompanyDeal/request",
  "companyRecord/removeCompanyDeal/success",
  "companyRecord/removeCompanyDeal/failure"
)<Deal["id"], Deal["id"], Deal["id"]>();

export const removeCompanyFromRoute = createAsyncAction(
  "companyRecord/removeCompanyFromRoute/request",
  "companyRecord/removeCompanyFromRoute/success",
  "companyRecord/removeCompanyFromRoute/failure"
)<{ id: Company["id"]; routeId: Route["id"] }, Route["id"], Route["id"]>();

export const toggleCompleteActivity = createAsyncAction(
  "companyRecord/toggleCompleteActivity/request",
  "companyRecord/toggleCompleteActivity/success",
  "companyRecord/toggleCompleteActivity/failure"
)<Activity, Pick<Activity, "completed" | "completedAt" | "id">, Activity["id"]>();

export const postponeActivity = createAsyncAction(
  "companyRecord/postponeActivity/request",
  "companyRecord/postponeActivity/success",
  "companyRecord/postponeActivity/failure"
)<Activity, Activity, Activity["id"]>();

export const updateActivityNote = createAsyncAction(
  "companyRecord/updateActivityNote/request",
  "companyRecord/updateActivityNote/success",
  "companyRecord/updateActivityNote/failure"
)<{ activity: Activity; onSuccess: () => void }, Pick<Activity, "id" | "note">, Activity["id"]>();

export const changeActivitiesSearchQuery = createAction(
  "companyRecord/changeActivitiesSearchQuery"
)<string>();

export const changeActivitiesFilter = createAction(
  "companyRecord/changeActivitiesFilter"
)<ActivityStatusOption>();

export const changeActivitiesRecapRange = createAction(
  "companyRecord/changeActivitiesRecapRange"
)<RecapRange>();

export const changeActivitiesSelectedActivityTypes = createAction(
  "companyRecord/changeActivitiesSelectedActivityTypes"
)<ActivityType[]>();

export const changeActivitiesAssignees = createAction("companyRecord/changeActivitiesAssignees")<
  User["id"][]
>();

export const changeActivitiesOrder = createAction(
  "companyRecord/changeActivitiesOrder"
)<SortModel>();

export const deleteCompany = createAsyncAction(
  "companyRecord/deleteCompany/request",
  "companyRecord/deleteCompany/success",
  "companyRecord/deleteCompany/failure"
)<void, void, void>();

export const fetchMoreDeals = createAsyncAction(
  "companyRecord/fetchMoreDeals/request",
  "companyRecord/fetchMoreDeals/success",
  "companyRecord/fetchMoreDeals/failure"
)<void, Deal[], void>();

export const fetchMorePeople = createAsyncAction(
  "companyRecord/fetchMorePeople/request",
  "companyRecord/fetchMorePeople/success",
  "companyRecord/fetchMorePeople/failure"
)<void, Person[], void>();

export const updateCompanyPrimaryPerson = createAsyncAction(
  "companyRecord/updateCompanyPrimaryCompany/request",
  "companyRecord/updateCompanyPrimaryCompany/success",
  "companyRecord/updateCompanyPrimaryCompany/failure"
)<{ id: Company["id"]; primaryPersonId: null | Person["id"] }, { company: Company }, void>();

export const setActiveTab = createAction("companyRecord/setActiveTab")<RecordPaneTab>();

export const setActivityElementId = createAction("companyRecord/setActivityElementId")<string>();

export const setActivityAutoScrolling = createAction(
  "companyRecord/setActivityAutoScrolling"
)<boolean>();

export const setHasChangesFlag = createAction("companyRecord/setHasChangesFlag")<boolean>();

export const setCompanyColor = createAction("companyRecord/setCompanyColor")<Company["color"]>();
export const setCompanyShape = createAction("companyRecord/setCompanyShape")<Company["shape"]>();

export const subscribeCompanyEmail = createAsyncAction(
  "companyRecord/subscribeCompanyEmail/request",
  "companyRecord/subscribeCompanyEmail/success",
  "companyRecord/subscribeCompanyEmail/failure"
)<void, void, void>();

export const unsubscribeCompanyEmail = createAsyncAction(
  "companyRecord/unsubscribeCompanyEmail/request",
  "companyRecord/unsubscribeCompanyEmail/success",
  "companyRecord/unsubscribeCompanyEmail/failure"
)<void, void, void>();

export type Actions = ActionType<
  | typeof addCompanyChildCompanies
  | typeof addCompanyDeals
  | typeof addCompanyParentCompany
  | typeof addCompanyPeople
  | typeof changeActivitiesAssignees
  | typeof changeActivitiesFilter
  | typeof changeActivitiesOrder
  | typeof changeActivitiesRecapRange
  | typeof changeActivitiesSearchQuery
  | typeof changeActivitiesSelectedActivityTypes
  | typeof clearAllUploadedCompanyFiles
  | typeof createCompanyNote
  | typeof deleteCompany
  | typeof deleteCompanyFile
  | typeof deleteCompanyNote
  | typeof downloadCompanyFile
  | typeof fetchCompanyActivities
  | typeof fetchCompanyActivitiesCompletedByType
  | typeof fetchCompanyActivitiesTotal
  | typeof fetchCompanyRoutes
  | typeof fetchMoreDeals
  | typeof fetchMorePeople
  | typeof fetchPreviewData
  | typeof fetchThumbnail
  | typeof postponeActivity
  | typeof removeCompanyChildCompany
  | typeof removeCompanyDeal
  | typeof removeCompanyFromRoute
  | typeof removeCompanyParentCompany
  | typeof removeCompanyPerson
  | typeof setActiveTab
  | typeof setActivityAutoScrolling
  | typeof setActivityElementId
  | typeof setCompanyColor
  | typeof setCompanyShape
  | typeof setHasChangesFlag
  | typeof subscribeCompanyEmail
  | typeof toggleCompleteActivity
  | typeof unsubscribeCompanyEmail
  | typeof updateActivityNote
  | typeof updateCompany
  | typeof updateCompanyFrequency
  | typeof updateCompanyNote
  | typeof updateCompanyPrimaryPerson
  | typeof uploadCompanyFiles
>;

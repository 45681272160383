import { defineMessages } from "react-intl";

const messages = defineMessages({
  bulkTitle: {
    id: "email.creationModal.bulkTitle",
    defaultMessage: "Bulk Email",
    description: "Title of bulk email creation modal",
  },
  chooseRecipient: {
    id: "email.creationModal.chooseRecipient",
    defaultMessage: "Choose any recipient to preview as that recipient.",
    description: "email creation modal - Choose Recipient",
  },
  closeConfirmationNo: {
    id: "email.creationModal.closeConfirmationNo",
    defaultMessage: "No",
    description: "Close confirmation no",
  },
  closeConfirmationTitle: {
    id: "email.creationModal.closeConfirmationTitle",
    defaultMessage: "Close without sending? <b>Email will not be saved.</b>",
    description: "Close confirmation title",
  },
  closeConfirmationYes: {
    id: "email.creationModal.closeConfirmationYes",
    defaultMessage: "Yes",
    description: "Close confirmation yes",
  },
  emailBodyLimitReached: {
    id: "email.creationModal.emailBodyLimitReached",
    defaultMessage: "The email body is too long. Please shorten it to proceed.",
    description: "Email body limit reached",
  },
  emailIsNotFilled: {
    id: "email.creationModal.emailIsNotFilled",
    defaultMessage: "Fill out both subject and body to continue",
    description: "Email is not filled",
  },
  emailIsQueued: {
    id: "email.creationModal.emailIsQueued",
    defaultMessage: "Your mail has been queued",
    description: "email creation modal - Email is queued",
  },
  emailIsSent: {
    id: "email.creationModal.emailIsSent",
    defaultMessage: "Your mail is on its way",
    description: "email creation modal - Email is sent",
  },
  emailIsSentAboutEmails: {
    id: "email.creationModal.emailIsSentAboutEmails",
    defaultMessage: "About Emails",
    description: "email creation modal - Email is sent - about emails",
  },
  emailIsSentDescription: {
    id: "email.creationModal.emailIsSentDescription",
    defaultMessage:
      "Please check your email client to confirm deliverability. Allow up to one minute for every email that is being sent.",
    description: "email creation modal - Email is sent description",
  },
  emailPreviewRecipient: {
    id: "email.creationModal.emailPreviewRecipient",
    defaultMessage: "To: {recipient}",
    description: "Email preview recipient",
  },
  emailPreviewSubject: {
    id: "email.creationModal.emailPreviewSubject",
    defaultMessage: "Subject: {subject}",
    description: "Email preview subject",
  },
  filesSizeLimitReached: {
    id: "email.creationModal.filesSizeLimitReached",
    defaultMessage:
      "Cannot send emails with attachments larger than 24 MB in total. Please remove or compress files and try again.",
    description: "Email attachments limit reached",
  },
  more: {
    id: "email.creationModal.recipients.moreText",
    defaultMessage: "More",
    description: "More text for recipients",
  },
  next: {
    id: "email.creationModal.next",
    defaultMessage: "Next",
    description: "next button in email creation modal",
  },
  nextConfirmationNo: {
    id: "email.creationModal.nextConfirmationNo",
    defaultMessage: "Cancel",
    description: "Next confirmation no",
  },
  nextConfirmationTitle: {
    id: "email.creationModal.nextConfirmationTitle",
    defaultMessage: "Changes to template were not saved. Send anyways?",
    description: "Next confirmation title",
  },
  nextConfirmationYes: {
    id: "email.creationModal.nextConfirmationYes",
    defaultMessage: "Send anyways",
    description: "Next confirmation yes",
  },
  outSideSchedule: {
    id: "email.creationModal.outSideSchedule",
    defaultMessage:
      "Mail has been queued because it is outside the scheduled sending hours, which can be changed in <a>Email Settings</a>.",
    description: "Outside schedule message",
  },
  previewAs: {
    id: "email.creationModal.previewAs",
    defaultMessage: "Preview As",
    description: "email creation modal - Preview as text",
  },
  previewAsDescription: {
    id: "email.creationModal.previewAs.description",
    defaultMessage:
      'The "Preview As" email feature lets you see how an email would look to a particular recipient, helping you check if personalized details are working correctly.',
    description: "email creation modal - Preview as text",
  },
  previewDisabled: {
    id: "email.creationModal.previewDisabled",
    defaultMessage: "Fill out both subject and body to preview email",
    description: "Email preview disabled ",
  },
  previewWillShowHere: {
    id: "email.creationModal.previewWillShowHere",
    defaultMessage: "Preview will show here",
    description: "email creation modal - Preview will show here",
  },
  reachSendLimit: {
    id: "email.creationModal.reachSendLimit",
    defaultMessage:
      "Because you’ve reached your sending limits, these emails have been added to the queue until they can be sent.",
    description: "Reached your sending limits",
  },
  selectRecipient: {
    id: "email.creationModal.selectRecipient",
    defaultMessage: "Select recipient to simulate",
    description: "email creation modal - Select Recipient",
  },
  subTitle: {
    id: "email.creationModal.subTitle",
    defaultMessage: "{sent}/{total} sends left today",
    description: "Sub Title of email creation modal",
  },
  title: {
    id: "email.creationModal.title",
    defaultMessage: "Email",
    description: "Title of email creation modal",
  },
  to: {
    id: "email.creationModal.recipients.to",
    defaultMessage: "To",
    description: "To text for recipients",
  },
  unsubscribeLink: {
    id: "email.creationModal.unsubscribeLink",
    defaultMessage: "Unsubscribe",
    description: "Unsubscribe link",
  },
});

export default messages;

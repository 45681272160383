import React, { useCallback } from "react";
import { connect } from "react-redux";

import { faArrowUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons/faArrowUpRightFromSquare";
import { faRefresh } from "@fortawesome/pro-solid-svg-icons/faRefresh";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import { parse } from "date-fns/esm";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";
import Setting from "@mapmycustomers/shared/types/Setting";
import BulkEmailSettingValue from "@mapmycustomers/shared/types/settingValues/BulkEmailSettingValue";

import OrganizationSetting from "@app/enum/OrganizationSetting";
import Path from "@app/enum/Path";
import SettingPath from "@app/enum/settings/SettingPath";
import TimeZones from "@app/enum/TimeZones";
import { getEmailQuota, isEmailQueueLogsLoading } from "@app/store/email";
import { fetchEmailQueueLogs, hideEmailQueue } from "@app/store/email/actions";
import { getBulkEmailSetting, getOrganizationSettingValue } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import TimeZone from "@app/types/TimeZone";
import { formatDate } from "@app/util/formatters";

import styles from "./EmailQueueSubtitle.module.scss";
import messages from "./messages";

const parseSettingTime = (time: string) => parse(time, "HH:mm", new Date());

interface EmailQueueSubtitleProps {
  bulkEmailSetting?: Setting<BulkEmailSettingValue>;
  emailLogsLoading: boolean;
  emailQuota?: EmailQuota;
  onClose: typeof hideEmailQueue;
  onFetchEmailLogs: typeof fetchEmailQueueLogs.request;
  timezoneSettingValue: string;
}

const EmailQueueSubtitle: React.FC<EmailQueueSubtitleProps> = ({
  bulkEmailSetting,
  emailLogsLoading,
  emailQuota,
  onClose,
  onFetchEmailLogs,
  timezoneSettingValue,
}) => {
  const intl = useIntl();
  const history = useHistory();

  const handleRefreshClick = useCallback(() => {
    onFetchEmailLogs();
  }, [onFetchEmailLogs]);

  const handleRedirectToSettingsClick = useCallback(() => {
    onClose();
    history.push(`${Path.SETTINGS}/${SettingPath.PERSONAL_EMAIL_PREFERENCES}`);
  }, [history, onClose]);

  if (!emailQuota) {
    return null;
  }

  return (
    <div className={styles.container}>
      <span>
        {intl.formatMessage(messages.emailsQueuedLabel, { submitted: emailQuota.submitted })}
      </span>
      <Tooltip title={intl.formatMessage(messages.refreshTooltip)}>
        <Button
          icon={<FontAwesomeIcon icon={faRefresh} />}
          loading={emailLogsLoading}
          onClick={handleRefreshClick}
          size="small"
          type="text"
        />
      </Tooltip>
      {bulkEmailSetting && (
        <>
          <span className={styles.separator} />
          <span>
            {intl.formatMessage(messages.sendIntervalLabel, {
              end: formatDate(parseSettingTime(bulkEmailSetting.value.endTime), "p"),
              start: formatDate(parseSettingTime(bulkEmailSetting.value.startTime), "p"),
              timezone: TimeZones[timezoneSettingValue as TimeZone].defaultMessage,
            })}
          </span>
          <Tooltip title={intl.formatMessage(messages.redirectToSettingsTooltip)}>
            <Button
              icon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
              loading={emailLogsLoading}
              onClick={handleRedirectToSettingsClick}
              size="small"
              type="text"
            />
          </Tooltip>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  bulkEmailSetting: getBulkEmailSetting(state),
  emailLogsLoading: isEmailQueueLogsLoading(state),
  emailQuota: getEmailQuota(state),
  timezoneSettingValue: getOrganizationSettingValue(state)(OrganizationSetting.TIMEZONE),
});

const mapDispatchToProps = {
  onClose: hideEmailQueue,
  onFetchEmailLogs: fetchEmailQueueLogs.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailQueueSubtitle);

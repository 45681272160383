enum SettingPath {
  PERSONAL_PROFILE = "personal/profile",
  PERSONAL_CALENDAR_SYNC = "personal/calendar-sync",
  PERSONAL_MAP_PREFERENCES = "personal/map-preferences",
  PERSONAL_EMAIL_PREFERENCES = "personal/email-preferences",
  ORGANIZATION_DETAILS = "organization/details",
  ORGANIZATION_CUSTOM_FIELDS = "organization/custom-fields",
  ORGANIZATION_ACTIVITY_TYPES = "organization/activity-types",
  ORGANIZATION_USERS_AND_PERMISSIONS = "organization/users-and-permissions",
  ORGANIZATION_INTEGRATIONS = "organization/integrations",
  ORGANIZATION_SANDBOX = "organization/sandbox",
  DATA_NEW_IMPORT = "data/new-import",
  DATA_IMPORT_HISTORY = "data/import-history",
  DATA_RESTORABLE_RECORDS = "data/restorable-records",
  PRIVACY_NOTIFICATIONS = "privacy/notifications",
  PRIVACY_MARKETING = "privacy/marketing",
  FORMS_MANAGE_FIELDS = "forms/manage-fields",
  FORMS_CREATE = "forms/create-forms",
  FORMS_MERGE_DUPLICATES = "forms/merge-duplicates",
  PINNED_FIELDS = "forms/pinned-fields",
}

export enum OrganizationDetailsTabs {
  GENERAL = "general",
  ACTIVITIES = "activities",
  MAP = "map",
  LEAD_GENERATION = "lead-generation",
  LEADERBOARD = "leaderboard",
  DATA = "data",
  DEFAULT_COLUMNS = "default-columns",
  DEALS = "deals",
  MILEAGE_TRACKING = "mileageTracking",
}

export default SettingPath;

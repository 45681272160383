import { Guide, GuideUserStatus } from "@mapmycustomers/shared/types/entity";

const getGuidesStatusMap = (guidesStatus: GuideUserStatus[]) =>
  guidesStatus.reduce((result, guideStatus) => {
    if (!result[guideStatus.guideId]) {
      result[guideStatus.guideId] = [];
    }
    result[guideStatus.guideId].push(guideStatus);
    return result;
  }, {} as Record<Guide["id"], GuideUserStatus[]>);

export default getGuidesStatusMap;

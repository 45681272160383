import { defineMessages } from "react-intl";

const messages = defineMessages({
  activityDateTimeDescription: {
    id: "email.creationModal.activityCreationModal.activityDateTimeDescription",
    defaultMessage: "Date and time will be a reflection of when each email is sent",
    description: "Activity date and time description in activity creation modal",
  },
  activityName: {
    id: "email.creationModal.activityCreationModal.activityName",
    defaultMessage: "Title",
    description: "Activity name label in activity creation modal",
  },
  activityType: {
    id: "email.creationModal.activityCreationModal.activityType",
    defaultMessage: "Type",
    description: "Activity type label in activity creation modal",
  },
  assignee: {
    id: "email.creationModal.activityCreationModal.assignee",
    defaultMessage: "Assignee",
    description: "Assignee label in activity creation modal",
  },
  autoFilledRequiredRelationshipFieldMissedTooltip: {
    id: "email.creationModal.activityCreationModal.autoFilledRequiredRelationshipFieldMissedTooltip",
    defaultMessage:
      "There is a required relationship field that cannot be used because altering its value could create inaccurate relationships when adding records in bulk. Please try emailing only one recipient at a time or changing your layout..",
    description: "Tooltip for send button when autofilled relationship field is required",
  },
  autoFilledWithDealRelationship: {
    id: "email.creationModal.activityCreationModal.autoFilledWithDealRelationship",
    defaultMessage: "Autofilled with deal’s relationship",
    description: "Auto-filled with deal relationship label in activity creation modal",
  },
  autoFilledWithDealRelationshipIfOnlyOneRelationshipExists: {
    id: "email.creationModal.activityCreationModal.autoFilledWithDealRelationshipIfOnlyOneRelationshipExists",
    defaultMessage: "Autofilled with deal’s relationship if one exists.",
    description:
      "Auto-filled with deal relationship if only one relationship exists label in activity creation modal",
  },
  autoFilledWithRecipient: {
    id: "email.creationModal.activityCreationModal.autoFilledWithRecipient",
    defaultMessage: "Auto filled with recipient",
    description: "Auto-filled with recipient label in activity creation modal",
  },
  autoFilledWithRecipientRelationship: {
    id: "email.creationModal.activityCreationModal.autoFilledWithRecipientRelationship",
    defaultMessage: "Autofilled with recipient’s relationship",
    description: "Auto-filled with recipient relationship label in activity creation modal",
  },
  autoFilledWithRecipientRelationshipIfOnlyOneRelationshipExists: {
    id: "email.creationModal.activityCreationModal.autoFilledWithRecipientRelationshipIfOnlyOneRelationshipExists",
    defaultMessage: "Autofilled with recipient’s relationship if only one relationship exists.",
    description:
      "Auto-filled with recipient relationship if only one relationship exists label in activity creation modal",
  },
  autoFilledWithSelectedDeals: {
    id: "email.creationModal.activityCreationModal.autoFilledWithSelectedDeals",
    defaultMessage: "Autofilled with selected deals",
    description: "Auto-filled with selected deals label in activity creation modal",
  },
  autoFillingRelationshipFieldDisabledTooltip: {
    id: "email.creationModal.activityCreationModal.autoFillingRelationshipFieldDisabledTooltip",
    defaultMessage:
      "This field is disabled because its value could create inaccurate relationships when adding records in bulk.",
    description: "Tooltip for auto filling relationship field when it is disabled",
  },
  closeConfirmationNo: {
    id: "email.creationModal.activityCreationModal.closeConfirmationNo",
    defaultMessage: "No, Stay",
    description: "Close confirmation no",
  },
  closeConfirmationTitle: {
    id: "email.creationModal.activityCreationModal.closeConfirmationTitle",
    defaultMessage: "Are you sure? Emails will not be sent and activities will not be created.",
    description: "Close confirmation title",
  },
  closeConfirmationYes: {
    id: "email.creationModal.activityCreationModal.closeConfirmationYes",
    defaultMessage: "Yes, exit",
    description: "Close confirmation yes",
  },
  doesNotRepeat: {
    id: "email.creationModal.activityCreationModal.doesNotRepeat",
    defaultMessage: "Does not repeat",
    description: "Does not repeat label in activity creation modal",
  },
  goBack: {
    id: "email.creationModal.activityCreationModal.goBack",
    defaultMessage: "Go back",
    description: "Go back button in activity creation modal",
  },
  note: {
    id: "email.creationModal.activityCreationModal.note",
    defaultMessage:
      "Values will be filled for all activities generated. You can edit individual activities after creation. Activities are not generated until the email is actually sent.",
    description: "Note in activity creation modal",
  },
  notificationsDisabled: {
    id: "email.creationModal.notificationsDisabled",
    defaultMessage: "Notifications disabled",
    description: "Notifications are disabled for this email",
  },
  title: {
    id: "email.creationModal.activityCreationModal.title",
    defaultMessage: "Create {count} {count, plural, one {Activity} other {Activities}}",
    description: "Title of activity creation modal",
  },
  uniqueCustomFieldRequiredTooltip: {
    id: "email.creationModal.activityCreationModal.uniqueCustomField.required.tooltip.title",
    defaultMessage:
      "There is a required field where the value must be unique, so only one recipient can be emailed at a time.",
    description: "Tooltip for send button when a custom field is required and unique",
  },
});

export default messages;

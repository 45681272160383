import React, { useCallback } from "react";

import Col from "antd/es/col";
import Row from "antd/es/row";
import cn from "classnames";
import { useIntl } from "react-intl";

import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";
import { EntityTypeIcon } from "@mapmycustomers/ui";

import Chip from "@app/component/Chip";
import layout from "@app/styles/layout";
import useAnalytics from "@app/util/contexts/useAnalytics";

import FrequencyPreview from "../../FrequencyPreview";
import FrequencyEntity from "../../type/FrequencyEntity";

import styles from "./CustomFrequencySelector.module.scss";

interface Props {
  entity?: FrequencyEntity;
  isGroup?: boolean;
  onHide?: () => void;
  onUpdate?: (data: Partial<Cadenced>) => void;
}

const CustomFrequencySelector: React.FC<Props> = ({ entity, isGroup, onHide, onUpdate }) => {
  const intl = useIntl();
  const analytics = useAnalytics();

  const handleSetFrequency = useCallback(
    (data: Partial<Cadenced>) => {
      analytics.clicked(["Selected Interval", "Custom"], data);
      onUpdate?.(data);
      onHide?.();
    },
    [analytics, onHide, onUpdate]
  );

  return (
    <div className={cn(styles.container, styles.shadowed)}>
      {entity && (
        <div className={cn(styles.title, styles.shadowed)}>
          <Row align="middle" gutter={layout.spacerM}>
            <Col>
              {isGroup
                ? intl.formatMessage({
                    id: "frequency.CustomFrequencySelector.manageTitle",
                    defaultMessage: "Manage Frequency",
                    description: "frequency component - custom cadence selector - Manage Frequency",
                  })
                : intl.formatMessage({
                    id: "frequency.CustomFrequencySelector.specificText",
                    defaultMessage: "Specific to",
                    description: "frequency component - custom cadence selector - specific to",
                  })}
            </Col>
            {!isGroup && (
              <Col className={styles.entityContainer}>
                <Chip
                  icon={
                    <EntityTypeIcon className={styles[entity.entity]} entityType={entity.entity} />
                  }
                >
                  <span>{entity.name}</span>
                </Chip>
              </Col>
            )}
          </Row>
        </div>
      )}
      <div className={styles.previewWrapper}>
        <FrequencyPreview
          entity={entity}
          isGroup={isGroup}
          lastCompletedActivity={entity?.lastCompletedActivity}
          onCancel={onHide}
          onSetFrequency={handleSetFrequency}
        />
      </div>
    </div>
  );
};

export default CustomFrequencySelector;

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { faSearch } from "@fortawesome/pro-regular-svg-icons/faSearch";
import { faBooks } from "@fortawesome/pro-solid-svg-icons/faBooks";
import { faCog } from "@fortawesome/pro-solid-svg-icons/faCog";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import AntDMenu, { MenuProps } from "antd/es/menu";
import cn from "classnames";
import { useIntl } from "react-intl";

import { isMacOS } from "@mapmycustomers/shared/util/browser";

import { showGlobalCreateActivityModal } from "@app/component/createEditEntity/Activity/store/actions";
import { showGlobalCreateCompanyModal } from "@app/component/createEditEntity/CreateCompanyModal/store/actions";
import { showGlobalCreateDealModal } from "@app/component/createEditEntity/CreateDealModal/store/actions";
import { showGlobalCreatePersonModal } from "@app/component/createEditEntity/CreatePersonModal/store/actions";
import Notifications from "@app/component/NavigationBar/component/Notifications";
import Path from "@app/enum/Path";
import SettingPath from "@app/enum/settings/SettingPath";
import { showEmailQueue } from "@app/store/email/actions";
import { showGlobalSearch } from "@app/store/globalSearch/actions";
import {
  doesUserHaveAccessToImports,
  isCurrentUserReadOnly,
  isEmailServiceSupported,
  isOnboardingEnabled,
} from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import navbarAnalytics, { navbarRightMenuClick } from "@app/util/analytic/navbarAnalytics";

import menuStyles from "./CommonMenu.module.scss";
import MenuItemTitle from "./MenuItemTitle";
import styles from "./RightMenu.module.scss";

interface Props {
  doesUserHaveAccessToImports: boolean;
  isEmailServiceSupported: boolean;
  isOnboardingSupported: boolean;
  isReadOnlyMember: boolean;
  onShowCreateActivityModal: typeof showGlobalCreateActivityModal;
  onShowCreateCompanyModal: typeof showGlobalCreateCompanyModal;
  onShowCreateDealModal: typeof showGlobalCreateDealModal;
  onShowCreatePersonModal: typeof showGlobalCreatePersonModal;
  onShowEmailQueue: typeof showEmailQueue;
  onShowGlobalSearch: typeof showGlobalSearch;
}

const RightMenu: React.FC<Props> = ({
  doesUserHaveAccessToImports,
  isEmailServiceSupported,
  isOnboardingSupported,
  isReadOnlyMember,
  onShowCreateActivityModal,
  onShowCreateCompanyModal,
  onShowCreateDealModal,
  onShowCreatePersonModal,
  onShowEmailQueue,
  onShowGlobalSearch,
}) => {
  const intl = useIntl();

  const handleNavigate: MenuProps["onSelect"] = useCallback((info) => {
    navbarRightMenuClick(info!.key);
  }, []);

  const showCreateCompanyModal = useCallback(() => {
    onShowCreateCompanyModal({ showPostCreatePrompt: true });
  }, [onShowCreateCompanyModal]);

  const showCreatePersonModal = useCallback(() => {
    onShowCreatePersonModal({ showPostCreatePrompt: true });
  }, [onShowCreatePersonModal]);

  const showCreateDealModal = useCallback(() => {
    onShowCreateDealModal({ showPostCreatePrompt: true });
  }, [onShowCreateDealModal]);

  const handleSearchClick = useCallback(() => {
    onShowGlobalSearch();
  }, [onShowGlobalSearch]);

  const handleEmailQueueClick = useCallback(() => {
    navbarAnalytics.clicked(["Email Queue"]);
    onShowEmailQueue();
  }, [onShowEmailQueue]);

  return (
    <AntDMenu
      className={cn(menuStyles.menu, styles.menu, {
        [styles.emailVisible]: isEmailServiceSupported,
        [styles.onboardingVisible]: isOnboardingSupported,
      })}
      mode="horizontal"
      onSelect={handleNavigate}
      triggerSubMenuAction="click"
    >
      <AntDMenu.Item className={menuStyles.topItem} key="search">
        <MenuItemTitle
          icon={faSearch}
          onClick={handleSearchClick}
          tooltip={
            <div>
              <div>
                {intl.formatMessage({
                  id: "navbar.findIcon.tooltip",
                  defaultMessage: "Search anything",
                  description: "Title of navbar search icon",
                })}
              </div>
              <div>{isMacOS() ? "⇧⌘F" : "Ctrl+Shift+F"}</div>
            </div>
          }
        />
      </AntDMenu.Item>
      {!isReadOnlyMember && (
        <AntDMenu.SubMenu
          className={menuStyles.topItem}
          key="add"
          popupClassName={menuStyles.submenu}
          popupOffset={[16, 0]}
          title={<MenuItemTitle icon={faPlus} />}
        >
          <AntDMenu.Item key="addCompany" onClick={showCreateCompanyModal}>
            {intl.formatMessage({
              id: "navbar.rightMenu.addCompany",
              defaultMessage: "Add Company",
              description: "Title of the Add Company plus item",
            })}
          </AntDMenu.Item>
          <AntDMenu.Item key="addPerson" onClick={showCreatePersonModal}>
            {intl.formatMessage({
              id: "navbar.rightMenu.addPerson",
              defaultMessage: "Add Person",
              description: "Title of the Add Person plus item",
            })}
          </AntDMenu.Item>
          <AntDMenu.Item key="addDeal" onClick={showCreateDealModal}>
            {intl.formatMessage({
              id: "navbar.rightMenu.addDeal",
              defaultMessage: "Add Deal",
              description: "Title of the Add Deal plus item",
            })}
          </AntDMenu.Item>
          <AntDMenu.Item key="addActivity" onClick={() => onShowCreateActivityModal(undefined)}>
            {intl.formatMessage({
              id: "navbar.rightMenu.addActivity",
              defaultMessage: "Add Activity",
              description: "Title of the Add Activity plus item",
            })}
          </AntDMenu.Item>
          {doesUserHaveAccessToImports && <AntDMenu.Divider />}
          {doesUserHaveAccessToImports && (
            <AntDMenu.Item key="import">
              <MenuItemTitle path={`${Path.SETTINGS}/${SettingPath.DATA_NEW_IMPORT}`}>
                {intl.formatMessage({
                  id: "navbar.rightMenu.import",
                  defaultMessage: "Import",
                  description: "Title of the Import plus item",
                })}
              </MenuItemTitle>
            </AntDMenu.Item>
          )}
        </AntDMenu.SubMenu>
      )}
      <AntDMenu.Item className={menuStyles.topItem} key="notifications">
        <Notifications />
      </AntDMenu.Item>
      {isEmailServiceSupported && (
        <AntDMenu.Item className={menuStyles.topItem} key="emailQueue">
          <MenuItemTitle icon={faEnvelope} onClick={handleEmailQueueClick} />
        </AntDMenu.Item>
      )}
      {isOnboardingSupported && (
        <AntDMenu.Item className={menuStyles.topItem} key="onboarding">
          <MenuItemTitle icon={faBooks} path={Path.ONBOARDING} />
        </AntDMenu.Item>
      )}
      <AntDMenu.Item className={menuStyles.topItem} key="settings">
        <MenuItemTitle icon={faCog} path={Path.SETTINGS} />
      </AntDMenu.Item>
    </AntDMenu>
  );
};

export const mapStateToProps = (state: RootState) => ({
  doesUserHaveAccessToImports: doesUserHaveAccessToImports(state),
  isEmailServiceSupported: isEmailServiceSupported(state),
  isOnboardingSupported: isOnboardingEnabled(state),
  isReadOnlyMember: isCurrentUserReadOnly(state),
});

const mapDispatchToProps = {
  onShowCreateActivityModal: showGlobalCreateActivityModal,
  onShowCreateCompanyModal: showGlobalCreateCompanyModal,
  onShowCreateDealModal: showGlobalCreateDealModal,
  onShowCreatePersonModal: showGlobalCreatePersonModal,
  onShowEmailQueue: showEmailQueue,
  onShowGlobalSearch: showGlobalSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenu);

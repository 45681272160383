import { useMemo } from "react";

import SchemaFieldType from "@mapmycustomers/shared/enum/SchemaFieldType";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import FormLayout, {
  ChildLayout,
  LayoutSchemaField,
} from "@mapmycustomers/shared/types/layout/FormLayout";

import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import useActualLayoutSchema from "@app/util/useActualLayoutSchema";

interface UseSchemaSchemaArgs {
  crmActivityTypeId?: ActivityType["id"];
  entityType: EntityTypesSupportingFieldCustomization;
  filterFields: (schemaField: LayoutSchemaField) => boolean;
  isCreateForm?: boolean;
  layout: FormLayout;
  stageId?: Stage["id"];
}

const useSchema = ({
  crmActivityTypeId,
  entityType,
  filterFields,
  isCreateForm,
  layout,
  stageId,
}: UseSchemaSchemaArgs): [
  actualSchema: LayoutSchemaField[],
  childLayout: ChildLayout | undefined,
  isVariant: boolean
] => {
  const [fieldsSchema, childLayout, isVariant] = useActualLayoutSchema({
    crmActivityTypeId,
    entityType,
    layout,
    stageId,
  });
  const fieldModel = useMemo(() => getFieldModelByEntityType(entityType), [entityType]);

  const schema = useMemo(
    () =>
      fieldsSchema
        .filter((schemaField) => {
          const field = fieldModel.getByPlatformName(schemaField.field);
          return (
            // interestingly, it is possible to have field in the layout, but archived
            !field?.isArchived &&
            (schemaField.fieldType === SchemaFieldType.DIVIDER ||
              // show only editable fields in create form (or non-editable too if they're required)
              // show all fields in create form respecting the layout (including no access fields if in layout)
              // show all fields in edit form
              (isCreateForm && (field?.isEditable || schemaField.required)) ||
              isCreateForm ||
              (!isCreateForm && field?.isReadable))
          );
        })
        .filter(filterFields),
    [fieldModel, filterFields, isCreateForm, fieldsSchema]
  );

  return [schema, childLayout, isVariant];
};

export default useSchema;

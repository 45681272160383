import { defineMessages, IntlShape } from "react-intl";

import CountryRegion from "@mapmycustomers/shared/enum/CountryRegion";

const messages = defineMessages<CountryRegion>({
  [CountryRegion.CA_ATLANTIC]: {
    id: "countryRegion.ca.atlantic",
    defaultMessage: "Atlantic region (CA)",
    description: "Atlantic region name in Canada",
  },
  [CountryRegion.CA_CENTRAL]: {
    id: "countryRegion.ca.central",
    defaultMessage: "Central Canada (CA)",
    description: "Central region name in Canada",
  },
  [CountryRegion.CA_NORTH]: {
    id: "countryRegion.ca.north",
    defaultMessage: "North (CA)",
    description: "North region name in Canada",
  },
  [CountryRegion.CA_PRAIRIE]: {
    id: "countryRegion.ca.prairie",
    defaultMessage: "Prairie Provinces (CA)",
    description: "Prairie region name in Canada",
  },
  [CountryRegion.CA_WEST_COAST]: {
    id: "countryRegion.ca.westCoast",
    defaultMessage: "West Coast (CA)",
    description: "West Coast region name in Canada",
  },
  [CountryRegion.US_MIDWEST]: {
    id: "countryRegion.us.midwest",
    defaultMessage: "Midwest (US)",
    description: "Midwest region name in US",
  },
  [CountryRegion.US_NORTHEAST]: {
    id: "countryRegion.us.northEast",
    defaultMessage: "Northeast (US)",
    description: "Northeast region name in US",
  },
  [CountryRegion.US_SOUTH]: {
    id: "countryRegion.us.south",
    defaultMessage: "South (US)",
    description: "South region name in US",
  },
  [CountryRegion.US_WEST]: {
    id: "countryRegion.us.west",
    defaultMessage: "West (US)",
    description: "West region name in US",
  },
});

const getCountryRegionDisplayName = (intl: IntlShape, countryRegion: CountryRegion) =>
  intl.formatMessage(messages[countryRegion]);

export default getCountryRegionDisplayName;

import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import FormLayout, { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";

import useSchema from "@app/util/hook/useSchema";

const useFormSchema = (
  entityType: EntityTypesSupportingFieldCustomization,
  layout: FormLayout,
  filterFields: (schemaField: LayoutSchemaField) => boolean,
  isCreateForm?: boolean
) => {
  const form = useFormInstance();
  const stageId = useWatch("stageId", form);
  const crmActivityTypeId = useWatch("activityTypeId", form);

  return useSchema({
    crmActivityTypeId,
    entityType,
    filterFields,
    isCreateForm,
    layout,
    stageId,
  });
};

export default useFormSchema;

import Color from "@mapmycustomers/shared/enum/Color";
import type AnyColor from "@mapmycustomers/shared/types/AnyColor";
import type LongLat from "@mapmycustomers/shared/types/base/LongLat";
import type AreaType from "@mapmycustomers/shared/types/territory/AreaType";
import type User from "@mapmycustomers/shared/types/User";

import { CreateTerritoryPayload } from "@app/store/api/ApiService";
import { addExtraCoordinateToTerritoryDetail } from "@app/store/api/utils/territories";

const getTerritoryPayload = (territory: {
  adm1: string[];
  adm2: string[];
  adm3: string[];
  color?: AnyColor;
  coordinates: LongLat[][];
  hidden?: boolean;
  name: string;
  opacity: number;
  type: "points" | AreaType;
  userId: User["id"];
}): CreateTerritoryPayload => {
  return {
    color: territory.color || Color.BLACK,
    name: territory.name,
    postalCodes: [],
    territoryDetail:
      territory.type !== "points"
        ? {
            adm1: territory.adm1,
            adm2: territory.adm2,
            adm3: territory.adm3,
            hidden: territory.hidden ?? false,
            opacity: territory.opacity,
            region: [],
            type: territory.type,
          }
        : addExtraCoordinateToTerritoryDetail({
            hidden: territory.hidden ?? false,
            opacity: territory.opacity,
            points: { coordinates: territory.coordinates, type: "Polygon" },
            region: [],
            type: territory.type,
          }),
    user: { id: territory.userId },
  };
};

export default getTerritoryPayload;

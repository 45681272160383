import React, { CSSProperties, useCallback } from "react";

import { FontAwesomeIconProps } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import cn from "classnames";

import { EntityType } from "@mapmycustomers/shared/enum";

import EntityTypeIcon from "./EntityTypeIcon";

const block = bem("mmc-entity-type-shaped-icon");

interface Props extends Omit<FontAwesomeIconProps, "className" | "icon" | "onClick"> {
  className?: string;
  entityType: EntityType;
  iconClassName?: string;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void;
  shape?: "circle" | "square";
  style?: CSSProperties;
}

const EntityTypeShapedIcon: React.FC<Props> = ({
  className,
  entityType,
  iconClassName,
  onClick,
  shape = "circle",
  style,
  ...fontAwesomeIconProps
}) => {
  const handleClick = useCallback(
    (event?: React.MouseEvent<HTMLElement>) => {
      onClick?.(event);
    },
    [onClick]
  );
  return (
    <div
      className={cn(block({ [shape]: true }), block(entityType), className)}
      onClick={handleClick}
      style={style}
    >
      <EntityTypeIcon
        className={iconClassName}
        {...fontAwesomeIconProps}
        color="white"
        entityType={entityType}
      />
    </div>
  );
};

export default EntityTypeShapedIcon;

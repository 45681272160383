import { createSelector } from "reselect";

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";

import { getActivityTypes } from "@app/store/activity";
import { getEmailActivityType } from "@app/store/email";
import { RootState } from "@app/store/rootReducer";

import DefaultCheckInVariant from "./DefaultCheckInVariant";

const formLayoutsState = (state: RootState) => state.scene.settings.formLayouts;

export const isLoading = createSelector(formLayoutsState, ({ loading }) => loading);

export const getActiveEntityType = createSelector(
  formLayoutsState,
  ({ activeEntityType }) => activeEntityType
);

export const getLayouts = createSelector(formLayoutsState, ({ layouts }) => layouts);

export const isCreating = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.isCreating
);

export const isSaving = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.saving
);

export const isLayoutEditorLoading = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.loading
);

export const isLayoutEditorHasChanges = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.hasChanges
);

export const getOriginalLayout = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.originalLayout
);

export const getEditedLayout = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.layout
);

export const getEditedLayoutId = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.layout?.id
);

export const getEditedLayoutDefaultCheckInVariant = createSelector(
  getActivityTypes,
  getEditedLayout,
  (activityTypes, layout): DefaultCheckInVariant | undefined => {
    if (layout) {
      if (layout.defaultCheckin) {
        return {
          isPrimary: true,
        };
      }

      const defaultChildLayout = layout.childLayouts?.find(
        (childLayout) => childLayout.defaultCheckin
      );
      const activityType = defaultChildLayout
        ? activityTypes.find(
            (activityType) => activityType.id === defaultChildLayout.crmActivityType!.id
          )
        : undefined;

      if (defaultChildLayout && activityType?.checkInEnabled) {
        return {
          id: defaultChildLayout.id,
          isPrimary: false,
        };
      }
      return undefined;
    }
  }
);

export const getEditedLayoutSchema = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.layout?.schema
);

export const getEditedLayoutSortedSchema = createSelector(getEditedLayout, (layout) =>
  layout?.schema
    ? layout.schema.sort((a: LayoutSchemaField, b: LayoutSchemaField) =>
        a.displayOrder < b.displayOrder ? -1 : a.displayOrder > b.displayOrder ? 1 : 0
      )
    : []
);

export const isEditingChildLayout = createSelector(
  formLayoutsState,
  ({ editedChildLayout }) => !!editedChildLayout
);

export const getEditedChildLayoutId = createSelector(
  formLayoutsState,
  ({ editedChildLayout }) => editedChildLayout
);

export const getEditedChildLayout = createSelector(
  formLayoutsState,
  ({ editedChildLayout, editedLayout }) =>
    editedChildLayout
      ? editedLayout?.layout?.childLayouts?.find(({ id }) => id === editedChildLayout)
      : undefined
);

export const getEditingLayoutSchema = createSelector(
  getEditedLayout,
  getEditedChildLayout,
  (editedLayout, editedChildLayout) => (editedChildLayout ?? editedLayout)?.schema ?? []
);

export const getEditedLayoutName = createSelector(
  formLayoutsState,
  ({ editedLayout }) => editedLayout?.layout?.name
);

export const getEditedLayoutAllowAdd = createSelector(
  formLayoutsState,
  ({ editedLayout }) => !!editedLayout?.layout?.addFields
);

export const areLayoutsRolesAssociationsLoading = createSelector(
  formLayoutsState,
  ({ roles }) => roles.loading
);

export const areLayoutsRolesAssociationsSaving = createSelector(
  formLayoutsState,
  ({ roles }) => roles.saving
);

export const getLayoutsRolesAssociationsList = createSelector(
  formLayoutsState,
  ({ roles }) => roles.roles
);

export const getLayoutsRolesAssociations = createSelector(
  formLayoutsState,
  ({ roles }) => roles.rolesMap
);

export const isEditingLayoutRolesAssociation = createSelector(
  formLayoutsState,
  ({ roles }) => !!roles.editing
);

export const isActivityTypeWarningDismissed = createSelector(
  formLayoutsState,
  ({ isActivityTypeWarningDismissed }) => isActivityTypeWarningDismissed
);

export const isDealStageWarningDismissed = createSelector(
  formLayoutsState,
  ({ isDealStageWarningDismissed }) => isDealStageWarningDismissed
);

export const isEditingActivityPrimaryLayout = createSelector(
  getEditedLayout,
  isEditingChildLayout,
  (editedLayout, editingChildLayout) =>
    editedLayout?.entity === EntityType.ACTIVITY && !editingChildLayout
);

export const isEditingActivityEmailVariantLayout = createSelector(
  getActiveEntityType,
  getEditedChildLayout,
  getEmailActivityType,
  (entityType, childLayout, emailActivityType) =>
    entityType === EntityType.ACTIVITY && childLayout?.crmActivityType?.id === emailActivityType?.id
);

export const isEditingActivityLayoutHasEmailVariantLayouts = createSelector(
  getEditedLayout,
  getEmailActivityType,
  (editedLayout, emailActivityType) =>
    editedLayout?.entity === EntityType.ACTIVITY &&
    editedLayout?.childLayouts?.some(
      ({ crmActivityType }) => crmActivityType?.id === emailActivityType?.id
    ) === true
);

import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import { callApi } from "@app/store/api/callApi";

export function* findCompanies(orgId: Organization["id"], companyIds?: Company["id"][]) {
  const companiesResponse: ListResponse<Company> = yield callApi("fetchCompanies", orgId, {
    $filters: {
      ...(companyIds
        ? {
            $and: [
              {
                id: { $in: companyIds },
              },
            ],
          }
        : undefined),
      includeAccessStatus: true,
    },
    $limit: 100,
    $order: "name",
  });
  return companiesResponse.data;
}

export function* findPeople(orgId: Organization["id"], companyId?: Company["id"]) {
  const peopleResponse: ListResponse<Person> = yield callApi("fetchPeople", orgId, {
    $filters: {
      ...(companyId
        ? {
            $and: [
              {
                "accounts.id": { $in: [companyId] },
              },
            ],
          }
        : undefined),
      includeAccessStatus: true,
    },
    $limit: 100,
    $order: "name",
  });
  return peopleResponse.data;
}

export function* findDeals(
  orgId: Organization["id"],
  companyId?: Company["id"],
  personId?: Person["id"]
) {
  const filters: PlatformFilterCondition[] = [];
  if (companyId) {
    filters.push({ accountId: companyId });
  }
  if (personId) {
    filters.push({ contactId: personId });
  }
  const dealsResponse: ListResponse<Deal> = yield callApi("fetchDeals", orgId, {
    $filters: filters.length ? { $and: filters } : undefined,
    $limit: 100,
    $order: "name",
  });
  return dealsResponse.data;
}

import { ActionType, createAction } from "typesafe-actions";

import {
  EntitiesSupportedByEmailFeature,
  EntityTypesSupportedByEmailFeature,
  MapEntity,
} from "@mapmycustomers/shared/types/entity";

export const fetchAnnotationData = createAction("@component/entityAnnotation/fetchAnnotationData")<{
  callback: (params: { emailSubscribed: boolean | undefined; entity: MapEntity }) => void;
  entityId: MapEntity["id"];
  entityType: MapEntity["entity"];
  failureCallback: () => void;
}>();

export const subscribeEntityEmail = createAction(
  "@component/entityAnnotation/subscribeEntityEmail"
)<{
  callback: () => void;
  entityId: EntitiesSupportedByEmailFeature["id"];
  entityType: EntityTypesSupportedByEmailFeature;
}>();

export const unsubscribeEntityEmail = createAction(
  "@component/entityAnnotation/unsubscribeEntityEmail"
)<{
  callback: () => void;
  entityId: EntitiesSupportedByEmailFeature["id"];
  entityType: EntityTypesSupportedByEmailFeature;
}>();

export type Actions = ActionType<
  typeof fetchAnnotationData | typeof subscribeEntityEmail | typeof unsubscribeEntityEmail
>;

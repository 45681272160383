import { defineMessages, IntlShape } from "react-intl";

import { GuideType } from "@mapmycustomers/shared/enum";

const messages = defineMessages<GuideType>({
  [GuideType.MOBILE]: {
    id: "guideType.mobile",
    defaultMessage: "Mobile",
    description: "Mobile guide type label",
  },
  [GuideType.WEB]: {
    id: "guideType.web",
    defaultMessage: "Web",
    description: "Web guide type label",
  },
});

const getGuideTypeDisplayName = (intl: IntlShape, guideType: GuideType) =>
  intl.formatMessage(messages[guideType]);

export default getGuideTypeDisplayName;

import { EntityType } from "@mapmycustomers/shared/enum";
import ILayoutModel from "@mapmycustomers/shared/types/layout/ILayoutModel";

import LayoutModel from "@app/util/layout/LayoutModel";

import DealLayoutModel from "../DealLayoutModel";

export const activityLayoutModel = new LayoutModel(EntityType.ACTIVITY);
export const companyLayoutModel = new LayoutModel(EntityType.COMPANY);
export const dealLayoutModel = new DealLayoutModel();
export const personLayoutModel = new LayoutModel(EntityType.PERSON);

const entityTypeToLayoutModel = {
  [EntityType.ACTIVITY]: activityLayoutModel,
  [EntityType.COMPANY]: companyLayoutModel,
  [EntityType.DEAL]: dealLayoutModel,
  [EntityType.PERSON]: personLayoutModel,
} as const;

const getLayoutModelByEntityType = (entityType: keyof typeof entityTypeToLayoutModel) =>
  entityTypeToLayoutModel[entityType] as ILayoutModel<typeof entityType>;

export default getLayoutModelByEntityType;

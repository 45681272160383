import React from "react";

import Tooltip from "antd/es/tooltip";

import { Activity, Company, Person } from "@mapmycustomers/shared/types/entity";

import ActivityAssignee from "@app/component/activity/ActivityAssignee";
import ActivityDate from "@app/component/activity/ActivityDate";
import ActivityTypeIcon from "@app/component/activity/ActivityTypeIcon";
import EmailMetrics from "@app/component/EmailMetrics";

import SectionLayout from "../SectionLayout";

import AddressLine from "./AddressLine";
import styles from "./TopSection.module.scss";

interface Props {
  activity: Activity;
  addressRecord: Company | Person | undefined;
  className?: string;
  title: string;
}

const TopSection: React.FC<Props> = ({ activity, addressRecord, className, title }) => {
  return (
    <SectionLayout
      className={className}
      left={
        <ActivityTypeIcon
          activityType={activity.crmActivityType}
          isAutoEmail={!!activity.emailLog}
          size="md"
        />
      }
    >
      <Tooltip mouseEnterDelay={1} title={title}>
        <div className={styles.title}>{title}</div>
      </Tooltip>
      <AddressLine addressRecord={addressRecord} />
      <div className={styles.dateAssignee}>
        <ActivityDate activity={activity} />
        <EmailMetrics emailLog={activity.emailLog} />
        <ActivityAssignee activity={activity} />
      </div>
    </SectionLayout>
  );
};

export default TopSection;

import uniq from "lodash-es/uniq";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import { Guide, GuideUserStatus } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import User from "@mapmycustomers/shared/types/User";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import getGuidesStatusMap from "@app/scene/onboarding/utils/getGuidesStatusMap";
import getUsersStatusMap from "@app/scene/onboarding/utils/getUsersStatusMap";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getCurrentUserId, getOrganizationId } from "@app/store/iam";
import { updateGuideStatus } from "@app/store/onboarding/actions";

import {
  bulkAddGuideAssignees,
  initialize,
  initializeMemberView,
  refreshGuidesStatus,
  updateGuideAssignees,
  updateUserGuides,
} from "./actions";
import { getGuidesStatus } from "./selectors";

export function* onInitialize() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const [response, statusResponse]: [ListResponse<Guide>, ListResponse<GuideUserStatus>] =
      yield all([
        callApi("fetchGuides", orgId, { $limit: 10000, $order: "name" }),
        callApi("fetchGuidesStatus", orgId, {}),
      ]);

    yield put(initialize.success({ guides: response.data, guidesStatus: statusResponse.data }));
  } catch (error) {
    yield put(initialize.failure());
    yield put(handleError({ error }));
  }
}

export function* onInitializeMemberView() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const [response, statusResponse]: [ListResponse<Guide>, ListResponse<GuideUserStatus>] =
      yield all([
        callApi("fetchGuides", orgId, { $limit: 10000, $order: "name" }),
        callApi("fetchGuidesStatus", orgId, {}),
      ]);

    const currentUserId: User["id"] = yield select(getCurrentUserId);
    const userStatusMap: Record<User["id"], GuideUserStatus[]> = yield call(
      getUsersStatusMap,
      statusResponse.data
    );
    const guideIdsAssignedToCurrentUser = new Set(
      (userStatusMap[currentUserId] ?? []).map(({ guideId }) => guideId)
    );

    yield put(
      initialize.success({
        guides: response.data.filter(({ id }) => guideIdsAssignedToCurrentUser.has(id)),
        guidesStatus: userStatusMap[currentUserId] ?? [],
      })
    );
  } catch (error) {
    yield put(initialize.failure());
    yield put(handleError({ error }));
  }
}

function* onUpdateGuideAssignees({ payload }: ReturnType<typeof updateGuideAssignees.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("updateUserAssignment", orgId, [payload]);
    const statusResponse: ListResponse<GuideUserStatus> = yield callApi(
      "fetchGuidesStatus",
      orgId,
      {}
    );
    yield put(updateGuideAssignees.success({ guidesStatus: statusResponse.data }));
  } catch (error) {
    yield put(updateGuideAssignees.failure());
    yield put(handleError({ error }));
  }
}

function* onUpdateUserGuides({ payload }: ReturnType<typeof updateUserGuides.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const guidesStatus: GuideUserStatus[] = yield select(getGuidesStatus);
    const guidesStatusMap: Record<Guide["id"], GuideUserStatus[]> = yield call(
      getGuidesStatusMap,
      guidesStatus
    );

    const previousGuideIds = guidesStatus
      .filter(({ user }) => user.id === payload.userId)
      .map(({ guideId }) => guideId);

    const requestPayload: Array<{ guideId: Guide["id"]; userIds: User["id"][] }> = [];

    // find guides which were removed from this user
    // and include them in request, but without payload.userId
    previousGuideIds.forEach((guideId) => {
      if (!payload.guideIds.includes(guideId)) {
        requestPayload.push({
          guideId,
          userIds: (guidesStatusMap[guideId] ?? [])
            .map(({ user }) => user.id)
            .filter((id) => id !== payload.userId),
        });
      }
    });

    // now let's add all new guides which were assigned to this user
    payload.guideIds.forEach((guideId) => {
      requestPayload.push({
        guideId,
        userIds: uniq([
          ...(guidesStatusMap[guideId] ?? []).map(({ user }) => user.id),
          payload.userId,
        ]),
      });
    });

    yield callApi("updateUserAssignment", orgId, requestPayload);
    const statusResponse: ListResponse<GuideUserStatus> = yield callApi(
      "fetchGuidesStatus",
      orgId,
      {}
    );
    yield put(updateUserGuides.success({ guidesStatus: statusResponse.data }));
  } catch (error) {
    yield put(updateUserGuides.failure());
    yield put(handleError({ error }));
  }
}

function* onBulkAddGuideAssignees({ payload }: ReturnType<typeof bulkAddGuideAssignees.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const guidesStatus: GuideUserStatus[] = yield select(getGuidesStatus);
    const guidesStatusMap: Record<Guide["id"], GuideUserStatus[]> = yield call(
      getGuidesStatusMap,
      guidesStatus
    );

    const requestPayload: Array<{ guideId: Guide["id"]; userIds: User["id"][] }> =
      payload.guideIds.map((guideId) => {
        const currentUserIds = (guidesStatusMap[guideId] ?? []).map(({ user }) => user.id);
        return {
          guideId,
          userIds: uniq([...currentUserIds, ...payload.userIds]),
        };
      });

    yield callApi("updateUserAssignment", orgId, requestPayload);

    const statusResponse: ListResponse<GuideUserStatus> = yield callApi(
      "fetchGuidesStatus",
      orgId,
      {}
    );
    yield put(bulkAddGuideAssignees.success({ guidesStatus: statusResponse.data }));
  } catch (error) {
    yield put(bulkAddGuideAssignees.failure());
    yield put(handleError({ error }));
  }
}

function* onRefreshGuideStatuses() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const statusResponse: ListResponse<GuideUserStatus> = yield callApi(
      "fetchGuidesStatus",
      orgId,
      {}
    );
    yield put(refreshGuidesStatus.success({ guidesStatus: statusResponse.data }));
  } catch {
    yield put(refreshGuidesStatus.failure());
  }
}

export function* onboardingSaga() {
  yield takeLatest(initialize.request, onInitialize);
  yield takeLatest(initializeMemberView.request, onInitializeMemberView);
  yield takeEvery(updateGuideAssignees.request, onUpdateGuideAssignees);
  yield takeEvery(bulkAddGuideAssignees.request, onBulkAddGuideAssignees);
  yield takeEvery(updateUserGuides.request, onUpdateUserGuides);
  yield takeLatest(refreshGuidesStatus.request, onRefreshGuideStatuses);
  // also force-refresh guides status when any flow progress happens:
  yield takeLatest(updateGuideStatus.success, onRefreshGuideStatuses);
}

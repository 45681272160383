import React, { useMemo } from "react";

import cn from "classnames";

import {
  AnyEntity,
  EntityTypesSupportingFieldCustomization,
  TypedEntity,
} from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

import Field from "./components/Field";
import registerAllComponents from "./components/registerAll";
import styles from "./FieldGrid.module.scss";
import { filterPinnedFields } from "./utils/util";

interface Props {
  className?: string;
  columns: IField[];
  entity: TypedEntity<AnyEntity, EntityTypesSupportingFieldCustomization>;
  showEmpty?: boolean;
}

const FieldGrid: React.FC<Props> = ({ className, columns, entity, showEmpty }) => {
  const filteredColumns = useMemo(() => columns.filter(filterPinnedFields), [columns]);

  if (!filteredColumns.length) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      {filteredColumns.map((field) => (
        <Field entity={entity} field={field} key={field.name} showEmpty={showEmpty} />
      ))}
    </div>
  );
};

registerAllComponents();

export default FieldGrid;

import isWeekend from "date-fns/isWeekend";
import { call, select } from "redux-saga/effects";

import { EntityType } from "@mapmycustomers/shared";
import DealStageType from "@mapmycustomers/shared/enum/DealStageType";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import PlatformFilterOperator from "@mapmycustomers/shared/enum/PlatformFilterOperator";
import { Activity, AnyEntity, MapEntity } from "@mapmycustomers/shared/types/entity";
import { Deal } from "@mapmycustomers/shared/types/entity";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import LeaderboardItem from "@mapmycustomers/shared/types/entity/LeaderboardItem";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformMapRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformMapRequest";

import { DateRange, getDatesRange } from "@app/enum/dashboard/dateRanges";
import { NO_CONTACT_IN_ENTITY_TYPES } from "@app/scene/dashboard/components/cards/NoContactIn/consts";
import ActivitiesLoggedCardData from "@app/scene/dashboard/store/cards/activitiesLogged/ActivitiesLoggedCardData";
import ActivitiesOverdueCardData from "@app/scene/dashboard/store/cards/activitiesOverdue/ActivitiesOverdueCardData";
import CheckInsCardData from "@app/scene/dashboard/store/cards/checkIns/CheckInsCardData";
import ClosedStageDealCountCardData from "@app/scene/dashboard/store/cards/closedStageDealCount/ClosedStageDealCountCardData";
import ClosedStageDealAmountCardData from "@app/scene/dashboard/store/cards/closedStageDealCount/ClosedStageDealCountCardData";
import CustomerFaceTimeCardData from "@app/scene/dashboard/store/cards/customerFaceTime/CustomerFaceTimeData";
import NewRecordsCardData from "@app/scene/dashboard/store/cards/newRecords/NewRecordsCardData";
import { getFilters } from "@app/scene/dashboard/store/cards/newRecords/sagas";
import NewRoutesCardData from "@app/scene/dashboard/store/cards/newRoutes/NewRoutesCardData";
import { getFilters as getNewRoutesFilters } from "@app/scene/dashboard/store/cards/newRoutes/sagas";
import NoContactInCardData from "@app/scene/dashboard/store/cards/noContactIn/NoContactInCardData";
import OpenStageDealAmountCardData from "@app/scene/dashboard/store/cards/openStageDealAmount/OpenStageDealAmountCardData";
import RecordsPastDueCardData from "@app/scene/dashboard/store/cards/recordsPastDue/RecordsPastDueCardData";
import { ApiMethodName } from "@app/store/api/ApiService";
import { callApi } from "@app/store/api/callApi";
import { getFunnelStages } from "@app/store/deal";
import { getOrganizationId } from "@app/store/iam";
import ActivitiesLoggedCardConfiguration from "@app/types/dashboard/cards/activitiesLogged/ActivitiesLoggedCardConfiguration";
import ActivitiesOverdueCardConfiguration from "@app/types/dashboard/cards/activitiesOverdue/ActivitiesOverdueCardConfiguration";
import CheckInsCardConfiguration from "@app/types/dashboard/cards/checkIns/CheckInsCardConfiguration";
import ClosedStageDealAmountCardConfiguration from "@app/types/dashboard/cards/closedStageDealAmount/ClosedStageDealAmountCardConfiguration";
import ClosedStageDealCountCardConfiguration from "@app/types/dashboard/cards/closedStageDealCount/ClosedStageDealCountCardConfiguration";
import CustomerFaceTimeCardConfiguration, {
  CustomerFaceTimeAggregationType,
} from "@app/types/dashboard/cards/customerFaceTime/CustomerFaceTimeCardConfiguration";
import EntityTypesSupportedInNewRecords from "@app/types/dashboard/cards/newRecords/EntityTypesSupportedInNewRecords";
import NewRecordsCardConfiguration from "@app/types/dashboard/cards/newRecords/NewRecordsCardConfiguration";
import EntityTypesSupportedInNewRoutes from "@app/types/dashboard/cards/newRoutes/EntityTypesSupportedInNewRoutes";
import NewRoutesCardConfiguration from "@app/types/dashboard/cards/newRoutes/NewRoutesCardConfiguration";
import NoContactInCountCardConfiguration from "@app/types/dashboard/cards/noContactInCount/NoContactInCountCardConfiguration";
import DealRottingCriteriaOptions from "@app/types/dashboard/cards/openStageDealAmount/DealRottingCriteriaOptions";
import OpenStageDealAmountCardConfiguration from "@app/types/dashboard/cards/openStageDealAmount/OpenStageDealAmountCardConfiguration";
import RecordsPastDueCardConfiguration from "@app/types/dashboard/cards/recordsPastDue/RecordsPastDueCardConfiguration";
import FrequencyStatusType from "@app/types/dashboard/FrequencyStatusType";
import Scope from "@app/types/dashboard/Scope";
import AggregatedListResponse from "@app/types/viewModel/AggregatedListResponse";
import { doesWeekStartFromMonday, getLocalTimeZone } from "@app/util/dates";
import { parseApiDate } from "@app/util/parsers";

interface CustomerFaceTimeCountAggregationItem {
  doc_count: number;
  key: number; // date in numeric format
  secondary: {
    doc_count: number;
    key: number;
    sum_faceTime: {
      value: number;
    };
  }[]; // key === 1 for verified and === 0 for unverified
}

interface ReliabilityCountAggregationItem {
  key: number; // date in numeric format
  secondary: { doc_count: number; key: number }[]; // key === 1 for verified and === 0 for unverified
}

const granularityByDateRange: Record<DateRange, string> = {
  [DateRange.DAY]: "hour",
  [DateRange.MONTH]: "day",
  [DateRange.QUARTER]: "week",
  [DateRange.WEEK]: "day",
  [DateRange.YEAR]: "month",
};

interface CountAggregationItem {
  doc_count: number;
  key: number; // date in numeric format
  sum_adjustedAmount?: { value: number };
  sum_amount?: { value: number };
}

const newRecordsMetricByEntityType: Record<
  EntityTypesSupportedInNewRecords,
  LeaderboardMetricFieldName
> = {
  [EntityType.COMPANY]: LeaderboardMetricFieldName.COMPANIES_CREATED,
  [EntityType.DEAL]: LeaderboardMetricFieldName.DEALS_CREATED,
  [EntityType.PERSON]: LeaderboardMetricFieldName.PEOPLE_CREATED,
};

const fetchMethodByEntityType: Record<EntityTypesSupportedInNewRecords, ApiMethodName> = {
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
};

const fetchMethodByEntityTypeNewRoutes: Record<EntityTypesSupportedInNewRoutes, ApiMethodName> = {
  [EntityType.COMPANY_ROUTE]: "fetchCompanyRoutesAggregated",
  [EntityType.PEOPLE_ROUTE]: "fetchPeopleRoutesAggregated",
};

export function* fetchCheckInsCardDataHelper(params: {
  callback: (data: CheckInsCardData) => void;
  configuration: CheckInsCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;
  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];
  const range = getDatesRange(dateRange.range, dateRange.subRange);

  const filters = {
    assigneeId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    completedAt: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
    crmActivityTypeId: criteria.activityTypeIds.length
      ? { $in: criteria.activityTypeIds }
      : undefined,
    reliability: { $ne: null },
    source: criteria.sources.length ? { $in: criteria.sources } : undefined,
    // TODO: instead of team/user here we should probably filter by an assigneeId field
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
  };

  const payload = {
    $aggs: {
      count: ["id"],
      primaryGroup: {
        field: "completedAt",
        interval: granularity,
        offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
        timezone: getLocalTimeZone(),
      },
      secondaryGroup: {
        field: "reliability",
      },
    },
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<Activity, ReliabilityCountAggregationItem[]> =
    yield callApi("fetchActivities", orgId, payload);

  const history: CheckInsCardData["history"] = response.aggregations
    .map((item) => ({
      date: parseApiDate(item.key),
      unverifiedCount: criteria.includeUnverified
        ? item.secondary.find(({ key }) => key === 0)?.doc_count
        : undefined,
      verifiedCount: item.secondary.find(({ key }) => key === 1)?.doc_count ?? 0,
    }))
    .filter(
      ({ date, unverifiedCount, verifiedCount }) =>
        !isWeekend(date) ||
        verifiedCount + (preferences.showUnverified && unverifiedCount ? unverifiedCount : 0) > 0
    );

  const result: CheckInsCardData = {
    history: preferences.showChart ? history : undefined,
    verifiedCount: 0,
  };

  result.verifiedCount = history.reduce((sum, item) => sum + item.verifiedCount, 0) ?? 0;
  if (criteria.includeUnverified) {
    result.unverifiedCount = history.reduce((sum, item) => sum + (item.unverifiedCount ?? 0), 0);
  }

  if (result.history && preferences.cumulative) {
    let verifiedSum = 0;
    let unverifiedSum = 0;
    result.history = result.history.map((item) => {
      verifiedSum += item.verifiedCount;
      unverifiedSum += item.unverifiedCount ?? 0;
      return { ...item, unverifiedCount: unverifiedSum, verifiedCount: verifiedSum };
    });
  }

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const previousRangePayload = {
      ...payload,
      $filters: {
        ...filters,
        completedAt: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
    };
    const response: AggregatedListResponse<Activity, ReliabilityCountAggregationItem[]> =
      yield callApi("fetchActivities", orgId, previousRangePayload);

    // if we include unverified check-ins, we can just take the total number
    // otherwise, we need to calculate total ourselves using only doc_count for
    // verified checkins
    result.previousCount = criteria.includeUnverified
      ? response.total
      : response.aggregations
          .flatMap((item) => item.secondary.filter((subitem) => subitem.key === 1))
          .reduce((sum, item) => sum + item.doc_count, 0);

    if (preferences.showChart) {
      result.previousHistory = response.aggregations
        .map((item) => ({
          date: parseApiDate(item.key),
          unverifiedCount: criteria.includeUnverified
            ? item.secondary.find(({ key }) => key === 0)?.doc_count
            : undefined,
          verifiedCount: item.secondary.find(({ key }) => key === 1)?.doc_count ?? 0,
        }))
        .filter(
          ({ date, unverifiedCount, verifiedCount }) =>
            !isWeekend(date) ||
            verifiedCount + (preferences.showUnverified && unverifiedCount ? unverifiedCount : 0) >
              0
        );

      if (result.previousHistory && preferences.cumulative) {
        let verifiedSum = 0;
        let unverifiedSum = 0;
        result.previousHistory = result.previousHistory.map((item) => {
          verifiedSum += item.verifiedCount;
          unverifiedSum += item.unverifiedCount ?? 0;
          return { ...item, unverifiedCount: unverifiedSum, verifiedCount: verifiedSum };
        });
      }
    }
  }

  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId)) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        crmActivityTypeId: criteria.activityTypeIds.length
          ? { $in: criteria.activityTypeIds }
          : undefined,
        date: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
        includePerformanceMetric: true,
        property: { $in: [LeaderboardMetricFieldName.CHECK_INS_COMPLETED] },
        summary: isTeamScope ? "team" : "user",
      },
      undefined
    );
    const rank = isTeamScope
      ? response.data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : response.data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchNewRecordsCardDataHelper(params: {
  callback: (data: NewRecordsCardData) => void;
  configuration: NewRecordsCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];

  const filters = getFilters(scope, configuration);
  const fetchMethod = fetchMethodByEntityType[criteria.entityType];

  const payload = {
    $aggs: preferences.showChart
      ? {
          count: ["id"],
          primaryGroup: {
            field: "createdAt",
            interval: granularity,
            offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
            timezone: getLocalTimeZone(),
          },
        }
      : undefined,
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<AnyEntity, CountAggregationItem[] | undefined> =
    yield callApi(fetchMethod, orgId, payload);

  let sum = 0;
  const result: NewRecordsCardData = {
    count: response.total,
    history: response.aggregations?.map((item) => {
      sum += item.doc_count;
      return {
        count: preferences.cumulative ? sum : item.doc_count,
        date: parseApiDate(item.key),
      };
    }),
  };

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const payload = {
      $aggs: preferences.showChart
        ? {
            count: ["id"],
            primaryGroup: {
              field: "createdAt",
              interval: granularity,
              offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
              timezone: getLocalTimeZone(),
            },
          }
        : undefined,
      $filters: {
        ...filters,
        createdAt: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
      $limit: 0,
    };
    const response: AggregatedListResponse<AnyEntity, CountAggregationItem[] | undefined> =
      yield callApi(fetchMethod, orgId, payload);
    result.previousCount = response.total;
    if (preferences.showChart) {
      let sum = 0;
      result.previousHistory = response.aggregations?.map((item) => {
        sum += item.doc_count;
        return {
          count: preferences.cumulative ? sum : item.doc_count,
          date: parseApiDate(item.key),
        };
      });
    }
  }

  const filteredHistory = result.history?.filter(
    ({ count, date }, index) =>
      !isWeekend(date) || count || (result.previousHistory && result.previousHistory[index]?.count)
  );

  const filteredPreviousHistory = result.previousHistory?.filter(
    ({ count }, index) =>
      count ||
      (result.history && (result.history[index]?.count || !isWeekend(result.history[index]?.date)))
  );

  result.history = filteredHistory;
  result.previousHistory = filteredPreviousHistory;
  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId)) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        date: filters.createdAt,
        includePerformanceMetric: true,
        property: { $in: [newRecordsMetricByEntityType[criteria.entityType]] },
        summary: isTeamScope ? "team" : "user",
      },
      undefined
    );
    const rank = isTeamScope
      ? response.data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : response.data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchNewRoutesCardDataHelper(params: {
  callback: (data: NewRoutesCardData) => void;
  configuration: NewRoutesCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];

  const filters = getNewRoutesFilters(scope, configuration);

  const payload = {
    $aggs: preferences.showChart
      ? {
          count: ["id"],
          primaryGroup: {
            field: "createdAt",
            interval: granularity,
            offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
            timezone: getLocalTimeZone(),
          },
        }
      : undefined,
    $filters: filters,
    $limit: 0,
  };
  let result: NewRoutesCardData = { count: 0 };

  if (configuration.criteria.entityTypes.length > 1) {
    const companyRoutesResponse: AggregatedListResponse<
      AnyEntity,
      CountAggregationItem[] | undefined
    > = yield callApi("fetchCompanyRoutesAggregated", orgId, payload);

    const peopleRoutesResponse: AggregatedListResponse<
      AnyEntity,
      CountAggregationItem[] | undefined
    > = yield callApi("fetchPeopleRoutesAggregated", orgId, payload);

    const companyRoutesHistory = companyRoutesResponse.aggregations?.map((item) => ({
      count: item.doc_count,
      date: parseApiDate(item.key),
    }));

    let sum = 0;
    result = {
      count: companyRoutesResponse.total + peopleRoutesResponse.total,
      history: companyRoutesHistory?.map((item, index) => {
        const totalCount =
          item.count + (peopleRoutesResponse.aggregations?.[index]?.doc_count ?? 0);
        sum += totalCount;
        return {
          ...item,
          count: preferences.cumulative ? sum : totalCount,
        };
      }),
    };
  } else {
    const fetchMethod = fetchMethodByEntityTypeNewRoutes[criteria.entityTypes[0]];
    const response: AggregatedListResponse<AnyEntity, CountAggregationItem[] | undefined> =
      yield callApi(fetchMethod, orgId, payload);

    let sum = 0;
    result = {
      count: response.total,
      history: response.aggregations?.map((item) => {
        sum += item.doc_count;
        return {
          count: preferences.cumulative ? sum : item.doc_count,
          date: parseApiDate(item.key),
        };
      }),
    };
  }

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const payload = {
      $aggs: preferences.showChart
        ? {
            count: ["id"],
            primaryGroup: {
              field: "createdAt",
              interval: granularity,
              offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
              timezone: getLocalTimeZone(),
            },
          }
        : undefined,
      $filters: {
        ...filters,
        createdAt: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
      $limit: 0,
    };
    if (configuration.criteria.entityTypes.length > 1) {
      const companyRoutesResponse: AggregatedListResponse<
        AnyEntity,
        CountAggregationItem[] | undefined
      > = yield callApi("fetchCompanyRoutes", orgId, payload);

      const peopleRoutesResponse: AggregatedListResponse<
        AnyEntity,
        CountAggregationItem[] | undefined
      > = yield callApi("fetchPeopleRoutes", orgId, payload);

      const companyRoutesHistory = companyRoutesResponse.aggregations?.map((item) => ({
        count: item.doc_count,
        date: parseApiDate(item.key),
      }));

      result.previousCount = companyRoutesResponse.total + peopleRoutesResponse.total;

      if (preferences.showChart) {
        result.previousHistory = companyRoutesHistory?.map((item, index) => ({
          ...item,
          count: item.count + (peopleRoutesResponse.aggregations?.[index]?.doc_count ?? 0),
        }));
      }
    } else {
      const fetchMethod = fetchMethodByEntityTypeNewRoutes[criteria.entityTypes[0]];
      const response: AggregatedListResponse<AnyEntity, CountAggregationItem[] | undefined> =
        yield callApi(fetchMethod, orgId, payload);
      result.previousCount = response.total;
      if (preferences.showChart) {
        result.previousHistory = response.aggregations?.map((item) => ({
          count: item.doc_count,
          date: parseApiDate(item.key),
        }));
      }
    }
  }

  const filteredHistory = result.history?.filter(
    ({ count, date }, index) =>
      !isWeekend(date) || count || (result.previousHistory && result.previousHistory[index]?.count)
  );

  const filteredPreviousHistory = result.previousHistory?.filter(
    ({ count }, index) =>
      count ||
      (result.history && (result.history[index]?.count || !isWeekend(result.history[index]?.date)))
  );

  result.history = filteredHistory;
  result.previousHistory = filteredPreviousHistory;
  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId)) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        date: filters.createdAt,
        includePerformanceMetric: true,
        property: { $in: [LeaderboardMetricFieldName.ROUTES_CREATED] },
        summary: isTeamScope ? "team" : "user",
      },
      undefined
    );
    const rank = isTeamScope
      ? response.data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : response.data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchActivitiesLoggedCardDataHelper(params: {
  callback: (data: ActivitiesLoggedCardData) => void;
  configuration: ActivitiesLoggedCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];
  const range = getDatesRange(dateRange.range, dateRange.subRange);

  const filters = {
    assigneeId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    completedAt: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
    crmActivityTypeId: criteria.activityTypeIds.length
      ? { $in: criteria.activityTypeIds }
      : undefined,
    source: criteria.sources.length ? { $in: criteria.sources } : undefined,
    // TODO: instead of team/user here we should probably filter by an assigneeId field
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
  };

  const payload = {
    $aggs: preferences.showChart
      ? {
          count: ["id"],
          primaryGroup: {
            field: "completedAt",
            interval: granularity,
            offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
            timezone: getLocalTimeZone(),
          },
        }
      : undefined,
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<Activity, CountAggregationItem[] | undefined> =
    yield callApi("fetchActivities", orgId, payload);

  let sum = 0;
  const result: ActivitiesLoggedCardData = {
    count: response.total,
    history: response.aggregations?.map((item) => {
      sum += item.doc_count;
      return {
        count: preferences.cumulative ? sum : item.doc_count,
        date: parseApiDate(item.key),
      };
    }),
  };

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const payload = {
      // we need to get previous period's chart data to show it when user hovers over the trend text
      $aggs: preferences.showChart
        ? {
            count: ["id"],
            primaryGroup: {
              field: "completedAt",
              interval: granularity,
              offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
              timezone: getLocalTimeZone(),
            },
          }
        : undefined,
      $filters: {
        ...filters,
        completedAt: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
      $limit: 0,
    };
    const response: AggregatedListResponse<Activity, CountAggregationItem[] | undefined> =
      yield callApi("fetchActivities", orgId, payload);
    result.previousCount = response.total;
    if (preferences.showChart) {
      let sum = 0;
      result.previousHistory = response.aggregations?.map((item) => {
        sum += item.doc_count;
        return {
          count: preferences.cumulative ? sum : item.doc_count,
          date: parseApiDate(item.key),
        };
      });
    }
  }

  const filteredHistory = result.history?.filter(
    ({ count, date }, index) =>
      !isWeekend(date) || count || (result.previousHistory && result.previousHistory[index]?.count)
  );

  const filteredPreviousHistory = result.previousHistory?.filter(
    ({ count }, index) =>
      count ||
      (result.history && (result.history[index]?.count || !isWeekend(result.history[index]?.date)))
  );

  result.history = filteredHistory;
  result.previousHistory = filteredPreviousHistory;
  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId)) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        crmActivityTypeId: criteria.activityTypeIds.length
          ? { $in: criteria.activityTypeIds }
          : undefined,
        date: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
        includePerformanceMetric: true,
        property: { $in: [LeaderboardMetricFieldName.ACTIVITIES_COMPLETED] },
        summary: isTeamScope ? "team" : "user",
      },
      undefined
    );
    const rank = isTeamScope
      ? response.data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : response.data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchActivitiesOverdueCardDataHelper(params: {
  callback: (data: ActivitiesOverdueCardData) => void;
  configuration: ActivitiesOverdueCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const filters = {
    assigneeId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    completed: false,
    crmActivityTypeId: criteria.activityTypeIds.length
      ? { $in: criteria.activityTypeIds }
      : undefined,
    source: criteria.sources.length ? { $in: criteria.sources } : undefined,
    startAt: { [PlatformFilterOperator.LESS_THAN]: [new Date().toISOString()] },
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
  };

  const payload = { $filters: filters, $limit: 0 };

  const response: ListResponse<AnyEntity> = yield callApi("fetchActivities", orgId, payload);

  const result: ActivitiesOverdueCardData = { count: response.total };

  yield call(callback, result);
}

export function* fetchNoContactInCountCardDataHelper(params: {
  callback: (data: NoContactInCardData) => void;
  configuration: NoContactInCountCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange } = configuration;
  const orgId: Organization["id"] = yield select(getOrganizationId);

  const entityTypes =
    Array.isArray(criteria.entityTypes) && criteria.entityTypes.length
      ? criteria.entityTypes
      : NO_CONTACT_IN_ENTITY_TYPES;

  const payload: PlatformMapRequest = {
    $filters: {
      $and: [
        { noContactDaysOut: dateRange.days === null ? null : { $gt: dateRange.days } },
        ...(!scope?.userId && scope?.teamId ? [{ teamId: { $in: [scope.teamId] } }] : []),
        ...(scope?.userId ? [{ userId: { $in: [scope.userId] } }] : []),
        ...(criteria.sources?.length ? [{ source: { $in: criteria.sources } }] : []),
        ...(criteria.frequencyStatus && criteria.frequencyStatus.length === 1
          ? [
              {
                cadenceStatus: {
                  [criteria.frequencyStatus[0] === FrequencyStatusType.UNKNOWN ? "$eq" : "$ne"]:
                    FrequencyStatusType.UNKNOWN,
                },
              },
            ]
          : []),
      ],
      entities: entityTypes.reduce((result, entityType) => ({ ...result, [entityType]: {} }), {}),
    },
    $limit: 0,
  };

  const response: ListResponse<MapEntity> = yield callApi("fetchPins", orgId, payload);
  yield call(callback, { total: response.total });
}

export function* fetchRecordsPastDueCardDataHelper(params: {
  callback: (data: RecordsPastDueCardData) => void;
  configuration: RecordsPastDueCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, extraFilters } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const fetchMethod = fetchMethodByEntityType[criteria.entityType];

  const filters = {
    ...extraFilters,
    cadenceDaysOut: { $gt: 0 },
    source: criteria.sources.length ? { $in: criteria.sources } : undefined,
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
    userId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
  };

  const payload = { $filters: filters, $limit: 0 };

  const response: ListResponse<AnyEntity> = yield callApi(fetchMethod, orgId, payload);

  const result: RecordsPastDueCardData = { count: response.total };

  yield call(callback, result);
}

export function* fetchCustomerFaceTimeCardDataHelper(params: {
  callback: (data: CustomerFaceTimeCardData) => void;
  configuration: CustomerFaceTimeCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;
  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];
  const range = getDatesRange(dateRange.range, dateRange.subRange);

  const filters = {
    assigneeId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
    checkinTime: { $ne: null },
    checkoutTime: { $ne: null },
    completedAt: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
    crmActivityTypeId: criteria.activityTypeIds.length
      ? { $in: criteria.activityTypeIds }
      : undefined,
    reliability: { $ne: null },
    // TODO: instead of team/user here we should probably filter by an assigneeId field
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
  };

  const payload = {
    $aggs: {
      primaryGroup: {
        field: "completedAt",
        interval: granularity,
        offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
        timezone: getLocalTimeZone(),
      },
      secondaryGroup: {
        field: "reliability",
      },
      sum: ["faceTime"],
    },
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<Activity, CustomerFaceTimeCountAggregationItem[]> =
    yield callApi("fetchActivities", orgId, payload);

  const history: CustomerFaceTimeCardData["history"] = response.aggregations.map((item) => {
    const verifiedItem = item.secondary.find(({ key }) => key === 1);
    const unverifiedItem = item.secondary.find(({ key }) => key === 0);
    return {
      date: parseApiDate(item.key),
      unverifiedTime: criteria.includeUnverified
        ? (unverifiedItem?.sum_faceTime.value ?? 0) /
          (criteria.aggregationType === CustomerFaceTimeAggregationType.SUM
            ? 1
            : verifiedItem?.doc_count ?? 1)
        : undefined,
      verifiedTime:
        (verifiedItem?.sum_faceTime.value ?? 0) /
        (criteria.aggregationType === CustomerFaceTimeAggregationType.SUM
          ? 1
          : verifiedItem?.doc_count ?? 1),
    };
  });

  const result: CustomerFaceTimeCardData = {
    history: preferences.showChart ? history : undefined,
    verifiedTime: 0,
  };

  result.verifiedTime = history?.reduce((sum, item) => sum + item.verifiedTime, 0) ?? 0;
  if (criteria.includeUnverified) {
    result.unverifiedTime = history?.reduce((sum, item) => sum + (item.unverifiedTime ?? 0), 0);
  }

  if (result.history && preferences.cumulative) {
    let verifiedSum = 0;
    let unverifiedSum = 0;
    result.history = result.history.map((item) => {
      verifiedSum += item.verifiedTime;
      unverifiedSum += item.unverifiedTime ?? 0;
      return { ...item, unverifiedTime: unverifiedSum, verifiedTime: verifiedSum };
    });
  }

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const payload = {
      $filters: {
        ...filters,
        ...(criteria.includeUnverified ? {} : { reliability: { $eq: true } }),
        completedAt: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
      $limit: 0,
    };
    const response: ListResponse<Activity> = yield callApi("fetchActivities", orgId, payload);
    result.previousCount = response.total;
  }

  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId)) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        crmActivityTypeId: criteria.activityTypeIds.length
          ? { $in: criteria.activityTypeIds }
          : undefined,
        date: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
        includePerformanceMetric: true,
        property: { $in: [LeaderboardMetricFieldName.TOTAL_FACE_TIME] },
        summary: isTeamScope ? "team" : "user",
      },
      undefined
    );
    const rank = isTeamScope
      ? response.data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : response.data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchClosedStageDealCountCardDataHelper(params: {
  callback: (data: ClosedStageDealCountCardData) => void;
  configuration: ClosedStageDealCountCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];
  const range = getDatesRange(dateRange.range, dateRange.subRange);

  const filters = {
    closingDate: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
    stageId: { $in: criteria.dealStageIds },
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
    userId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
  };

  const payload = {
    $aggs: preferences.showChart
      ? {
          count: ["id"],
          primaryGroup: {
            field: "closingDate",
            interval: granularity,
            offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
            timezone: getLocalTimeZone(),
          },
        }
      : undefined,
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<Deal, CountAggregationItem[] | undefined> = yield callApi(
    "fetchDeals",
    orgId,
    payload
  );

  let sum = 0;
  const result: ClosedStageDealCountCardData = {
    count: response.total,
    history: response.aggregations?.map((item) => {
      sum += item.doc_count;
      return {
        count: sum,
        date: parseApiDate(item.key),
      };
    }),
  };

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const payload = {
      // we need to get previous period's chart data to show it when user hovers over the trend text
      $aggs: preferences.showChart
        ? {
            count: ["id"],
            primaryGroup: {
              field: "closingDate",
              interval: granularity,
              offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
              timezone: getLocalTimeZone(),
            },
          }
        : undefined,
      $filters: {
        ...filters,
        closingDate: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
      $limit: 0,
    };
    const response: AggregatedListResponse<Deal, CountAggregationItem[] | undefined> =
      yield callApi("fetchDeals", orgId, payload);
    result.previousCount = response.total;
    if (preferences.showChart) {
      let sum = 0;
      result.previousHistory = response.aggregations?.map((item) => {
        sum += item.doc_count;
        return {
          count: sum,
          date: parseApiDate(item.key),
        };
      });
    }
  }

  const filteredHistory = result.history?.filter(
    ({ count, date }, index) =>
      !isWeekend(date) || count || (result.previousHistory && result.previousHistory[index]?.count)
  );

  const filteredPreviousHistory = result.previousHistory?.filter(
    ({ count }, index) =>
      count ||
      (result.history && (result.history[index]?.count || !isWeekend(result.history[index]?.date)))
  );

  result.history = filteredHistory;
  result.previousHistory = filteredPreviousHistory;
  const funnelStages: Record<number, Stage[]> = yield select(getFunnelStages);
  const stages = Object.values(funnelStages).flat();

  const metricTypes = [];
  const dealClosedStages = stages.filter(({ id }) => criteria.dealStageIds.includes(id));

  const hasClosedWon = dealClosedStages.some(({ type }) => type === DealStageType.WON);
  if (hasClosedWon) {
    metricTypes.push(LeaderboardMetricFieldName.DEALS_CLOSED_WON);
  }

  const hasClosedLost = dealClosedStages.some(({ type }) => type === DealStageType.LOST);
  if (hasClosedLost) {
    metricTypes.push(LeaderboardMetricFieldName.DEALS_CLOSED_LOST);
  }

  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId) && metricTypes.length) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        date: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
        includePerformanceMetric: true,
        property: {
          $in: metricTypes,
        },
        stageId: { $in: criteria.dealStageIds },
        summary: isTeamScope ? "team" : "user",
      },
      undefined
    );
    const data = response.data.sort(
      (a, b) =>
        (b.values.dealsClosedLost ?? 0) +
        (b.values.dealsClosedWon ?? 0) -
        ((a.values.dealsClosedLost ?? 0) + (a.values.dealsClosedWon ?? 0))
    );

    const rank = isTeamScope
      ? data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchClosedStageDealAmountCardDataHelper(params: {
  callback: (data: ClosedStageDealAmountCardData) => void;
  configuration: ClosedStageDealAmountCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange, preferences } = configuration;
  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const orgId: Organization["id"] = yield select(getOrganizationId);

  const granularity = granularityByDateRange[dateRange.range];
  const range = getDatesRange(dateRange.range, dateRange.subRange);

  const filters = {
    closingDate: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
    stageId: { $in: criteria.dealStageIds },
    teamId: isTeamScope ? { [PlatformFilterOperator.CONTAINS]: [scope.teamId!] } : undefined,
    userId: scope?.userId ? { [PlatformFilterOperator.CONTAINS]: [scope.userId] } : undefined,
  };

  const payload = {
    $aggs: preferences.showChart
      ? {
          count: ["id"],
          primaryGroup: {
            field: "closingDate",
            interval: granularity,
            offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
            timezone: getLocalTimeZone(),
          },
          sum: ["amount", "adjustedAmount"],
        }
      : undefined,
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<Deal, CountAggregationItem[] | undefined> = yield callApi(
    "fetchDeals",
    orgId,
    payload
  );

  let sum = 0;
  const result: ClosedStageDealAmountCardData = {
    count:
      response.aggregations?.reduce(
        (sum, { sum_adjustedAmount, sum_amount }) =>
          sum + ((criteria.adjustedAmount ? sum_adjustedAmount?.value : sum_amount?.value) ?? 0),
        0
      ) ?? 0,
    history: response.aggregations?.map((item) => {
      sum +=
        (criteria.adjustedAmount ? item.sum_adjustedAmount?.value : item.sum_amount?.value) ?? 0;
      return {
        count: sum,
        date: parseApiDate(item.key),
      };
    }),
  };

  if (preferences.showTrend) {
    // fetch again, but for the previous range
    const previousRange = getDatesRange(dateRange.range, dateRange.subRange, true);
    const payload = {
      // we need to get previous period's chart data to show it when user hovers over the trend text
      $aggs: preferences.showChart
        ? {
            count: ["id"],
            primaryGroup: {
              field: "closingDate",
              interval: granularity,
              offset: granularity !== "week" || doesWeekStartFromMonday() ? undefined : "-1 day",
              timezone: getLocalTimeZone(),
            },
            sum: ["amount", "adjustedAmount"],
          }
        : undefined,
      $filters: {
        ...filters,
        closingDate: {
          $gte: previousRange.startDate.toISOString(),
          $lte: previousRange.endDate.toISOString(),
        },
      },
      $limit: 0,
    };
    const response: AggregatedListResponse<Deal, CountAggregationItem[] | undefined> =
      yield callApi("fetchDeals", orgId, payload);
    result.previousCount = response.total;
    if (preferences.showChart) {
      let sum = 0;
      result.previousHistory = response.aggregations?.map((item) => {
        sum +=
          (criteria.adjustedAmount ? item.sum_adjustedAmount?.value : item.sum_amount?.value) ?? 0;
        return {
          count: sum,
          date: parseApiDate(item.key),
        };
      });
    }
  }

  const filteredHistory = result.history?.filter(
    ({ count, date }, index) =>
      !isWeekend(date) || count || (result.previousHistory && result.previousHistory[index]?.count)
  );

  const filteredPreviousHistory = result.previousHistory?.filter(
    ({ count }, index) =>
      count ||
      (result.history && (result.history[index]?.count || !isWeekend(result.history[index]?.date)))
  );

  result.history = filteredHistory;
  result.previousHistory = filteredPreviousHistory;
  const funnelStages: Record<number, Stage[]> = yield select(getFunnelStages);
  const stages = Object.values(funnelStages).flat();

  const metricTypes = [];
  const dealClosedStages = stages.filter(({ id }) => criteria.dealStageIds.includes(id));

  const hasClosedWon = dealClosedStages.some(({ type }) => type === DealStageType.WON);
  if (hasClosedWon) {
    metricTypes.push(LeaderboardMetricFieldName.REVENUE_CLOSED_WON);
  }

  const hasClosedLost = dealClosedStages.some(({ type }) => type === DealStageType.LOST);
  if (hasClosedLost) {
    metricTypes.push(LeaderboardMetricFieldName.REVENUE_CLOSED_LOST);
  }

  // if we should show the rank and scope is not the Entire Org
  if (preferences.showRankPosition && (scope?.teamId || scope?.userId) && metricTypes.length) {
    const response: ListResponse<LeaderboardItem> = yield callApi(
      "fetchLeaderboardData",
      orgId,
      {
        date: { $gte: range.startDate.toISOString(), $lte: range.endDate.toISOString() },
        includePerformanceMetric: true,
        property: {
          $in: metricTypes,
        },
        stageId: { $in: criteria.dealStageIds },
        summary: isTeamScope ? "team" : "user",
      },
      undefined
    );
    const data = response.data.sort(
      (a, b) =>
        (b.values.revenueClosedLost ?? 0) +
        (b.values.revenueClosedWon ?? 0) -
        ((a.values.revenueClosedLost ?? 0) + (a.values.revenueClosedWon ?? 0))
    );

    const rank = isTeamScope
      ? data.findIndex(({ teamId }) => teamId === scope!.teamId)
      : data.findIndex(({ userId }) => userId === scope!.userId);
    result.rank = rank >= 0 ? rank + 1 : undefined;
  }

  yield call(callback, result);
}

export function* fetchOpenStageDealAmountCardDataHelper(params: {
  callback: (data: OpenStageDealAmountCardData) => void;
  configuration: OpenStageDealAmountCardConfiguration;
  scope?: Scope;
}) {
  const { callback, configuration, scope } = params;
  const { criteria, dateRange, extraFilters } = configuration;

  const orgId: Organization["id"] = yield select(getOrganizationId);
  const filters = {
    ...extraFilters,
    $and: [
      ...(dateRange.days
        ? [
            {
              closingDate: {
                $interval: {
                  $gte: "0 days",
                  $lt: `${dateRange.days} days`,
                  $round: "days",
                },
              },
            },
          ]
        : []),
      ...(criteria.rottingOptions.length === 2 || criteria.rottingOptions.length === 0
        ? []
        : [
            {
              rottingDaysOut: criteria.rottingOptions.includes(
                DealRottingCriteriaOptions.DEALS_ROTTING
              )
                ? { $gt: 0 }
                : { $lt: 0 },
            },
          ]),
      { stageId: { $in: criteria.dealStageIds } },
      ...(scope?.teamId ? [{ teamId: { $in: [scope.teamId] } }] : []),
      ...(scope?.userId ? [{ userId: { $in: [scope.userId] } }] : []),
    ],
  };

  const payload = {
    $aggs: { sum: ["amount"] },
    $filters: filters,
    $limit: 0,
  };

  const response: AggregatedListResponse<Deal> = yield callApi("fetchDeals", orgId, payload);

  const result: OpenStageDealAmountCardData = { amount: response.aggregations.sum_amount.value };

  yield call(callback, result);
}

import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import { Dropdown, MenuProps } from "antd";
import { isEqual } from "lodash-es";

import { PinnedFieldsVisibility } from "@mapmycustomers/shared";
import RoleEnum from "@mapmycustomers/shared/enum/Role";
import Visibility from "@mapmycustomers/shared/enum/Visibility";
import Team from "@mapmycustomers/shared/types/Team";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import { useConfigProvider } from "../ConfigProvider";
import TextField from "../input/TextField";

const block = bem("mmc-visibility-selector");

interface VisibilitySelectorProps {
  children: ReactNode;
  disabled?: boolean;
  onChange?: (visibility: PinnedFieldsVisibility, selectedTeams?: Team["id"][]) => void;
  selectedTeams?: Team["id"][];
  subLabel?: string;
  teams: Team[];
  userRole?: RoleEnum;
  value?: PinnedFieldsVisibility;
}

const VisibilitySelector: React.FC<VisibilitySelectorProps> = ({
  children,
  disabled,
  onChange,
  selectedTeams,
  subLabel,
  teams,
  userRole,
  value,
}) => {
  const configProvider = useConfigProvider();
  const [open, show, close] = useBoolean(false);
  const [visibility, setVisibility] = useState(value);
  const [selectedTeamIds, setSelectedTeamIds] = useState(selectedTeams);

  const [search, setSearch] = useState("");

  const handleSelect = useCallback((info) => {
    setVisibility?.(info.key);
    if (info.key !== Visibility.SHARED_WITH_TEAM) {
      setSelectedTeamIds(undefined);
    }
  }, []);

  const isChanged = useMemo(
    () => visibility !== value || !isEqual(selectedTeamIds, selectedTeams),
    [selectedTeamIds, selectedTeams, value, visibility]
  );

  const handleOpenChange = useCallback(
    (open: boolean) => {
      (open ? show : close)();
      if (!open && visibility && isChanged) {
        onChange?.(visibility, selectedTeamIds);
      }
    },
    [close, isChanged, onChange, selectedTeamIds, show, visibility]
  );

  const menu: MenuProps = useMemo(() => {
    const items = [
      ...(userRole === RoleEnum.OWNER
        ? [
            {
              key: Visibility.SHARED_WITH_ORGANIZATION,
              label: configProvider.formatMessage("ui.visibilitySelector.entireOrg"),
              value: Visibility.SHARED_WITH_ORGANIZATION,
            },
            {
              children: [
                {
                  className: block("sub-menu"),
                  disabled: true,
                  key: "teams",
                  label: (
                    <div className={block("teams-list")}>
                      <TextField
                        onChange={setSearch}
                        placeholder={configProvider.formatMessage(
                          "ui.visibilitySelector.search.placeholder"
                        )}
                        suffix={
                          <FontAwesomeIcon className={block("search-icon")} icon={faSearch} />
                        }
                      />
                      <div className={block("teams")}>
                        {teams
                          .filter(
                            (team) =>
                              !search ||
                              team.name.trim().toLowerCase().includes(search.trim().toLowerCase())
                          )
                          .map((team) => {
                            const selected = selectedTeamIds?.includes(team.id);
                            return (
                              <div
                                className={block("team-item", { selected })}
                                key={team.id}
                                onClick={() => {
                                  setVisibility(Visibility.SHARED_WITH_TEAM);
                                  setSelectedTeamIds(
                                    selected
                                      ? selectedTeamIds?.filter((id) => id !== team.id)
                                      : [...(selectedTeamIds ?? []), team.id]
                                  );
                                }}
                              >
                                {team.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ),
                },
              ],
              key: Visibility.SHARED_WITH_TEAM,
              label: configProvider.formatMessage(
                userRole !== RoleEnum.OWNER
                  ? "ui.visibilitySelector.myTeam"
                  : "ui.visibilitySelector.specificTeams"
              ),
              value: Visibility.SHARED_WITH_TEAM,
            },
          ]
        : []),
      {
        key: Visibility.PRIVATE,
        label: configProvider.formatMessage("ui.visibilitySelector.onlyMe"),
        value: Visibility.PRIVATE,
      },
    ];

    return {
      className: block("menu"),
      items,
      onSelect: handleSelect,
      openKeys: open ? undefined : [],
      selectable: true,
      selectedKeys: visibility ? [visibility] : [],
      triggerSubMenuAction: "click",
    };
  }, [userRole, configProvider, teams, handleSelect, open, visibility, search, selectedTeamIds]);

  useEffect(() => {
    setVisibility(value);
  }, [value]);

  useEffect(() => {
    setSelectedTeamIds(selectedTeams);
  }, [selectedTeams]);

  return (
    <Dropdown
      className={block()}
      disabled={disabled}
      dropdownRender={(menu) => (
        <div className={block("dropdown-content")}>
          <span className={block("sub-label")}>{subLabel}</span>
          {menu}
        </div>
      )}
      menu={menu}
      onOpenChange={handleOpenChange}
      open={open}
      trigger={["click"]}
    >
      {children}
    </Dropdown>
  );
};

export default VisibilitySelector;

import React from "react";
import { connect } from "react-redux";

import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Row } from "antd/es/grid";
import { useIntl } from "react-intl";

import User from "@mapmycustomers/shared/types/User";

import Lexer from "@app/component/Lexer";
import LongContentParagraph from "@app/component/typography/LongContentParagraph";
import MultilineText from "@app/component/typography/MultilineText";
import MmcNotificationAction from "@app/enum/MmcNotificationAction";
import { getUsers } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import layout from "@app/styles/layout";
import MmcNotification from "@app/types/MmcNotification";

import styles from "../NotificationCommon.module.scss";

interface Props {
  notification: MmcNotification;
  users: User[];
}

const Title: React.FC<Props> = ({
  notification: { action, entity, group, guides, note, route },
  users,
}) => {
  const intl = useIntl();
  const isPlural = entity && entity.length > 1;
  const entityName = entity && entity[0] ? entity[0].name : "";
  let text: React.ReactNode = entityName;

  if (action === MmcNotificationAction.ACTIVITY_LOGGED) {
    text = (
      <Row gutter={layout.spacerS}>
        <Col>
          <FontAwesomeIcon icon={faCalendarCheck} />
        </Col>
        <Col>{entityName}</Col>
      </Row>
    );
  } else if ([MmcNotificationAction.MENTION, MmcNotificationAction.NOTE_ADDED].includes(action)) {
    text = (
      <LongContentParagraph className={styles.mentionWrapper} noMargin>
        <MultilineText
          wrapLinesWith={Lexer}
          wrapperProps={{ mentionClassName: styles.mention, users }}
        >
          {note?.note ?? ""}
        </MultilineText>
      </LongContentParagraph>
    );
  } else if (
    [
      MmcNotificationAction.COMPANY_GROUP_SHARED,
      MmcNotificationAction.DEAL_GROUP_SHARED,
      MmcNotificationAction.PEOPLE_GROUP_SHARED,
    ].includes(action)
  ) {
    text = group?.name ?? "";
  } else if (
    [
      MmcNotificationAction.COMPANY_ROUTE_SHARED,
      MmcNotificationAction.PEOPLE_ROUTE_SHARED,
    ].includes(action)
  ) {
    text = route?.name ?? "";
  } else if (action === MmcNotificationAction.OUT_OF_CADENCE && isPlural) {
    text = intl.formatMessage({
      id: "navbar.notifications.list.notification.title.seeRecordsOutOfCadence",
      defaultMessage: "See records that are past due",
      description: "Navbar Notifications - list - notification - See records out of cadence",
    });
  } else if (action === MmcNotificationAction.OVERDUE_ACTIVITIES && isPlural) {
    text = intl.formatMessage({
      id: "navbar.notifications.list.notification.title.seeOverdueActivities",
      defaultMessage: "See overdue activities",
      description: "Navbar Notifications - list - notification - See overdue activities",
    });
  } else if (action === MmcNotificationAction.DEALS_ROTTING && isPlural) {
    text = intl.formatMessage({
      id: "navbar.notifications.list.notification.title.seeRottingDeals",
      defaultMessage: "See rotting deals",
      description: "Navbar Notifications - list - notification - See rotting deals",
    });
  } else if (action === MmcNotificationAction.DEALS_MARKED_WON && isPlural) {
    text = intl.formatMessage({
      id: "navbar.notifications.list.notification.title.seeAllClosedWonDeals",
      defaultMessage: "See all Closed Won Deals",
      description: "Navbar Notifications - list - notification - See all Closed Won Deals",
    });
  } else if (action === MmcNotificationAction.DEALS_MARKED_LOST && isPlural) {
    text = intl.formatMessage({
      id: "navbar.notifications.list.notification.title.seeAllClosedLostDeals",
      defaultMessage: "See all Closed Lost Deals",
      description: "Navbar Notifications - list - notification - See all Closed Lost Deals",
    });
  } else if (action === MmcNotificationAction.DEALS_STAGE_CHANGED && isPlural) {
    text = intl.formatMessage({
      id: "navbar.notifications.list.notification.title.seeAllDeals",
      defaultMessage: "See all Deals",
      description: "Navbar Notifications - list - notification - See all Deals",
    });
  } else if (action === MmcNotificationAction.MARK_ACTIVITY_AS_DONE) {
    text = intl.formatMessage({
      id: "navbar.notifications.list.notification.title.mark_activity_as_done",
      defaultMessage: 'Open to mark as "Done"',
      description: "Navbar Notifications - list - notification - Open to mark as Done",
    });
  } else if (
    [
      MmcNotificationAction.OWNED_DEALS_CLOSING,
      MmcNotificationAction.SHARED_DEALS_CLOSING,
    ].includes(action) &&
    isPlural
  ) {
    text = intl.formatMessage({
      id: "navbar.notifications.list.notification.title.seeDeals",
      defaultMessage: "See Deals",
      description: "Navbar Notifications - list - notification  - See Deals",
    });
  } else if (MmcNotificationAction.INCOMPLETE_ACTIVITY === action) {
    text = intl.formatMessage({
      id: "navbar.notifications.list.notification.title.openToMarkItAsComplete",
      defaultMessage: 'Open to mark as "Done"',
      description: "Navbar Notifications - list - notification  - Open to mark as Done",
    });
  } else if (
    [MmcNotificationAction.GUIDE_ASSIGNED, MmcNotificationAction.GUIDE_REMINDER].includes(action)
  ) {
    text = intl.formatMessage(
      {
        id: "navbar.notifications.list.notification.title.seeGuides",
        defaultMessage: "See {count, plural, one {guide} other {guides}}",
        description: "Navbar Notifications - list - notification  - See Guides",
      },
      { count: guides?.length ?? 0 }
    );
  }
  return <div className={styles.title}>{text}</div>;
};

const mapStateToProps = (state: RootState) => ({
  users: getUsers(state),
});

export default connect(mapStateToProps)(Title);

import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import { EntityType } from "@mapmycustomers/shared/types/entity";
import { Deal } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import localSettings from "@app/config/LocalSettings";
import IntervalUnit from "@app/enum/IntervalUnit";
import {
  applyOpenStageDealAmountDrillDownListViewSettings,
  exportOpenStageDealAmountCardDrillDownData,
  fetchOpenStageDealAmountCardDrillDownData,
  showOpenStageDealAmountDrillDown,
} from "@app/scene/dashboard/store/cards/openStageDealAmount/actions";
import {
  getOpenStageDealAmountDrillDownTotalFilteredRecords,
  getOpenStageDealAmountDrillDownViewState,
} from "@app/scene/dashboard/store/cards/openStageDealAmount/selectors";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import expandColumns from "@app/scene/dashboard/utils/expandColumns";
import { callApi } from "@app/store/api/callApi";
import { fetchOpenStageDealAmountCardDataHelper } from "@app/store/dashboard/cardDataFetchHelpers";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganizationId } from "@app/store/iam";
import dealFieldModel from "@app/util/fieldModel/DealFieldModel";
import loggingService from "@app/util/logging";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import { fetchOpenStageDealAmountCardData } from "./actions";

function* onFetchOpenStageDealAmountCardData({
  payload,
}: ReturnType<typeof fetchOpenStageDealAmountCardData>) {
  const { callback, configuration, failureCallback, scope } = payload;
  try {
    yield call(fetchOpenStageDealAmountCardDataHelper, { callback, configuration, scope });
  } catch (error) {
    yield put(handleError({ error }));
    if (failureCallback) {
      yield call(failureCallback);
    }
  }
}

function* onOpenDrillDown({
  payload,
}: ReturnType<typeof showOpenStageDealAmountDrillDown.request>) {
  const { configuration, scope } = payload;
  const { criteria, dateRange } = configuration;

  const drillDownViewState: DrillDownViewState = yield select(
    getOpenStageDealAmountDrillDownViewState
  );

  const viewState = localSettings.getViewSettings(
    `dashboard/openStageDealAmount`,
    dealFieldModel,
    drillDownViewState
  );

  const isTeamScope = !!scope?.teamId && !scope?.userId;

  const filter: FilterModel = {};
  if (criteria.dealStageIds.length) {
    filter.stage = { operator: FilterOperator.IN_ANY, value: criteria.dealStageIds };
  }
  if (isTeamScope) {
    filter.teams = { operator: FilterOperator.IN_ANY, value: [scope.teamId!] };
  }
  if (scope?.userId) {
    filter.assignee = { operator: FilterOperator.IN_ANY, value: [scope.userId] };
  }
  if (dateRange.days) {
    filter.closingDate = {
      operator: FilterOperator.INTERVAL_WITHIN_NEXT,
      value: { from: 0, to: dateRange.days, unit: IntervalUnit.DAY },
    };
  }
  viewState.filter = filter;

  yield put(showOpenStageDealAmountDrillDown.success({ viewState }));
}

export function* onFetchDrillDownData({
  payload,
}: ReturnType<typeof fetchOpenStageDealAmountCardDrillDownData.request>) {
  try {
    loggingService.debug("Dashboard: Open Stage Deal Amount card, onFetchDrillDownData", payload);
    if (!payload.updateOnly) {
      // We do not listen to filter returned by AgGrid from PlatformDataSource
      delete payload.request.filter;
    }

    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyOpenStageDealAmountDrillDownListViewSettings(payload.request));
    }

    const drillDownViewState: DrillDownViewState = yield select(
      getOpenStageDealAmountDrillDownViewState
    );

    if (!payload.fetchOnlyWithoutFilters) {
      localSettings.setViewSettings(drillDownViewState, `dashboard/openStageDealAmount`);
    }

    if (payload.updateOnly) {
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.startRow
        : drillDownViewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.request.range
        ? payload.request.range.endRow - payload.request.range.startRow
        : drillDownViewState.range.endRow - drillDownViewState.range.startRow;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const requestPayload = {
      $filters: {
        includeAccessStatus: true,
        ...convertToPlatformFilterModel(
          payload.fetchOnlyWithoutFilters ? {} : drillDownViewState.filter,
          drillDownViewState.columns,
          dealFieldModel,
          true,
          drillDownViewState.viewAs
        ),
      },
      $limit,
      $offset,
      $order: convertToPlatformSortModel(drillDownViewState.sort),
    };

    const response: ListResponse<Deal> = yield callApi("fetchDeals", orgId, requestPayload);
    payload.dataCallback?.(response);
    yield put(
      fetchOpenStageDealAmountCardDrillDownData.success({
        totalFilteredRecords: response.total,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchOpenStageDealAmountCardDrillDownData.failure(error));
    yield put(handleError({ error }));
  }
}

function* onExport() {
  const drillDownViewState: DrillDownViewState = yield select(
    getOpenStageDealAmountDrillDownViewState
  );
  const total: number = yield select(getOpenStageDealAmountDrillDownTotalFilteredRecords);

  yield put(
    exportEntities.request({
      entityType: EntityType.DEAL,
      total,
      viewState: expandColumns(drillDownViewState),
    })
  );
}

export function* openStageDealAmountSagas() {
  // we use takeEvery because there might be several cards of such type on board
  // however, it would be more optimal to use takeLatest, but also filter by card ids
  yield takeEvery(fetchOpenStageDealAmountCardData, onFetchOpenStageDealAmountCardData);
  yield takeEvery(fetchOpenStageDealAmountCardData, onFetchOpenStageDealAmountCardData);
  yield takeLatest(showOpenStageDealAmountDrillDown.request, onOpenDrillDown);
  yield takeLatest(fetchOpenStageDealAmountCardDrillDownData.request, onFetchDrillDownData);
  yield takeLatest(exportOpenStageDealAmountCardDrillDownData, onExport);
}

import React from "react";

import { faEllipsisV } from "@fortawesome/pro-regular-svg-icons/faEllipsisV";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import { Button, ButtonProps } from "@mapmycustomers/ui";

interface Props extends ButtonProps {
  horizontal?: boolean;
}

const MoreButton: React.FC<Props> = ({
  className,
  horizontal = false,
  size,
  type = "text",
  ...props
}) => {
  const intl = useIntl();

  return (
    <Tooltip
      title={intl.formatMessage({
        id: "moreButton.openMenu",
        defaultMessage: "Open menu",
        description: "Tooltip for open menu button",
      })}
    >
      <Button
        className={className}
        iconOnly
        size={size}
        type={type}
        {...props}
        icon={<FontAwesomeIcon icon={horizontal ? faEllipsisH : faEllipsisV} />}
      />
    </Tooltip>
  );
};

export default MoreButton;

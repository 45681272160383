import avatarMessages from "../Avatar/messages";
import { messages as userDisplayNameWithInfoMessages } from "../Avatar/util/getUserDisplayNameWithInfo";
import colorComponentsMessages from "../colorComponents/messages";
import commonMessages from "../common/messages";
import attachedFilesMessages from "../EmailBody/component/AttachedFiles/messages";
import emailBodyToolbarMessages from "../EmailBody/component/toolbar/messages";
import emailBodyMessages from "../EmailBody/messages";
import entityFieldSelectorMessages from "../EntityFieldSelector/messages";
import inputMessages from "../input/messages";
import recipientListMessages from "../RecipientList/messages";
import subjectInputMessages from "../SubjectInput/messages";
import { messages as teamMultiSelectMessages } from "../TeamMultiSelectField";
import { messages as teamPickerMessages } from "../TeamPicker";
import { messages as userPickerMessages } from "../UserPicker";
import visibilitySelectorMessages from "../VisibilitySelector/messages";

const messages = {
  ...attachedFilesMessages,
  ...avatarMessages,
  ...colorComponentsMessages,
  ...commonMessages,
  ...emailBodyMessages,
  ...emailBodyToolbarMessages,
  ...entityFieldSelectorMessages,
  ...visibilitySelectorMessages,
  ...inputMessages,
  ...recipientListMessages,
  ...subjectInputMessages,
  ...teamMultiSelectMessages,
  ...teamPickerMessages,
  ...userDisplayNameWithInfoMessages,
  ...userPickerMessages,
} as const;

export type MessageKey = keyof typeof messages;

export default messages;

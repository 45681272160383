import React, { useCallback } from "react";
import { connect } from "react-redux";

import cn from "classnames";

import Identified from "@mapmycustomers/shared/types/base/Identified";
import Named from "@mapmycustomers/shared/types/base/Named";
import { EntityTypesSupportedByActivities } from "@mapmycustomers/shared/types/entity";
import { EntityTypeShapedIcon } from "@mapmycustomers/ui";

import ButtonLink from "@app/component/ButtonLink";
import { showEntityView } from "@app/store/entityView/actions";

import styles from "./EntityLink.module.scss";

interface Props {
  className?: string;
  entity?: Identified & Named;
  entityType: EntityTypesSupportedByActivities;
  onClick?: () => void;
  onShowEntityView: typeof showEntityView;
  showIcon?: boolean;
}

const EntityLink: React.FC<Props> = ({
  className,
  entity,
  entityType,
  onClick,
  onShowEntityView,
  showIcon = true,
}) => {
  const handleClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onShowEntityView({ entityId: entity!.id, entityType });
      onClick?.();
    },
    [entity, entityType, onShowEntityView, onClick]
  );

  if (!entity) {
    return null;
  }

  return (
    <div className={cn(styles.container, className)}>
      {showIcon && (
        <EntityTypeShapedIcon
          className={styles.iconContainer}
          entityType={entityType}
          iconClassName={styles.icon}
        />
      )}
      <ButtonLink className={styles.link} onClick={handleClick}>
        {entity.name}
      </ButtonLink>
    </div>
  );
};

const mapDispatchToProps = {
  onShowEntityView: showEntityView,
};

export default connect(null, mapDispatchToProps)(EntityLink);

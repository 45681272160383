import React from "react";
import { connect } from "react-redux";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Progress from "antd/es/progress";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";

import { getEmailQuota } from "@app/store/email";
import { RootState } from "@app/store/rootReducer";

import styles from "./EmailQueueProgress.module.scss";
import messages from "./messages";

interface EmailQueueProgressProps {
  emailQuota?: EmailQuota;
}

const EmailQueueProgress: React.FC<EmailQueueProgressProps> = ({ emailQuota }) => {
  const intl = useIntl();

  if (!emailQuota) {
    return null;
  }

  const { remaining, sent, submitted, total } = emailQuota;

  const percent = ((total - remaining) / total) * 100;
  const successPercent = (sent / total) * 100;

  return (
    <div className={styles.container}>
      <Tooltip
        overlayClassName={styles.progressTooltipOverlay}
        title={intl.formatMessage(messages.progressTooltip, { remaining, sent, submitted })}
      >
        <Progress
          percent={percent}
          showInfo={false}
          status="active"
          success={{ percent: successPercent }}
        />
      </Tooltip>
      <span className={styles.caption}>
        {intl.formatMessage(messages.progressCaption, { sent, total })}
        &nbsp;
        <Tooltip title={intl.formatMessage(messages.progressInfoTooltip, { total })}>
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </span>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  emailQuota: getEmailQuota(state),
});

export default connect(mapStateToProps)(EmailQueueProgress);

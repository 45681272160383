import notification from "antd/es/notification";
import { uniq } from "lodash-es";
import isEqual from "lodash-es/isEqual";
import omit from "lodash-es/omit";
import { defineMessages } from "react-intl";
import {
  all,
  call,
  delay,
  put,
  race,
  select,
  take,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import invariant from "tiny-invariant";
import * as uuid from "uuid";

import ActivityStatusOption from "@mapmycustomers/shared/enum/activity/ActivityStatusOption";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import Identified from "@mapmycustomers/shared/types/base/Identified";
import CustomFieldValuesUpsertResponse from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import EmailSubscriptionStatus from "@mapmycustomers/shared/types/email/EmailSubscriptionStatus";
import { Activity, Deal, EntityType, Person, Route } from "@mapmycustomers/shared/types/entity";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Note from "@mapmycustomers/shared/types/entity/common/Note";
import Company from "@mapmycustomers/shared/types/entity/Company";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { RawFile } from "@mapmycustomers/shared/types/File";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import Organization from "@mapmycustomers/shared/types/Organization";
import User from "@mapmycustomers/shared/types/User";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import { PinLegendsFilter } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";

import getSuccessNotificationNode from "@app/component/createEditEntity/util/getSuccessNotificationNode";
import { RELATIONSHIP_DATA_LOAD_LIMIT } from "@app/component/preview/util/consts";
import getFileRemovedNotificationNode from "@app/component/preview/util/getFileRemovedNotificationNode";
import getStartRange from "@app/component/preview/util/getStartRange";
import getYourDownloadWillStartShortlyNode from "@app/component/preview/util/getYourDownloadWillStartShortlyNode";
import i18nService from "@app/config/I18nService";
import localSettings from "@app/config/LocalSettings";
import { recapRangeIntervalMap } from "@app/enum/preview/RecapRange";
import { applyMapViewSettings, fetchPins } from "@app/scene/map/store/mapMode/actions";
import { getMapViewState } from "@app/scene/map/store/mapMode/selectors";
import { getActivityTypes } from "@app/store/activity";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import {
  getCurrentUser,
  getOrganization,
  getOrganizationId,
  isEmailServiceSupported,
  shouldIncludeSharedActivities,
} from "@app/store/iam";
import { updateMetadata } from "@app/store/iam/actions";
import { getAllColorLegends, getAllShapeLegends } from "@app/store/pinLegends";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import FileListItem from "@app/types/FileListItem";
import getParentActivitiesFilter from "@app/util/activity/getParentActivitiesFilter";
import { NOTIFICATION_DURATION_WITH_ACTION } from "@app/util/consts";
import { getLocalTimeZoneFormattedOffset } from "@app/util/dates";
import { allSettled, SettleResult } from "@app/util/effects";
import companyFieldModel, { CompanyFieldName } from "@app/util/fieldModel/CompanyFieldModel";
import { downloadFileByUrl } from "@app/util/file";
import { activityLayoutModel } from "@app/util/layout/impl";
import getColorShapeForEntity from "@app/util/map/markerStyles/getColorShapeForEntity";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";

import {
  addCompanyChildCompanies,
  addCompanyDeals,
  addCompanyParentCompany,
  addCompanyPeople,
  changeActivitiesRecapRange,
  changeActivitiesSelectedActivityTypes,
  createCompanyNote,
  deleteCompany,
  deleteCompanyFile,
  deleteCompanyNote,
  downloadCompanyFile,
  fetchCompanyActivities,
  fetchCompanyActivitiesCompletedByType,
  fetchCompanyActivitiesTotal,
  fetchCompanyRoutes,
  fetchMoreDeals,
  fetchMorePeople,
  fetchPreviewData,
  fetchThumbnail,
  postponeActivity,
  removeCompanyChildCompany,
  removeCompanyDeal,
  removeCompanyFromRoute,
  removeCompanyParentCompany,
  removeCompanyPerson,
  setCompanyColor,
  setCompanyShape,
  subscribeCompanyEmail,
  toggleCompleteActivity,
  unsubscribeCompanyEmail,
  updateActivityNote,
  updateCompany,
  updateCompanyFrequency,
  updateCompanyNote,
  updateCompanyPrimaryPerson,
  uploadCompanyFiles,
} from "./actions";
import CompanyRecordData from "./CompanyRecordData";
import { getCompany, getRecordData } from "./selectors";

const messages = defineMessages({
  addChildCompanySuccess: {
    id: "companyRecordPane.addChildCompany.success",
    defaultMessage:
      "Company successfully related to {count, select, 1 {Child Company} other {Child Companies}}",
    description: "Company successfully related to Child Company message",
  },
  addDealSuccess: {
    id: "companyRecordPane.addDeal.success",
    defaultMessage: "Company successfully related to {count, select, 1 {Deal} other {Deals}}",
    description: "Company successfully related to Deal message",
  },
  addParentCompanySuccess: {
    id: "companyRecordPane.addParentCompany.success",
    defaultMessage: "Company successfully related to Parent Company",
    description: "Company successfully related to Parent Company message",
  },
  addPersonSuccess: {
    id: "companyRecordPane.addPerson.success",
    defaultMessage: "Company successfully related to {count, select, 1 {Person} other {People}}",
    description: "Company successfully related to Person message",
  },
  deleteSuccess: {
    id: "companyRecordPane.delete.success.title",
    defaultMessage: "Company successfully deleted",
    description: "Company deleted success message",
  },
  deleteSuccessDescription: {
    id: "companyRecordPane.delete.success.description",
    defaultMessage: "To restore this record, go to Data in Settings.",
    description: "Company deleted success description",
  },
  postponeSuccess: {
    id: "companyRecordPane.activities.postpone.success",
    defaultMessage: "Activity postponed successfully",
    description: "Activity postpone success message",
  },
  toggleCompleteSuccess: {
    id: "companyRecordPane.activities.toggleComplete.success",
    defaultMessage: 'Activity{completed, select, true {} other { is no longer}} marked as "Done"',
    description: "Activity toggle complete success message",
  },
  updateError: {
    id: "companyRecordPane.update.error",
    defaultMessage: "Error updating company. Please try again or contact support",
    description: "Company update error message",
  },
  updateSuccess: {
    id: "companyRecordPane.update.success",
    defaultMessage: "Company updated successfully",
    description: "Company update success message",
  },
});

function* getFetchCompanyPayload(companyId: Company["id"]) {
  const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
  const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);
  const mapViewState: MapViewState = yield select(getMapViewState);

  const pinLegendFilter: PinLegendsFilter = {
    $offset: getLocalTimeZoneFormattedOffset(),
    [EntityType.COMPANY]: getColorShapeForEntity(
      mapViewState,
      colorPinLegends,
      shapePinLegends,
      EntityType.COMPANY
    ),
  };

  return {
    $filters: {
      cadence: true,
      entities: {
        [EntityType.COMPANY]: {
          $and: [{ id: companyId }],
          includeGroups: true,
          includeTerritories: true,
        },
      },
      includeAccessStatus: true,
      includeAssociations: true,
      includeCustomFields: true,
      includeUsersWithAccess: true,
      pinLegends: pinLegendFilter,
    },
    $limit: 1,
  };
}

export function* onFetchPreviewData({
  payload: companyId,
}: ReturnType<typeof fetchPreviewData.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const emailServiceSupported: boolean = yield select(isEmailServiceSupported);

    const response: ListResponse<Company> = yield callApi(
      "fetchPins",
      organization.id,
      yield call(getFetchCompanyPayload, companyId)
    );

    const company: Company | undefined = response.data[0];

    if (!company) {
      yield put(fetchPreviewData.failure());
      return;
    }

    const filesField: IField | undefined = yield call(
      [companyFieldModel, companyFieldModel.getByName],
      CompanyFieldName.FILES
    );

    const notesField: IField | undefined = yield call(
      [companyFieldModel, companyFieldModel.getByName],
      CompanyFieldName.NOTES
    );

    const includeSharedActivities: boolean = yield select(shouldIncludeSharedActivities);

    const [
      notes,
      files,
      childCompanies,
      dealsResponse,
      peopleResponse,
      activitiesResponse,
      uncompletedActivitiesResponse,
      routesResponse,
      emailSubscriptionStatus,
    ]: [
      ListResponse<Note>,
      ListResponse<RawFile>,
      ListResponse<Company>,
      ListResponse<Deal>,
      ListResponse<Person>,
      ListResponse<Activity>,
      ListResponse<Activity>,
      ListResponse<Route>,
      EmailSubscriptionStatus | undefined
    ] = yield all([
      notesField?.isReadable
        ? callApi("fetchNotes", EntityType.COMPANY, company.id, {
            $limit: 1000,
            $order: "-updatedAt",
          })
        : { data: [] },
      filesField?.isReadable
        ? callApi("fetchEntityFiles", organization.id, EntityType.COMPANY, company.id, {
            $limit: 1000,
            $order: "-createdAt",
          })
        : { data: [] },
      callApi("fetchCompanies", organization.id, {
        $filters: {
          $and: [{ parentAccountId: { $eq: company.id } }],
          includeAccessStatus: true,
          includeCustomFields: true,
          includeGroups: true,
          includeRoutes: true,
          includeTerritories: true,
        },
        $limit: 1000,
        $order: "name",
      }),
      callApi("fetchDeals", organization.id, {
        $filters: {
          $and: [
            {
              accountId: company.id,
            },
          ],
          includeAccessStatus: true,
          includeCustomFields: true,
          includeGroups: true,
          includeTerritories: true,
        },
        $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
        $order: "name",
      }),
      callApi("fetchPeople", organization.id, {
        $filters: {
          $and: [
            {
              "accounts.id": { $in: [company.id] },
            },
          ],
          includeAccessStatus: true,
          includeCustomFields: true,
          includeGroups: true,
          includeTerritories: true,
        },
        $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
        $order: "name",
      }),
      callApi("fetchActivities", organization.id, {
        $columns: ["id", "startAt"],
        $filters: {
          $and: [
            {
              accountId: company.id,
            },
          ],
          includeAccessStatus: true,
          includeAccountSharedActivities: includeSharedActivities,
        },
        $limit: 0,
      }),
      callApi("fetchActivities", organization.id, {
        $columns: ["id", "startAt"],
        $filters: {
          $and: [
            {
              accountId: company.id,
              completed: false,
            },
          ],
          includeAccessStatus: true,
          includeAccountSharedActivities: includeSharedActivities,
        },
        $limit: 1000,
        $order: "startAt",
      }),
      callApi("fetchCompanyRoutes", organization.id, {
        $filters: {
          $and: [
            {
              accountId: company.id,
            },
          ],
          includeAccessStatus: true,
        },
        $limit: 1000,
        $order: "name",
      }),
      emailServiceSupported && company.email
        ? callApi(
            "fetchEntityEmailSubscriptionStatus",
            organization.id,
            EntityType.COMPANY,
            company.id
          )
        : undefined,
    ]);

    const [parentCompany]: [SettleResult<Company | undefined>] = yield allSettled([
      company.parentAccount
        ? callApi("fetchCompany", organization.id, company.parentAccount.id, {
            includeAccessStatus: true,
            includeCustomFields: true,
            includeGroups: true,
            includeTerritories: true,
          })
        : undefined,
    ]);

    const user: User = yield select(getCurrentUser);

    // get stored selected activity types ids
    const activityTypes: ActivityType[] = yield select(getActivityTypes);
    const selectedActivityTypesIds = user?.metaData?.recapChartTypesIds;

    // find corresponding activity types
    const selectedActivityTypes: ActivityType[] = selectedActivityTypesIds
      ? activityTypes.filter(({ id }) => selectedActivityTypesIds.includes(id))
      : activityTypes;

    yield put(
      fetchPreviewData.success({
        recordData: {
          activities: [],
          activitiesCompletedByType: [],
          activitiesTotal: activitiesResponse.total,
          childCompanies: childCompanies.data,
          company,
          deals: dealsResponse.data,
          emailSubscribed: emailSubscriptionStatus?.subscribed,
          files: files.data,
          notes: notes.data,
          parentCompany:
            (!parentCompany.error ? parentCompany.result : company.parentAccount) || undefined,
          people: peopleResponse.data,
          routes: routesResponse.data,
          totalDeals: dealsResponse.total,
          totalPeople: peopleResponse.total,
          uncompletedActivities: uncompletedActivitiesResponse.data,
        },
        selectedActivityTypes,
      })
    );
  } catch (error) {
    yield put(fetchPreviewData.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchCompanyActivities({
  payload: { activityTypes, company, filter, order, recapRange, search, selectedAssignees },
}: ReturnType<typeof fetchCompanyActivities.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const recapInterval = recapRangeIntervalMap[recapRange];
    const includeSharedActivities: boolean = yield select(shouldIncludeSharedActivities);

    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      {
        $filters: {
          $and: [
            {
              accountId: company.id,
              completed:
                filter === ActivityStatusOption.COMPLETED
                  ? true
                  : filter === ActivityStatusOption.OVERDUE
                  ? false
                  : undefined,
              "crmActivityType.id": { $in: activityTypes.map(({ id }) => id) },
              name: search ? { $in: search.trim() } : undefined,
              startAt: getStartRange(recapInterval, filter),
              ...(selectedAssignees.length ? { assigneeId: { $in: selectedAssignees } } : {}),
            },
            getParentActivitiesFilter(),
          ],
          includeAccessStatus: true,
          includeAccountSharedActivities: includeSharedActivities,
          includeCustomFields: true,
          includeEmails: true,
        },
        $limit: 1000,
        $order: convertToPlatformSortModel(order),
      }
    );
    yield put(fetchCompanyActivities.success(activitiesResponse.data));
  } catch (error) {
    yield put(fetchCompanyActivities.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchCompanyActivitiesCompletedByType({
  payload: { activityTypes, company, filter, order, recapRange, selectedAssignees },
}: ReturnType<typeof fetchCompanyActivitiesCompletedByType.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const recapInterval = recapRangeIntervalMap[recapRange];
    const includeSharedActivities: boolean = yield select(shouldIncludeSharedActivities);

    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      {
        $filters: {
          $and: [
            {
              accountId: company.id,
              completed:
                filter === ActivityStatusOption.COMPLETED
                  ? true
                  : filter === ActivityStatusOption.OVERDUE
                  ? false
                  : undefined,
              "crmActivityType.id": { $in: activityTypes.map(({ id }) => id) },
              startAt: getStartRange(recapInterval, filter),
              ...(selectedAssignees.length ? { assigneeId: { $in: selectedAssignees } } : {}),
            },
            getParentActivitiesFilter(),
          ],
          includeAccessStatus: true,
          includeAccountSharedActivities: includeSharedActivities,
          includeCustomFields: true,
        },
        $limit: 1000,
        $order: convertToPlatformSortModel(order),
      }
    );
    yield put(fetchCompanyActivitiesCompletedByType.success(activitiesResponse.data));
  } catch (error) {
    yield put(fetchCompanyActivitiesCompletedByType.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchCompanyActivitiesTotal({
  payload,
}: ReturnType<typeof fetchCompanyActivitiesTotal.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const includeSharedActivities: boolean = yield select(shouldIncludeSharedActivities);

    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      {
        $columns: ["id"],
        $filters: {
          $and: [
            {
              accountId: payload.id,
            },
            getParentActivitiesFilter(),
          ],
          includeAccountSharedActivities: includeSharedActivities,
        },
        $limit: 0,
      }
    );
    yield put(fetchCompanyActivitiesTotal.success(activitiesResponse.total));
  } catch (error) {
    yield put(fetchCompanyActivitiesTotal.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchCompanyRoutes({ payload }: ReturnType<typeof fetchCompanyRoutes.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const routesResponse: ListResponse<Route> = yield callApi(
      "fetchCompanyRoutes",
      organization.id,
      {
        $filters: { $and: [{ accountId: payload }], includeAccessStatus: true },
        $limit: 1000,
        $order: "name",
      }
    );

    yield put(fetchCompanyRoutes.success(routesResponse.data));
    yield put(
      notifyAboutChanges({ entityType: EntityType.COMPANY_ROUTE, updated: routesResponse.data })
    );
  } catch (error) {
    yield put(fetchCompanyRoutes.failure());
    yield put(handleError({ error }));
  }
}

function* onAddCompanyParentCompany({
  payload: { id, parentCompanyId },
}: ReturnType<typeof addCompanyParentCompany.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    const [company, parentCompany]: [Company, Company] = yield all([
      callApi("updateCompany", org.id, undefined, {
        id,
        parentAccount: { id: parentCompanyId },
      }),
      callApi("fetchCompany", org.id, parentCompanyId, { includeAccessStatus: true }),
    ]);

    notification.success({
      message: i18nService.formatMessage(
        messages.addParentCompanySuccess,
        "Company successfully related to Parent Company"
      ),
    });

    yield put(addCompanyParentCompany.success(parentCompany));
    yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: [company] }));
  } catch (error) {
    yield put(addCompanyParentCompany.failure());
    yield put(handleError({ error }));
  }
}

function* onRemoveCompanyParentCompany({
  payload,
}: ReturnType<typeof removeCompanyParentCompany.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    const company: Company = yield callApi("updateCompany", org.id, undefined, {
      id: payload,
      parentAccount: null,
    });

    yield put(removeCompanyParentCompany.success(payload));
    yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: [company] }));
  } catch (error) {
    yield put(removeCompanyParentCompany.failure(payload));
    yield put(handleError({ error }));
  }
}

function* onAddCompanyChildCompanies({
  payload: { id, companyIds, removedCompaniesIds },
}: ReturnType<typeof addCompanyChildCompanies.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    // Remove Child Companies
    yield all(
      (removedCompaniesIds ?? []).map((companyId) =>
        callApi("updateCompany", org.id, undefined, {
          id: companyId,
          parentAccount: null,
        })
      )
    );

    yield all(
      (companyIds ?? []).map((companyId) =>
        callApi("updateCompany", org.id, undefined, {
          id: companyId,
          parentAccount: { id },
        })
      )
    );

    const childCompanies: ListResponse<Company> = yield callApi("fetchCompanies", org.id, {
      $filters: { $and: [{ parentAccountId: { $eq: id } }], includeAccessStatus: true },
      $limit: 1000,
      $order: "name",
    });

    notification.success({
      message: i18nService.formatMessage(
        messages.addChildCompanySuccess,
        "Company successfully related to Child Company",
        { count: companyIds?.length }
      ),
    });

    yield put(addCompanyChildCompanies.success(childCompanies.data));
    yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: childCompanies.data }));
  } catch (error) {
    yield put(addCompanyChildCompanies.failure());
    yield put(handleError({ error }));
  }
}

function* onRemoveCompanyChildCompany({
  payload: childCompanyId,
}: ReturnType<typeof removeCompanyChildCompany.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    yield callApi("updateCompany", org.id, undefined, {
      id: childCompanyId,
      parentAccount: null,
    });
    yield put(removeCompanyChildCompany.success(childCompanyId));
  } catch (error) {
    yield put(removeCompanyChildCompany.failure(childCompanyId));
    yield put(handleError({ error }));
  }
}

function* onAddCompanyPeople({
  payload: { id: companyId, peopleIds, primaryPersonId, removedPeopleIds },
}: ReturnType<typeof addCompanyPeople.request>) {
  try {
    const company: Company = yield select(getCompany);
    const org: Organization = yield select(getOrganization);

    // Fetch removed associated people
    const removedPeopleResponse: ListResponse<Person> | undefined = yield removedPeopleIds
      ? callApi("fetchPeople", org.id, {
          $filters: { $and: [{ id: { $in: removedPeopleIds } }], includeAccessStatus: true },
          $order: "name",
        })
      : undefined;

    const newAssociatedPeopleResponse: ListResponse<Person> | undefined = yield peopleIds &&
    peopleIds.length > 0
      ? callApi("fetchPeople", org.id, {
          $filters: { $and: [{ id: { $in: peopleIds } }], includeAccessStatus: true },
          $limit: 1000,
          $order: "name",
        })
      : undefined;

    // Remove company from person associations
    yield all(
      (removedPeopleResponse?.data ?? []).map(({ id, accounts }) => {
        return callApi("updatePerson", org.id, undefined, {
          id,
          accounts: accounts.filter(({ id }) => id !== companyId),
        });
      })
    );

    // Associate company to new people
    yield all(
      (newAssociatedPeopleResponse?.data ?? []).map(({ id, accounts }) => {
        const updatedAccountIds = uniq([...(accounts ?? []).map(({ id }) => id), companyId]);
        return callApi("updatePerson", org.id, undefined, {
          id,
          accounts: updatedAccountIds.map((id) => ({ id })),
        });
      })
    );

    const updatedPeopleResponse: ListResponse<Person> = yield callApi("fetchPeople", org.id, {
      $filters: { $and: [{ id: { $in: peopleIds } }], includeAccessStatus: true },
      $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
      $order: "name",
    });

    notification.success({
      message: i18nService.formatMessage(
        messages.addPersonSuccess,
        "Company successfully related to Person",
        { count: peopleIds?.length }
      ),
    });

    let primaryPerson = company.primaryContact;
    if (primaryPerson?.id !== primaryPersonId) {
      const updatedCompany: Company = yield callApi("updateCompany", org.id, undefined, {
        id: company.id,
        primaryContact: primaryPersonId ? { id: primaryPersonId } : null,
      });

      yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: [company] }));

      primaryPerson = updatedCompany.primaryContact;
    }

    yield put(
      addCompanyPeople.success({
        people: updatedPeopleResponse.data,
        primaryPerson,
        total: updatedPeopleResponse.total,
      })
    );

    yield put(
      notifyAboutChanges({ entityType: EntityType.PERSON, updated: updatedPeopleResponse.data })
    );
  } catch (error) {
    yield put(addCompanyPeople.failure());
    yield put(handleError({ error }));
  }
}

function* onRemoveCompanyPerson({
  payload: personId,
}: ReturnType<typeof removeCompanyPerson.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const company: Company = yield select(getCompany);
    const person: Person = yield callApi("fetchPerson", org.id, personId);
    yield callApi("updatePerson", org.id, undefined, {
      id: personId,
      accounts: person.accounts.filter(({ id }) => id !== company.id) ?? [],
    });
    yield put(removeCompanyPerson.success(personId));
  } catch (error) {
    yield put(removeCompanyPerson.failure(personId));
    yield put(handleError({ error }));
  }
}

function* onAddCompanyDeals({
  payload: { id, dealIds, removedDealsIds },
}: ReturnType<typeof addCompanyDeals.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    // Remove Deals Association
    yield all(
      (removedDealsIds ?? []).map((dealId) =>
        callApi("updateDeal", org.id, undefined, {
          id: dealId,
          account: null,
        })
      )
    );

    // Add new Deals Association
    yield all(
      (dealIds ?? []).map((dealId) =>
        callApi("updateDeal", org.id, undefined, {
          id: dealId,
          account: { id },
        })
      )
    );

    const dealsResponse: ListResponse<Deal> = yield callApi("fetchDeals", org.id, {
      $filters: { $and: [{ accountId: id }], includeAccessStatus: true },
      $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
      $order: "name",
    });

    notification.success({
      message: i18nService.formatMessage(
        messages.addDealSuccess,
        "Company successfully related to Deal",
        { count: dealIds?.length }
      ),
    });

    yield put(addCompanyDeals.success({ deals: dealsResponse.data, total: dealsResponse.total }));
    yield put(notifyAboutChanges({ entityType: EntityType.DEAL, updated: dealsResponse.data }));
  } catch (error) {
    yield put(addCompanyDeals.failure());
    yield put(handleError({ error }));
  }
}

function* onRemoveCompanyDeal({ payload }: ReturnType<typeof removeCompanyDeal.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    yield callApi("updateDeal", org.id, undefined, {
      id: payload,
      account: null,
    });
    yield put(removeCompanyDeal.success(payload));
  } catch (error) {
    yield put(removeCompanyDeal.failure(payload));
    yield put(handleError({ error }));
  }
}

function* onRemoveCompanyFromRoute({
  payload: { id, routeId },
}: ReturnType<typeof removeCompanyFromRoute.request>) {
  try {
    const org: Organization = yield select(getOrganization);

    yield callApi("removeCompaniesFromRoute", org.id, routeId, [id]);
    yield put(removeCompanyFromRoute.success(routeId));
  } catch (error) {
    yield put(removeCompanyFromRoute.failure(routeId));
    yield put(handleError({ error }));
  }
}

export function* onUpdateCompany({
  payload: {
    callback,
    company,
    customFields,
    customFieldsValueValidateCallback,
    groupIdsToAdd,
    groupIdsToRemove,
    layoutId,
  },
}: ReturnType<typeof updateCompany.request>) {
  const intl = i18nService.getIntl();
  try {
    const org: Organization = yield select(getOrganization);
    const recordData: CompanyRecordData = yield select(getRecordData);

    // TODO: fixme 2023-11-07
    const readOnlyFields = new Set(
      companyFieldModel.fields
        .filter((field) => !field.isEditable || field.hasFeature(FieldFeature.CALCULATED_FIELD))
        .map((field) => field.name)
    );

    const changedCustomFields = Object.values(customFields ?? {}).filter((customField) => {
      if (!customField) {
        return false;
      }
      if (readOnlyFields.has(customField.esKey)) {
        return false;
      }

      const oldCustomField = recordData.company?.customFields?.find(
        ({ customField: { id } }) => id === customField.customField.id
      );
      return !oldCustomField || !isEqual(oldCustomField.value, customField.value);
    });

    // Validate custom fields
    if (changedCustomFields.length && customFieldsValueValidateCallback) {
      invariant(!!layoutId, "Must use layoutId to validate custom fields");
      const customFieldValidateResponse: CustomFieldValuesUpsertResponse[] = yield callApi(
        "upsertCustomFieldsValues",
        true,
        org.id,
        layoutId,
        EntityType.COMPANY,
        company.id,
        changedCustomFields,
        true
      );

      const erroredCustomFields = customFieldValidateResponse[0].errorCF.filter(
        ({ error }) => error.length > 0
      );

      if (erroredCustomFields.length) {
        yield call(customFieldsValueValidateCallback, erroredCustomFields);
        yield put(updateCompany.failure());
        return;
      }
    }

    const strippedCompany = readOnlyFields.size
      ? (omit(company, Array.from(readOnlyFields)) as Identified)
      : company;
    yield callApi("updateCompany", org.id, layoutId, strippedCompany);

    if (changedCustomFields.length) {
      invariant(!!layoutId, "Must use layoutId to update custom fields");
      yield callApi(
        "upsertCustomFieldsValues",
        true,
        org.id,
        layoutId,
        EntityType.COMPANY,
        company.id,
        changedCustomFields
      );
    }

    const companyIds: Company["id"][] = [company.id];
    yield all([
      ...(groupIdsToAdd ?? []).map((groupId) =>
        callApi("addToGroup", org.id, groupId, EntityType.COMPANY, companyIds)
      ),
      ...(groupIdsToRemove ?? []).map((groupId) =>
        callApi("deleteFromGroup", org.id, groupId, EntityType.COMPANY, companyIds)
      ),
    ]);

    const response: ListResponse<Company> = yield callApi(
      "fetchPins",
      org.id,
      yield call(getFetchCompanyPayload, company.id)
    );
    const updatedCompany: Company = response.data[0];

    if (intl) {
      notification.success({
        message: getSuccessNotificationNode(intl, intl.formatMessage(messages.updateSuccess)),
      });
    }
    yield put(updateCompany.success(updatedCompany));
    yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: [updatedCompany] }));
    callback?.(updatedCompany);
  } catch (error) {
    yield put(updateCompany.failure());
    yield put(handleError({ defaultMessage: messages.updateError, error }));
  }
}

export function* onUpdateCompanyFrequency({
  payload: { companyId },
}: ReturnType<typeof updateCompanyFrequency.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<Company> = yield callApi(
      "fetchPins",
      orgId,
      yield call(getFetchCompanyPayload, companyId)
    );
    const updatedCompany: Company = response.data[0];
    yield put(updateCompanyFrequency.success(updatedCompany));
  } catch (error) {
    yield put(updateCompanyFrequency.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateCompanyNote({ payload }: ReturnType<typeof createCompanyNote.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const company: Company = yield select(getCompany);
    const note: Note = yield callApi("createNote", org.id, EntityType.COMPANY, company.id, payload);
    yield put(createCompanyNote.success(note));
    yield put(
      notifyAboutChanges({
        entityType: EntityType.COMPANY,
        updated: [{ ...company, notes: [...(company.notes ?? []), note] }],
      })
    );
  } catch (error) {
    yield put(createCompanyNote.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateCompanyNote({ payload }: ReturnType<typeof updateCompanyNote.request>) {
  try {
    const company: Company = yield select(getCompany);
    const updatedNote: Note = yield callApi("updateNote", EntityType.COMPANY, company.id, payload);
    yield put(updateCompanyNote.success(updatedNote));
    yield put(
      notifyAboutChanges({
        entityType: EntityType.COMPANY,
        updated: [
          {
            ...company,
            notes: (company.notes ?? []).map((note) =>
              note.id === updatedNote.id ? updatedNote : note
            ),
          },
        ],
      })
    );
  } catch (error) {
    yield put(updateCompanyNote.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onUploadCompanyFiles({ payload }: ReturnType<typeof uploadCompanyFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const company: Company = yield select(getCompany);
    const fileGroupId = payload.fileGroupId ?? uuid.v4();
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) =>
        callApi("createFile", org.id, file, false, EntityType.COMPANY, company.id, {
          headers: {
            "x-mmc-file-group-id": fileGroupId,
          },
        })
      )
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    payload.callback?.(fileList);
    yield put(uploadCompanyFiles.success(fileList));
  } catch (error) {
    payload.callback?.(payload.files.map((file) => ({ errored: true, file, uploading: false })));
    yield put(uploadCompanyFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteCompanyFile({ payload }: ReturnType<typeof deleteCompanyFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const company: Company = yield select(getCompany);

    const notificationKey = `file_removal_${payload.id}`;
    const cancelled = new Promise<true>((resolve) => {
      notification.info({
        duration: NOTIFICATION_DURATION_WITH_ACTION,
        key: notificationKey,
        message: getFileRemovedNotificationNode(i18nService.getIntl(), payload, () => {
          resolve(true);
          notification.close(notificationKey);
        }),
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let markWindowClosed = (flag: true) => {};
    const windowClosed = new Promise<boolean>((resolve) => {
      markWindowClosed = resolve; // I need to "extract" it from here, so that I can call removeEventListener later
    });
    const documentListener = () => {
      if (document.visibilityState === "hidden") {
        markWindowClosed(true);
        notification.close(notificationKey);
      }
    };
    document.addEventListener("visibilitychange", documentListener);
    const windowListener = () => markWindowClosed(true);
    window.addEventListener("beforeunload", windowListener);

    const result: { cancelled?: Promise<true>; delete?: true; windowClosed?: Promise<true> } =
      yield race({
        cancelled,
        delete: delay(NOTIFICATION_DURATION_WITH_ACTION * 1000 /* since it is in seconds */),
        windowClosed,
      });

    window.removeEventListener("beforeunload", windowListener);
    document.removeEventListener("visibilitychange", documentListener);

    if (result.delete || result.windowClosed) {
      yield callApi("deleteEntityFile", org.id, EntityType.COMPANY, company.id, payload.id);
      yield put(deleteCompanyFile.success({ file: payload, removed: true }));
    } else {
      yield put(deleteCompanyFile.success({ file: payload, removed: false }));
    }
  } catch (error) {
    yield put(deleteCompanyFile.failure(payload));
    yield put(handleError({ error }));
  }
}

export function* onFetchThumbnail({ payload }: ReturnType<typeof fetchThumbnail>) {
  try {
    const org: Organization = yield select(getOrganization);
    const company: Company = yield select(getCompany);
    const fileData: Blob = yield callApi(
      "fetchFile",
      org.id,
      payload.fileId,
      false,
      true,
      EntityType.COMPANY,
      company.id,
      { responseType: "blob", timeout: 0 } // no timeout
    );
    yield call(payload.callback, fileData);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onDownloadCompanyFile({ payload: file }: ReturnType<typeof downloadCompanyFile>) {
  try {
    const org: Organization = yield select(getOrganization);
    const company: Company = yield select(getCompany);

    const key = `download_file_${file.id}`;
    const timeout = setTimeout(() => {
      notification.info({
        duration: 0, // don't close automatically, we'll close it ourselves
        key,
        message: getYourDownloadWillStartShortlyNode(i18nService.getIntl(), file.name),
      });
    }, 2000);

    const fileBlob: Blob = yield callApi(
      "fetchFile",
      org.id,
      file.id,
      true,
      false,
      EntityType.COMPANY,
      company.id,
      { responseType: "blob", timeout: 0 }
    );
    clearTimeout(timeout);
    notification.close(key);

    yield call(downloadFileByUrl, window.URL.createObjectURL(fileBlob), file.name);
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onDeleteCompanyNote({
  payload: note,
}: ReturnType<typeof deleteCompanyNote.request>) {
  try {
    const company: Company = yield select(getCompany);
    yield callApi("deleteNote", EntityType.COMPANY, company.id, note);
    yield put(deleteCompanyNote.success(note.id));
    yield put(
      notifyAboutChanges({
        entityType: EntityType.COMPANY,
        updated: [
          {
            ...company,
            notes: (company.notes ?? []).filter((n) => n.id !== note.id),
          },
        ],
      })
    );
  } catch (error) {
    yield put(deleteCompanyNote.failure(note.id));
    yield put(handleError({ error }));
  }
}

export function* onChangeActivitiesRecapRange({
  payload: recapRange,
}: ReturnType<typeof changeActivitiesRecapRange>) {
  yield call(localSettings.setRecapChartRange, recapRange);
}

export function* onChangeActivitiesSelectedActivityTypes({
  payload: activityTypes,
}: ReturnType<typeof changeActivitiesSelectedActivityTypes>) {
  yield put(updateMetadata.request({ recapChartTypesIds: activityTypes.map(({ id }) => id) }));
}

export function* onPostponeActivity({ payload }: ReturnType<typeof postponeActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload).id;

    const updatedActivity: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      payload
    );
    yield put(postponeActivity.success(updatedActivity));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(messages.postponeSuccess, "Activity postponed successfully")
      ),
    });
  } catch (error) {
    yield put(postponeActivity.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onToggleCompleteActivity({
  payload,
}: ReturnType<typeof toggleCompleteActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload).id;

    const response: Activity = yield callApi("updateActivity", organization.id, layoutId, {
      ...payload,
      completed: !payload.completed,
    });
    yield put(toggleCompleteActivity.success(response));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(
          messages.toggleCompleteSuccess,
          `Activity marked as "${response.completed ? "Done" : "Not yet done"}"`,
          { completed: response.completed }
        )
      ),
    });
  } catch (error) {
    yield put(toggleCompleteActivity.failure(payload.id));
    yield put(handleError({ error }));
  }
}

export function* onUpdateActivityNote({ payload }: ReturnType<typeof updateActivityNote.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload.activity).id;

    const response: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      payload.activity
    );
    yield put(
      updateActivityNote.success({
        id: response.id,
        note: response.note,
      })
    );
    payload.onSuccess();
  } catch (error) {
    yield put(updateActivityNote.failure(payload.activity.id));
    yield put(handleError({ error }));
  }
}

export function* onDeleteCompany() {
  try {
    const org: Organization = yield select(getOrganization);
    const company: Company = yield select(getCompany);
    yield callApi("deleteCompany", org.id, company.id);
    yield put(deleteCompany.success());
    notification.success({
      description: i18nService.formatMessage(
        messages.deleteSuccessDescription,
        "To restore this record, go to Data in Settings."
      ),
      message: i18nService.formatMessage(messages.deleteSuccess, "Company successfully deleted"),
    });
    yield put(notifyAboutChanges({ deletedIds: [company.id], entityType: EntityType.COMPANY }));
  } catch (error) {
    yield put(deleteCompany.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchDeals() {
  try {
    const org: Organization = yield select(getOrganization);
    const company: Company = yield select(getCompany);
    const recordData: CompanyRecordData = yield select(getRecordData);
    const $offset = recordData.deals.length;

    const response: ListResponse<Deal> = yield callApi("fetchDeals", org.id, {
      $filters: {
        $and: [
          {
            accountId: company.id,
          },
        ],
        includeAccessStatus: true,
        includeCustomFields: true,
        includeGroups: true,
        includeTerritories: true,
      },
      $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
      $offset,
      $order: "name",
    });
    yield put(fetchMoreDeals.success(response.data));
  } catch (error) {
    yield put(fetchMoreDeals.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchPeople() {
  try {
    const org: Organization = yield select(getOrganization);
    const company: Company = yield select(getCompany);
    const recordData: CompanyRecordData = yield select(getRecordData);
    const $offset = recordData.people.length;

    const response: ListResponse<Person> = yield callApi("fetchPeople", org.id, {
      $filters: {
        $and: [
          {
            "accounts.id": { $in: [company.id] },
          },
        ],
        includeAccessStatus: true,
        includeCustomFields: true,
        includeGroups: true,
        includeTerritories: true,
      },
      $limit: RELATIONSHIP_DATA_LOAD_LIMIT,
      $offset,
      $order: "name",
    });
    yield put(fetchMorePeople.success(response.data));
  } catch (error) {
    yield put(fetchMorePeople.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchPins({ payload }: ReturnType<typeof fetchPins.request>) {
  try {
    const isColorChanged = !!payload.request?.colorKey?.[EntityType.COMPANY];
    const isShapeChanged = !!payload.request?.shapeKey?.[EntityType.COMPANY];

    if (!isColorChanged && !isShapeChanged) {
      return;
    }

    const company: Company | undefined = yield select(getCompany);
    if (!company) {
      return;
    }

    const organization: Organization = yield select(getOrganization);

    yield take(applyMapViewSettings);

    const response: ListResponse<Company> = yield callApi(
      "fetchPins",
      organization.id,
      yield call(getFetchCompanyPayload, company.id)
    );

    const fetchedCompany: Company = response.data[0];

    if (isColorChanged) {
      yield put(setCompanyColor(fetchedCompany.color));
    }

    if (isShapeChanged) {
      yield put(setCompanyShape(fetchedCompany.shape));
    }
  } catch (error) {
    handleError({ error });
  }
}

export function* onUpdateCompanyPrimaryPerson({
  payload,
}: ReturnType<typeof updateCompanyPrimaryPerson.request>) {
  try {
    const { id, primaryPersonId } = payload;
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const company: Company = yield callApi("updateCompany", orgId, undefined, {
      id,
      primaryContact: primaryPersonId ? { id: primaryPersonId } : null,
    });

    yield put(notifyAboutChanges({ entityType: EntityType.COMPANY, updated: [company] }));

    yield put(
      updateCompanyPrimaryPerson.success({
        company,
      })
    );
  } catch (error) {
    yield put(updateCompanyPrimaryPerson.failure());
    yield put(handleError({ error }));
  }
}

export function* onSubscribeEmail() {
  try {
    const organization: Organization = yield select(getOrganization);
    const recordData: CompanyRecordData = yield select(getRecordData);

    if (!recordData.company) {
      return;
    }

    yield callApi(
      "subscribeEntityEmail",
      organization.id,
      EntityType.COMPANY,
      recordData.company.id
    );

    yield put(subscribeCompanyEmail.success());
  } catch (error) {
    yield put(subscribeCompanyEmail.failure());
    handleError({ error });
  }
}

export function* onUnsubscribeEmail() {
  try {
    const organization: Organization = yield select(getOrganization);
    const recordData: CompanyRecordData = yield select(getRecordData);

    if (!recordData.company) {
      return;
    }

    yield callApi(
      "unsubscribeEntityEmail",
      organization.id,
      EntityType.COMPANY,
      recordData.company.id
    );

    yield put(unsubscribeCompanyEmail.success());
  } catch (error) {
    yield put(unsubscribeCompanyEmail.failure());
    handleError({ error });
  }
}

export function* companyRecordSaga() {
  yield takeEvery(addCompanyChildCompanies.request, onAddCompanyChildCompanies);
  yield takeEvery(addCompanyDeals.request, onAddCompanyDeals);
  yield takeEvery(addCompanyParentCompany.request, onAddCompanyParentCompany);
  yield takeEvery(addCompanyPeople.request, onAddCompanyPeople);
  yield takeEvery(createCompanyNote.request, onCreateCompanyNote);
  yield takeEvery(deleteCompanyFile.request, onDeleteCompanyFile);
  yield takeEvery(deleteCompanyNote.request, onDeleteCompanyNote);
  yield takeEvery(downloadCompanyFile, onDownloadCompanyFile);
  yield takeLatest(fetchCompanyRoutes.request, onFetchCompanyRoutes);
  yield takeLatest(fetchCompanyActivities.request, onFetchCompanyActivities);
  yield takeLatest(
    fetchCompanyActivitiesCompletedByType.request,
    onFetchCompanyActivitiesCompletedByType
  );
  yield takeLatest(fetchPreviewData.request, onFetchPreviewData);
  yield takeLatest(fetchCompanyActivitiesTotal.request, onFetchCompanyActivitiesTotal);
  yield takeEvery(postponeActivity.request, onPostponeActivity);
  yield takeEvery(removeCompanyChildCompany.request, onRemoveCompanyChildCompany);
  yield takeEvery(removeCompanyDeal.request, onRemoveCompanyDeal);
  yield takeEvery(removeCompanyParentCompany.request, onRemoveCompanyParentCompany);
  yield takeEvery(removeCompanyPerson.request, onRemoveCompanyPerson);
  yield takeEvery(removeCompanyFromRoute.request, onRemoveCompanyFromRoute);
  yield takeEvery(toggleCompleteActivity.request, onToggleCompleteActivity);
  yield takeEvery(updateActivityNote.request, onUpdateActivityNote);
  yield takeEvery(updateCompany.request, onUpdateCompany);
  yield takeEvery(updateCompanyFrequency.request, onUpdateCompanyFrequency);
  yield takeEvery(uploadCompanyFiles.request, onUploadCompanyFiles);
  yield takeEvery(updateCompanyNote.request, onUpdateCompanyNote);
  yield takeEvery(changeActivitiesRecapRange, onChangeActivitiesRecapRange);
  yield takeEvery(changeActivitiesSelectedActivityTypes, onChangeActivitiesSelectedActivityTypes);
  yield takeEvery(deleteCompany.request, onDeleteCompany);
  yield takeEvery(fetchThumbnail, onFetchThumbnail);
  yield takeLeading(fetchMoreDeals.request, onFetchDeals);
  yield takeLeading(fetchMorePeople.request, onFetchPeople);
  yield takeLatest(updateCompanyPrimaryPerson.request, onUpdateCompanyPrimaryPerson);
  yield takeEvery(fetchPins.request, onFetchPins);
  yield takeLatest(subscribeCompanyEmail.request, onSubscribeEmail);
  yield takeLatest(unsubscribeCompanyEmail.request, onUnsubscribeEmail);
}

import React, { ReactElement, ReactNode, useCallback, useMemo } from "react";

import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faClose } from "@fortawesome/pro-solid-svg-icons/faClose";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons/faInfoCircle";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons/faQuestionCircle";
import { faXmarkCircle } from "@fortawesome/pro-solid-svg-icons/faXmarkCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
// eslint-disable-next-line no-restricted-imports
import AntdModal, { ModalProps as AntdModalProps } from "antd/es/modal";
// eslint-disable-next-line no-restricted-imports
import { ModalFuncProps as AntdModalFuncProps } from "antd/es/modal/Modal";
// eslint-disable-next-line no-restricted-imports
import useModalAntd from "antd/es/modal/useModal";
import cn from "classnames";

const block = bem("mmc-modal");

export type ModalFuncProps = Omit<AntdModalFuncProps, "type"> & { type: ModalType };

// we can support changing size if needed in future by changing className
export type ModalConfigUpdateModalProps = Omit<ModalFuncProps, "icon" | "size" | "type">;

export type ModalConfigUpdate =
  | ((prevConfig: ModalConfigUpdateModalProps) => ModalConfigUpdateModalProps)
  | ModalConfigUpdateModalProps;

export type ModalType = "confirm" | "error" | "info" | "success" | "warning";

export type ModalSize = "normal" | "small";

export type ModalFunc = (args: ModalFuncProps) => {
  destroy: () => void;
  update: (configUpdate: ModalConfigUpdate) => void;
};

export type ModalFunctions = { show: ModalFunc };

const getBemModifiers = ({
  closable,
  icon,
  scrollable,
  size,
  type,
}: {
  closable?: boolean;
  icon?: ReactNode;
  scrollable?: boolean;
  size?: ModalSize;
  type?: ModalType;
}): Record<string, boolean | undefined> => {
  return {
    ...{
      closable,
      normal: size === "normal",
      scrollable,
      small: size === "small",
      ["with-icon"]: !!icon,
    },
    ...(type ? { [type]: true } : {}),
  };
};

const getIcon = (type?: ModalType, icon?: ReactNode) => {
  const getFaIcon = (type: ModalType) => {
    switch (type) {
      case "confirm":
        return faQuestionCircle;
      case "error":
        return faXmarkCircle;
      case "info":
        return faInfoCircle;
      case "success":
        return faCheckCircle;
      case "warning":
        return faExclamationCircle;
    }
  };

  const wrapIcon = (icon: ReactNode) => {
    return <span className={block("icon")}>{icon}</span>;
  };

  if (icon) {
    return wrapIcon(icon);
  }

  if (icon === undefined && type) {
    return wrapIcon(<FontAwesomeIcon icon={getFaIcon(type)} />);
  }

  return null;
};

const getAntModalFuncProps = ({
  className,
  closable = false,
  closeIcon = <FontAwesomeIcon icon={faClose} />,
  icon: iconProp,
  type,
  ...props
}: ModalFuncProps): AntdModalFuncProps => {
  const icon = getIcon(type, iconProp);
  return {
    ...props,
    className: cn(block(getBemModifiers({ closable, icon, size: "small", type })), className),
    closeIcon,
    icon,
  };
};

export const useModal = (): [ModalFunctions, ReactElement] => {
  const [modalAntd, modalContext] = useModalAntd();

  const open = useCallback(
    (props: ModalFuncProps) => {
      return modalAntd[props.type](getAntModalFuncProps(props));
    },
    [modalAntd]
  );

  const modal = useMemo(
    () => ({
      show: (args: ModalFuncProps) => open(args),
    }),
    [open]
  );

  return [modal, modalContext];
};

export const showModal = (props: ModalFuncProps): ReturnType<ModalFunc> => {
  return AntdModal[props.type](getAntModalFuncProps(props));
};

export type ModalProps = AntdModalProps & {
  actions?: ReactNode;
  extra?: ReactNode;
  icon?: ReactNode;
  scrollable?: boolean;
  size?: ModalSize;
  subtitle?: ReactNode;
  type?: ModalType;
};

const Modal: React.FC<ModalProps> = ({
  actions,
  className,
  closable = true,
  closeIcon = <FontAwesomeIcon icon={faClose} />,
  extra,
  icon: iconProp,
  scrollable,
  size = "normal",
  subtitle,
  title,
  type,
  ...props
}) => {
  const icon = useMemo(() => getIcon(type, iconProp), [iconProp, type]);

  return (
    <AntdModal
      centered
      className={cn(block(getBemModifiers({ closable, icon, scrollable, size, type })), className)}
      closable={closable}
      closeIcon={closeIcon}
      title={
        title && (
          <div className={block("header")}>
            <div className={block("title-actions")}>
              {icon}
              <div className={block("title")}>{title}</div>
              {actions && <div className={block("actions")}>{actions}</div>}
            </div>
            {subtitle && <div className={block("subtitle")}>{subtitle}</div>}
            {extra && <div className={block("extra")}>{extra}</div>}
          </div>
        )
      }
      {...props}
    />
  );
};

export default Modal;

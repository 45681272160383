enum CountryRegion {
  CA_ATLANTIC = "CA_Atlantic",
  CA_CENTRAL = "CA_Central",
  CA_NORTH = "CA_North",
  CA_PRAIRIE = "CA_Prairie",
  CA_WEST_COAST = "CA_WestCoast",
  US_MIDWEST = "US_Midwest",
  US_NORTHEAST = "US_Northeast",
  US_SOUTH = "US_South",
  US_WEST = "US_West",
}

export default CountryRegion;

import React, { SyntheticEvent } from "react";

import { defineMessages, useIntl } from "react-intl";

import ButtonLink from "@app/component/ButtonLink";
import IntervalUnit, { getIntervalUnitDisplayName } from "@app/enum/IntervalUnit";
import MmcNotificationAction from "@app/enum/MmcNotificationAction";
import MmcNotification, { MmcNotificationEntity } from "@app/types/MmcNotification";
import { MINUTES_IN_DAY, MINUTES_IN_HOUR, MINUTES_IN_WEEK } from "@app/util/consts";
import { userDisplayName } from "@app/util/formatters";
import { getEntityTypeDisplayName } from "@app/util/ui";

import styles from "../NotificationCommon.module.scss";

const descriptionMessages = defineMessages({
  [MmcNotificationAction.ACTIVITY_ASSIGNED]: {
    id: "navbar.notifications.list.notification.description.activityAssigned",
    defaultMessage: "{user} assigned an activity to you",
    description: "Navbar Notifications - list - notification - activity assigned description",
  },
  [MmcNotificationAction.ACTIVITY_LOGGED]: {
    id: "navbar.notifications.list.notification.description.activityLogged",
    defaultMessage: "{user} logged an activity with {associatedEntityLink}",
    description: "Navbar Notifications - list - notification - activity logged description",
  },
  [MmcNotificationAction.ACTIVITY_STARTING]: {
    id: "navbar.notifications.list.notification.description.activity_starting",
    defaultMessage: "Activity starting in {remindBefore} {intervalUnit}",
    description: "Navbar Notifications - list - notification - activity starting",
  },
  [MmcNotificationAction.ACTIVITY_TIME_CHANGED]: {
    id: "navbar.notifications.list.notification.description.activity_time_changed",
    defaultMessage: "{user} updated the time of an activity",
    description: "Navbar Notifications - list - notification - activity time changed",
  },
  [MmcNotificationAction.COMPANY_GROUP_SHARED]: {
    id: "navbar.notifications.list.notification.description.companyGroupShared",
    defaultMessage: "1 Company group was shared with you",
    description: "Navbar Notifications - list - notification - company group shared description",
  },
  [MmcNotificationAction.COMPANY_ROUTE_SHARED]: {
    id: "navbar.notifications.list.notification.description.companyRouteShared",
    defaultMessage: "1 Company route was shared with you",
    description: "Navbar Notifications - list - notification - company route shared description",
  },

  [MmcNotificationAction.DEAL_GROUP_SHARED]: {
    id: "navbar.notifications.list.notification.description.dealGroupShared",
    defaultMessage: "1 deal group was shared with you",
    description: "Navbar Notifications - list - notification - deal group shared description",
  },
  [MmcNotificationAction.DEALS_MARKED_LOST]: {
    id: "navbar.notifications.list.notification.description.dealsMarkedLost",
    defaultMessage:
      "{count, plural, one {A Deal has} other {{count} Deals have}} been marked Closed Lost",
    description: "Navbar Notifications - list - notification - deals marked lost description",
  },
  [MmcNotificationAction.DEALS_MARKED_WON]: {
    id: "navbar.notifications.list.notification.description.dealsMarkedWon",
    defaultMessage:
      "{count, plural, one {A Deal has} other {{count} Deals have}} been marked Closed Won",
    description: "Navbar Notifications - list - notification - deals marked won description",
  },
  [MmcNotificationAction.DEALS_ROTTING]: {
    id: "navbar.notifications.list.notification.description.dealsRotting",
    defaultMessage: "{count, plural, one {A Deal} other {{count} Deals}} started rotting",
    description: "Navbar Notifications - list - notification - deals rotting description",
  },
  [MmcNotificationAction.DEALS_STAGE_CHANGED]: {
    id: "navbar.notifications.list.notification.description.dealsStageChanged",
    defaultMessage:
      "{count, plural, one {A Deal has} other {{count} Deals have}} been moved to a new stage",
    description: "Navbar Notifications - list - notification - deals stage changed description",
  },
  [MmcNotificationAction.GUIDE_ASSIGNED]: {
    id: "navbar.notifications.list.notification.description.guideAssigned",
    defaultMessage:
      "{user} assigned {count} {count, plural, one {training guide} other {training guides}} to you",
    description: "Navbar Notifications - list - notification - shared deals closing",
  },
  [MmcNotificationAction.GUIDE_REMINDER]: {
    id: "navbar.notifications.list.notification.description.guideReminder",
    defaultMessage:
      "You have {count} more {count, plural, one {training guide} other {training guides}} to complete",
    description: "Navbar Notifications - list - notification - shared deals closing",
  },
  [MmcNotificationAction.INCOMPLETE_ACTIVITY]: {
    id: "navbar.notifications.list.notification.description.incomplete_activity",
    defaultMessage: "Did you finish “{entityName}”?",
    description: "Navbar Notifications - list - notification - incomplete activity",
  },
  [MmcNotificationAction.MARK_ACTIVITY_AS_DONE]: {
    id: "navbar.notifications.list.notification.description.mark_activity_as_done",
    defaultMessage: "Did you finish “{entityName}”?",
    description: "Navbar Notifications - list - notification - mark activity as done description",
  },
  [MmcNotificationAction.MENTION]: {
    id: "navbar.notifications.list.notification.description.mention",
    defaultMessage: "{user} mentioned you in a note on {entityLink}",
    description: "Navbar Notifications - list - notification - mention description",
  },
  [MmcNotificationAction.NOTE_ADDED]: {
    id: "navbar.notifications.list.notification.description.noteAdded",
    defaultMessage: "{user} left a note on {entityLink}",
    description: "Navbar Notifications - list - notification - note added description",
  },
  [MmcNotificationAction.OUT_OF_CADENCE]: {
    id: "navbar.notifications.list.notification.description.outOfCadence",
    defaultMessage: "{count} {count, plural, one {record} other {records}} you own is past due",
    description: "Navbar Notifications - list - notification - out of cadence description",
  },
  [MmcNotificationAction.OVERDUE_ACTIVITIES]: {
    id: "navbar.notifications.list.notification.description.overdueActivities",
    defaultMessage:
      "{count, plural, one {An activity is} other {{count} activities became}} overdue",
    description: "Navbar Notifications - list - notification - overdue activities description",
  },
  [MmcNotificationAction.OWNED_DEALS_CLOSING]: {
    id: "navbar.notifications.list.notification.description.ownedDealsClosing",
    defaultMessage:
      "{count, plural, one {Set} other {Multiple deals set}} to close in {entityInterval, select, day {one day} week {7 days} other {30 days}}",
    description: "Navbar Notifications - list - notification - owned deals closing",
  },
  [MmcNotificationAction.OWNERSHIP_CHANGED]: {
    id: "navbar.notifications.list.notification.description.ownershipChanged",
    defaultMessage: "You’ve been made owner of a {entityType}",
    description: "Navbar Notifications - list - notification - ownership changed description",
  },
  [MmcNotificationAction.PEOPLE_GROUP_SHARED]: {
    id: "navbar.notifications.list.notification.description.peopleGroupShared",
    defaultMessage: "1 people group was shared with you",
    description: "Navbar Notifications - list - notification - people group shared description",
  },
  [MmcNotificationAction.PEOPLE_ROUTE_SHARED]: {
    id: "navbar.notifications.list.notification.description.peopleRouteShared",
    defaultMessage: "1 people route was shared with you",
    description: "Navbar Notifications - list - notification - people route shared description",
  },
  [MmcNotificationAction.SHARED_DEALS_CLOSING]: {
    id: "navbar.notifications.list.notification.description.sharedDealsClosing",
    defaultMessage:
      "{count, plural, one {Set} other {Multiple deals set}} to close in {entityInterval, select, day {one day} week {7 days} other {30 days}}",
    description: "Navbar Notifications - list - notification - shared deals closing",
  },
});

interface Props {
  notification: MmcNotification;
  onEntityClick: (entity: MmcNotificationEntity, event?: SyntheticEvent<HTMLElement>) => void;
}

const Description: React.FC<Props> = ({
  notification: { action, associatedEntity, entity, updatedBy },
  onEntityClick,
}) => {
  const intl = useIntl();

  let intervalUnit;
  let remindBefore = entity?.[0].remindBeforeMinutes;

  if (remindBefore) {
    if (remindBefore % MINUTES_IN_WEEK === 0) {
      remindBefore = Math.round(remindBefore / MINUTES_IN_WEEK);
      intervalUnit = getIntervalUnitDisplayName(intl, IntervalUnit.WEEK, remindBefore);
    } else if (remindBefore % MINUTES_IN_DAY === 0) {
      remindBefore = Math.round(remindBefore / MINUTES_IN_DAY);
      intervalUnit = getIntervalUnitDisplayName(intl, IntervalUnit.DAY, remindBefore);
    } else if (remindBefore % MINUTES_IN_HOUR === 0) {
      remindBefore = Math.round(remindBefore / MINUTES_IN_HOUR);
      intervalUnit = getIntervalUnitDisplayName(intl, IntervalUnit.HOUR, remindBefore);
    } else {
      intervalUnit = getIntervalUnitDisplayName(intl, IntervalUnit.MINUTE, remindBefore);
    }
  }

  return (
    <div className={styles.description}>
      {intl.formatMessage(descriptionMessages[action], {
        associatedEntityLink: associatedEntity ? (
          <ButtonLink onClick={(event) => onEntityClick(associatedEntity, event)}>
            {associatedEntity.name}
          </ButtonLink>
        ) : (
          ""
        ),
        count: entity?.length ?? 0,
        entityInterval: entity?.[0]?.interval ?? "",
        entityLink:
          entity && entity.length > 0 ? (
            <ButtonLink onClick={(event) => onEntityClick(entity[0], event)}>
              {entity[0].name}
            </ButtonLink>
          ) : (
            ""
          ),
        entityName: entity && entity.length > 0 ? entity[0].name : "",
        entityType:
          entity && entity.length > 0
            ? getEntityTypeDisplayName(intl, entity[0].type, {
                lowercase: false,
                plural: false,
              })
            : "",
        intervalUnit,
        remindBefore,
        user: updatedBy ? userDisplayName(updatedBy) : "",
      })}
    </div>
  );
};

export default Description;

import React from "react";

import { differenceInCalendarDays, differenceInMinutes, subMinutes } from "date-fns/esm";
import { defineMessages, IntlShape } from "react-intl";

import { Activity } from "@mapmycustomers/shared/types/entity";
import { showModal } from "@mapmycustomers/ui";

import { formatDate } from "@app/util/formatters";
import { parseApiDate } from "@app/util/parsers";

import styles from "./ActivityTimeUpdateAlert.module.scss";

const messages = defineMessages({
  autoAdjust: {
    id: "activities.timeUpdateAlert.autoAdjust",
    defaultMessage:
      "We can automatically adjust the date and time for you. The new date and time would be:",
    description: "Warning description to auto adjust time for activity time update",
  },
  cancelText: {
    id: "activities.timeUpdateAlert.cancelText",
    defaultMessage: "Keep original",
    description: "Cancel text for activity time update",
  },
  dateFormat: {
    id: "activities.timeUpdateAlert.dateFormat",
    defaultMessage: "{startDate} at {startAt}.",
    description: "Activity date format",
  },
  description: {
    id: "activities.timeUpdateAlert.description",
    defaultMessage: "The activity was originally scheduled for:",
    description: "Warning description for activity time update",
  },
  multiDateFormat: {
    id: "activities.timeUpdateAlert.multiDateFormat",
    defaultMessage:
      "{startDate} {sameDate, select, true {from} other {at}} {startAt} - {sameDate, select, true {} other {{endDate} at}} {endAt}.",
    description: "Activity date format",
  },
  okText: {
    id: "activities.timeUpdateAlert.okText",
    defaultMessage: "Update date and time",
    description: "Ok text for activity time update",
  },
  title: {
    id: "activities.timeUpdateAlert.title",
    defaultMessage: "Did you do this activity today?",
    description: "Warning title for activity time update",
  },
});

const showActivityTimeUpdateAlert = (
  intl: IntlShape,
  activity: Activity,
  onUpdate: (updatedActivity: Activity) => void
) => {
  const startAt = parseApiDate(activity.startAt!);
  const endAt = activity.endAt ? parseApiDate(activity.endAt) : undefined;
  const currentDate = new Date();
  const difference = endAt ? differenceInMinutes(endAt, startAt) : 0;
  const suggestedStartAt = subMinutes(currentDate, difference);

  const handleOk = () => {
    const updatedActivity = {
      ...activity,
      endAt: endAt ? currentDate?.toISOString() : null,
      startAt: suggestedStartAt.toISOString(),
    };
    onUpdate(updatedActivity);
  };

  const handleCancel = () => {
    onUpdate(activity);
  };

  showModal({
    cancelText: intl.formatMessage(messages.cancelText),
    content: (
      <div className={styles.content}>
        <span>{intl.formatMessage(messages.description)}</span>
        <span className={styles.date}>
          {intl.formatMessage(endAt ? messages.multiDateFormat : messages.dateFormat, {
            endAt: endAt ? formatDate(endAt, "p") : null,
            endDate: endAt ? formatDate(endAt, "PPPP") : null,
            sameDate: endAt ? differenceInCalendarDays(startAt, endAt) === 0 : false,
            startAt: formatDate(startAt, "p"),
            startDate: formatDate(startAt, "PPPP"),
          })}
        </span>
        <span>{intl.formatMessage(messages.autoAdjust)}</span>
        <span className={styles.date}>
          {intl.formatMessage(endAt ? messages.multiDateFormat : messages.dateFormat, {
            endAt: endAt ? formatDate(currentDate, "p") : null,
            endDate: endAt ? formatDate(currentDate, "PPPP") : null,
            sameDate: endAt ? differenceInCalendarDays(currentDate, suggestedStartAt) === 0 : false,
            startAt: formatDate(suggestedStartAt, "p"),
            startDate: formatDate(suggestedStartAt, "PPPP"),
          })}
        </span>
      </div>
    ),
    okText: intl.formatMessage(messages.okText),
    onCancel: handleCancel,
    onOk: handleOk,
    title: intl.formatMessage(messages.title),
    type: "confirm",
    wrapClassName: styles.alertWrapper,
  });
};

export default showActivityTimeUpdateAlert;

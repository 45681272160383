import { ActionType, createAsyncAction } from "typesafe-actions";

import { GuideUserStatus } from "@mapmycustomers/shared/types/entity";

// WARNING! Only use this action when you can't get company from the store
export const updateGuideStatus = createAsyncAction(
  "@onboarding/updateGuideStatus/request",
  "@onboarding/updateGuideStatus/success",
  "@onboarding/updateGuideStatus/failure"
)<GuideUserStatus, void, void>();

export type Actions = ActionType<typeof updateGuideStatus>;

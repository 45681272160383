import { defineMessages } from "react-intl";

import EmailLogStatus from "@mapmycustomers/shared/enum/EmailLogStatus";

const statusMessages = defineMessages({
  [EmailLogStatus.ERRORED]: {
    id: "emailQueueModal.status.errored",
    defaultMessage: "Not Sent",
    description: "Email queue modal action errored status name.",
  },
  [EmailLogStatus.SENDING]: {
    id: "emailQueueModal.status.sending",
    defaultMessage: "Sending",
    description: "Email queue modal action sending status name.",
  },
  [EmailLogStatus.SENT]: {
    id: "emailQueueModal.status.sent",
    defaultMessage: "Sent",
    description: "Email queue modal action sent status name.",
  },
  [EmailLogStatus.SUBMITTED]: {
    id: "emailQueueModal.status.submitted",
    defaultMessage: "Queued",
    description: "Email queue modal action submitted status name.",
  },
});

export default statusMessages;

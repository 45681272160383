import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Activity from "@mapmycustomers/shared/types/entity/Activity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import FormLayout from "@mapmycustomers/shared/types/layout/FormLayout";
import Team from "@mapmycustomers/shared/types/Team";
import { UserRef } from "@mapmycustomers/shared/types/User";
import ListRequest from "@mapmycustomers/shared/types/viewModel/internalModel/ListRequest";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";

import { RecurringActionType } from "@app/component/createEditEntity/Activity/enum/RecurringActionType";
import FileListItem from "@app/types/FileListItem";
import { ListFetcherPayload } from "@app/types/viewModel/ListFetcher";

export const fetchList = createAsyncAction(
  "activityList/fetch/request",
  "activityList/fetch/success",
  "activityList/fetch/failure"
)<
  ListFetcherPayload<Activity>,
  { list: Activity[]; totalFilteredRecords: number; totalRecords: number },
  unknown
>();

export const fetchChildActivities = createAsyncAction(
  "activityList/fetchChildActivities/request",
  "activityList/fetchChildActivities/success",
  "activityList/fetchChildActivities/failure"
)<
  { parentActivityId: Activity["id"]; preselect?: boolean },
  { activities: Activity[]; parentActivityId: Activity["id"]; preselect?: boolean } | void,
  void
>();

export const changeActivityAssignee = createAction("activityList/changeActivityAssignee")<
  undefined | UserRef
>();

export const changeActivityType = createAction("activityList/changeActivityType")<
  ActivityType | undefined
>();

export const changeActivityVisibility = createAction("activityList/changeActivityVisibility")<
  ActivityVisibility | undefined
>();

export const fetchFilePreview = createAsyncAction(
  "activityList/fetchFilePreview/request",
  "activityList/fetchFilePreview/success",
  "activityList/fetchFilePreview/failure"
)<{ activityId: Activity["id"]; file: RawFile["id"] }, Blob, void>();

export const changeActivityVisibilityTeamsIds = createAction(
  "activityList/changeActivityVisibilityTeamsIds"
)<Team["id"][] | undefined>();

export const bulkEdit = createAsyncAction(
  "activityList/bulkEdit/request",
  "activityList/bulkEdit/success",
  "activityList/bulkEdit/failure"
)<
  {
    activities: Array<OnlyRequiredFields<Activity, "id"> & { layoutId: FormLayout["id"] }>;
    onSuccess: () => void;
  },
  OnlyRequiredFields<Activity, "id">[],
  unknown
>();

export const uploadActivityFiles = createAsyncAction(
  "activityList/uploadActivityFiles/request",
  "activityList/uploadActivityFiles/success",
  "activityList/uploadActivityFiles/failure"
)<
  {
    activityId: Activity["id"];
    callback?: (filesList: FileListItem[]) => void;
    files: (Blob | File)[];
  },
  FileListItem[],
  void
>();

export const removeActivityFile = createAsyncAction(
  "activityList/removeActivityFile/request",
  "activityList/removeActivityFile/success",
  "activityList/removeActivityFile/failure"
)<
  { activity: Activity; file: RawFile; onSuccess: (activity: Activity) => void },
  { activity: Activity; file: RawFile["id"] },
  void
>();

export const updateActivity = createAsyncAction(
  "activityList/updateActivity/request",
  "activityList/updateActivity/success",
  "activityList/updateActivity/failure"
)<Activity, Activity, Activity["id"]>();

export const bulkPostpone = createAsyncAction(
  "activityList/bulkPostpone/request",
  "activityList/bulkPostpone/success",
  "activityList/bulkPostpone/failure"
)<
  {
    activities: {
      endAt?: Activity["endAt"];
      id: Activity["id"];
      layoutId: FormLayout["id"];
      startAt: Activity["startAt"];
    }[];
    period: string;
  },
  { endAt?: Activity["endAt"]; id: Activity["id"]; startAt: Activity["startAt"] }[],
  unknown
>();

export const toggleComplete = createAsyncAction(
  "activityList/toggleComplete/request",
  "activityList/toggleComplete/success",
  "activityList/toggleComplete/failure"
)<Activity, Pick<Activity, "completed" | "completedAt" | "id">, Activity["id"]>();

export const bulkToggleComplete = createAsyncAction(
  "activityList/bulkToggleComplete/request",
  "activityList/bulkToggleComplete/success",
  "activityList/bulkToggleComplete/failure"
)<
  {
    activities: Array<
      Pick<Activity, "completed" | "completedAt" | "id"> & { layoutId: FormLayout["id"] }
    >;
    completed: boolean;
  },
  Pick<Activity, "completed" | "completedAt" | "id">[],
  unknown
>();

export const openDuplicationModal = createAsyncAction(
  "activityList/openDuplicationModal/request",
  "activityList/openDuplicationModal/success",
  "activityList/openDuplicationModal/failure"
)<Activity["id"], Activity["id"], Activity["id"]>();

export const deleteActivity = createAsyncAction(
  "activity/deleteActivity/request",
  "activity/deleteActivity/success",
  "activity/deleteActivity/failure"
)<
  { callback?: () => void; deleteType: RecurringActionType | undefined; id: Activity["id"] },
  Activity["id"],
  Activity["id"]
>();

export const bulkDeleteActivities = createAsyncAction(
  "activityList/bulkDeleteActivities/request",
  "activityList/bulkDeleteActivities/success",
  "activityList/bulkDeleteActivities/failure"
)<{ ids: Activity["id"][]; onSuccess: () => void }, void, unknown>();

export const updateNote = createAsyncAction(
  "activityList/updateNote/request",
  "activityList/updateNote/success",
  "activityList/updateNote/failure"
)<
  { activity: Activity; onSuccess: () => void; updated?: boolean },
  Pick<Activity, "id" | "note">,
  Activity["id"]
>();

export const applyListViewSettings = createAction("activityList/applyListViewSettings")<
  Partial<ListRequest>
>();

export const toggleActivitySelection = createAction(
  "activityList/toggleActivitySelection"
)<Activity>();

export const resetSelectedActivities = createAction("activityList/resetSelectedActivities")();

export const toggleSelectAll = createAction("activityList/toggleSelectAll")<void>();

export const deselectAll = createAction("activityList/deselectAll")<void>();

export const subscribeOnViewStateUpdating = createAction("activity/subscribeOnViewStateUpdating")<{
  callback: (state: Partial<ViewState>) => void;
}>();

export const unsubscribeOnViewStateUpdating = createAction(
  "activity/unsubscribeOnViewStateUpdating"
)<void>();

export const updateActivityView = createAction("activity/updateActivityView")<{
  viewState: Partial<ViewState>;
}>();

export const exportActivities = createAction("activity/exportActivities")<{
  viewState: ViewState;
}>();

export type Actions = ActionType<
  | typeof applyListViewSettings
  | typeof bulkDeleteActivities
  | typeof bulkEdit
  | typeof bulkPostpone
  | typeof bulkToggleComplete
  | typeof changeActivityAssignee
  | typeof changeActivityType
  | typeof changeActivityVisibility
  | typeof changeActivityVisibilityTeamsIds
  | typeof deleteActivity
  | typeof deselectAll
  | typeof exportActivities
  | typeof fetchChildActivities
  | typeof fetchFilePreview
  | typeof fetchList
  | typeof openDuplicationModal
  | typeof removeActivityFile
  | typeof resetSelectedActivities
  | typeof subscribeOnViewStateUpdating
  | typeof toggleActivitySelection
  | typeof toggleComplete
  | typeof toggleSelectAll
  | typeof unsubscribeOnViewStateUpdating
  | typeof updateActivity
  | typeof updateActivityView
  | typeof updateNote
  | typeof uploadActivityFiles
>;

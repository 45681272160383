import { defineMessages } from "react-intl";

const messages = defineMessages({
  "ui.address.autoCompleteAddress.placeholder": {
    id: "ui.address.autoCompleteAddress.placeholder",
    defaultMessage: "Type to search",
    description: "Placeholder text for Auto Complete Address input",
  },
  "ui.address.findMe.tooltip": {
    id: "ui.address.findMe.tooltip",
    defaultMessage: "Find my location",
    description: "Find my location button in a Auto Complete Address input",
  },
  "ui.autoComplete.address.manualButton": {
    id: "ui.autoComplete.address.manualButton",
    defaultMessage: "Enter address manually",
    description: "Manual Address Button",
  },
  "ui.autoComplete.address.noResult": {
    id: "ui.autoComplete.address.noResult",
    defaultMessage: "No results",
    description: "No results label",
  },
  "ui.avatar.tooltip.open": {
    id: "ui.avatar.tooltip.open",
    defaultMessage: "Open {name}",
    description: "Open user/team tooltip message",
  },
  "ui.avatar.userDisplayNameWithInfo": {
    id: "ui.avatar.userDisplayNameWithInfo",
    defaultMessage:
      "{username}{isDeleted, select, true { (Deleted)} other {}}{isDeactivated, select, true { (Deactivated)} other {}}",
    description: "Full username with meta-info about its delete or deactivated status",
  },
  "ui.colorComponents.changeColor": {
    id: "ui.colorComponents.changeColor",
    defaultMessage: "Change Color",
    description: "Change Color message in a ColorComponent",
  },
  "ui.common.teamItem.noTeam": {
    id: "ui.common.teamItem.noTeam",
    defaultMessage: "No Team",
    description: "No Team item label in the User Menu",
  },
  "ui.common.teamItem.owners": {
    id: "ui.common.teamItem.owners",
    defaultMessage: "Owners",
    description: "Owners item label in the User Menu",
  },
  "ui.editableText.cancel.tooltip": {
    id: "ui.editableText.cancel.tooltip",
    defaultMessage: "Cancel",
    description: "Tooltip of the cancel button in EditableText field",
  },
  "ui.editableText.save.tooltip": {
    id: "ui.editableText.save.tooltip",
    defaultMessage: "Save",
    description: "Tooltip of the save button in EditableText field",
  },
  "ui.editableText.save.tooltip.error.required": {
    id: "ui.editableText.save.tooltip.error.required",
    defaultMessage: "Non-empty value is required",
    description:
      "Tooltip of the save button in EditableText field when non-empty value is required",
  },
  "ui.editableText.tooltip": {
    id: "ui.editableText.tooltip",
    defaultMessage: "Edit",
    description: "Tooltip for the EditableText field",
  },
  "ui.emailBody.attachedFiles.fileSize": {
    id: "ui.emailBody.attachedFiles.fileSize",
    defaultMessage: "{size, number, ::K}MB",
  },
  "ui.emailBody.dynamicVar.someEntitiesMissingData.company": {
    id: "ui.emailBody.dynamicVar.someEntitiesMissingData.company",
    defaultMessage: "Some companies are missing data for this variable",
  },
  "ui.emailBody.dynamicVar.someEntitiesMissingData.description": {
    id: "ui.emailBody.dynamicVar.someEntitiesMissingData.description",
    defaultMessage: "When a variable is missing, it will show up blank.",
  },
  "ui.emailBody.dynamicVar.someEntitiesMissingData.ok": {
    id: "ui.emailBody.dynamicVar.someEntitiesMissingData.ok",
    defaultMessage: "Okay",
  },
  "ui.emailBody.dynamicVar.someEntitiesMissingData.person": {
    id: "ui.emailBody.dynamicVar.someEntitiesMissingData.person",
    defaultMessage: "Some people are missing data for this variable",
  },
  "ui.emailBody.emailUnsubscribeLink.checkboxLabel": {
    id: "ui.emailBody.emailUnsubscribeLink.checkboxLabel",
    defaultMessage: "Add unsubscribe link to email footer",
    description: "Email unsubscribe link checkbox label",
  },
  "ui.emailBody.emailUnsubscribeLink.checkboxTooltip": {
    id: "ui.emailBody.emailUnsubscribeLink.checkboxTooltip",
    defaultMessage:
      "Email laws require an unsubscribe link for all bulk emails sent for commercial purposes. If this bulk email is being sent for relationship or transactional purposes, it is not required. If a recipient unsubscribes, they will be automatically excluded from all emails when this box is checked.",
    description: "Email unsubscribe link checkbox checkbox tooltip",
  },
  "ui.emailBody.emailUnsubscribeLink.linkLabel": {
    id: "ui.emailBody.emailUnsubscribeLink.linkLabel",
    defaultMessage: "Unsubscribe",
    description: "Email unsubscribe link checkbox link label",
  },
  "ui.emailBody.imageModal.description": {
    id: "ui.emailBody.imageModal.description",
    defaultMessage:
      "Alt text is a brief image description that helps visually impaired users via screen readers and appears if images don't load.",
    description: "Email image alt description",
  },
  "ui.emailBody.imageModal.editAlt": {
    id: "ui.emailBody.imageModal.editAlt",
    defaultMessage: "Edit alt text",
    description: "Email image edit alt label",
  },
  "ui.emailBody.imageModal.input": {
    id: "ui.emailBody.imageModal.input",
    defaultMessage: "Description",
    description: "Email image description label",
  },
  "ui.emailBody.imageModal.ok": {
    id: "ui.emailBody.imageModal.ok",
    defaultMessage: "Okay",
    description: "Email image modal ok",
  },
  "ui.emailBody.imageModal.remove": {
    id: "ui.emailBody.imageModal.remove",
    defaultMessage: "Remove",
    description: "Email image remove action label",
  },
  "ui.emailBody.imageModal.size.bestFit": {
    id: "ui.emailBody.imageModal.size.bestFit",
    defaultMessage: "Best Fit",
    description: "Email image best fit action label",
  },
  "ui.emailBody.imageModal.size.original": {
    id: "ui.emailBody.imageModal.size.original",
    defaultMessage: "Original Size",
    description: "Email image original size action label",
  },
  "ui.emailBody.imageModal.size.small": {
    id: "ui.emailBody.imageModal.size.small",
    defaultMessage: "Small",
    description: "Email image small size action label",
  },
  "ui.emailBody.imageModal.title": {
    id: "ui.emailBody.imageModal.title",
    defaultMessage: "Alt Text",
    description: "Email image alt text title",
  },
  "ui.emailBody.linkModal.change": {
    id: "ui.emailBody.linkModal.change",
    defaultMessage: "Change",
  },
  "ui.emailBody.linkModal.goToLink": {
    id: "ui.emailBody.linkModal.goToLink",
    defaultMessage: "Go to link: ",
  },
  "ui.emailBody.linkModal.insertLink": {
    id: "ui.emailBody.linkModal.insertLink",
    defaultMessage: "Insert Link",
  },
  "ui.emailBody.linkModal.linkText": {
    id: "ui.emailBody.linkModal.linkText",
    defaultMessage: "Link Text",
  },
  "ui.emailBody.linkModal.okay": { id: "ui.emailBody.linkModal.okay", defaultMessage: "Okay" },
  "ui.emailBody.linkModal.preview": {
    id: "ui.emailBody.linkModal.preview",
    defaultMessage: "Preview",
  },
  "ui.emailBody.linkModal.remove": {
    id: "ui.emailBody.linkModal.remove",
    defaultMessage: "Remove",
  },
  "ui.emailBody.linkModal.title": {
    id: "ui.emailBody.linkModal.title",
    defaultMessage: "Insert Link",
  },
  "ui.emailBody.linkModal.url": {
    id: "ui.emailBody.linkModal.url",
    defaultMessage: "URL",
  },
  "ui.emailBody.toolbar.actions.attachFiles": {
    id: "ui.emailBody.toolbar.actions.attachFiles",
    defaultMessage: "Attach Files",
  },
  "ui.emailBody.toolbar.actions.dynamicVar.searchPlaceholder": {
    id: "ui.emailBody.toolbar.actions.dynamicVar.searchPlaceholder",
    defaultMessage: "Search dynamic variables",
  },
  "ui.emailBody.toolbar.actions.dynamicVar.someEntityDontHaveValue": {
    id: "ui.emailBody.toolbar.actions.dynamicVar.someEntityDontHaveValue",
    defaultMessage: "One or more recipient does not have a value for this dynamic variable",
  },
  "ui.emailBody.toolbar.actions.formatting": {
    id: "ui.emailBody.toolbar.actions.formatting",
    defaultMessage: "Formatting options",
  },
  "ui.emailBody.toolbar.actions.info": {
    id: "ui.emailBody.toolbar.actions.info",
    defaultMessage:
      "Each recipient will receive an individual copy of this email and won't see the other recipients.",
  },
  "ui.emailBody.toolbar.actions.insertDynamicVar": {
    id: "ui.emailBody.toolbar.actions.insertDynamicVar",
    defaultMessage: "Insert dynamic variable",
  },
  "ui.emailBody.toolbar.actions.insertLink": {
    id: "ui.emailBody.toolbar.actions.insertLink",
    defaultMessage: "Insert link",
    description: "Insert Link button text",
  },
  "ui.emailBody.toolbar.actions.preview": {
    id: "ui.emailBody.toolbar.actions.preview",
    defaultMessage: "Preview",
  },
  "ui.emailBody.toolbar.dynamicVar.noResult.subTitle": {
    id: "ui.emailBody.toolbar.dynamicVar.noResult.subTitle",
    defaultMessage: "There are no dynamic variables that match this search.",
  },
  "ui.emailBody.toolbar.dynamicVar.noResult.title": {
    id: "ui.emailBody.toolbar.dynamicVar.noResult.title",
    defaultMessage: "No results",
  },
  "ui.emailBody.toolbar.large": {
    id: "ui.emailBody.toolbar.large",
    defaultMessage: "Large",
  },
  "ui.emailBody.toolbar.medium": {
    id: "ui.emailBody.toolbar.medium",
    defaultMessage: "Medium",
  },
  "ui.emailBody.toolbar.small": {
    id: "ui.emailBody.toolbar.small",
    defaultMessage: "Small",
  },
  "ui.entityFieldSelector.company": {
    id: "ui.entityFieldSelector.company",
    defaultMessage: "Company",
    description: "Company",
  },
  "ui.entityFieldSelector.deal": {
    id: "ui.entityFieldSelector.deal",
    defaultMessage: "Deal",
    description: "Deal",
  },
  "ui.entityFieldSelector.person": {
    id: "ui.entityFieldSelector.person",
    defaultMessage: "Person",
    description: "Person",
  },
  "ui.entityFieldSelector.search.placeholder": {
    id: "ui.entityFieldSelector.search.placeholder",
    defaultMessage: "Search for fields",
    description: "Select for fields placeholder",
  },
  "ui.inlineInput.unknown": {
    id: "ui.inlineInput.unknown",
    defaultMessage: "Unknown",
    description: "Inline inputs Unknown value placeholder",
  },
  "ui.input.richtext.mentionsDropdown.noResult": {
    id: "ui.input.richtext.mentionsDropdown.noResult",
    defaultMessage: "No results",
    description: "text Area With Mentions - No results",
  },
  "ui.manualAddress.city": {
    id: "ui.manualAddress.city",
    defaultMessage: "City",
    description: "City address field",
  },
  "ui.manualAddress.country": {
    id: "ui.manualAddress.country",
    defaultMessage: "Country",
    description: "Country address field",
  },
  "ui.manualAddress.postalCode": {
    id: "ui.manualAddress.postalCode",
    defaultMessage: "Postal Code",
    description: "Postal code address field",
  },
  "ui.manualAddress.regionProvince": {
    id: "ui.manualAddress.regionProvince",
    defaultMessage: "State/Province",
    description: "State/Province field on the Create Company modal",
  },
  "ui.manualAddress.saveAllFields": {
    id: "ui.manualAddress.saveAllFields",
    defaultMessage: "Save all address fields",
    description: "Save all fields",
  },
  "ui.manualAddress.searchGoogle": {
    id: "ui.manualAddress.searchGoogle",
    defaultMessage: "Search Addresses via Google",
    description: "Search with Google button for auto-complete address field",
  },
  "ui.manualAddress.street": {
    id: "ui.manualAddress.street",
    defaultMessage: "Street",
    description: "Street Address field",
  },
  "ui.recipientList.waringText.tooltip.duplicate": {
    id: "ui.recipientList.waringText.tooltip.duplicate",
    defaultMessage: "Duplicate email detected",
  },
  "ui.recipientList.waringText.tooltip.duplicate.description": {
    id: "ui.recipientList.waringText.tooltip.duplicate.description",
    defaultMessage:
      "This email already exists in the selection. Remove to avoid sending the same email twice.",
  },
  "ui.recipientList.waringText.tooltip.missingFields": {
    id: "ui.recipientList.waringText.tooltip.missingFields",
    defaultMessage: "Missing dynamic variable",
  },
  "ui.recipientList.waringText.tooltip.missingFields.description": {
    id: "ui.recipientList.waringText.tooltip.missingFields.description",
    defaultMessage:
      "This email doesn’t have a value for at least one dynamic variable that was used.",
  },
  "ui.recipientList.waringText.tooltip.ok": {
    id: "ui.recipientList.waringText.tooltip.ok",
    defaultMessage: "Remove",
  },
  "ui.recipientList.waringText.tooltip.title": {
    id: "ui.recipientList.waringText.tooltip.title",
    defaultMessage: "Issues were detected with this recipient's email:",
  },
  "ui.selectField.placeholder": {
    id: "ui.selectField.placeholder",
    defaultMessage: "Select",
    description: "Select field placeholder",
  },
  "ui.subjectInput.placeholder": {
    id: "ui.subjectInput.placeholder",
    defaultMessage: "Subject",
    description: "SubjectInput field's placeholder",
  },
  "ui.teamMultiSelect.placeholder": {
    id: "ui.teamMultiSelect.placeholder",
    defaultMessage: "Click or type to select teams",
    description: "Placeholder displayed in a TeamMultiSelectField component",
  },
  "ui.teamPicker.deselectAll": {
    id: "ui.teamPicker.deselectAll",
    defaultMessage: "Deselect All",
    description: "Deselect all teams button",
  },
  "ui.teamPicker.search.placeholder": {
    id: "ui.teamPicker.search.placeholder",
    defaultMessage: "Search teams",
    description: "Search teams placeholder",
  },
  "ui.teamPicker.selectAll": {
    id: "ui.teamPicker.selectAll",
    defaultMessage: "Select All",
    description: "Select all teams button",
  },
  "ui.textField.error.limit": {
    id: "ui.textField.error.limit",
    defaultMessage: "Character limit reached",
    description: "Character limit reached text on text input",
  },
  "ui.userPicker.deselectAll": {
    id: "ui.userPicker.deselectAll",
    defaultMessage: "Deselect All",
    description: "Deselect all users button",
  },
  "ui.userPicker.search.placeholder": {
    id: "ui.userPicker.search.placeholder",
    defaultMessage: "Search users",
    description: "Search users placeholder",
  },
  "ui.userPicker.selectAll": {
    id: "ui.userPicker.selectAll",
    defaultMessage: "Select All",
    description: "Select all users button",
  },
  "ui.visibilitySelector.entireOrg": {
    id: "ui.visibilitySelector.entireOrg",
    defaultMessage: "Entire org",
    description: "Entire org option",
  },
  "ui.visibilitySelector.myTeam": {
    id: "ui.visibilitySelector.myTeam",
    defaultMessage: "My team",
    description: "My team option",
  },
  "ui.visibilitySelector.onlyMe": {
    id: "ui.visibilitySelector.onlyMe",
    defaultMessage: "Only me",
    description: "Only me option",
  },
  "ui.visibilitySelector.search.placeholder": {
    id: "ui.visibilitySelector.search.placeholder",
    defaultMessage: "Search for teams",
    description: "Search for teams placeholdef",
  },
  "ui.visibilitySelector.specificTeams": {
    id: "ui.visibilitySelector.specificTeams",
    defaultMessage: "Specific teams",
    description: "Specific teams option",
  },
});

export default messages;

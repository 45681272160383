const messages = {
  "ui.emailBody.dynamicVar.someEntitiesMissingData.company":
    "Some companies are missing data for this variable",
  "ui.emailBody.dynamicVar.someEntitiesMissingData.description":
    "When a variable is missing, it will show up blank.",
  "ui.emailBody.dynamicVar.someEntitiesMissingData.ok": "Okay",
  "ui.emailBody.dynamicVar.someEntitiesMissingData.person":
    "Some people are missing data for this variable",
  "ui.emailBody.emailUnsubscribeLink.checkboxLabel": "Add unsubscribe link to email footer",
  "ui.emailBody.emailUnsubscribeLink.checkboxTooltip":
    "Email laws require an unsubscribe link for all bulk emails sent for commercial purposes. If this bulk email is being sent for relationship or transactional purposes, it is not required. If a recipient unsubscribes, they will be automatically excluded from all emails when this box is checked.",
  "ui.emailBody.emailUnsubscribeLink.linkLabel": "Unsubscribe",
  "ui.emailBody.linkModal.change": "Change",
  "ui.emailBody.linkModal.goToLink": "Go to link: ",
  "ui.emailBody.linkModal.insertLink": "Insert Link",
  "ui.emailBody.linkModal.linkText": "Link Text",
  "ui.emailBody.linkModal.okay": "Okay",
  "ui.emailBody.linkModal.preview": "Preview",
  "ui.emailBody.linkModal.remove": "Remove",
  "ui.emailBody.linkModal.title": "Insert Link",
  "ui.emailBody.linkModal.url": "URL",
  "ui.emailBody.toolbar.actions.attachFiles": "Attach Files",
  "ui.emailBody.toolbar.actions.dynamicVar.searchPlaceholder": "Search dynamic variables",
  "ui.emailBody.toolbar.actions.dynamicVar.someEntityDontHaveValue":
    "One or more recipient does not have a value for this dynamic variable",
  "ui.emailBody.toolbar.actions.formatting": "Formatting options",
  "ui.emailBody.toolbar.actions.info":
    "Each recipient will receive an individual copy of this email and won't see the other recipients.",
  "ui.emailBody.toolbar.actions.insertDynamicVar": "Insert dynamic variable",
  "ui.emailBody.toolbar.actions.insertLink": "Insert link",
  "ui.emailBody.toolbar.actions.preview": "Preview",
  "ui.emailBody.toolbar.dynamicVar.noResult.subTitle":
    "There are no dynamic variables that match this search.",
  "ui.emailBody.toolbar.dynamicVar.noResult.title": "No results",
  "ui.emailBody.toolbar.large": "Large",
  "ui.emailBody.toolbar.medium": "Medium",
  "ui.emailBody.toolbar.small": "Small",
} as const;

export default messages;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { Deal } from "@mapmycustomers/shared/types/entity";

import { DrillDownListFetcherPayload } from "@app/scene/dashboard/types/DrillDownListFetcher";
import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import OpenStageDealAmountCardConfiguration from "@app/types/dashboard/cards/openStageDealAmount/OpenStageDealAmountCardConfiguration";
import Scope from "@app/types/dashboard/Scope";

import OpenStageDealAmountCardData from "./OpenStageDealAmountCardData";

export const fetchOpenStageDealAmountCardData = createAction(
  "@dashboard/fetchOpenStageDealAmountCardData"
)<{
  callback: (data: OpenStageDealAmountCardData) => void;
  configuration: OpenStageDealAmountCardConfiguration;
  failureCallback?: () => void;
  scope?: Scope;
}>();

export const showOpenStageDealAmountDrillDown = createAsyncAction(
  "@dashboard/showOpenStageDealAmountCardDrillDownModal/request",
  "@dashboard/showOpenStageDealAmountCardDrillDownModal/success",
  "@dashboard/showOpenStageDealAmountCardDrillDownModal/failure"
)<
  {
    configuration: OpenStageDealAmountCardConfiguration;
    scope: Scope;
  },
  { viewState: DrillDownViewState },
  unknown
>();

export const hideOpenStageDealAmountDrillDown = createAction(
  "@dashboard/hideOpenStageDealAmountCardDrillDownModal"
)<void>();

export const fetchOpenStageDealAmountCardDrillDownData = createAsyncAction(
  "@dashboard/fetchOpenStageDealAmountCardDrillDownData/request",
  "@dashboard/fetchOpenStageDealAmountCardDrillDownData/success",
  "@dashboard/fetchOpenStageDealAmountCardDrillDownData/failure"
)<
  DrillDownListFetcherPayload<Deal>,
  {
    totalFilteredRecords: number;
    totalRecords: number;
  },
  unknown
>();

export const applyOpenStageDealAmountDrillDownListViewSettings = createAction(
  "@dashboard/applyOpenStageDealAmountDrillDownListViewSettings"
)<Partial<DrillDownViewState>>();

export const exportOpenStageDealAmountCardDrillDownData = createAction(
  "@dashboard/exportOpenStageDealAmountCardData"
)<{
  configuration: OpenStageDealAmountCardConfiguration;
  scope: Scope;
}>();

export type OpenStageDealAmountCardActions = ActionType<
  | typeof applyOpenStageDealAmountDrillDownListViewSettings
  | typeof exportOpenStageDealAmountCardDrillDownData
  | typeof fetchOpenStageDealAmountCardData
  | typeof fetchOpenStageDealAmountCardData
  | typeof fetchOpenStageDealAmountCardDrillDownData
  | typeof hideOpenStageDealAmountDrillDown
  | typeof showOpenStageDealAmountDrillDown
>;

import CountryRegion from "../enum/CountryRegion";
import { CountryState } from "../types/territory/CountryState";

export const LARGE_TEXT_LENGTH_LIMIT = 4000;

export const regionStates: Readonly<Record<CountryRegion, Readonly<CountryState[]>>> = {
  [CountryRegion.CA_ATLANTIC]: [
    "CA_NL", // Newfoundland and Labrador / Terre-Neuve-et-Labrador
    "CA_PE", // Prince Edward Island / Île-du-Prince-Édouard
    "CA_NS", // Nova Scotia / Nouvelle-Écosse
    "CA_NB", // New Brunswick / Nouveau-Brunswick
  ],

  [CountryRegion.CA_CENTRAL]: [
    "CA_QC", // Quebec / Québec
    "CA_ON", // Ontario
  ],

  [CountryRegion.CA_NORTH]: [
    "CA_MB", // Manitoba
    "CA_SK", // Saskatchewan
    "CA_AB", // Alberta
  ],

  [CountryRegion.CA_PRAIRIE]: [
    "CA_YT", // Yukon
    "CA_NT", // Northwest Territories / Territoires du Nord-Ouest
    "CA_NU", // Nunavut
  ],

  [CountryRegion.CA_WEST_COAST]: [
    "CA_BC", // British Columbia / Colombie-Britannique
  ],

  [CountryRegion.US_MIDWEST]: [
    // East North Central
    "US_IN", // Indiana
    "US_IL", // Illinois
    "US_MI", // Michigan
    "US_OH", // Ohio
    "US_WI", // Wisconsin
    // West North Central
    "US_IA", // Iowa
    "US_KS", // Kansas
    "US_MN", // Minnesota
    "US_MO", // Missouri
    "US_NE", // Nebraska
    "US_ND", // North Dakota
    "US_SD", // South Dakota
  ],

  [CountryRegion.US_NORTHEAST]: [
    // New England
    "US_CT", // Connecticut
    "US_ME", // Maine
    "US_MA", // Massachusetts
    "US_NH", // New Hampshire
    "US_RI", // Rhode Island
    "US_VT", // Vermont
    // Middle Atlantic
    "US_NJ", // New Jersey
    "US_NY", // New York
    "US_PA", // Pennsylvania
  ],

  [CountryRegion.US_SOUTH]: [
    // South Atlantic
    "US_DE", // Delaware
    "US_DC", // District of Columbia
    "US_FL", // Florida
    "US_GA", // Georgia
    "US_MD", // Maryland
    "US_NC", // North Carolina
    "US_SC", // South Carolina
    "US_VA", // Virginia
    "US_WV", // West Virginia
    // East South Central
    "US_AL", // Alabama
    "US_KY", // Kentucky
    "US_MS", // Mississippi
    "US_TN", // Tennessee
    // West South Central
    "US_AR", // Arkansas
    "US_LA", // Louisiana
    "US_OK", // Oklahoma
    "US_TX", // Texas
  ],

  [CountryRegion.US_WEST]: [
    // Mountain
    "US_AZ", // Arizona
    "US_CO", // Colorado
    "US_ID", // Idaho
    "US_NM", // New Mexico
    "US_MT", // Montana
    "US_UT", // Utah
    "US_NV", // Nevada
    "US_WY", // Wyoming
    // Pacific
    "US_AK", // Alaska
    "US_CA", // California
    "US_HI", // Hawaii
    "US_OR", // Oregon
    "US_WA", // Washington
  ],
} as const;

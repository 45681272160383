import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import {
  EntitiesSupportingBulkEdit,
  EntitiesSupportingGroups,
  EntityTypesSupportingBulkEdit,
  EntityTypesSupportingRoutes,
  EntityTypeSupportingGroups,
  Group,
  Route,
} from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";

export const deleteEntities = createAsyncAction(
  "@component/selectBar/deleteEntities/request",
  "@component/selectBar/deleteEntities/success",
  "@component/selectBar/deleteEntities/failure"
)<
  {
    entityIds: Array<EntitiesSupportingBulkEdit["id"]>;
    entityType: EntityTypesSupportingBulkEdit;
    multiEntities?: boolean;
    onSuccess: () => void;
  },
  void,
  void
>();

export const updateEntities = createAsyncAction(
  "@component/selectBar/updateEntities/request",
  "@component/selectBar/updateEntities/success",
  "@component/selectBar/updateEntities/failure"
)<
  {
    entities: EntitiesSupportingBulkEdit[];
    entityType: EntityTypesSupportingBulkEdit;
    field: IField;
    onSuccess: () => void;
    primaryEntityId?: number;
    value: unknown;
  },
  void,
  void
>();

export const bulkAddEntitiesToGroups = createAsyncAction(
  "@component/selectBar/bulkAddEntitiesToGroups/request",
  "@component/selectBar/bulkAddEntitiesToGroups/success",
  "@component/selectBar/bulkAddEntitiesToGroups/failure"
)<
  {
    entities: Array<EntitiesSupportingGroups>;
    entityType: EntityTypeSupportingGroups;
    groupIds: Group["id"][];
    onSuccess: () => void;
  },
  void,
  void
>();

export const bulkRemoveEntitiesFromGroups = createAsyncAction(
  "@component/selectBar/bulkRemoveEntitiesFromGroups/request",
  "@component/selectBar/bulkRemoveEntitiesFromGroups/success",
  "@component/selectBar/bulkRemoveEntitiesFromGroups/failure"
)<
  {
    entities: Array<EntitiesSupportingGroups>;
    entityType: EntityTypeSupportingGroups;
    groupIds: Group["id"][];
    onSuccess: () => void;
  },
  void,
  void
>();

export const bulkAddEntitiesToRoutes = createAsyncAction(
  "@component/selectBar/bulkAddEntitiesToRoutes/request",
  "@component/selectBar/bulkAddEntitiesToRoutes/success",
  "@component/selectBar/bulkAddEntitiesToRoutes/failure"
)<
  {
    entities: Array<EntitiesSupportingBulkEdit>;
    entityType: EntityTypesSupportingRoutes;
    includeDeals: boolean;
    onSuccess: () => void;
    routeIds: Route["id"][];
  },
  void,
  void
>();

export const bulkRemoveEntitiesFromRoutes = createAsyncAction(
  "@component/selectBar/bulkRemoveEntitiesFromRoutes/request",
  "@component/selectBar/bulkRemoveEntitiesFromRoutes/success",
  "@component/selectBar/bulkRemoveEntitiesFromRoutes/failure"
)<
  {
    entities: Array<EntitiesSupportingBulkEdit>;
    entityType: EntityTypesSupportingRoutes;
    includeDeals: boolean;
    onSuccess: () => void;
    routeIds: Route["id"][];
  },
  void,
  void
>();

export const initializeRouteModal = createAsyncAction(
  "@component/selectBar/initializeRouteModal/request",
  "@component/selectBar/initializeRouteModal/success",
  "@component/selectBar/initializeRouteModal/failure"
)<
  {
    callback?: () => void;
    entities: EntitiesSupportingBulkEdit[];
    entityType: EntityTypesSupportingRoutes;
    includeDeals: boolean;
  },
  { existingRoutesIds: Route["id"][]; routes: Route[]; totalRoutes: number },
  void
>();

export const filterRoutes = createAsyncAction(
  "@component/selectBar/filterRoutes/request",
  "@component/selectBar/filterRoutes/success",
  "@component/selectBar/filterRoutes/failure"
)<
  {
    entityType: EntityTypesSupportingRoutes;
    query?: string;
  },
  Route[],
  void
>();

export const updateRoutes = createAction("@component/selectBar/updateRoutes")<Route>();

export type Actions = ActionType<
  | typeof bulkAddEntitiesToGroups
  | typeof bulkAddEntitiesToRoutes
  | typeof bulkRemoveEntitiesFromGroups
  | typeof bulkRemoveEntitiesFromRoutes
  | typeof deleteEntities
  | typeof filterRoutes
  | typeof initializeRouteModal
  | typeof updateEntities
  | typeof updateRoutes
>;

import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import { Avatar, EntityTypeIcon } from "@mapmycustomers/ui";

import dealStage from "@app/assets/icons/deal-stage-dark.svg";
import MmcNotificationAction from "@app/enum/MmcNotificationAction";
import MmcNotification from "@app/types/MmcNotification";

import styles from "../NotificationCommon.module.scss";
import getActionIcon from "../util/getActionIcon";

const DANGER_NOTIFICATION_ACTIONS: MmcNotificationAction[] = [
  MmcNotificationAction.OUT_OF_CADENCE,
  MmcNotificationAction.OVERDUE_ACTIVITIES,
  MmcNotificationAction.DEALS_MARKED_LOST,
  MmcNotificationAction.GUIDE_REMINDER,
];

const WARNING_NOTIFICATION_ACTIONS: MmcNotificationAction[] = [MmcNotificationAction.DEALS_ROTTING];
const SUCCESS_NOTIFICATION_ACTIONS: MmcNotificationAction[] = [
  MmcNotificationAction.DEALS_MARKED_WON,
];

interface Props {
  notification: MmcNotification;
}

const Icon: React.FC<Props> = ({ notification: { action, entity, updatedBy } }) => {
  if (
    [
      MmcNotificationAction.ACTIVITY_ASSIGNED,
      MmcNotificationAction.ACTIVITY_LOGGED,
      MmcNotificationAction.ACTIVITY_TIME_CHANGED,
      MmcNotificationAction.MENTION,
      MmcNotificationAction.NOTE_ADDED,
    ].includes(action)
  ) {
    return updatedBy ? (
      <Avatar className={styles.actionType} tooltip={false} user={updatedBy} />
    ) : null;
  }
  if (
    [
      MmcNotificationAction.DEALS_STAGE_CHANGED,
      MmcNotificationAction.OWNED_DEALS_CLOSING,
      MmcNotificationAction.SHARED_DEALS_CLOSING,
    ].includes(action)
  ) {
    return (
      <div className={cn(styles.actionType, styles.iconContainer)}>
        <img alt="deal-stage-icon" src={dealStage} />
      </div>
    );
  }

  const icon = getActionIcon(action);
  let actionType;
  if (icon) {
    actionType = (
      <FontAwesomeIcon
        className={cn(styles.icon, {
          [styles.grayIcon]: [
            MmcNotificationAction.ACTIVITY_STARTING,
            MmcNotificationAction.ACTIVITY_TIME_CHANGED,
            MmcNotificationAction.GUIDE_ASSIGNED,
            MmcNotificationAction.INCOMPLETE_ACTIVITY,
            MmcNotificationAction.MARK_ACTIVITY_AS_DONE,
          ].includes(action),
        })}
        icon={icon}
      />
    );
  } else if (action === MmcNotificationAction.OWNERSHIP_CHANGED && entity && entity.length > 0) {
    actionType = <EntityTypeIcon entityType={entity[0].type} />;
  }

  return actionType ? (
    <div
      className={cn(styles.actionType, styles.iconContainer, {
        [styles.iconContainerDanger]: DANGER_NOTIFICATION_ACTIONS.includes(action),
        [styles.iconContainerSuccess]: SUCCESS_NOTIFICATION_ACTIONS.includes(action),
        [styles.iconContainerWarning]: WARNING_NOTIFICATION_ACTIONS.includes(action),
      })}
    >
      {actionType}
    </div>
  ) : null;
};

export default Icon;

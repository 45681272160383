import { ColDef } from "@ag-grid-community/core";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";

const CHECKBOX_COLUMN_DEF: ColDef = {
  colId: "selection",
  editable: false,
  filter: false,
  hide: false,
  pinned: "left",
  resizable: false,
  sortable: false,
  suppressAutoSize: true,
  suppressMenu: true,
  suppressSizeToFit: true,
  type: [FieldFeature.ALWAYS_VISIBLE, FieldFeature.WITH_CHECKBOX_SELECTION],
  width: 32,
};

export default CHECKBOX_COLUMN_DEF;

import notification from "antd/es/notification";
import { push } from "connected-react-router";
import cloneDeep from "lodash-es/cloneDeep";
import { defineMessages } from "react-intl";
import { call, put, select, take, takeEvery } from "redux-saga/effects";

import Role from "@mapmycustomers/shared/enum/Role";
import SchemaFieldType from "@mapmycustomers/shared/enum/SchemaFieldType";
import {
  EntityType,
  EntityTypesSupportingFieldCustomization,
} from "@mapmycustomers/shared/types/entity";
import FormLayout, { ChildLayout } from "@mapmycustomers/shared/types/layout/FormLayout";
import FormLayoutRoleAssociation from "@mapmycustomers/shared/types/layout/FormLayoutRoleAssociation";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { showModal } from "@mapmycustomers/ui";

import i18nService from "@app/config/I18nService";
import Path from "@app/enum/Path";
import SettingPath from "@app/enum/settings/SettingPath";
import {
  exitArchiveMode,
  setActiveEntityType,
} from "@app/scene/settings/component/ManageFields/store/actions";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import { getLayoutsForEntity, LayoutsGetter } from "@app/store/layout";
import { fetchLayoutsForEntityType } from "@app/store/layout/actions";
import defaultNodeNameGetter from "@app/util/defaultNodeNameGetter";
import getValidationErrors from "@app/util/errorHandling/getValidationErrors";

import { FormLayoutRoleMap } from "../type/FormLayoutRoleMap";
import isActivityRequiredRelationshipField from "../util/isActivityRequiredRelationshipField";

import {
  cancelEditLayout,
  cancelLayoutRolesAssociation,
  createChildLayout,
  deleteChildLayout,
  deleteLayout,
  initiateEditLayout,
  reloadLayoutRoles,
  reloadLayouts,
  reloadLayoutsAndRoles,
  saveLayout,
  saveLayoutsRoles,
  selectChildLayout,
  setAsDefault,
  setDefaultCheckInVariant,
  updateChildLayout,
  updateLayout,
} from "./actions";
import {
  getActiveEntityType,
  getEditedChildLayoutId,
  getEditedLayout,
  getEditedLayoutId,
  isCreating,
} from "./selectors";

const messages = defineMessages({
  bulkEmailsLimitedModalDescription: {
    id: "settings.formLayouts.notifications.bulkEmailsLimitedModalDescription",
    defaultMessage:
      "To enable bulk emails, mark all relationship record fields as “not required” either in the Primary Layout or in a new Email Variant.",
    description: "Bulk emails limited modal description",
  },
  bulkEmailsLimitedModalOkButton: {
    id: "settings.formLayouts.notifications.bulkEmailsLimitedModalOkButton",
    defaultMessage: "Okay",
    description: "Bulk emails limited modal ok button",
  },
  bulkEmailsLimitedModalTitle: {
    id: "settings.formLayouts.notifications.bulkEmailsLimitedModalTitle",
    defaultMessage:
      "Ability to send bulk emails is limited because at least one relationship record field is required",
    description: "Bulk emails limited modal title",
  },
  created: {
    id: "settings.formLayouts.notifications.created",
    defaultMessage: "Layout created successfully",
    description: "Layout created success message",
  },
  deleted: {
    id: "settings.formLayouts.notifications.deleted",
    defaultMessage: "Layout successfully deleted",
    description: "LLayout successfully deleted message",
  },
  newLayout: {
    id: "settings.formLayouts.newLayout.defaultName",
    defaultMessage: "New Layout",
    description: "Default name for new layout",
  },
  rolesUpdated: {
    id: "settings.formLayouts.notifications.rolesUpdated",
    defaultMessage: "Layouts updated successfully",
    description: "Layouts updated successfully",
  },
  saved: {
    id: "settings.formLayouts.notifications.saved",
    defaultMessage: "Layout updated successfully",
    description: "Layout updated success message",
  },
  variantCreated: {
    id: "settings.formLayouts.notifications.variantCreated",
    defaultMessage: "Variant Created",
    description: "Variant Created",
  },
  variantCreatedDescription: {
    id: "settings.formLayouts.notifications.variantCreatedDescription",
    defaultMessage: "This variant will not be visible to users until layout is saved.",
    description: "Variant Created - description",
  },
});

const errors = defineMessages({
  layoutNameAlreadyExists: {
    id: "settings.formLayouts.errors.layoutNameAlreadyExists",
    defaultMessage: "The name of this layout {name} already exists. Please choose another name.",
    description: "Layout name already exists error",
  },
});

enum LayoutValidationErrorCodes {
  DUPLICATE_VALUE = "pg-23505",
}

export function* onReloadLayouts() {
  try {
    const entityType: EntityTypesSupportingFieldCustomization = yield select(getActiveEntityType);

    yield put(setActiveEntityType(entityType));
    yield put(exitArchiveMode());

    yield put(fetchLayoutsForEntityType.request(entityType));
    yield take([fetchLayoutsForEntityType.success, fetchLayoutsForEntityType.failure]);

    const layoutsGetter: LayoutsGetter = yield select(getLayoutsForEntity);
    const layouts = layoutsGetter(entityType);
    yield put(reloadLayouts.success({ layouts, total: layouts.length }));
  } catch (error) {
    yield put(reloadLayouts.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onInitiateEditLayout({ payload }: ReturnType<typeof initiateEditLayout.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const { id, entityType } = payload;

    if (id === "create") {
      const layoutsGetter: LayoutsGetter = yield select(getLayoutsForEntity);
      const layouts = layoutsGetter(entityType);

      const defaultName = i18nService.formatMessage(messages.newLayout, "New Layout");

      // TODO: move this logic into layout store, into selectors
      if (layouts.length) {
        let max = -1;

        const layoutNames = layouts.map(defaultNodeNameGetter);
        if (layoutNames.includes(defaultName)) {
          layoutNames
            .filter((layoutName) => layoutName.startsWith(defaultName))
            .map((layoutName) => layoutName.replace(defaultName, "").trim())
            .forEach((layoutName) => {
              if (layoutName) {
                const number = parseInt(layoutName, 10);
                if (!isNaN(number)) {
                  max = Math.max(max, number);
                }
              } else {
                max = 0;
              }
            });
        }

        const name = max >= 0 ? `${defaultName} ${max + 1}` : defaultName;

        const baseLayout = layouts[0];
        yield put(
          initiateEditLayout.success({
            addFields: true,
            entity: entityType,
            name: name,
            schema: baseLayout.schema,
          })
        );
      } else {
        yield put(
          initiateEditLayout.success({
            addFields: true,
            entity: entityType,
            name: defaultName,
            schema: [
              {
                displayOrder: 1,
                field: "name",
                fieldType: SchemaFieldType.STANDARD,
                required: true,
              },
            ],
          })
        );
      }
    } else {
      const data: FormLayout = yield callApi("getLayout", orgId, Number(id));
      yield put(initiateEditLayout.success(data));
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onSaveLayout({ payload: { callback } }: ReturnType<typeof saveLayout.request>) {
  try {
    const intl = i18nService.getIntl()!;

    const orgId: Organization["id"] = yield select(getOrganizationId);
    const entityType: EntityTypesSupportingFieldCustomization = yield select(getActiveEntityType);
    const creating: boolean = yield select(isCreating);
    const editedLayout: FormLayout = yield select(getEditedLayout);

    let response: FormLayout;
    try {
      response = yield callApi(creating ? "createLayout" : "updateLayout", orgId, editedLayout);
    } catch (error) {
      const validationErrors = getValidationErrors(error);
      if (
        validationErrors.some(({ code }) => code === LayoutValidationErrorCodes.DUPLICATE_VALUE)
      ) {
        throw new Error(
          intl.formatMessage(errors.layoutNameAlreadyExists, { name: editedLayout.name })
        );
      } else {
        throw error;
      }
    }

    const supportsChildLayouts =
      entityType === EntityType.ACTIVITY || entityType === EntityType.DEAL;

    if (!creating && supportsChildLayouts) {
      yield put(selectChildLayout(undefined));
    }

    yield put(saveLayout.success(response));

    if (callback) {
      yield call(callback);
    }

    notification.success({
      message: creating
        ? i18nService.formatMessage(messages.created, "Layout created successfully")
        : i18nService.formatMessage(messages.saved, "Layout updated successfully"),
    });

    yield put(reloadLayoutsAndRoles());

    yield put(fetchLayoutsForEntityType.request(entityType));
  } catch (error) {
    yield put(saveLayout.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onReloadLayoutRoles() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const response: ListResponse<FormLayoutRoleAssociation> = yield callApi(
      "getLayoutRoles",
      orgId,
      {
        $limit: 1000,
        $offset: 0,
      }
    );

    const data = response.data ?? [];

    const rolesMap: FormLayoutRoleMap = data.reduce((result, curr) => {
      if (curr?.layout?.id && curr?.role.key !== Role.OWNER) {
        if (result.has(curr.layout.id)) {
          result.set(curr.layout.id, [...result.get(curr.layout.id), curr]);
        } else {
          result.set(curr.layout.id, [curr]);
        }
      }
      return result;
    }, new Map());

    yield put(reloadLayoutRoles.success({ data, map: rolesMap }));
  } catch (error) {
    yield put(reloadLayoutRoles.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onReloadLayoutsAndRoles() {
  yield put(reloadLayouts.request());
  yield put(reloadLayoutRoles.request());
}

export function* onSaveLayoutsRoles({ payload }: ReturnType<typeof saveLayoutsRoles.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    yield callApi("updateLayoutRoles", orgId, payload);

    yield put(saveLayoutsRoles.success());
    yield put(reloadLayoutsAndRoles());

    notification.success({
      message: i18nService.formatMessage(messages.rolesUpdated, "Layouts updated successfully"),
    });

    yield put(cancelLayoutRolesAssociation());

    const entityType: EntityTypesSupportingFieldCustomization = yield select(getActiveEntityType);
    yield put(fetchLayoutsForEntityType.request(entityType));
  } catch (error) {
    yield put(saveLayoutsRoles.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onDeleteLayout({ payload }: ReturnType<typeof deleteLayout>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const layoutId: FormLayout["id"] = yield select(getEditedLayoutId);

    if (layoutId) {
      const entityType: EntityTypesSupportingFieldCustomization = yield select(getActiveEntityType);

      yield put(cancelEditLayout());

      const url = `${Path.SETTINGS}/${SettingPath.FORMS_CREATE}/${entityType}`;
      yield put(push(url));
    }

    yield callApi("deleteLayout", orgId, payload);

    yield put(reloadLayoutsAndRoles());

    notification.success({
      message: i18nService.formatMessage(messages.deleted, "Layout successfully deleted"),
    });
  } catch (error) {
    yield put(handleError({ error }));
  }
}
export function* onSetAsDefault({ payload }: ReturnType<typeof setAsDefault.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("updateLayout", orgId, { ...payload, default: true });

    yield put(reloadLayoutsAndRoles());
    yield put(setAsDefault.success());
  } catch (error) {
    yield put(setAsDefault.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onCreateChildLayout({ payload }: ReturnType<typeof createChildLayout>) {
  try {
    const entityType: EntityTypesSupportingFieldCustomization = yield select(getActiveEntityType);
    const layout: FormLayout = yield select(getEditedLayout);

    const newId = Math.random();
    const childLayouts: Array<ChildLayout> = layout.childLayouts ?? [];

    if (entityType === EntityType.DEAL && payload.stage) {
      const newRecord: ChildLayout = {
        id: newId,
        funnel: {
          id: payload.stage.funnel.id,
        },
        schema: cloneDeep(layout.schema),
        stage: {
          id: payload.stage.id,
          name: payload.stage.name,
        },
      };

      childLayouts.push(newRecord);

      layout.childLayouts = childLayouts;
      yield put(updateLayout(layout));
    } else if (entityType === EntityType.ACTIVITY && payload.activityTypeId) {
      const newRecord: ChildLayout = {
        id: newId,
        crmActivityType: {
          id: payload.activityTypeId,
        },
        schema: cloneDeep(layout.schema),
      };

      childLayouts.push(newRecord);

      layout.childLayouts = childLayouts;
      yield put(updateLayout(layout));
    }

    yield put(selectChildLayout(newId));

    notification.success({
      description: i18nService.formatMessage(
        messages.variantCreatedDescription,
        "This variant will not be visible to users until layout is saved."
      ),
      message: i18nService.formatMessage(messages.variantCreated, "Variant Created"),
    });
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onDeleteChildLayout({ payload }: ReturnType<typeof deleteChildLayout>) {
  try {
    const layout: FormLayout = yield select(getEditedLayout);
    const activeChildLayoutId: ChildLayout["id"] | undefined = yield select(getEditedChildLayoutId);

    if (activeChildLayoutId === payload) {
      yield put(selectChildLayout(undefined));
    }

    const childLayouts: Array<ChildLayout> = layout.childLayouts ?? [];
    layout.childLayouts = childLayouts.filter((child) => child.id !== payload);

    if (!layout.childLayouts.length) {
      layout.defaultCheckin = false;
    }

    yield put(updateLayout(layout));

    if (layout.entity === EntityType.ACTIVITY) {
      const primaryLayout: FormLayout = yield select(getEditedLayout);
      const intl = i18nService.getIntl();
      if (intl && primaryLayout.schema.some(isActivityRequiredRelationshipField)) {
        showModal({
          centered: true,
          closable: true,
          content: intl.formatMessage(messages.bulkEmailsLimitedModalDescription),
          okButtonProps: {
            type: "primary",
          },
          okText: intl.formatMessage(messages.bulkEmailsLimitedModalOkButton),
          title: intl.formatMessage(messages.bulkEmailsLimitedModalTitle),
          type: "warning",
          width: "30rem",
        });
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onUpdateChildLayout({ payload }: ReturnType<typeof updateChildLayout>) {
  try {
    const layout: FormLayout = yield select(getEditedLayout);
    const activeChildLayoutId: ChildLayout["id"] | undefined = yield select(getEditedChildLayoutId);

    const childLayouts: Array<ChildLayout> = layout.childLayouts ?? [];
    if (childLayouts && childLayouts?.length) {
      const childLayoutIndex = childLayouts.findIndex(
        (child: ChildLayout) => child.id === activeChildLayoutId
      );
      if (childLayoutIndex >= 0) {
        const updatedLayout = {
          childLayouts: cloneDeep(childLayouts),
        };
        updatedLayout.childLayouts[childLayoutIndex] = {
          ...updatedLayout.childLayouts[childLayoutIndex],
          ...payload,
        };
        yield put(updateLayout(updatedLayout));
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onSetDefaultCheckinVariant({
  payload,
}: ReturnType<typeof setDefaultCheckInVariant>) {
  try {
    const layout: FormLayout = yield select(getEditedLayout);

    layout.defaultCheckin = payload?.isPrimary === true;
    if (layout.childLayouts) {
      for (const childLayout of layout.childLayouts!) {
        childLayout.defaultCheckin = payload?.isPrimary === false && payload.id === childLayout.id;
      }
    }

    yield put(updateLayout(layout));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* formLayoutsSaga() {
  yield takeEvery(initiateEditLayout.request, onInitiateEditLayout);
  yield takeEvery(saveLayout.request, onSaveLayout);
  yield takeEvery(reloadLayouts.request, onReloadLayouts);
  yield takeEvery(reloadLayoutRoles.request, onReloadLayoutRoles);
  yield takeEvery(reloadLayoutsAndRoles, onReloadLayoutsAndRoles);
  yield takeEvery(saveLayoutsRoles.request, onSaveLayoutsRoles);
  yield takeEvery(deleteLayout, onDeleteLayout);
  yield takeEvery(createChildLayout, onCreateChildLayout);
  yield takeEvery(deleteChildLayout, onDeleteChildLayout);
  yield takeEvery(updateChildLayout, onUpdateChildLayout);
  yield takeEvery(setAsDefault.request, onSetAsDefault);
  yield takeEvery(setDefaultCheckInVariant, onSetDefaultCheckinVariant);
}

import React, {
  forwardRef,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";
import InputNumber, { InputNumberProps } from "antd/es/input-number";
import cn from "classnames";

import NumberFormatType from "@mapmycustomers/shared/enum/NumberFormatType";

import { useConfigProvider } from "../../ConfigProvider";
import ErrorRow from "../ErrorRow";
import Labeled, { LabeledFieldProps } from "../Labeled";

export const allowedKeys = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "Tab",
  "Backspace",
  "ArrowLeft",
  "ArrowRight",
  "ArrowUp",
  "ArrowDown",
  "Enter",
  ",",
  ".",
  "-",
];

export interface NumberFieldProps
  extends Omit<InputNumberProps, "className" | "onChange">,
    Omit<LabeledFieldProps, "children"> {
  caption?: string;
  className?: string;
  error?: ReactNode;
  format?: null | NumberFormatType;
  fullWidth?: boolean;
  inputClassName?: string;
  locked?: boolean;
  onChange?: (value?: number) => void;
}

const block = bem("mmc-number-field");

const NumberField = forwardRef<NumberFieldComponent, NumberFieldProps>(
  (
    {
      addonAfter,
      caption,
      className,
      disabled,
      error,
      format,
      fullWidth,
      inputClassName,
      label,
      labelClassName,
      labelPosition,
      locked,
      onChange,
      onKeyDown,
      required,
      rowProps,
      sideLabelSpan,
      size = "large",
      value,
      ...props
    }: NumberFieldProps,
    ref
  ) => {
    const configProvider = useConfigProvider();

    const [internalValue, setInternalValue] = useState<null | number | string | undefined>(
      value ?? null
    );

    const handleChange = useCallback(
      (value: null | number | string | undefined) => {
        setInternalValue(value);
        onChange?.(typeof value === "number" ? value : undefined);
      },
      [onChange, setInternalValue]
    );

    // prevent pressing any non-numeric or some other special buttons (see allowedKeys list)
    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        onKeyDown?.(e);
        if (e.isDefaultPrevented()) {
          return;
        }
        if (["c", "v", "x"].includes(e.key) && (e.metaKey || e.ctrlKey)) {
          return; // allow ctrl+c/v/x
        }
        if (!allowedKeys.includes(e.key) && e.key !== configProvider.getDecimalSeparator()) {
          e.preventDefault();
          e.stopPropagation();
        }
      },
      [configProvider, onKeyDown]
    );

    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => ({
      blur: () => inputRef.current?.blur(),
      focus: () => inputRef.current?.focus(),
    }));

    const formatter = useCallback(
      (value: number | string | undefined) =>
        value === undefined || value === ""
          ? ""
          : format === NumberFormatType.PERCENTAGE
          ? configProvider.formatNumber(+value / 100, { style: "percent" })
          : configProvider.formatNumber(+value),
      [format, configProvider]
    );

    const parser = useCallback(
      (value: string | undefined) => {
        const delimiter = configProvider.getDecimalSeparator();
        return (value ?? "")
          .replace(new RegExp(`[^0-9${delimiter}-]`, "g"), "")
          .replace(delimiter, ".");
      },
      [configProvider]
    );

    useEffect(() => {
      setInternalValue(value);
    }, [value]);

    return (
      <Labeled
        className={cn(block({ disabled: disabled || locked, fullWidth }), className)}
        label={label}
        labelClassName={cn(block("label"), labelClassName)}
        labelPosition={labelPosition}
        required={required}
        rowProps={rowProps}
        sideLabelSpan={sideLabelSpan}
      >
        <div className={block("input")}>
          <InputNumber
            addonAfter={
              locked ? (
                <div className={block("lock")}>
                  <FontAwesomeIcon icon={faLock} size="sm" />
                </div>
              ) : (
                addonAfter
              )
            }
            className={cn(block("input-field"), inputClassName)}
            defaultValue={value || undefined}
            disabled={disabled || locked}
            formatter={format === NumberFormatType.NUMERIC_NO_COMMA ? undefined : formatter}
            key={format}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            parser={format === NumberFormatType.NUMERIC_NO_COMMA ? undefined : parser}
            ref={inputRef}
            required={required}
            size={size}
            value={typeof internalValue === "number" ? internalValue : ""}
            {...props}
          />
          {error ? (
            <ErrorRow>{error}</ErrorRow>
          ) : caption ? (
            <div className={block("caption")}>{caption}</div>
          ) : null}
        </div>
      </Labeled>
    );
  }
);

export interface NumberFieldComponent {
  blur(): void;
  focus: () => void;
}

NumberField.displayName = "NumberField";

export default NumberField;

import { put, select, takeLatest } from "redux-saga/effects";

import EmailUnsubscribeInfo from "@mapmycustomers/shared/types/email/EmailUnsubscribeInfo";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";

import { loadEmailUnsubscribeInfo, unsubscribeEmail } from "./actions";
import { getEmailUnsubscribeCode } from "./selectors";

function* onLoadEmailUnsubscribeInfo({
  payload: { code },
}: ReturnType<typeof loadEmailUnsubscribeInfo.request>) {
  try {
    const unsubscribeInfo: EmailUnsubscribeInfo = yield callApi("fetchEmailUnsubscribeInfo", code);
    yield put(loadEmailUnsubscribeInfo.success(unsubscribeInfo));
  } catch (error) {
    yield put(loadEmailUnsubscribeInfo.failure());
    yield put(handleError({ error }));
  }
}

function* onUnsubscribeEmail() {
  try {
    const unsubscribeCode: string = yield select(getEmailUnsubscribeCode);
    yield callApi("unsubscribeEmail", unsubscribeCode);
    yield put(unsubscribeEmail.success());
  } catch (error) {
    yield put(unsubscribeEmail.failure());
    yield put(handleError({ error }));
  }
}

export function* emailUnsubscribeSaga() {
  yield takeLatest(loadEmailUnsubscribeInfo.request, onLoadEmailUnsubscribeInfo);
  yield takeLatest(unsubscribeEmail.request, onUnsubscribeEmail);
}

import { createSelector } from "reselect";

import { EntityType } from "@mapmycustomers/shared";

import { RootState } from "@app/store/rootReducer";

const recordPreviewState = (state: RootState) => state.recordPreview;

export const getRecordPreviewConfiguration = createSelector(
  recordPreviewState,
  ({ configuration }) => configuration
);

export const isRecordPreviewConfigurationLoading = createSelector(
  recordPreviewState,
  ({ loading }) => loading
);

export const getRecordPreviewConfigurationColumns = createSelector(
  getRecordPreviewConfiguration,
  (configuration) => {
    return {
      actions: !!configuration.actions.visibility,
      engagement: !!configuration.engagement.visibility,
      [EntityType.COMPANY]: configuration[EntityType.COMPANY].map(({ field }) => field),
      [EntityType.DEAL]: configuration[EntityType.DEAL].map(({ field }) => field),
      [EntityType.PERSON]: configuration[EntityType.PERSON].map(({ field }) => field),
    };
  }
);

import { ColDef } from "@ag-grid-community/core";
import memoize from "fast-memoize";
import { createSelector } from "reselect";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";

import { areMileageTrackingAmountsVisible } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import CHECKBOX_COLUMN_DEF from "@app/util/fieldModel/checkBoxColumnDef";

export const getMileageTrackingState = (state: RootState) => state.scene.report.mileageTracking;

export const getTripGridColumnDefs = createSelector(
  areMileageTrackingAmountsVisible,
  getMileageTrackingState,
  (amountsVisible, { columns }) =>
    memoize((forClientSideGrid?: boolean) => {
      // get field's gridProperties && also add width/pinned/hide props from the column model
      const columnDefs = columns
        .filter(
          ({ field }) =>
            (field.isReadable || field.isSystemRequired) &&
            (amountsVisible || !field.hasFeature(FieldFeature.MONETARY_VALUE))
        )
        .map<ColDef>(({ field, pinned, visible, width }) => ({
          ...(field.gridProperties(forClientSideGrid) ?? {}),
          hide: !visible,
          pinned,
          width,
        }));

      // always make a checkbox column to appear on the left side of a grid
      columnDefs.unshift(CHECKBOX_COLUMN_DEF);

      return columnDefs;
    })
);

export const isTripListInitialized = createSelector(
  getMileageTrackingState,
  ({ initialized }) => initialized
);

export const isTripListLoading = createSelector(getMileageTrackingState, ({ loading }) => loading);

export const getTotalFilteredTripRecordsCount = createSelector(
  getMileageTrackingState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getTotalTripRecordsCount = createSelector(
  getMileageTrackingState,
  ({ totalRecords }) => totalRecords
);

export const getFirstDateWithData = createSelector(
  getMileageTrackingState,
  ({ firstDateWithData }) => firstDateWithData
);

export const areDeletingTrips = createSelector(
  getMileageTrackingState,
  ({ deletingTrips }) => deletingTrips
);

export const getTripsStats = createSelector(getMileageTrackingState, ({ stats }) => stats);

export const getTripPreviewRecordData = createSelector(
  getMileageTrackingState,
  ({ previewData }) => previewData
);

export const isTripPreviewLoading = createSelector(
  getMileageTrackingState,
  ({ previewLoading }) => previewLoading
);

export const areFilesUploading = createSelector(
  getMileageTrackingState,
  ({ filesUploading }) => filesUploading
);

export const getFileIdsBeingDeleted = createSelector(
  getMileageTrackingState,
  ({ filesToDeletedIds }) => filesToDeletedIds
);

export const isDeleting = createSelector(
  getMileageTrackingState,
  ({ deleteLoading }) => deleteLoading
);

import { ActionType, createAsyncAction } from "typesafe-actions";

import { Guide, GuideUserStatus } from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";

export const initialize = createAsyncAction(
  "onboarding/initialize/request",
  "onboarding/initialize/success",
  "onboarding/initialize/failure"
)<
  void,
  {
    guides: Guide[];
    guidesStatus: GuideUserStatus[];
  },
  void
>();

export const initializeMemberView = createAsyncAction(
  "onboarding/initializeMemberView/request",
  "onboarding/initializeMemberView/success",
  "onboarding/initializeMemberView/failure"
)<
  void,
  {
    guides: Guide[];
    guidesStatus: GuideUserStatus[];
  },
  void
>();

export const bulkAddGuideAssignees = createAsyncAction(
  "onboarding/bulkAddGuideAssignees/request",
  "onboarding/bulkAddGuideAssignees/success",
  "onboarding/bulkAddGuideAssignees/failure"
)<
  { guideIds: Guide["id"][]; userIds: User["id"][] },
  {
    guidesStatus: GuideUserStatus[];
  },
  void
>();

export const updateGuideAssignees = createAsyncAction(
  "onboarding/updateGuideAssignees/request",
  "onboarding/updateGuideAssignees/success",
  "onboarding/updateGuideAssignees/failure"
)<
  { guideId: Guide["id"]; userIds: User["id"][] },
  {
    guidesStatus: GuideUserStatus[];
  },
  void
>();

export const updateUserGuides = createAsyncAction(
  "onboarding/updateUserGuides/request",
  "onboarding/updateUserGuides/success",
  "onboarding/updateUserGuides/failure"
)<
  { guideIds: Guide["id"][]; userId: User["id"] },
  {
    guidesStatus: GuideUserStatus[];
  },
  void
>();

export const refreshGuidesStatus = createAsyncAction(
  "onboarding/refreshGuidesStatus/request",
  "onboarding/refreshGuidesStatus/success",
  "onboarding/refreshGuidesStatus/failure"
)<
  void,
  {
    guidesStatus: GuideUserStatus[];
  },
  void
>();

export type Actions = ActionType<
  | typeof bulkAddGuideAssignees
  | typeof initialize
  | typeof initializeMemberView
  | typeof refreshGuidesStatus
  | typeof updateGuideAssignees
  | typeof updateUserGuides
>;

import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import EntityColumnsConfiguration from "./EntityColumnsConfiguration";

export const updateRecordsPreviewConfiguration = createAsyncAction(
  "@recordPreview/updateRecordsPreviewConfiguration/request",
  "@recordPreview/updateRecordsPreviewConfiguration/success",
  "@recordPreview/updateRecordsPreviewConfiguration/failure"
)<
  { configuration: Partial<EntityColumnsConfiguration>; successCallback?: () => void },
  void,
  void
>();

export const setRecordsPreviewConfiguration = createAction(
  "@recordPreview/setRecordsPreviewConfiguration"
)<Partial<EntityColumnsConfiguration>>();

export type Actions = ActionType<
  typeof setRecordsPreviewConfiguration | typeof updateRecordsPreviewConfiguration
>;

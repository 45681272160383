import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import Dropdown from "antd/es/dropdown";
import Radio from "antd/es/radio";
import { useIntl } from "react-intl";

import Cadenced from "@mapmycustomers/shared/types/base/Cadenced";
import { LoadingSpinner } from "@mapmycustomers/ui";

import ButtonLink from "@app/component/ButtonLink";
import RadioGroup from "@app/component/input/RadioGroup";
import { getFrequencyCustomInterval } from "@app/scene/map/store/selectors";
import { RootState } from "@app/store/rootReducer";
import useAnalytics from "@app/util/contexts/useAnalytics";
import useFrequencyIntervalOptions from "@app/util/frequency/useFrequencyIntervalOptions";

import FrequencyEntity from "../type/FrequencyEntity";

import CustomFrequencySelector from "./CustomFrequencySelector";
import styles from "./FrequencySelector.module.scss";

const NONE_VALUE = -1;

interface Props {
  disabled?: boolean;
  entity?: FrequencyEntity;
  frequencyIntervalValue?: Cadenced["cadenceInterval"];
  isGroup?: boolean;
  onChange: (cadence: Partial<Cadenced>) => void;
  savedFrequencyInterval?: Cadenced["cadenceInterval"];
  updating?: boolean;
}

const FrequencySelector: React.FC<Props> = ({
  disabled,
  entity,
  frequencyIntervalValue,
  isGroup,
  onChange,
  savedFrequencyInterval,
  updating,
}) => {
  const intl = useIntl();

  const [customSelectorVisible, setCustomSelectorVisibility] = useState<boolean>(false);
  const analytics = useAnalytics();
  const [selectedNumber, setSelectedNumber] = useState<number>(0);

  const handleHideCustomSelector = useCallback(() => {
    setCustomSelectorVisibility(false);
  }, [setCustomSelectorVisibility]);

  const options = useFrequencyIntervalOptions(savedFrequencyInterval, frequencyIntervalValue);

  const handleChange = useCallback(
    (cadence: number) => {
      onChange({ cadenceInterval: cadence === NONE_VALUE ? null : cadence });
      analytics.clicked(["Selected Interval"], { value: cadence === NONE_VALUE ? null : cadence });
      setSelectedNumber(cadence);
    },
    [analytics, onChange, setSelectedNumber]
  );

  useEffect(() => {
    if (customSelectorVisible) {
      analytics.clicked(["Selected Interval", "Custom"]);
    }
  }, [analytics, customSelectorVisible]);

  return (
    <div className={styles.container}>
      <div className={styles.intervalInDays}>
        {intl.formatMessage({
          id: "frequency.cadenceSelector.intervalInDays",
          defaultMessage: "interval in days",
          description: "frequency component - cadence selector - interval in days",
        })}
      </div>
      <div className={styles.variants}>
        <RadioGroup<number>
          buttonStyle="solid"
          disabled={disabled}
          onChange={handleChange}
          value={frequencyIntervalValue ?? NONE_VALUE}
        >
          <Radio.Button className={styles.option} key="no-cadence" value={NONE_VALUE}>
            {selectedNumber === NONE_VALUE && updating ? (
              <LoadingSpinner className={styles.loader} micro />
            ) : (
              intl.formatMessage({
                id: "frequency.cadenceSelector.none",
                defaultMessage: "None",
                description: "frequency component - cadence selector - none text",
              })
            )}
          </Radio.Button>
          {options.map((number) => (
            <Radio.Button className={styles.option} key={number} value={number}>
              {selectedNumber === number && updating ? (
                <LoadingSpinner className={styles.loader} micro />
              ) : (
                number
              )}
            </Radio.Button>
          ))}
        </RadioGroup>
        <div className={styles.customContainer}>
          <Dropdown
            disabled={disabled}
            onOpenChange={setCustomSelectorVisibility}
            open={customSelectorVisible}
            overlay={
              <CustomFrequencySelector
                entity={entity}
                isGroup={isGroup}
                onHide={handleHideCustomSelector}
                onUpdate={onChange}
              />
            }
            placement="bottomLeft"
            trigger={["click"]}
          >
            <ButtonLink className={styles.customInterval} disabled={disabled}>
              {intl.formatMessage({
                id: "frequency.cadenceSelector.custom",
                defaultMessage: "Custom",
                description: "frequency component - cadence selector - Custom text",
              })}
            </ButtonLink>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  savedFrequencyInterval: getFrequencyCustomInterval(state),
});

export default connect(mapStateToProps)(FrequencySelector);

import React, { useCallback, useRef } from "react";

import { useIntl } from "react-intl";
import { v4 } from "uuid";

import { RawFile } from "@mapmycustomers/shared/types/File";
import { Modal } from "@mapmycustomers/ui";

import FileUploader from "@app/component/FileUploader";
import FileListItem from "@app/types/FileListItem";
import useAnalytics from "@app/util/contexts/useAnalytics";

interface Props {
  clearAllUploadedFiles?: () => void;
  fileUploading?: boolean;
  onDelete?: (file: RawFile) => void;
  onHide: () => void;
  onUpload?: (payload: {
    callback?: (filesList: FileListItem[]) => void;
    fileGroupId?: string;
    files: (Blob | File)[];
  }) => void;
}

const FileUploaderModal: React.FC<Props> = ({
  clearAllUploadedFiles,
  fileUploading,
  onDelete,
  onHide,
  onUpload,
}) => {
  const intl = useIntl();
  const analytics = useAnalytics();
  const handleCancelAddingFile = useCallback(() => {
    analytics.cancelled(["Add File", "Cancel"]);
    onHide();
    clearAllUploadedFiles?.();
  }, [analytics, clearAllUploadedFiles, onHide]);

  const handleDelete = useCallback(
    (file: RawFile) => {
      analytics.clicked(["Delete File"]);
      onDelete?.(file);
    },
    [analytics, onDelete]
  );

  const fileGroupId = useRef(v4());

  const handleFileAdd = useCallback(
    (payload: { callback?: (filesList: FileListItem[]) => void; files: (Blob | File)[] }) => {
      analytics.completed(["Add File", "Upload File"]);
      onUpload?.({ ...payload, fileGroupId: fileGroupId.current });
    },
    [analytics, onUpload]
  );

  return (
    <Modal
      footer={null}
      onCancel={handleCancelAddingFile}
      open
      title={intl.formatMessage({
        id: "component.fileUploaderModal.title",
        defaultMessage: "File upload",
        description: "File uploading modal title",
      })}
      zIndex={1030} // Higher than activity annotation
    >
      <FileUploader
        fileUploading={fileUploading}
        onFileUpload={handleFileAdd}
        onRemoveFile={handleDelete}
      />
    </Modal>
  );
};

export default FileUploaderModal;

import React, { ComponentType, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFormInstance from "antd/es/form/hooks/useFormInstance";
import cn from "classnames";
import isFunction from "lodash-es/isFunction";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import SchemaFieldType from "@mapmycustomers/shared/enum/SchemaFieldType";
import { EntityTypesSupportingFieldCustomization } from "@mapmycustomers/shared/types/entity";
import Funnel from "@mapmycustomers/shared/types/entity/deals/Funnel";
import Stage from "@mapmycustomers/shared/types/entity/deals/Stage";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import FormLayout, { LayoutSchemaField } from "@mapmycustomers/shared/types/layout/FormLayout";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { CardCollapse, Collapse, CollapsePanel } from "@mapmycustomers/ui";

import Divider from "@app/component/FormFields/components/Divider";
import convertFieldToLayoutSchemaField from "@app/component/FormFields/utils/convertFieldToLayoutSchemaField";
import getAddableFields from "@app/component/FormFields/utils/getAddableFields";
import useAddedFieldsSchema from "@app/component/FormFields/utils/useAddedFieldsSchema";
import useFormFieldRender, {
  createFormCustomFieldNamePathGetter,
  editFormCustomFieldNamePathGetter,
} from "@app/component/FormFields/utils/useFormFieldRender";
import useFormSchema from "@app/component/FormFields/utils/useFormSchema";
import localSettings from "@app/config/LocalSettings";
import AddFieldButton from "@app/scene/settings/component/FormLayouts/component/AddFieldButton";
import { getFunnelStages } from "@app/store/deal";
import { RootState } from "@app/store/rootReducer";
import { displayNameComparator } from "@app/util/comparator";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import { isUniqueCustomField } from "@app/util/fieldModel/impl/assert";
import loggingService from "@app/util/logging";

import styles from "./FormFields.module.scss";
import useDefaultValues from "./utils/useDefaultValues";

const noFilter = () => true;
const yes = () => true;
interface Props {
  appendAddableFieldsWithFilter?: (field: IField) => boolean;
  autoFocus?: boolean;
  canAddField?: (field: IField) => boolean;
  children?: ReactNode | ReactNode[];
  className?: string;
  defaultAddedFields?: LayoutSchemaField[];
  disableAllFields?: boolean;
  disableUniqueCustomFields?: boolean;
  disallowAdding?: boolean;
  entityType: EntityTypesSupportingFieldCustomization;
  fileComponent?: ComponentType<{ disabled?: boolean; required?: boolean }>;
  filterFields?: (schemaField: LayoutSchemaField) => boolean;
  funnelStages: Record<Funnel["id"], Stage[]>;
  ignoreRequired?: boolean;
  isCreateForm?: boolean;
  layout: FormLayout;
  onAddField?: (field: LayoutSchemaField) => void;
  rowClassName?: ((schemaField?: LayoutSchemaField) => string | undefined) | string;
}

const FormFields: React.FC<Props> = ({
  appendAddableFieldsWithFilter,
  autoFocus = true,
  canAddField,
  children,
  className,
  defaultAddedFields,
  disableAllFields,
  disableUniqueCustomFields,
  disallowAdding,
  entityType,
  fileComponent,
  filterFields = noFilter,
  funnelStages,
  ignoreRequired,
  isCreateForm,
  layout,
  onAddField,
  rowClassName,
}) => {
  const intl = useIntl();
  // fields user manually added to the form
  const [addedFields, setAddedFields] = useState<LayoutSchemaField[]>(defaultAddedFields ?? []);
  const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState<boolean>(
    localSettings.getAdditionalFieldsCollapseByEntityType(entityType)
  );
  const [open, expand, collapse] = useBoolean(true);

  const handleAddField = useCallback(
    (newField: LayoutSchemaField) => {
      setAddedFields((fields) => [
        ...fields.filter((existing) => existing.field !== newField.field),
        newField,
      ]);
      onAddField?.(newField);
    },
    [onAddField]
  );

  const handleCollapseChange = useCallback(
    (key) => {
      if (key.length === 0) {
        collapse();
      } else {
        expand();
      }
    },
    [collapse, expand]
  );

  const formRef = useRef<HTMLDivElement>(null);
  const form = useFormInstance();

  useEffect(() => {
    if (autoFocus && formRef.current) {
      const focusable = formRef.current.querySelectorAll(
        'input:not(:disabled), select:not(:disabled), textarea:not(:disabled), [tabindex]:not([tabindex="-1"]):not(:disabled)'
      );

      (focusable?.[0] as HTMLElement)?.focus();
    }
  }, [autoFocus, formRef]);

  const [actualSchema, childLayout, isVariant] = useFormSchema(
    entityType,
    layout,
    filterFields,
    isCreateForm
  );
  const addedFieldsSchema = useAddedFieldsSchema(
    entityType,
    actualSchema,
    addedFields,
    filterFields,
    isCreateForm
  );
  useEffect(() => {
    if (appendAddableFieldsWithFilter) {
      setAddedFields(
        getAddableFields(entityType, actualSchema, isCreateForm ?? false)
          .filter(appendAddableFieldsWithFilter)
          .sort(displayNameComparator)
          .map(convertFieldToLayoutSchemaField)
      );
    }
  }, [actualSchema, appendAddableFieldsWithFilter, entityType, isCreateForm]);

  const fieldModel = getFieldModelByEntityType(entityType);
  const fieldRenderer = useFormFieldRender(
    entityType,
    children,
    actualSchema,
    {
      customFieldNamePathGetter: isCreateForm
        ? createFormCustomFieldNamePathGetter
        : editFormCustomFieldNamePathGetter,
      fileComponent,
      ignoreRequired,
      isCreateForm,
      isFieldDisabled: disableAllFields
        ? yes
        : disableUniqueCustomFields
        ? isUniqueCustomField
        : undefined,
    },
    disableUniqueCustomFields
  );

  const renderField = useCallback(
    (schemaField) => {
      const rowKey = `${schemaField.fieldType}.${schemaField.field}.${
        // displayOrder may change if user manually adds new fields, so we don't take it into
        // account for fields, just for dividers to keep fields' key persistent
        schemaField.fieldType === SchemaFieldType.DIVIDER ? schemaField.displayOrder : ""
      }`;
      let component: JSX.Element | null = <Divider />;
      let fullWidth = true;

      if (schemaField.fieldType !== SchemaFieldType.DIVIDER) {
        const field = fieldModel.getByPlatformName(schemaField.field);
        if (!field) {
          loggingService.error("Unknown schema field found, skipped", { schemaField });
          return null;
        }

        // such fields are rendered with some other fields. E.g. person's lastName
        // is rendered along with person's firstName, so we don't need to show
        // it separately.
        if (field.hasFeature(FieldFeature.FORM_RENDERED_WITH_OTHER_FIELD)) {
          return null;
        }

        // Read-only fields should be still allowed in create forms
        const readOnly = !field?.isEditable && !isCreateForm;
        component = fieldRenderer(field, schemaField, readOnly, isVariant);
        fullWidth = !!field.formProperties?.fullWidth;
      }

      const className = isFunction(rowClassName) ? rowClassName(schemaField) : rowClassName;

      return (
        <div className={cn(styles.row, { [styles.full]: fullWidth }, className)} key={rowKey}>
          {component}
        </div>
      );
    },
    [fieldModel, fieldRenderer, isCreateForm, rowClassName, isVariant]
  );

  useDefaultValues(actualSchema, childLayout, isCreateForm, fieldModel, form, funnelStages);

  const canAddFieldExtended = useCallback(
    (field: IField) => {
      return (
        !addedFields.some((addedField) => addedField.field === field.platformName) &&
        canAddField?.(field) !== false
      );
    },
    [canAddField, addedFields]
  );

  const handleChange = useCallback(
    (key: string | string[]) => {
      setAdditionalFieldsVisible(!!key);
      localSettings.setAdditionalFieldsCollapseByEntityType(entityType, !!key);
    },
    [entityType]
  );

  return (
    <div className={cn(styles.container, className)} ref={formRef}>
      {disableAllFields && (
        <CardCollapse
          activeKey={open ? "alert" : undefined}
          className={styles.alert}
          onChange={handleCollapseChange}
          size="small"
        >
          <CollapsePanel
            header={
              <div className={styles.title}>
                <FontAwesomeIcon className={styles.icon} icon={faExclamationTriangle} />
                {intl.formatMessage({
                  id: "component.formFields.unableToEdit.alert.title",
                  defaultMessage: "Unable to edit record",
                  description: "Unable to edit record alert - title",
                })}
              </div>
            }
            key="alert"
          >
            <span>
              {intl.formatMessage({
                id: "component.formFields.unableToEdit.alert.description",
                defaultMessage:
                  "One or more required fields on this record are not accessible to you. Please contact your administrator to either gain access or remove the requirement for those fields.",
                description: "Unable to edit record alert - description",
              })}
            </span>
          </CollapsePanel>
        </CardCollapse>
      )}
      {actualSchema.map(renderField)}
      {addedFieldsSchema.length > 0 && (
        <div
          className={cn(
            styles.row,
            styles.full,
            isFunction(rowClassName) ? rowClassName() : rowClassName
          )}
          key="__addedFieldsDivider"
        >
          <Divider />
        </div>
      )}
      {isCreateForm || entityType === EntityType.ACTIVITY ? (
        addedFieldsSchema.map(renderField)
      ) : addedFieldsSchema.length > 0 ? (
        <Collapse
          accordion
          activeKey={additionalFieldsVisible ? "additionalFields" : undefined}
          className={styles.collapse}
          expandIconPosition="end"
          ghost
          onChange={handleChange}
        >
          <CollapsePanel
            forceRender
            header={intl.formatMessage({
              id: "component.formFields.additionalFields",
              defaultMessage: "Additional Fields",
              description: "Additional fields collapse header",
            })}
            key="additionalFields"
          >
            <div className={styles.content}>{addedFieldsSchema.map(renderField)}</div>
          </CollapsePanel>
        </Collapse>
      ) : null}
      {!disallowAdding && (
        <AddFieldButton
          canAddField={canAddFieldExtended}
          entityType={entityType}
          isCreateForm={isCreateForm}
          layout={layout}
          onAddField={handleAddField}
          schema={actualSchema}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  funnelStages: getFunnelStages(state),
});

export default connect(mapStateToProps)(FormFields);

import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const toolbarState = (state: RootState) => state.scene.map.toolbar;

export const areTerritoriesLoading = createSelector(
  toolbarState,
  ({ territoriesLoading }) => territoriesLoading
);

export const getTerritories = createSelector(toolbarState, ({ territories }) => territories);

export const getTerritoriesTotal = createSelector(
  toolbarState,
  ({ territoriesTotal }) => territoriesTotal
);

export const getTerritoriesToolbarFilters = createSelector(toolbarState, ({ filters }) => filters);

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import Skeleton from "antd/es/skeleton";
import { useIntl } from "react-intl";
import { defineMessages } from "react-intl";

import { Activity } from "@mapmycustomers/shared/types/entity";
import { Modal, ModalProps } from "@mapmycustomers/ui";

import { showEntityView } from "@app/store/entityView/actions";
import EntityViewPayload from "@app/store/entityView/EntityViewPayload";
import { RootState } from "@app/store/rootReducer";
import loggingService from "@app/util/logging";

import ActivitiesView from "./ActivitiesView";
import styles from "./ActivityAnnotation.module.scss";
import ActivityView from "./ActivityView";
import NoAccess from "./NoAccess";
import { destroyAnnotation, initializeAnnotation } from "./store/actions";
import { getActivities, isActivitiesHasNoAccess, isActivitiesLoading } from "./store/selectors";

const messages = defineMessages({
  activitiesModalTitle: {
    id: "component.activityAnnotation.activities.modalTitle",
    defaultMessage: "Email: {subject}",
    description: "Email activities modal title",
  },
});

const ACTIVITIES_MODAL_WIDTH = "60rem";

const ACTIVITY_MODAL_WIDTH = 450;
const ACTIVITY_MODAL_X_GAP = 20;
const ACTIVITY_MODAL_FULL_HEIGHT_ADDON = 50; // we measure height of modal content. Full modal size is a bit bigger, by this number of pixels (or close to that).

interface Props {
  activities: Activity[];
  activitiesHasNoAccess: boolean;
  activitiesLoading: boolean;
  activityId: Activity["id"];
  eventElement?: HTMLElement;
  onChange?: (activity: Activity) => void;
  onDestroy: typeof destroyAnnotation;
  onEdit: (payload: EntityViewPayload) => void;
  onHide: () => void;
  onInitialize: typeof initializeAnnotation.request;
}

export const ActivityAnnotation: React.FC<Props> = ({
  activities,
  activitiesHasNoAccess,
  activitiesLoading,
  activityId,
  eventElement,
  onChange,
  onDestroy,
  onEdit,
  onHide,
  onInitialize,
}) => {
  const intl = useIntl();

  const modalTitle: ModalProps["title"] = useMemo(() => {
    return activities.length > 1
      ? intl.formatMessage(messages.activitiesModalTitle, {
          subject: activities[0].emailLog?.subject,
        })
      : null;
  }, [intl, activities]);

  // position modal so that it's on the left of the event block (if possible), otherwise position it to the right
  // also, vertically position so that modal and event centers are aligned. If not possible (is behind to screen
  // edges), position it to be slightly lower than screen top or slightly higher than screen bottom.
  const [contentElement, setContentElement] = useState<HTMLElement | null>(null);
  const [{ left, top }, setPosition] = useState<{ left: number; top: number }>(() => {
    if (eventElement) {
      const rect = eventElement.getBoundingClientRect();
      let left =
        rect.left - ACTIVITY_MODAL_WIDTH - ACTIVITY_MODAL_X_GAP < 0
          ? rect.right + ACTIVITY_MODAL_X_GAP
          : rect.left - ACTIVITY_MODAL_WIDTH - ACTIVITY_MODAL_X_GAP;
      if (left + ACTIVITY_MODAL_WIDTH > window.innerWidth) {
        left = window.innerWidth - ACTIVITY_MODAL_WIDTH - ACTIVITY_MODAL_X_GAP;
      }
      if (left < 0) {
        left = 0;
      }
      return { left, top: rect.top };
    }
    return { left: 0, top: 0 };
  });

  useEffect(() => {
    onInitialize(activityId);

    return () => {
      onDestroy();
    };
  }, [activityId, onInitialize, onDestroy]);

  useEffect(() => {
    if (!eventElement || !contentElement) {
      return;
    }

    try {
      const observer = new ResizeObserver(() => {
        const eventRect = eventElement.getBoundingClientRect();
        const contentRect = contentElement!.getBoundingClientRect();
        let top = Math.max(
          ACTIVITY_MODAL_X_GAP,
          eventRect.top +
            eventRect.height / 2 -
            (contentRect.height + ACTIVITY_MODAL_FULL_HEIGHT_ADDON) / 2
        );
        if (top + contentRect.height + ACTIVITY_MODAL_FULL_HEIGHT_ADDON > window.innerHeight) {
          top =
            window.innerHeight -
            contentRect.height -
            ACTIVITY_MODAL_FULL_HEIGHT_ADDON -
            ACTIVITY_MODAL_X_GAP;
        }
        setPosition((position) => ({ ...position, top }));
      });

      observer.observe(contentElement);
      return () => {
        observer.disconnect();
      };
    } catch (e) {
      loggingService.error("Error in ActivityAnnotation", e);
    }
  }, [contentElement, eventElement]);

  const handleHide = useCallback(() => {
    onHide();
  }, [onHide]);

  const renderModalContent = () => {
    if (activitiesLoading) {
      return (
        <div ref={setContentElement}>
          <Skeleton active />
        </div>
      );
    }

    if (activitiesHasNoAccess) {
      return <NoAccess />;
    }

    if (activities.length > 1) {
      return <ActivitiesView onChange={onChange} onEdit={onEdit} onHide={handleHide} />;
    }

    return (
      <div ref={setContentElement}>
        <ActivityView onChange={onChange} onEdit={onEdit} />
      </div>
    );
  };

  const isActivitiesModal = activities.length > 1;

  return (
    <Modal
      centered={isActivitiesModal || !eventElement}
      className={!isActivitiesModal ? styles.activityModal : undefined}
      footer={null}
      mask={isActivitiesModal}
      onCancel={handleHide}
      open
      scrollable={isActivitiesModal}
      style={eventElement && !isActivitiesModal ? { left, margin: "unset", top } : {}}
      title={modalTitle}
      width={isActivitiesModal ? ACTIVITIES_MODAL_WIDTH : ACTIVITY_MODAL_WIDTH}
      wrapClassName={
        !isActivitiesModal ? styles.activityModalWrapper : styles.activitiesModalWrapper
      }
    >
      {renderModalContent()}
    </Modal>
  );
};

export const mapStateToPropsActivityAnnotation = (state: RootState) => ({
  activities: getActivities(state),
  activitiesHasNoAccess: isActivitiesHasNoAccess(state),
  activitiesLoading: isActivitiesLoading(state),
});

export const mapDispatchToPropsActivityAnnotation = {
  onDestroy: destroyAnnotation,
  onEdit: showEntityView,
  onInitialize: initializeAnnotation.request,
};

export default connect(
  mapStateToPropsActivityAnnotation,
  mapDispatchToPropsActivityAnnotation
)(ActivityAnnotation);

import React, { useMemo } from "react";
import { connect } from "react-redux";

import { faArrowPointer } from "@fortawesome/free-solid-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import cn from "classnames";

import EmailLog from "@mapmycustomers/shared/types/email/EmailLog";

import { getUserSettingValue } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";

import styles from "./EmailsMetrics.module.scss";

interface EmailsMetricsProps {
  emailLogs: EmailLog[];
  visible?: boolean;
}

const EmailMetrics: React.FC<EmailsMetricsProps> = ({ emailLogs, visible }) => {
  const sendEmailsCount = useMemo(() => {
    return emailLogs.filter((emailLog) => emailLog.opened).length;
  }, [emailLogs]);

  const linkOpenedEmailsCount = useMemo(() => {
    return emailLogs.filter((emailLog) => emailLog.linksOpened).length;
  }, [emailLogs]);

  const totalCount = emailLogs.length;

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={cn(styles.chip, { [styles.chipActive]: sendEmailsCount > 0 })}>
        <FontAwesomeIcon className={styles.chipIcon} icon={faEnvelopeOpen} />
        <span className={styles.chipLabel}>
          {sendEmailsCount}/{totalCount}
        </span>
      </div>
      <div className={cn(styles.chip, { [styles.chipActive]: linkOpenedEmailsCount > 0 })}>
        <FontAwesomeIcon className={styles.chipIcon} icon={faArrowPointer} />
        <span className={styles.chipLabel}>{linkOpenedEmailsCount}</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  visible: getUserSettingValue(state)("bulkEmail").trackEmails,
});

export default connect(mapStateToProps)(EmailMetrics);

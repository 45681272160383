import { put, select, takeLatest } from "redux-saga/effects";

import { Territory } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import { regionStates } from "@mapmycustomers/shared/util/consts";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";

import { fetchTerritories } from "./actions";

export function* onFetchTerritories({ payload }: ReturnType<typeof fetchTerritories.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);

    const $filters: PlatformFilterModel = {
      $and: [],
      includeAccessStatus: true,
      includeEntitiesCount: true,
    };
    if (payload.query) {
      $filters.$and!.push({ name: { $in: payload.query } });
    }
    if (payload.creatorIds?.length) {
      $filters.$and!.push({ userId: { $in: payload.creatorIds } });
    }
    if (payload.sharedWithIds?.length) {
      $filters.$and!.push({ userIds: { $in: payload.sharedWithIds } });
    }
    if (payload.countryRegions?.length) {
      const countryStates = payload.countryRegions.flatMap((region) => regionStates[region] ?? []);
      $filters.$and!.push({ "territoryDetail.region": { $in: countryStates } });
    }

    const response: ListResponse<Territory> = yield callApi("fetchTerritories", organizationId, {
      $filters,
      $order: "name",
    });

    yield put(
      fetchTerritories.success({
        territories: response.data,
        territoriesTotal: response.accessible,
      })
    );
  } catch (error) {
    yield put(fetchTerritories.failure(error));
    yield put(handleError({ error }));
  }
}

export function* toolbarSagas() {
  yield takeLatest(fetchTerritories.request, onFetchTerritories);
}

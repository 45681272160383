import { combineReducers } from "redux";

import activityRouteBuilder, {
  RouteBuilderActions,
} from "./activity/component/ActivityCalendarPage/components/Sidebar/RouteBuilder/store";
import activityCalendar, { CalendarActions } from "./activity/component/ActivityCalendarPage/store";
import activityScheduler from "./activity/component/ActivitySchedulerPage/store";
import activity, { ActivityActions } from "./activity/store";
import billing, { BillingActions } from "./billing/store";
import company, { CompanyActions } from "./company/store";
import dashboard, { DashboardActions } from "./dashboard/store";
import deal, { DealSceneActions } from "./deal/store";
import emailUnsubscribe, { EmailUnsubscribeActions } from "./emailUnsubscribe/store";
import home, { HomeActions } from "./home/store";
import map, { MapActions } from "./map/store";
import onboarding, { OnboardingSceneActions } from "./onboarding/store";
import people, { PeopleActions } from "./people/store";
import report, { ReportActions } from "./reports/store";
import settings, { SettingsActions } from "./settings/settingsReducer";

const sceneReducer = combineReducers({
  activity,
  activityCalendar,
  activityRouteBuilder,
  activityScheduler,
  billing,
  company,
  dashboard,
  deal,
  emailUnsubscribe,
  home,
  map,
  onboarding,
  people,
  report,
  settings,
});

export type SceneActions =
  | ActivityActions
  | BillingActions
  | CalendarActions
  | CompanyActions
  | DashboardActions
  | DealSceneActions
  | EmailUnsubscribeActions
  | HomeActions
  | MapActions
  | OnboardingSceneActions
  | PeopleActions
  | ReportActions
  | RouteBuilderActions
  | SettingsActions;

export default sceneReducer;

import React, { useCallback } from "react";

import Alert from "antd/es/alert";
import { useIntl } from "react-intl";

import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import localSettings from "@app/config/LocalSettings";

import messages from "./messages";

interface EmailTemplateAttachmentsAlertProps {
  className?: string;
}

const TemplateAttachmentsAlert: React.FC<EmailTemplateAttachmentsAlertProps> = ({ className }) => {
  const intl = useIntl();

  const [hidden, hide] = useBoolean(() => localSettings.isEmailTemplateAttachmentsAlertDismissed());

  const handleDontShowAgainClick = useCallback(() => {
    localSettings.setEmailTemplateAttachmentsAlertDismissed(true);
    hide();
  }, [hide]);

  if (hidden) {
    return null;
  }

  return (
    <Alert
      className={className}
      closable
      message={intl.formatMessage(messages.attachmentsAlert, {
        b: (text) => <strong>{text}</strong>,
      })}
      onClose={handleDontShowAgainClick}
      showIcon
      type="info"
    />
  );
};

export default TemplateAttachmentsAlert;

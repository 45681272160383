import React from "react";

import cn from "classnames";
import { useIntl } from "react-intl";

import queueEmptySrc from "@app/assets/emailQueue/queue-empty.svg";

import styles from "./EmailQueueEmpty.module.scss";
import messages from "./messages";

interface EmailQueueEmpty {
  className?: string;
}

const EmailQueueEmpty: React.FC<EmailQueueEmpty> = ({ className }) => {
  const intl = useIntl();
  return (
    <div className={cn(className, styles.container)}>
      <img src={queueEmptySrc} />
      <span className={styles.title}>{intl.formatMessage(messages.queueEmptyLabel)}</span>
      <span className={styles.description}>
        {intl.formatMessage(messages.queueEmptyDescription)}
      </span>
    </div>
  );
};

export default EmailQueueEmpty;

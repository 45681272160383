import React, { useCallback, useMemo, useState } from "react";

import { faSearch } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { bem } from "@react-md/utils";

import FieldCategory from "@mapmycustomers/shared/enum/fieldModel/FieldCategory";
import { EntityTypeSupportingPreview } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import { CategorizedFields } from "@mapmycustomers/shared/types/fieldModel/IFieldModel";

import { useConfigProvider } from "../ConfigProvider";
import TextField from "../input/TextField";

const block = bem("mmc-entity-field-selector-fields-list");

interface Props {
  editableFields?: Partial<CategorizedFields>;
  entityType: EntityTypeSupportingPreview;
  onChange?: (field: IField, entityType: EntityTypeSupportingPreview) => void;
  onCloseDropdown: () => void;
}

const EntityFieldsList: React.FC<Props> = ({
  editableFields,
  entityType,
  onChange,
  onCloseDropdown,
}) => {
  const [search, setSearch] = useState("");

  const { formatMessage, getFieldCategoryDisplayName } = useConfigProvider();
  const filteredCategories = useMemo(
    () =>
      editableFields
        ? (Object.keys(editableFields) as FieldCategory[]).filter(
            (category) => editableFields[category]?.length
          )
        : [],
    [editableFields]
  );

  const handleFieldClick = useCallback(
    (field: IField) => {
      onChange?.(field, entityType);
      onCloseDropdown();
    },
    [entityType, onChange, onCloseDropdown]
  );

  if (!editableFields) {
    return null;
  }

  return (
    <div className={block()}>
      <TextField
        onChange={setSearch}
        placeholder={formatMessage("ui.entityFieldSelector.search.placeholder")}
        suffix={<FontAwesomeIcon className={block("search-icon")} icon={faSearch} />}
        value={search}
      />
      <div className={block("entity-fields")}>
        {filteredCategories.map((category) => {
          const filteredFields = editableFields[category]?.filter(
            (field) =>
              !search ||
              field.displayName.trim().toLowerCase().includes(search.trim().toLowerCase())
          );
          if (!filteredFields || !filteredFields.length) {
            return;
          }

          return (
            <React.Fragment key={category}>
              <span className={block("field-category")} key={category}>
                {getFieldCategoryDisplayName(category)}
              </span>
              {filteredFields?.map((field) => (
                <span
                  className={block("field-item")}
                  key={field.name}
                  onClick={() => handleFieldClick(field)}
                >
                  {field.displayName}
                </span>
              ))}
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default EntityFieldsList;

import React, { FC, ReactNode, useCallback, useMemo } from "react";

import { bem } from "@react-md/utils";
import { Dropdown } from "antd";
import { MenuProps } from "antd/es/menu";

import { EntityType } from "@mapmycustomers/shared";
import FieldCategory from "@mapmycustomers/shared/enum/fieldModel/FieldCategory";
import { EntityTypeSupportingPreview } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import IFieldModel, {
  CategorizedFields,
} from "@mapmycustomers/shared/types/fieldModel/IFieldModel";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";

import { useConfigProvider } from "../ConfigProvider/";
import EntityTypeIcon from "../EntityTypeShapedIcon";

import EntityFieldsList from "./EntityFieldsList";

const block = bem("mmc-entity-field-selector");

const yes = () => true;

interface Props {
  addedFields: IField[];
  children: ReactNode;
  entityFieldModels: Record<EntityTypeSupportingPreview, IFieldModel>;
  fieldFilter?: (field: IField) => boolean;
  onChange?: (field: IField, entityType: EntityTypeSupportingPreview) => void;
  value?: IField;
}

const EntityFieldSelector: FC<Props> = ({
  addedFields,
  children,
  entityFieldModels,
  fieldFilter = yes,
  onChange,
  value,
}) => {
  const configProvider = useConfigProvider();
  const [open, show, close] = useBoolean(false);

  const handleOpenChange = useCallback((open: boolean) => (open ? show : close)(), [close, show]);

  const entityFields: Partial<Record<EntityTypeSupportingPreview, Partial<CategorizedFields>>> =
    useMemo(
      () =>
        (
          [EntityType.COMPANY, EntityType.PERSON, EntityType.DEAL] as EntityTypeSupportingPreview[]
        ).reduce((result, entityType) => {
          const fieldModel = entityFieldModels[entityType];
          const fields = (Object.keys(fieldModel.categorizedFields) as FieldCategory[]).reduce(
            (result, category) => ({
              ...result,
              [category]: fieldModel.categorizedFields[category].filter(
                (field: IField) =>
                  field.isReadable &&
                  field.isCustomizableField &&
                  fieldFilter(field) &&
                  (field.name === value?.name || !addedFields.includes(field))
              ),
            }),
            {}
          );
          return { ...result, [entityType]: fields };
        }, {}),
      [addedFields, entityFieldModels, fieldFilter, value?.name]
    );

  const items: MenuProps["items"] = useMemo(
    () => [
      {
        children: [
          {
            className: block("sub-menu"),
            disabled: true,
            key: "companyFields",
            label: (
              <EntityFieldsList
                editableFields={entityFields[EntityType.COMPANY]}
                entityType={EntityType.COMPANY}
                onChange={onChange}
                onCloseDropdown={close}
              />
            ),
          },
        ],
        className: block("entity"),
        key: "company",
        label: (
          <div className={block("label")}>
            <EntityTypeIcon
              className={block("entity-icon")}
              entityType={EntityType.COMPANY}
              size="2xs"
            />
            <span>{configProvider.formatMessage("ui.entityFieldSelector.company")}</span>
          </div>
        ),
      },
      {
        children: [
          {
            className: block("sub-menu"),
            disabled: true,
            key: "personFields",
            label: (
              <EntityFieldsList
                editableFields={entityFields[EntityType.PERSON]}
                entityType={EntityType.PERSON}
                onChange={onChange}
                onCloseDropdown={close}
              />
            ),
          },
        ],
        className: block("entity"),
        key: "person",
        label: (
          <div className={block("label")}>
            <EntityTypeIcon
              className={block("entity-icon")}
              entityType={EntityType.PERSON}
              size="2xs"
            />
            <span>{configProvider.formatMessage("ui.entityFieldSelector.person")}</span>
          </div>
        ),
      },
      {
        children: [
          {
            className: block("sub-menu"),
            disabled: true,
            key: "dealFields",
            label: (
              <EntityFieldsList
                editableFields={entityFields[EntityType.DEAL]}
                entityType={EntityType.DEAL}
                onChange={onChange}
                onCloseDropdown={close}
              />
            ),
          },
        ],
        className: block("entity"),
        key: "deal",
        label: (
          <div className={block("label")}>
            <EntityTypeIcon
              className={block("entity-icon")}
              entityType={EntityType.DEAL}
              size="2xs"
            />
            <span>{configProvider.formatMessage("ui.entityFieldSelector.deal")}</span>
          </div>
        ),
      },
    ],
    [close, configProvider, entityFields, onChange]
  );

  return (
    <Dropdown
      className={block()}
      menu={{
        className: block("menu"),
        items,
        openKeys: open ? undefined : [],
        selectable: true,
        triggerSubMenuAction: "click",
      }}
      onOpenChange={handleOpenChange}
      open={open}
      trigger={["click"]}
    >
      {children}
    </Dropdown>
  );
};

export default EntityFieldSelector;

import React, { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import { notification } from "antd";
import Button from "antd/es/button";
import { isEqual } from "lodash-es";
import { useIntl } from "react-intl";

import {
  Company,
  EntityType,
  EntityTypesSupportingCompanyAssociation,
  Person,
} from "@mapmycustomers/shared/types/entity";
import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import { PlatformFilterCondition } from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import useDynamicCallback from "@mapmycustomers/shared/util/hook/useDynamicCallback";
import { Modal } from "@mapmycustomers/ui";

import noRecordsImageMap from "@app/component/associations/utils/noRecordsImages";
import CreateCompanyModal from "@app/component/createEditEntity/CreateCompanyModal";
import { isCurrentUserReadOnly } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";

import styles from "./Association.module.scss";
import Associations from "./components/Associations";
import messages from "./messages";
import {
  filterCompanies,
  getCompanies,
  getFilteredCompaniesCount,
  getTotalCompanies,
  isLoading,
} from "./store";
import useEntitySorter from "./utils/useEntitySorter";

const LIMITS = {
  // High limits as this will never be possible
  [EntityType.ACTIVITY]: 1000,
  [EntityType.COMPANY]: 1000,
  [EntityType.DEAL]: 1000,
  [EntityType.PERSON]: 100,
  [EntityType.TRIP]: 1,
};

interface Props {
  allowAdd?: boolean;
  assignedCompanies?: CompanyRef[] | Person["accounts"];
  associateWith?: Company["id"];
  childSelect?: boolean;
  companies?: Company[];
  defaultQuery?: string;
  entityType: EntityTypesSupportingCompanyAssociation;
  filtered?: number;
  getListTitle?: (count: number, total: number) => string;
  hideOnSelect?: boolean;
  info?: React.ReactNode;
  isReadOnlyMember: boolean;
  loading?: boolean;
  multiselect?: boolean;
  okLoading?: boolean;
  okText?: string;
  onHide: (updated?: boolean) => void;
  onSearch: typeof filterCompanies.request;
  onSelect?: (selectedCompaniesIds: Company["id"][], removedCompaniesIds?: Company["id"][]) => void;
  onUpdatePrimaryCompany?: (accountId?: Company["id"]) => void;
  primaryCompanyId?: Company["id"];
  requestFilters?: PlatformFilterCondition[];
  suggestedCompanies?: Company[];
  supportsPrimaryEntity?: boolean;
  title?: ReactNode;
  total: number;
  zIndex?: number;
}

const CompanyAssociation: React.FC<Props> = ({
  allowAdd = true,
  assignedCompanies,
  associateWith,
  childSelect = false,
  companies,
  defaultQuery = "",
  entityType,
  filtered,
  getListTitle,
  hideOnSelect = true,
  info,
  isReadOnlyMember,
  loading,
  multiselect = true,
  okLoading,
  okText,
  onHide,
  onSearch,
  onSelect,
  onUpdatePrimaryCompany,
  primaryCompanyId,
  requestFilters,
  suggestedCompanies,
  supportsPrimaryEntity,
  title,
  total,
  zIndex,
}) => {
  const intl = useIntl();

  const [query, setQuery] = useState<string>(defaultQuery);
  const [selectedCompaniesIds, setSelectedCompaniesIds] = useState<Company["id"][]>(
    (assignedCompanies ?? []).map(({ id }) => id)
  );
  const [internalPrimaryCompanyId] = useState<Company["id"] | undefined>(primaryCompanyId);

  const removedCompaniesIds = useMemo(
    () =>
      assignedCompanies
        ?.filter(({ id }) => !selectedCompaniesIds.includes(id))
        .map(({ id }) => id) ?? [],
    [assignedCompanies, selectedCompaniesIds]
  );

  const [createdCompanies, setCreatedCompanies] = useState<Company[]>([]);
  const sortedCompanies = useEntitySorter(
    companies ?? [],
    createdCompanies,
    query.trim().length > 0,
    assignedCompanies as Company[],
    suggestedCompanies,
    primaryCompanyId
  );

  const handleOkClick = useCallback(() => {
    const limit = LIMITS[entityType];
    if (limit && selectedCompaniesIds.length > limit) {
      notification.error({
        description: intl.formatMessage(messages.errorDescription, {
          entity: intl.formatMessage(messages[entityType]),
          limit,
        }),
        message: intl.formatMessage(messages.errorTitle),
      });
    } else {
      onSelect?.(selectedCompaniesIds, removedCompaniesIds);
      if (hideOnSelect) {
        onHide(true);
      }
    }
  }, [entityType, hideOnSelect, intl, onHide, onSelect, removedCompaniesIds, selectedCompaniesIds]);

  useEffect(() => {
    onSearch({
      companyId: associateWith,
      isChildSelection: childSelect,
      query: defaultQuery,
      requestFilters,
    });
  }, [associateWith, childSelect, defaultQuery, onSearch, requestFilters]);

  const handleSearch = useCallback(
    (query: string) => {
      onSearch({ companyId: associateWith, isChildSelection: childSelect, query, requestFilters });
    },
    [associateWith, childSelect, onSearch, requestFilters]
  );

  const [createNewCompanyVisible, showCreateCompanyModal, hideCreateCompanyModal] = useBoolean();
  const handleHideCreateCompanyModal = useDynamicCallback((newCompany?: Company) => {
    const alreadyAssignedCompaniesIds = new Set(
      [...(assignedCompanies ?? []), ...createdCompanies].map(({ id }) => id)
    );
    const hadAnySelectedCompaniesAlready = selectedCompaniesIds.some(
      (id) => !alreadyAssignedCompaniesIds.has(id)
    );
    if (newCompany) {
      setSelectedCompaniesIds((ids) => (multiselect ? [...ids, newCompany.id] : [newCompany.id]));
      // we also store created companies, just to show then in the top of the list, to not confuse user
      // because otherwise, newly added record could get filtered out according to current filter
      // that's also the reason why we reset the filter here
      setCreatedCompanies((companies) => [...companies, newCompany]);
      setQuery("");
      onSearch({
        companyId: associateWith,
        isChildSelection: childSelect,
        query: "",
        requestFilters,
      });
    }
    hideCreateCompanyModal();

    // if user selected no deals in the modal before adding new deal, then just hide both modals
    if (!hadAnySelectedCompaniesAlready && newCompany) {
      onSelect?.([...selectedCompaniesIds, newCompany.id], removedCompaniesIds);
      if (hideOnSelect) {
        onHide(true);
      }
    }
  });

  const handleCancel = useCallback(() => {
    if (internalPrimaryCompanyId !== primaryCompanyId) {
      onUpdatePrimaryCompany?.(internalPrimaryCompanyId);
    }
    onHide(false);
  }, [internalPrimaryCompanyId, onHide, onUpdatePrimaryCompany, primaryCompanyId]);

  const companiesUpdated = useMemo(
    () =>
      multiselect
        ? !isEqual(selectedCompaniesIds.sort(), assignedCompanies?.map(({ id }) => id).sort())
        : assignedCompanies?.[0]?.id !== selectedCompaniesIds[0],
    [assignedCompanies, multiselect, selectedCompaniesIds]
  );

  const alreadyAssignedEntities = useMemo(
    () => [...((assignedCompanies ?? []) as Company[]), ...createdCompanies],
    [assignedCompanies, createdCompanies]
  );

  return (
    <Modal
      className={styles.modal}
      okButtonProps={{
        disabled: (!companiesUpdated && internalPrimaryCompanyId === primaryCompanyId) || okLoading,
        loading: okLoading,
      }}
      okText={
        okText ??
        intl.formatMessage(messages.footerOkButton, {
          addCompanies: assignedCompanies?.length === 0 || !assignedCompanies,
          multiselect,
        })
      }
      onCancel={handleCancel}
      onOk={handleOkClick}
      open
      title={title ?? intl.formatMessage(messages.header, { multiselect })}
      width="clamp(500px, 50vw, 750px)"
      zIndex={zIndex}
    >
      {info}
      <Associations<Company>
        alreadyAssociatedMessage={intl.formatMessage(messages.alreadyAssociated)}
        assignedRecords={alreadyAssignedEntities}
        associateWith={associateWith}
        cantAssociateWithSelfMessage={intl.formatMessage(messages.cantAssociateWithSelf)}
        entities={sortedCompanies}
        extras={
          allowAdd && !isReadOnlyMember ? (
            <Button onClick={showCreateCompanyModal} type="link">
              {intl.formatMessage(messages.addNewCompanyButton)}
            </Button>
          ) : undefined
        }
        filtered={filtered}
        getListTitle={getListTitle}
        loading={loading}
        multiselect={multiselect}
        noDataImageSrc={noRecordsImageMap[EntityType.COMPANY]}
        onChange={setSelectedCompaniesIds}
        onChangeQuery={setQuery}
        onSearch={handleSearch}
        onUpdatePrimaryEntity={onUpdatePrimaryCompany}
        primaryEntityId={primaryCompanyId}
        query={query}
        suggestedEntities={suggestedCompanies}
        supportsPrimaryEntity={supportsPrimaryEntity}
        textFieldProps={{
          placeholder: intl.formatMessage(messages.placeholder),
        }}
        total={total}
        value={selectedCompaniesIds}
      />
      {createNewCompanyVisible && (
        <CreateCompanyModal
          fixedParentCompanyId={childSelect ? associateWith : undefined}
          okText={intl.formatMessage(messages.modalOkButton)}
          onHide={handleHideCreateCompanyModal}
        />
      )}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  companies: getCompanies(state),
  filtered: getFilteredCompaniesCount(state),
  isReadOnlyMember: isCurrentUserReadOnly(state),
  loading: isLoading(state),
  total: getTotalCompanies(state),
});

const mapDispatchToProps = {
  onSearch: filterCompanies.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAssociation);

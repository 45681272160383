import { FieldDescription } from "@mapmycustomers/shared/types/entity";

const convertToFieldDescription =
  (startFrom: number) =>
  (field: FieldDescription | string, index: number): FieldDescription => {
    if (typeof field === "string") {
      return { displayOrder: startFrom + index, fieldName: field };
    }
    return field;
  };

export default convertToFieldDescription;

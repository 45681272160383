import { MessageDescriptor } from "@formatjs/intl/src/types";
import { ActionType, createAction } from "typesafe-actions";

export const handleError = createAction("@errors/handleError")<{
  defaultMessage?: MessageDescriptor;
  error: unknown;
  messageByErrorCodeMap?: Record<string, MessageDescriptor>;
}>();

export type Actions = ActionType<typeof handleError>;

import React from "react";

import { useIntl } from "react-intl";

import { UserRef } from "@mapmycustomers/shared/types/User";
import { Avatar } from "@mapmycustomers/ui";
import { SelectField, SelectFieldProps } from "@mapmycustomers/ui";

import { getUserDisplayNameWithInfo } from "@app/util/formatters";

import messages from "./messages";

interface Props {
  assignee: UserRef;
  label: SelectFieldProps["label"];
  required?: boolean;
}

const LockedAssignee: React.FC<Props> = ({ assignee, label, required }) => {
  const intl = useIntl();

  return (
    <SelectField
      disabled
      label={label}
      locked
      lockTooltip={intl.formatMessage(messages.assigneeLockTooltip)}
      placeholder={getUserDisplayNameWithInfo(intl, assignee)}
      prefixIcon={<Avatar size="small" user={assignee} />}
      required={required}
    />
  );
};

export default LockedAssignee;

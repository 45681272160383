import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { faPenAlt } from "@fortawesome/free-solid-svg-icons/faPenAlt";
import Skeleton from "antd/es/skeleton";
import cn from "classnames";
import { differenceInHours } from "date-fns/esm";
import { defineMessages, useIntl } from "react-intl";

import { Activity, EntityType } from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";

import AccessReasonExposer from "@app/component/AccessReasonExposer";
import EditActivityFocusableField from "@app/component/activity/type/EditActivityFocusableField";
import ActivityNotePreview from "@app/component/preview/components/ActivityNotePreview";
import EntityViewPayload from "@app/store/entityView/EntityViewPayload";
import { addUserRecent } from "@app/store/globalSearch/actions";
import { getMe } from "@app/store/iam";
import { getUsersOfEntireOrganization } from "@app/store/members";
import { RootState } from "@app/store/rootReducer";
import Iam from "@app/types/Iam";
import { ACTIVITY_TIME_DIFFERENCE_ALERT } from "@app/util/activity/const";
import showActivityTimeUpdateAlert from "@app/util/activity/showActivityTimeUpdateAlert";
import useAnalytics from "@app/util/contexts/useAnalytics";
import { activityLayoutModel } from "@app/util/layout/impl";
import { parseApiDate } from "@app/util/parsers";

import styles from "./ActivityView.module.scss";
import AttendeesSection from "./AttendeesSection";
import DetailsSection from "./DetailsSection";
import EmailSection from "./EmailSection";
import FilesSection from "./FilesSection";
import Footer from "./Footer";
import NoAccess from "./NoAccess";
import RelationShipsSection from "./RelationshipsSection";
import Section from "./Section";
import { postponeActivity, toggleComplete } from "./store/actions";
import AnnotationData from "./store/AnnotationData";
import {
  getAnnotationData,
  isAnnotationDataHasNoAccess,
  isAnnotationDataLoading,
  isMultipleActivities,
} from "./store/selectors";
import TopSection from "./TopSection";

const messages = defineMessages({
  crmLink: {
    id: "activity.field.crmLink",
    defaultMessage: "CRM Link",
    description: "CRM link field of activity",
  },
  emailTitle: {
    id: "component.activityAnnotation.emailTitle",
    defaultMessage: "Email to {recipient}: {subject}",
    description: "Title for activity when multiply activities are displaying",
  },
  notesSectionTitle: {
    id: "component.activityAnnotation.notesSection.title",
    defaultMessage: "Notes",
    description: "Title for activity notes section",
  },
});

interface ActivityViewProps {
  annotationData?: AnnotationData;
  annotationDataHasNoAccess: boolean;
  annotationDataLoading: boolean;
  className?: string;
  me: Iam;
  multiplyActivities: boolean;
  onAddUserRecent: typeof addUserRecent;
  onChange?: (activity: Activity) => void;
  onEdit: (payload: EntityViewPayload) => void;
  onPostponeActivity: typeof postponeActivity.request;
  onToggleComplete: typeof toggleComplete.request;
  users: User[];
}

const ActivityView: React.FC<ActivityViewProps> = ({
  annotationData,
  annotationDataHasNoAccess,
  annotationDataLoading,
  className,
  me,
  multiplyActivities,
  onAddUserRecent,
  onChange,
  onEdit,
  onPostponeActivity,
  onToggleComplete,
  users,
}) => {
  const intl = useIntl();
  const analyticIssuer = useAnalytics();

  const hasMissingRequiredFields = useMemo(() => {
    return annotationData
      ? activityLayoutModel.hasMissingRequiredFieldsFor(annotationData.activity)
      : false;
  }, [annotationData]);

  const handleEdit = useCallback(
    (focusedFieldName?: EditActivityFocusableField) => {
      if (!annotationData) {
        return;
      }

      onEdit({
        edit: true,
        entityId: annotationData.activity.id,
        entityType: EntityType.ACTIVITY,
        focusedFieldName,
      });
      analyticIssuer?.clicked(["Edit"]);
    },
    [annotationData, analyticIssuer, onEdit]
  );

  const handlePostpone = useCallback(
    (activity: Activity) => {
      onPostponeActivity({ activity, callback: onChange });
    },
    [onChange, onPostponeActivity]
  );

  const handleToggleComplete = useCallback(() => {
    if (!annotationData) {
      return;
    }

    const { activity } = annotationData;

    // If trying to mark activity as done and have some required fields
    // missing - then display dialog to fill-in missing fields
    if (!activity.completed && hasMissingRequiredFields) {
      onEdit({
        edit: true,
        editValues: { completed: true },
        entityId: activity.id,
        entityType: activity.entity,
        requiredFieldsOnly: true,
      });
    } else {
      const startAt = activity.startAt ? parseApiDate(activity.startAt) : undefined;
      const endAt = activity.endAt ? parseApiDate(activity.endAt) : undefined;

      if (
        !activity.completed &&
        startAt &&
        Math.abs(differenceInHours(Date.now(), endAt ?? startAt)) >= ACTIVITY_TIME_DIFFERENCE_ALERT
      ) {
        showActivityTimeUpdateAlert(intl, activity, (activity: Activity) => {
          onToggleComplete({ activity, callback: onChange });
        });
      } else {
        onToggleComplete({ activity, callback: onChange });
      }
    }
  }, [annotationData, hasMissingRequiredFields, intl, onChange, onEdit, onToggleComplete]);

  useEffect(() => {
    if (!annotationData) {
      return;
    }

    onAddUserRecent({ entity: { id: annotationData.activity.id, type: EntityType.ACTIVITY } });
  }, [onAddUserRecent, annotationData]);

  if (annotationDataHasNoAccess) {
    return <NoAccess />;
  }

  if (!annotationData || annotationDataLoading) {
    return <Skeleton />;
  }

  const { activity, addressRecord, files } = annotationData;

  return (
    <div className={cn(styles.container, className)}>
      <TopSection
        activity={activity}
        addressRecord={addressRecord}
        title={
          multiplyActivities
            ? intl.formatMessage(messages.emailTitle, {
                recipient: activity.emailLog!.recipientAddress[0].name,
                subject: activity.emailLog!.subject,
              })
            : activity.name
        }
      />
      <div className={styles.details}>
        <EmailSection activity={activity} />
        <RelationShipsSection activity={activity} />
        <DetailsSection activity={activity} />
        {activity.note && (
          <Section icon={faPenAlt} title={intl.formatMessage(messages.notesSectionTitle)}>
            <ActivityNotePreview
              activity={activity}
              className={styles.paragraph}
              currentUserId={me.id}
              note={activity.note ?? ""}
              users={users}
            />
          </Section>
        )}
        <FilesSection activity={activity} files={files} />
        <AttendeesSection activity={activity} />
      </div>
      <AccessReasonExposer entity={activity} />
      {activity.hasAccess && (
        <Footer
          activity={activity}
          analyticIssuer={analyticIssuer}
          onEdit={handleEdit}
          onPostponeActivity={handlePostpone}
          onToggleComplete={handleToggleComplete}
        />
      )}
    </div>
  );
};

export const mapStateToProps = (state: RootState) => ({
  annotationData: getAnnotationData(state),
  annotationDataHasNoAccess: isAnnotationDataHasNoAccess(state),
  annotationDataLoading: isAnnotationDataLoading(state),
  me: getMe(state)!,
  multiplyActivities: isMultipleActivities(state),
  users: getUsersOfEntireOrganization(state),
});

export const mapDispatch = {
  onAddUserRecent: addUserRecent,
  onPostponeActivity: postponeActivity.request,
  onToggleComplete: toggleComplete.request,
};

export default connect(mapStateToProps, mapDispatch)(ActivityView);

import { createReducer } from "typesafe-actions";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";

import DrillDownViewState from "@app/scene/dashboard/types/DrillDownViewState";
import OpenStageDealAmountCardConfiguration from "@app/types/dashboard/cards/openStageDealAmount/OpenStageDealAmountCardConfiguration";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";
import dealFieldModel, { DealFieldName } from "@app/util/fieldModel/DealFieldModel";

import getDefaultDrillDownColumns from "../../utils/getDefaultDrillDownColumns";

import {
  applyOpenStageDealAmountDrillDownListViewSettings,
  fetchOpenStageDealAmountCardDrillDownData,
  hideOpenStageDealAmountDrillDown,
  OpenStageDealAmountCardActions,
  showOpenStageDealAmountDrillDown,
} from "./actions";

const defaultColumns: string[] = [
  DealFieldName.NAME,
  DealFieldName.USER,
  DealFieldName.ADDRESS,
  DealFieldName.CREATED_AT,
  DealFieldName.SOURCE,
];

const defaultSortColumn = DealFieldName.CREATED_AT;

export interface OpenStageDealAmountCardState {
  configuration?: OpenStageDealAmountCardConfiguration;
  loading?: boolean;
  totalFilteredRecords: number;
  totalRecords: number;
  viewState: DrillDownViewState;
}

export const openStageDealAmountInitialState: OpenStageDealAmountCardState = {
  totalFilteredRecords: 0,
  totalRecords: 0,
  viewState: {
    columns: getDefaultDrillDownColumns(defaultColumns, dealFieldModel),
    filter: {},
    range: { endRow: DEFAULT_PAGE_SIZE, startRow: 0 },
    sort: [
      {
        field: dealFieldModel.getByName(defaultSortColumn)!,
        order: SortOrder.DESC,
      },
    ],
    viewAs: undefined,
  },
};

const openStageDealAmountState = createReducer<
  OpenStageDealAmountCardState,
  OpenStageDealAmountCardActions
>(openStageDealAmountInitialState)
  .handleAction(showOpenStageDealAmountDrillDown.request, (state, { payload }) => ({
    ...state,
    configuration: payload.configuration,
  }))
  .handleAction(showOpenStageDealAmountDrillDown.success, (state, { payload }) => ({
    ...state,
    viewState: payload.viewState,
  }))
  .handleAction(hideOpenStageDealAmountDrillDown, (state) => ({
    ...state,
    configuration: undefined,
  }))
  .handleAction(fetchOpenStageDealAmountCardDrillDownData.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(fetchOpenStageDealAmountCardDrillDownData.success, (state, { payload }) => ({
    ...state,
    loading: false,
    totalFilteredRecords: payload.totalFilteredRecords,
    totalRecords: payload.totalRecords,
  }))
  .handleAction(fetchOpenStageDealAmountCardDrillDownData.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(applyOpenStageDealAmountDrillDownListViewSettings, (state, { payload }) => ({
    ...state,
    viewState: {
      ...state.viewState,
      columns: payload.columns ?? state.viewState.columns,
      filter: payload.filter ?? state.viewState.filter,
      range: payload.range ?? state.viewState.range,
      sort: payload.sort ?? state.viewState.sort,
      // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
      viewAs: "viewAs" in payload ? payload.viewAs : state.viewState.viewAs,
    },
  }));

export default openStageDealAmountState;

import React, { useCallback, useMemo } from "react";
import { connect } from "react-redux";

import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons/faCircleXmark";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popover from "antd/es/popover";
import Tag from "antd/es/tag";
import cn from "classnames";
import { PrimitiveType } from "intl-messageformat/src/formatters";
import { defineMessages, useIntl } from "react-intl";

import ActivityReliabilityReason from "@mapmycustomers/shared/enum/activity/ActivityReliabilityReason";
import { Activity } from "@mapmycustomers/shared/types/entity";

import OrganizationSetting from "@app/enum/OrganizationSetting";
import { areCheckInFieldsVisible, getOrganizationSettingValue } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import getFormattedDate from "@app/util/activity/getFormattedDate";
import getOverdueStatus from "@app/util/activity/getOverdueStatus";
import { formatRawDate } from "@app/util/formatters";

import useView from "../view/utils/useView";

import styles from "./ActivityDate.module.scss";
import { RecurringTooltip } from "./RecurringTooltip";

const messages = defineMessages({
  allDay: {
    id: "activityDate.allDay",
    defaultMessage: "All day",
    description: "All day suffix for date tag",
  },
  checkInReliabilityReasonsLabel: {
    id: "activityDate.checkInReliabilityReasonsLabel",
    defaultMessage: "For a check-in to be verified, you must:",
    description: "Check-in sub reliability reasons label",
  },
  completed: {
    id: "activityDate.completed",
    defaultMessage: "Marked done on:",
    description: "Completed label date tag tooltip",
  },
  createdAt: {
    id: "activityDate.createdAt",
    defaultMessage: "Created on {date}",
    description: "Created at label for displaying an additional date if sorted",
  },
  notVerifiedCheckIn: {
    id: "activityDate.notVerifiedCheckIn",
    defaultMessage: "Unverified check-in",
    description: "Check-in unverified label",
  },
  notVerifiedCheckInTitle: {
    id: "activityDate.notVerifiedCheckIn.title",
    defaultMessage: "Check-in is not verified",
    description: "A message for displaying not verified checkin title",
  },
  updatedAt: {
    id: "activityDate.updatedAt",
    defaultMessage: "Last modified on {date}",
    description: "Updated at label for displaying an additional date if sorted",
  },
  verifiedCheckIn: {
    id: "activityDate.verifiedCheckIn",
    defaultMessage: "Verified check-in",
    description: "Check-in verified label",
  },
  verifiedCheckInTitle: {
    id: "activityDate.verifiedCheckIn.title",
    defaultMessage: "Check-in is verified",
    description: "A message for displaying verified checkin title",
  },
});

const reliabilityReasonMessages = defineMessages({
  [ActivityReliabilityReason.DISTANCE]: {
    id: "activityDate.unreliableReason.distance",
    defaultMessage: "Start it within {radius}m of a record",
    description: "Distance unreliable reason description",
  },
  [ActivityReliabilityReason.ENTITY]: {
    id: "activityDate.unreliableReason.entity",
    defaultMessage:
      "Not remove the primary association, which is the record selected when you check in",
    description: "Primary association unreliable reason description",
  },
  [ActivityReliabilityReason.GPS_ACCURACY]: {
    id: "activityDate.unreliableReason.location",
    defaultMessage: "Check-in with precise location enabled on your mobile device",
    description: "Precise location unreliable reason description",
  },
  [ActivityReliabilityReason.TIME]: {
    id: "activityDate.unreliableReason.time",
    defaultMessage: "Not manually change the start time",
    description: "Start time unreliable reason description",
  },
});

interface Props {
  activity: Activity;
  checkInRadius: number;
  checkInVisible: boolean;
}

const ActivityDate: React.FC<Props> = ({ activity, checkInRadius, checkInVisible }) => {
  const intl = useIntl();
  const { viewState } = useView();

  const unreliableReasonsSet: ReadonlySet<ActivityReliabilityReason> = useMemo(
    () => new Set(activity.unreliableReasons),
    [activity.unreliableReasons]
  );

  const dateFormat = activity.allDay ? "PPP" : "PPPp";
  const hasBothStartAndEnd = activity.startAt && activity.endAt;
  const isOverdue = getOverdueStatus(activity);
  const tagColor = isOverdue ? "error" : undefined;
  const sortFieldName = viewState?.sort[0]?.field?.name;
  const sortFieldMessage = messages[sortFieldName as keyof typeof messages];

  const renderReliabilityReason = useCallback(
    (reliabilityReason: ActivityReliabilityReason, values?: Record<string, PrimitiveType>) => {
      const isUnreliableReason = unreliableReasonsSet.has(reliabilityReason);
      return (
        <div className={styles.checkInReliabilityReason}>
          <FontAwesomeIcon
            className={
              isUnreliableReason
                ? styles.checkInUnreliabilityReasonIcon
                : styles.checkInReliabilityReasonIcon
            }
            icon={isUnreliableReason ? faCircleXmark : faCheckCircle}
          />
          <div className={styles.checkInLabel}>
            {intl.formatMessage(reliabilityReasonMessages[reliabilityReason], values)}
          </div>
        </div>
      );
    },
    [intl, unreliableReasonsSet]
  );

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Popover
          content={
            <div className={styles.completedTooltipWrapper}>
              {hasBothStartAndEnd && (
                <div className={styles.durationWrapper}>
                  <span className={styles.date}>{formatRawDate(activity.startAt, dateFormat)}</span>
                  <span className={styles.date}>— {formatRawDate(activity.endAt, dateFormat)}</span>
                  {activity.allDay && (
                    <span className={styles.date}>{intl.formatMessage(messages.allDay)}</span>
                  )}
                </div>
              )}
              {activity.completedAt && (
                <>
                  <span className={styles.completedLabel}>
                    {intl.formatMessage(messages.completed)}
                  </span>
                  <span className={styles.date}>
                    {formatRawDate(activity.completedAt, dateFormat)}
                  </span>
                </>
              )}
            </div>
          }
          placement="bottom"
          trigger={activity.completedAt || hasBothStartAndEnd ? ["hover"] : []}
        >
          <Tag className={styles.tag} color={tagColor}>
            {intl.formatMessage(
              {
                id: "activityDate.dateLabel",
                defaultMessage:
                  "{showDate, select, true {{dateString} {allDay, select, true {(All day)} other {}}} other {Not scheduled}}",
                description: "A message for displaying date under different conditions",
              },
              {
                allDay: activity.allDay,
                dateString: <span className={styles.date}>{getFormattedDate(intl, activity)}</span>,
                showDate: !!activity.startAt || activity.completed,
              }
            )}
            <RecurringTooltip recurInterval={activity.recurInterval} />
          </Tag>
        </Popover>
        {sortFieldMessage && (sortFieldName === "updatedAt" || sortFieldName === "createdAt") && (
          <span className={styles.sortFieldMessage}>
            {intl.formatMessage(sortFieldMessage, {
              date: formatRawDate(activity[sortFieldName], "PPP"),
            })}
          </span>
        )}
        {checkInVisible && activity.reliability !== null && (
          <Popover
            content={
              <div className={styles.checkInContainer}>
                <div className={styles.checkInLabel}>
                  {intl.formatMessage(messages.checkInReliabilityReasonsLabel)}
                </div>
                <div className={styles.checkInReliabilityReasonsContainer}>
                  {renderReliabilityReason(ActivityReliabilityReason.DISTANCE, {
                    radius: checkInRadius,
                  })}
                  {renderReliabilityReason(ActivityReliabilityReason.TIME)}
                  {renderReliabilityReason(ActivityReliabilityReason.ENTITY)}
                  {renderReliabilityReason(ActivityReliabilityReason.GPS_ACCURACY)}
                </div>
              </div>
            }
            overlayClassName={styles.checkInOverlay}
            placement="bottom"
            title={
              <div className={styles.checkInTitle}>
                {activity.reliability
                  ? intl.formatMessage(messages.verifiedCheckInTitle)
                  : intl.formatMessage(messages.notVerifiedCheckInTitle)}
              </div>
            }
            trigger={["hover"]}
          >
            <span className={cn(styles.checkIn, { [styles.notVerified]: !activity.reliability })}>
              <FontAwesomeIcon icon={faCheckCircle} />
              {intl.formatMessage(
                activity.reliability ? messages.verifiedCheckIn : messages.notVerifiedCheckIn
              )}
            </span>
          </Popover>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  checkInRadius: getOrganizationSettingValue(state)(OrganizationSetting.CHECK_IN_RADIUS, 100),
  checkInVisible: areCheckInFieldsVisible(state),
});

export default connect(mapStateToProps)(ActivityDate);

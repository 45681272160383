import { defineMessages } from "react-intl";

const messages = defineMessages({
  linksClickedTooltip: {
    id: "emailMetrics.linksClickedTooltip",
    defaultMessage: "Link(s) have been opened",
    description: "Email metrics links clicked tooltip",
  },
  linksNotClickedTooltip: {
    id: "emailMetrics.linksNotClickedTooltip",
    defaultMessage: "Link(s) haven’t been opened yet",
    description: "Email metrics links not clicked tooltip",
  },
  notOpenedTooltip: {
    id: "emailMetrics.notOpenedTooltip",
    defaultMessage: "Email hasn't been opened yet",
    description: "Email metrics not opened tooltip",
  },
  openedTooltip: {
    id: "emailMetrics.openedTooltip",
    defaultMessage: "Email has been opened",
    description: "Email metrics opened tooltip",
  },
});

export default messages;

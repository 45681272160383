import { notification } from "antd";
import { defineMessages } from "react-intl";
import { put, select, takeEvery } from "redux-saga/effects";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import CustomFieldValuesUpsertPayload from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertPayload";
import CustomFieldValuesUpsertResponse from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import { CustomFieldValueType } from "@mapmycustomers/shared/types/customField/CustomFieldValueType";
import {
  EntitySupportingRecordRestoration,
  EntityType,
  EntityTypesSupportingRestoration,
  EntityTypeSupportingCustomFields,
} from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { showModal } from "@mapmycustomers/ui";

import i18nService from "@app/config/I18nService";
import { ApiMethodName } from "@app/store/api/ApiService";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";
import { DEFAULT_PAGE_SIZE } from "@app/util/consts";
import getLayoutModelByEntityType from "@app/util/layout/impl";

import { ViewState } from ".";
import { fetchDeletedEntities, restoreRecords } from "./actions";
import { getDeletedEntities, getViewState } from "./selectors";

const fetchMethodByEntityType: Record<EntityTypesSupportingRestoration, ApiMethodName> = {
  [EntityType.ACTIVITY]: "fetchActivities",
  [EntityType.COMPANY]: "fetchCompanies",
  [EntityType.DEAL]: "fetchDeals",
  [EntityType.PERSON]: "fetchPeople",
};

const upsertCustomFieldPayloadPart: Record<EntityTypeSupportingCustomFields, string> = {
  [EntityType.ACTIVITY]: "crmActivityId",
  [EntityType.COMPANY]: "accountId",
  [EntityType.DEAL]: "dealId",
  [EntityType.PERSON]: "contactId",
} as const;

const messages = defineMessages({
  errorDescription: {
    id: "settings.restorableRecords.restore.error.description",
    defaultMessage:
      "One or more records have conflicting values in a unique field, preventing restoration. To restore the remaining records, change the values on the existing records.",
    description: "Error description for restore records error",
  },
  errorTitle: {
    id: "settings.restorableRecords.restore.error.title",
    defaultMessage: "Some records could not be restored",
    description: "Error title for restore records error",
  },
  okText: {
    id: "settings.restorableRecords.restore.error.okay",
    defaultMessage: "Okay",
    description: "Error modal okay button text for restore records error",
  },
  restoreSuccessMessage: {
    id: "settings.restorableRecords.restore.success.message",
    defaultMessage: "{count, plural, one {Record} other {Records}} successfully restored",
    description: "Records restore success message",
  },
});

export function* onFetchDeletedEntities({
  payload,
}: ReturnType<typeof fetchDeletedEntities.request>) {
  try {
    const { entityType, page, query, sortFieldName, sortOrder } = payload;
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<EntitySupportingRecordRestoration> = yield callApi(
      fetchMethodByEntityType[entityType],
      organizationId,
      {
        $filters: {
          $and: [{ name: { $in: query } }],
          includeCustomFields: true,
          includeDeletedOnly: true,
        },
        $limit: DEFAULT_PAGE_SIZE,
        $offset: (page - 1) * DEFAULT_PAGE_SIZE,
        $order: `${sortOrder === SortOrder.DESC ? "-" : ""}${sortFieldName}`,
      }
    );
    yield put(fetchDeletedEntities.success({ entities: response.data, total: response.total }));
  } catch (error) {
    yield put(fetchDeletedEntities.failure(error));
    yield put(handleError({ error }));
  }
}

export function* onRestoreRecords({ payload }: ReturnType<typeof restoreRecords.request>) {
  try {
    const { entityType, ids } = payload;
    const entities: EntitySupportingRecordRestoration[] = yield select(getDeletedEntities);
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const layoutModel = getLayoutModelByEntityType(entityType);

    const customFieldsValidatePayload: CustomFieldValuesUpsertPayload[] = entities
      .filter(({ id, customFields }) => ids.includes(id) && customFields && customFields.length)
      .map((entity) => ({
        customFieldData: entity.customFields!.map(({ customField, value }) => ({
          customField,
          value: value ? (value as CustomFieldValueType) : [],
        })),
        layoutId: layoutModel.getLayoutFor(entity).id,
        [upsertCustomFieldPayloadPart[entityType]]: entity.id,
      }));

    let entitiesWithError: CustomFieldValuesUpsertResponse[] | undefined;
    if (customFieldsValidatePayload.length) {
      const customFieldsErrorResponse: CustomFieldValuesUpsertResponse[] = yield callApi(
        "bulkValidateCustomFieldsValues",
        organizationId,
        entityType,
        customFieldsValidatePayload
      );
      entitiesWithError = customFieldsErrorResponse.filter(({ errorCF }) =>
        errorCF.some(({ error }) => error.length)
      );
    }

    const filteredIds = ids.filter(
      (id) =>
        !entitiesWithError?.some(
          (response) =>
            response[
              upsertCustomFieldPayloadPart[entityType] as keyof CustomFieldValuesUpsertResponse
            ] === id
        )
    );

    if (filteredIds.length) {
      yield callApi("restoreRecords", organizationId, entityType, filteredIds);
      const intl = i18nService.getIntl();

      if (intl) {
        notification.success({
          message: intl.formatMessage(messages.restoreSuccessMessage, { count: ids.length }),
        });
      }
    }

    if (entitiesWithError?.length) {
      const intl = i18nService.getIntl();

      showModal({
        cancelButtonProps: { hidden: true },
        centered: true,
        content: intl?.formatMessage(messages.errorDescription),
        okText: intl?.formatMessage(messages.okText),
        title: intl?.formatMessage(messages.errorTitle),
        type: "error",
      });
    }

    yield put(restoreRecords.success());

    const { page, query, sortFieldName, sortOrder }: ViewState = yield select(getViewState);
    yield put(
      fetchDeletedEntities.request({
        entityType,
        page,
        query,
        sortFieldName,
        sortOrder,
      })
    );
  } catch (error) {
    yield put(restoreRecords.failure(error));
    yield put(handleError({ error }));
  }
}

export function* restorableRecordsSaga() {
  yield takeEvery(fetchDeletedEntities.request, onFetchDeletedEntities);
  yield takeEvery(restoreRecords.request, onRestoreRecords);
}

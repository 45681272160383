import { defineMessages, IntlShape } from "react-intl";

import EntityType from "@mapmycustomers/shared/enum/EntityType";

import loggingService from "@app/util/logging";

const lowercaseSingularMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.activity",
    defaultMessage: "activity",
    description: "Name of the Activity entity type, lowercase singular",
  },
  [EntityType.COMPANY]: {
    id: "entityType.company",
    defaultMessage: "company",
    description: "Name of the Company entity type, lowercase singular",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.companyGroup",
    defaultMessage: "company group",
    description: "Name of the Company Group entity type, lowercase singular",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.companyRoute",
    defaultMessage: "company route",
    description: "Name of the Company Route entity type, lowercase singular",
  },
  [EntityType.DEAL]: {
    id: "entityType.deal",
    defaultMessage: "deal",
    description: "Name of the Deal entity type, lowercase singular",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.dealGroup",
    defaultMessage: "deal group",
    description: "Name of the Deal Group entity type, lowercase singular",
  },
  [EntityType.HISTORY]: {
    id: "entityType.history",
    defaultMessage: "history",
    description: "Name of the history entity type, lowercase singular",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.integration",
    defaultMessage: "integration",
    description: "Name of the Integration entity type, lowercase singular",
  },
  [EntityType.LEAD]: {
    id: "entityType.lead",
    defaultMessage: "lead",
    description: "Name of the Lead entity type, lowercase singular",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.leaderboard",
    defaultMessage: "stack rank",
    description: "Name of the Leaderboard entity type, lowercase singular",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.peopleGroup",
    defaultMessage: "people group",
    description: "Name of the People Group entity type, lowercase singular",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.peopleRoute",
    defaultMessage: "people route",
    description: "Name of the People Route entity type, lowercase singular",
  },
  [EntityType.PERSON]: {
    id: "entityType.person",
    defaultMessage: "person",
    description: "Name of the Person entity type, lowercase singular",
  },
  [EntityType.PIN]: {
    id: "entityType.pin",
    defaultMessage: "pin",
    description: "Name of the Pin entity type, lowercase singular",
  },
  [EntityType.ROUTE]: {
    id: "entityType.route",
    defaultMessage: "route",
    description: "Name of the Route entity type, lowercase singular",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.territory",
    defaultMessage: "territory",
    description: "Name of the Territory entity type, lowercase singular",
  },
  [EntityType.TRIP]: {
    id: "entityType.trip",
    defaultMessage: "drive",
    description: "Name of the Trip entity type, lowercase singular",
  },
});

const lowercasePluralMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.activities",
    defaultMessage: "activities",
    description: "Name of the Activity entity type, lowercase plural",
  },
  [EntityType.COMPANY]: {
    id: "entityType.companies",
    defaultMessage: "companies",
    description: "Name of the Company entity type, lowercase plural",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.companyGroups",
    defaultMessage: "company groups",
    description: "Name of the Company Group entity type, lowercase plural",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.companyRoutes",
    defaultMessage: "company routes",
    description: "Name of the Company Route entity type, lowercase plural",
  },
  [EntityType.DEAL]: {
    id: "entityType.deals",
    defaultMessage: "deals",
    description: "Name of the Deal entity type, lowercase plural",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.dealGroups",
    defaultMessage: "deal groups",
    description: "Name of the Deal Group entity type, lowercase plural",
  },
  [EntityType.HISTORY]: {
    id: "entityType.histories",
    defaultMessage: "history rows",
    description: "Name of the history entity type, lowercase plural",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.integrations",
    defaultMessage: "integrations",
    description: "Name of the Integration entity type, lowercase plural",
  },
  [EntityType.LEAD]: {
    id: "entityType.leads",
    defaultMessage: "leads",
    description: "Name of the Lead entity type, lowercase plural",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.leaderboards",
    defaultMessage: "stack ranks",
    description: "Name of the Leaderboard entity type, lowercase plural",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.peopleGroups",
    defaultMessage: "people groups",
    description: "Name of the People Group entity type, lowercase plural",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.peopleRoutes",
    defaultMessage: "people routes",
    description: "Name of the People Route entity type, lowercase plural",
  },
  [EntityType.PERSON]: {
    id: "entityType.people",
    defaultMessage: "people",
    description: "Name of the Person entity type, lowercase plural",
  },
  [EntityType.PIN]: {
    id: "entityType.pins",
    defaultMessage: "pins",
    description: "Name of the Pin entity type, lowercase plural",
  },
  [EntityType.ROUTE]: {
    id: "entityType.routes",
    defaultMessage: "routes",
    description: "Name of the Route entity type, lowercase plural",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.territories",
    defaultMessage: "territories",
    description: "Name of the Territory entity type, lowercase plural",
  },
  [EntityType.TRIP]: {
    id: "entityType.trips",
    defaultMessage: "drives",
    description: "Name of the Trip entity type, lowercase plural",
  },
});

const capitalizedSingularMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.Activity",
    defaultMessage: "Activity",
    description: "Name of the Activity entity type, capitalized singular",
  },
  [EntityType.COMPANY]: {
    id: "entityType.Company",
    defaultMessage: "Company",
    description: "Name of the Company entity type, capitalized singular",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.CompanyGroup",
    defaultMessage: "Company group",
    description: "Name of the Company Group entity type, capitalized singular",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.CompanyRoute",
    defaultMessage: "Company route",
    description: "Name of the Company Route entity type, capitalized singular",
  },
  [EntityType.DEAL]: {
    id: "entityType.Deal",
    defaultMessage: "Deal",
    description: "Name of the Deal entity type, capitalized singular",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.DealGroup",
    defaultMessage: "Deal group",
    description: "Name of the Deal Group entity type, capitalized singular",
  },
  [EntityType.HISTORY]: {
    id: "entityType.History",
    defaultMessage: "History",
    description: "Name of the history entity type, capitalized singular",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.Integration",
    defaultMessage: "Integration",
    description: "Name of the Integration entity type, capitalized singular",
  },
  [EntityType.LEAD]: {
    id: "entityType.Lead",
    defaultMessage: "Lead",
    description: "Name of the Lead entity type, capitalized singular",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.Leaderboard",
    defaultMessage: "Stack Rank",
    description: "Name of the Leaderboard entity type, capitalized singular",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.PeopleGroup",
    defaultMessage: "People group",
    description: "Name of the People Group entity type, capitalized singular",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.PeopleRoute",
    defaultMessage: "People route",
    description: "Name of the People Route entity type, capitalized singular",
  },
  [EntityType.PERSON]: {
    id: "entityType.Person",
    defaultMessage: "Person",
    description: "Name of the Person entity type, capitalized singular",
  },
  [EntityType.PIN]: {
    id: "entityType.Pin",
    defaultMessage: "Pin",
    description: "Name of the Pin entity type, capitalized singular",
  },
  [EntityType.ROUTE]: {
    id: "entityType.Route",
    defaultMessage: "Route",
    description: "Name of the Route entity type, capitalized singular",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.Territory",
    defaultMessage: "Territory",
    description: "Name of the Territory entity type, capitalized singular",
  },
  [EntityType.TRIP]: {
    id: "entityType.Trip",
    defaultMessage: "Drive",
    description: "Name of the Trip entity type, capitalized singular",
  },
});

const capitalizedPluralMessages = defineMessages({
  [EntityType.ACTIVITY]: {
    id: "entityType.Activities",
    defaultMessage: "Activities",
    description: "Name of the Activity entity type, capitalized plural",
  },
  [EntityType.COMPANY]: {
    id: "entityType.Companies",
    defaultMessage: "Companies",
    description: "Name of the Company entity type, capitalized plural",
  },
  [EntityType.COMPANY_GROUP]: {
    id: "entityType.CompanyGroups",
    defaultMessage: "Company groups",
    description: "Name of the Company Group entity type, capitalized plural",
  },
  [EntityType.COMPANY_ROUTE]: {
    id: "entityType.CompanyRoutes",
    defaultMessage: "Company routes",
    description: "Name of the Company Route entity type, capitalized plural",
  },
  [EntityType.DEAL]: {
    id: "entityType.Deals",
    defaultMessage: "Deals",
    description: "Name of the Deal entity type, capitalized plural",
  },
  [EntityType.DEAL_GROUP]: {
    id: "entityType.DealGroups",
    defaultMessage: "Deal groups",
    description: "Name of the Deal Group entity type, capitalized plural",
  },
  [EntityType.HISTORY]: {
    id: "entityType.HistoryRows",
    defaultMessage: "History Rows",
    description: "Name of the history entity type, capitalized singular",
  },
  [EntityType.INTEGRATION]: {
    id: "entityType.Integrations",
    defaultMessage: "Integrations",
    description: "Name of the Integration entity type, capitalized plural",
  },
  [EntityType.LEAD]: {
    id: "entityType.Leads",
    defaultMessage: "Leads",
    description: "Name of the Lead entity type, capitalized plural",
  },
  [EntityType.LEADERBOARD]: {
    id: "entityType.Leaderboards",
    defaultMessage: "Stack Ranks",
    description: "Name of the Leaderboard entity type, capitalized plural",
  },
  [EntityType.PEOPLE_GROUP]: {
    id: "entityType.PeopleGroups",
    defaultMessage: "People groups",
    description: "Name of the People Group entity type, capitalized plural",
  },
  [EntityType.PEOPLE_ROUTE]: {
    id: "entityType.PeopleRoutes",
    defaultMessage: "People routes",
    description: "Name of the People Route entity type, capitalized plural",
  },
  [EntityType.PERSON]: {
    id: "entityType.People",
    defaultMessage: "People",
    description: "Name of the Person entity type, capitalized plural",
  },
  [EntityType.PIN]: {
    id: "entityType.Pins",
    defaultMessage: "Pins",
    description: "Name of the Pin entity type, capitalized plural",
  },
  [EntityType.ROUTE]: {
    id: "entityType.Routes",
    defaultMessage: "Routes",
    description: "Name of the Route entity type, capitalized plural",
  },
  [EntityType.TERRITORY]: {
    id: "entityType.Territories",
    defaultMessage: "Territories",
    description: "Name of the Territory entity type, capitalized plural",
  },
  [EntityType.TRIP]: {
    id: "entityType.Trips",
    defaultMessage: "Drives",
    description: "Name of the Trip entity type, capitalized plural",
  },
});

/**
 * Only use this function to format standalone entity type. If entity type is
 * used in a sentence, use entity type translation right in that message, do not
 * use getEntityTypeDisplayName in that case.
 */
export const getEntityTypeDisplayName = (
  intl: IntlShape,
  entityType: EntityType,
  options: { lowercase: boolean; plural?: boolean }
): string => {
  const { lowercase, plural } = options;

  let messages;
  if (plural) {
    messages = lowercase ? lowercasePluralMessages : capitalizedPluralMessages;
  } else {
    messages = lowercase ? lowercaseSingularMessages : capitalizedSingularMessages;
  }

  try {
    return intl.formatMessage(messages[entityType]);
  } catch (e) {
    loggingService.error("failed entity type format", { e, entityType, messages });
    return "";
  }
};

const analyticsNamesSingular: Record<EntityType, string> = {
  [EntityType.ACTIVITY]: "Activity",
  [EntityType.COMPANY]: "Company",
  [EntityType.COMPANY_GROUP]: "Company Group",
  [EntityType.COMPANY_ROUTE]: "Company Route",
  [EntityType.DEAL]: "Deal",
  [EntityType.DEAL_GROUP]: "Deal Group",
  [EntityType.HISTORY]: "History",
  [EntityType.INTEGRATION]: "Integration",
  [EntityType.LEAD]: "Lead",
  [EntityType.LEADERBOARD]: "Leaderboard Item",
  [EntityType.PEOPLE_GROUP]: "People Group",
  [EntityType.PEOPLE_ROUTE]: "People Route",
  [EntityType.PERSON]: "Person",
  [EntityType.PIN]: "Pin",
  [EntityType.ROUTE]: "Route",
  [EntityType.TERRITORY]: "Territory",
  [EntityType.TRIP]: "Trip",
};

const analyticsNamesPlural: Record<EntityType, string> = {
  [EntityType.ACTIVITY]: "Activities",
  [EntityType.COMPANY]: "Companies",
  [EntityType.COMPANY_GROUP]: "Company Groups",
  [EntityType.COMPANY_ROUTE]: "Company Routes",
  [EntityType.DEAL]: "Deals",
  [EntityType.DEAL_GROUP]: "Deal Groups",
  [EntityType.HISTORY]: "History",
  [EntityType.INTEGRATION]: "Integrations",
  [EntityType.LEAD]: "Leads",
  [EntityType.LEADERBOARD]: "Leaderboard Items",
  [EntityType.PEOPLE_GROUP]: "People Groups",
  [EntityType.PEOPLE_ROUTE]: "People Routes",
  [EntityType.PERSON]: "People",
  [EntityType.PIN]: "Pins",
  [EntityType.ROUTE]: "Routes",
  [EntityType.TERRITORY]: "Territories",
  [EntityType.TRIP]: "Trips",
};

export const getEntityTypeAnalyticsName = (
  entityType: EntityType,
  lowercase: boolean,
  plural: boolean
): string => {
  const name = (plural ? analyticsNamesPlural : analyticsNamesSingular)[entityType];
  return lowercase ? name.toLowerCase() : name;
};

export default getEntityTypeDisplayName;

import { createReducer } from "typesafe-actions";

import SortOrder from "@mapmycustomers/shared/enum/SortOrder";
import { EntitySupportingRecordRestoration } from "@mapmycustomers/shared/types/entity";

import { Actions, fetchDeletedEntities, restoreRecords } from "./actions";

export interface ViewState {
  page: number;
  query?: string;
  sortFieldName: string;
  sortOrder: SortOrder;
}

export interface RestorableRecordsState {
  entities?: EntitySupportingRecordRestoration[];
  loading: boolean;
  total: number;
  viewState: ViewState;
}

const initialState: RestorableRecordsState = {
  loading: false,
  total: 0,
  viewState: {
    page: 0,
    sortFieldName: "name",
    sortOrder: SortOrder.ASC,
  },
};

const restorableRecords = createReducer<RestorableRecordsState, Actions>(initialState)
  .handleAction(fetchDeletedEntities.request, (state, { payload }) => ({
    ...state,
    loading: true,
    viewState: {
      page: payload.page,
      query: payload.query,
      sortFieldName: payload.sortFieldName,
      sortOrder: payload.sortOrder,
    },
  }))
  .handleAction(fetchDeletedEntities.success, (state, { payload }) => ({
    ...state,
    entities: payload.entities,
    loading: false,
    total: payload.total,
  }))
  .handleAction(fetchDeletedEntities.failure, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(restoreRecords.request, (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction(restoreRecords.success, (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(restoreRecords.failure, (state) => ({
    ...state,
    loading: false,
  }));

export * from "./selectors";
export type RestorableRecordsActions = Actions;
export default restorableRecords;

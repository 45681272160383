import React, { useCallback } from "react";
import { connect } from "react-redux";

import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons/faEllipsisH";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "antd/es/button";
import Dropdown from "antd/es/dropdown";
import { useIntl } from "react-intl";

import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";
import { showModal } from "@mapmycustomers/ui";

import styles from "@app/component/EmailQueueModal/EmailQueueActions.module.scss";
import { getEmailQuota } from "@app/store/email";
import { clearEmailQueue } from "@app/store/email/actions";
import { RootState } from "@app/store/rootReducer";

import messages from "./messages";

interface EmailQueueActions {
  emailQuota?: EmailQuota;
  onClearQueue: typeof clearEmailQueue;
}

const EmailQueueActions: React.FC<EmailQueueActions> = ({ emailQuota, onClearQueue }) => {
  const intl = useIntl();

  const handleClearQueue = useCallback(() => {
    return new Promise<void>((resolve) => {
      onClearQueue({
        onFinish: resolve,
      });
    });
  }, [onClearQueue]);

  const handleClearQueueClick = useCallback(() => {
    showModal({
      autoFocusButton: null,
      cancelButtonProps: {
        type: "default",
      },
      cancelText: intl.formatMessage(messages.goBackLabel),
      centered: true,
      closable: false,
      content: (
        <div className={styles.confirmDescription}>
          {intl.formatMessage(messages.clearQueueModalDescription)}
        </div>
      ),
      icon: <FontAwesomeIcon icon={faExclamationCircle} />,
      okButtonProps: {
        danger: true,
        type: "text",
      },
      okText: intl.formatMessage(messages.clearQueueLabel),
      onOk: handleClearQueue,
      title: intl.formatMessage(messages.clearQueueModalTitle),
      type: "confirm",
    });
  }, [intl, handleClearQueue]);

  if (emailQuota && !emailQuota.submitted) {
    return null;
  }

  return (
    <Dropdown
      menu={{
        items: [
          {
            className: styles.clearQueue,
            key: "clearQueue",
            label: intl.formatMessage(messages.clearQueueLabel),
            onClick: handleClearQueueClick,
          },
        ],
      }}
      trigger={["click"]}
    >
      <Button
        className={styles.menuButton}
        icon={<FontAwesomeIcon className={styles.menuIcon} icon={faEllipsisH} />}
        loading={!emailQuota}
        type="link"
      />
    </Dropdown>
  );
};

const mapStateToProps = (state: RootState) => ({
  emailQuota: getEmailQuota(state),
});

const mapDispatchToProps = {
  onClearQueue: clearEmailQueue,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailQueueActions);

import React, { useCallback } from "react";
import { connect } from "react-redux";

import Button from "antd/es/button";
import Col from "antd/es/col";
import Form from "antd/es/form";
import { useForm } from "antd/es/form/Form";
import Row from "antd/es/row";
import { FormattedMessage, useIntl } from "react-intl";

import DealLossReason from "@mapmycustomers/shared/types/entity/deals/DealLossReason";
import { Modal, ModalProps, TextField } from "@mapmycustomers/ui";

import { createDealLossReason } from "@app/store/dealLossReasons/actions";
import { isLostReasonCreating } from "@app/store/dealLossReasons/selectors";
import { RootState } from "@app/store/rootReducer";

interface Props extends ModalProps {
  createDealLossReason: typeof createDealLossReason.request;
  loading: boolean;
  onHide: () => void;
}

const CreateReasonModal: React.FC<Props> = ({ createDealLossReason, loading, onHide }) => {
  const intl = useIntl();
  const [form] = useForm<Pick<DealLossReason, "name">>();
  const handleFinish = useCallback(() => {
    createDealLossReason({
      callback: onHide,
      reason: { name: form.getFieldsValue().name },
    });
  }, [createDealLossReason, form, onHide]);

  return (
    <Modal
      footer={null}
      onCancel={onHide}
      title={intl.formatMessage({
        id: "createReasonModal.title",
        defaultMessage: "Create new reason",
        description: "Add new reason modal title",
      })}
      visible
    >
      <Form form={form} onFinish={handleFinish}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="name"
              required
              rules={[
                {
                  message: intl.formatMessage({
                    id: "createReasonModal.field.name.validation.required",
                    defaultMessage: "Please, Enter reason name",
                    description: "Add new reason validation error",
                  }),
                  required: true,
                },
              ]}
            >
              <TextField
                label={intl.formatMessage({
                  id: "createReasonModal.field.name",
                  defaultMessage: "Name your reason for deal loss",
                  description: "Add new reason label title",
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <Button disabled={loading} htmlType="submit" loading={loading} type="primary">
            <FormattedMessage
              id="createReasonModal.createButton"
              defaultMessage="Create Reason"
              description="Close button title in the edit profile photo modal."
            />
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  loading: isLostReasonCreating(state),
});

const mapDispatchToProps = {
  createDealLossReason: createDealLossReason.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateReasonModal);

import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Divider from "antd/es/divider";
import { Col } from "antd/es/grid";
import Popconfirm from "antd/es/popconfirm";
import Row from "antd/es/row";
import { useIntl } from "react-intl";
import { v4 as uuidv4 } from "uuid";

import { CustomFieldError } from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import { ActivityCreationPayloadWithCustomFields } from "@mapmycustomers/shared/types/email/Email";
import EmailAssociatedEntity from "@mapmycustomers/shared/types/email/EmailAssociatedEntity";
import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";
import EmailTemplate from "@mapmycustomers/shared/types/email/EmailTemplate";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import {
  EntitiesSupportedByEmailFeature,
  EntityTypesSupportedByEmailFeature,
} from "@mapmycustomers/shared/types/map/types";
import User from "@mapmycustomers/shared/types/User";
import { isNotEmpty } from "@mapmycustomers/shared/util/assert";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import {
  AttachedFile,
  DYNAMIC_VAR_REGEX,
  EmailBody,
  Modal,
  RecipientList,
  SubjectInput,
} from "@mapmycustomers/ui";

import {
  doesNeedToOpenActivityFormAfterSendingEmail,
  getEmailActivityType,
  getEmailAssociatedEntities,
  getEmailQuota,
  getEmailRecipients,
  getEmailRecipientsEntityType,
  getNewEmailAttachments,
  isEmailSending,
  isNewEmailUnsubscribeLinkIncluded,
} from "@app/store/email";
import {
  deleteUploadedFile,
  downloadAttachedFile,
  hideEmailCreationModal,
  loadImageToEmailBody,
  sendEmail,
  setNewEmailUnsubscribeLinkIncluded,
  uploadFileForNewEmail,
} from "@app/store/email/actions";
import { getCurrentUser, getUserSettingValue } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import layout from "@app/styles/layout";
import useAnalytics from "@app/util/contexts/useAnalytics";
import { EMAIL_ACTIVITY_TYPE_NAME } from "@app/util/email/const";
import useEmailBodyWithAddons from "@app/util/email/useEmailBodyWithAddons";
import getDynamicVarVariants from "@app/util/fieldModel/util/getDynamicVarVariants";
import { activityLayoutModel } from "@app/util/layout/impl";

import ActionButton from "./ActionButton";
import ActivityCreationModal from "./ActivityCreationModal";
import AddRecipientsButton from "./AddRecipients/AddRecipientsButton";
import AddRecipientsModal from "./AddRecipients/AddRecipientsModal";
import ConfirmationMessageModal from "./ConfirmationMessageModal";
import messages from "./messages";
import styles from "./Modal.module.scss";
import PreviewModal from "./PreviewModal";
import EmailTemplateButton from "./Template/EmailTemplateButton";

const ATTACHMENTS_SIZE_LIMIT = 25165824;

interface Props {
  attachments: AttachedFile[];
  currentUserId: User["id"];
  emailActivityType?: ActivityType;
  emailAssociatedEntities: EmailAssociatedEntity[];
  emailQuota?: EmailQuota;
  emailSending: boolean;
  entityType?: EntityTypesSupportedByEmailFeature;
  initialRecipients: EntitiesSupportedByEmailFeature[];
  onDeleteUploadedFile: typeof deleteUploadedFile;
  onDownloadAttachedFile: typeof downloadAttachedFile;
  onHide: () => void;
  onLoadImageToEmailBody: typeof loadImageToEmailBody;
  onSendEmail: typeof sendEmail.request;
  onUnsubscribeLinkIncludedChange: typeof setNewEmailUnsubscribeLinkIncluded;
  onUploadFilesForNewEmail: typeof uploadFileForNewEmail.request;
  openActivityModal: boolean;
  signatureText: string;
  unsubscribeLinkIncluded: boolean;
}

const EmailCreationModal: React.FC<Props> = ({
  attachments,
  currentUserId,
  emailActivityType,
  emailAssociatedEntities,
  emailQuota,
  emailSending,
  entityType,
  initialRecipients,
  onDeleteUploadedFile,
  onDownloadAttachedFile,
  onHide,
  onLoadImageToEmailBody,
  onSendEmail,
  onUnsubscribeLinkIncludedChange,
  onUploadFilesForNewEmail,
  openActivityModal,
  signatureText,
  unsubscribeLinkIncluded,
}) => {
  const intl = useIntl();
  const [recipients, setRecipients] =
    useState<EntitiesSupportedByEmailFeature[]>(initialRecipients);
  const [activityCreationModalVisible, showActivityCreationModal, hideActivityCreationModal] =
    useBoolean();
  const [addRecipientVisible, showAddRecipient, hideAddRecipient] = useBoolean();
  const analytics = useAnalytics();

  const defaultBodyText = useMemo(
    // We add 2 empty lines before a signature text
    () => (signatureText.length ? `<div><div></div><div></div>${signatureText}</div>` : ""),
    [signatureText]
  );

  const [emailBody, setEmailBody] = useState<string>(defaultBodyText);
  const [emailSubject, setEmailSubject] = useState<string>("");
  const [selectedTemplate, setSelectedTemplate] = useState<EmailTemplate | undefined>();
  const [processedTemplateId, setProcessedTemplateId] = useState<EmailTemplate["id"] | undefined>();
  const [bodyLimitReached, setBodyLimitReached] = useState<boolean>(false);
  const [templateChanged, setTemplateChanged] = useState<boolean>(false);
  const [confirmationModalVisible, showConfirmationModal] = useBoolean();
  const [previewVisible, showPreview, hidePreview] = useBoolean();
  const [bodyImageLoading, setBodyImageLoading] = useState<boolean>(false);

  const emailBodyWithAddons = useEmailBodyWithAddons({ emailBody, unsubscribeLinkIncluded });

  const [selectedRecipientIdForPreview, setSelectedRecipientIdForPreviewForPreview] = useState<
    EntitiesSupportedByEmailFeature["id"] | undefined
  >();

  const handleDeleteRecipient = useCallback(
    ({ id }: EntitiesSupportedByEmailFeature) => {
      setRecipients((recipients) => recipients.filter((recipient) => recipient.id !== id));
    },
    [setRecipients]
  );

  const handleLoadImage = useCallback(
    (file: Blob, callback: (url: string) => void) => {
      setBodyImageLoading(true);
      onLoadImageToEmailBody({
        callback: (url) => {
          callback(url);
          setBodyImageLoading(false);
        },
        file,
      });
    },
    [onLoadImageToEmailBody, setBodyImageLoading]
  );

  const handleFilesSelect = useCallback(
    (files: File) => {
      onUploadFilesForNewEmail({
        id: uuidv4(),
        preparedFile: files,
      });
    },
    [onUploadFilesForNewEmail]
  );

  const handleChangeSubject = useCallback(
    (value: string) => {
      setEmailSubject(value);
      if (selectedTemplate) {
        setTemplateChanged(true);
      }
    },
    [selectedTemplate, setEmailSubject]
  );

  const handleChangeBody = useCallback(
    (text: string, limitReached: boolean, appliedTemplate?: EmailTemplate) => {
      setEmailBody(text);
      setBodyLimitReached(limitReached);
      if (appliedTemplate && appliedTemplate.id === processedTemplateId) {
        setTemplateChanged(true);
      }
      setProcessedTemplateId(appliedTemplate?.id);
    },
    [
      setTemplateChanged,
      setEmailBody,
      setBodyLimitReached,
      processedTemplateId,
      setProcessedTemplateId,
    ]
  );

  const handleAction = useCallback(
    (analyticKey: string) => {
      analytics.clicked([analyticKey]);
    },
    [analytics]
  );

  const handleSelectTemplate = useCallback(
    (template: EmailTemplate) => {
      setEmailBody(template.body);
      setEmailSubject(template.subject);
      setSelectedTemplate(template);
      setTemplateChanged(false);
      analytics.clicked(["Selected Template"], { template: template.name });
    },
    [analytics, setEmailBody, setEmailSubject, setTemplateChanged, setSelectedTemplate]
  );

  const handleClearTemplate = useCallback(() => {
    setEmailBody(defaultBodyText);
    setEmailSubject("");
    setSelectedTemplate(undefined);
    setTemplateChanged(false);
  }, [defaultBodyText]);

  const [dynamicVarVariants, missingFields] = useMemo(() => {
    if (entityType) {
      return getDynamicVarVariants(
        intl,
        entityType,
        recipients,
        (Array.from(emailBody.matchAll(DYNAMIC_VAR_REGEX)) as RegExpMatchArray[])
          .map(({ groups }) => groups?.fieldName)
          .filter(isNotEmpty)
      );
    }
    return [[], {}];
  }, [entityType, emailBody, intl, recipients]);

  const [formLayout, setFormLayout] = useState(() => activityLayoutModel.defaultFormLayout);

  const bulkEmail = recipients.length > 1;

  const handleCreate = useCallback(
    (
      crmActivity?: ActivityCreationPayloadWithCustomFields,
      customFieldsValidationCallback?: (customFieldErrors: CustomFieldError[]) => void
    ) => {
      if (entityType && formLayout) {
        const childLayout = formLayout.childLayouts?.find(
          ({ crmActivityType }) => crmActivityType?.name === EMAIL_ACTIVITY_TYPE_NAME
        );
        onSendEmail({
          callback: () => {
            hideActivityCreationModal();
            showConfirmationModal();
            analytics.clicked(["Successfully Scheduled Emails"]);
          },
          email: {
            ...(attachments.length
              ? {
                  attachments: attachments
                    .filter((attachment) => attachment.file?.id)
                    .map((attachment) => attachment.file!.id),
                }
              : {}),
            associatedEntities: bulkEmail ? emailAssociatedEntities : [],
            body: emailBodyWithAddons,
            crmActivity: crmActivity ?? {
              name: emailSubject,
              ...(emailActivityType
                ? { assigneeId: currentUserId, crmActivityType: { id: emailActivityType.id } }
                : {}),
            },
            entityIds: !bulkEmail ? [recipients[0].id] : undefined,
            entityType,
            isHtml: true,
            layoutId: formLayout.id,
            ...(childLayout ? { childLayoutId: childLayout.id } : {}),
            logActivity: true,
            subject: emailSubject,
            tracking: true,
          },
          failureCallback: customFieldsValidationCallback,
        });
      }
    },
    [
      analytics,
      attachments,
      bulkEmail,
      currentUserId,
      emailActivityType,
      emailAssociatedEntities,
      emailBodyWithAddons,
      emailSubject,
      entityType,
      formLayout,
      hideActivityCreationModal,
      onSendEmail,
      recipients,
      showConfirmationModal,
    ]
  );

  const emailFilled = emailBody.trim() !== "" && emailSubject.trim() !== "";

  const filesSizeLimitReached = useMemo(
    () =>
      ATTACHMENTS_SIZE_LIMIT <
      attachments.reduce<number>((result, attachment) => result + attachment.preparedFile.size, 0),
    [attachments]
  );

  const handleHide = useCallback(() => {
    analytics.clicked(["Closed"]);
    onHide();
  }, [analytics, onHide]);

  if (!entityType || !formLayout) {
    return null;
  }

  return (
    <>
      <Modal
        className={styles.modal}
        closeIcon={
          <Popconfirm
            cancelText={intl.formatMessage(messages.closeConfirmationNo)}
            okText={intl.formatMessage(messages.closeConfirmationYes)}
            onConfirm={handleHide}
            overlayClassName={styles.confirmation}
            title={intl.formatMessage(messages.closeConfirmationTitle, {
              b: (text) => <b>{text}</b>,
            })}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Popconfirm>
        }
        footer={null}
        maskClosable={false}
        open={!confirmationModalVisible}
        scrollable
        title={
          <Row align="middle" justify="space-between">
            <Col>
              <Row align="middle" gutter={layout.spacerL}>
                <Col>{intl.formatMessage(bulkEmail ? messages.bulkTitle : messages.title)}</Col>
                <Col className={styles.subTitle}>
                  {intl.formatMessage(messages.subTitle, {
                    sent: emailQuota?.remaining ?? 0,
                    total: emailQuota?.total ?? 0,
                  })}
                </Col>
              </Row>
            </Col>
          </Row>
        }
        width="40rem"
      >
        <div className={styles.container}>
          <EmailTemplateButton
            emailBody={emailBody}
            emailFilled={emailFilled}
            emailSubject={emailSubject}
            onClear={handleClearTemplate}
            onSelect={handleSelectTemplate}
            onSelectRecipientId={setSelectedRecipientIdForPreviewForPreview}
            recipients={recipients}
            selectedRecipientId={selectedRecipientIdForPreview}
            templateChanged={templateChanged}
          />
          <RecipientList
            action={<AddRecipientsButton onClick={showAddRecipient} />}
            entities={recipients}
            label={intl.formatMessage(messages.to)}
            missingFields={missingFields}
            moreText={intl.formatMessage(messages.more)}
            onDeleteEntity={handleDeleteRecipient}
          />
          <Divider className={styles.divider} />
          <SubjectInput onChange={handleChangeSubject} value={emailSubject} />
          <Divider className={styles.divider} />
          <EmailBody
            action={
              <ActionButton
                analytics={analytics}
                bodyLimitReached={bodyLimitReached}
                emailFilled={emailFilled}
                emailSending={emailSending}
                filesSizeLimitReached={filesSizeLimitReached}
                onClick={openActivityModal ? showActivityCreationModal : handleCreate}
                openActivityModal={openActivityModal}
                recipientCount={recipients.length}
                templateChanged={templateChanged}
              />
            }
            appliedTemplate={selectedTemplate}
            attachedFiles={attachments}
            dynamicVarVariants={dynamicVarVariants}
            editorId="bulk-email-editor"
            entityType={entityType}
            loading={bodyImageLoading}
            onAction={handleAction}
            onChange={handleChangeBody}
            onDeleteFile={onDeleteUploadedFile}
            onDownloadFile={(attachedFile) => onDownloadAttachedFile({ attachedFile })}
            onFilesSelect={handleFilesSelect}
            onLoadFile={handleLoadImage}
            onPreview={showPreview}
            onPreviewFile={(attachedFile) =>
              onDownloadAttachedFile({ attachedFile, preview: true })
            }
            onUnsubscribeLinkIncludedChange={onUnsubscribeLinkIncludedChange}
            previewDisableText={
              emailFilled ? undefined : intl.formatMessage(messages.previewDisabled)
            }
            recipientCount={recipients.length}
            showActions
            unsubscribeLinkIncluded={unsubscribeLinkIncluded}
            value={emailBody}
          />
        </div>
      </Modal>
      <ActivityCreationModal
        entityType={entityType}
        formLayout={formLayout}
        onBack={hideActivityCreationModal}
        onChangeFormLayout={setFormLayout}
        onCreate={handleCreate}
        onHide={onHide}
        recipients={recipients}
        subject={emailSubject}
        visible={activityCreationModalVisible}
      />
      <ConfirmationMessageModal onHide={onHide} open={confirmationModalVisible} />
      {previewVisible && (
        <PreviewModal
          body={emailBody}
          onHide={hidePreview}
          onSelectRecipientId={setSelectedRecipientIdForPreviewForPreview}
          recipients={recipients}
          selectedRecipientId={selectedRecipientIdForPreview}
          subject={emailSubject}
        />
      )}
      <AddRecipientsModal
        entityType={entityType}
        onHide={hideAddRecipient}
        onSelect={setRecipients}
        recipients={recipients}
        visible={addRecipientVisible}
      />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  attachments: getNewEmailAttachments(state),
  currentUserId: getCurrentUser(state)!.id,
  emailActivityType: getEmailActivityType(state),
  emailAssociatedEntities: getEmailAssociatedEntities(state),
  emailQuota: getEmailQuota(state),
  emailSending: isEmailSending(state),
  entityType: getEmailRecipientsEntityType(state),
  initialRecipients: getEmailRecipients(state),
  openActivityModal: doesNeedToOpenActivityFormAfterSendingEmail(state),
  signatureText: getUserSettingValue(state)("bulkEmail").signature
    ? getUserSettingValue(state)("bulkEmail")?.signatureText ?? ""
    : "",
  unsubscribeLinkIncluded: isNewEmailUnsubscribeLinkIncluded(state),
});

const mapDispatchToProps = {
  hideEmailCreationModal,
  onDeleteUploadedFile: deleteUploadedFile,
  onDownloadAttachedFile: downloadAttachedFile,
  onLoadImageToEmailBody: loadImageToEmailBody,
  onSendEmail: sendEmail.request,
  onUnsubscribeLinkIncludedChange: setNewEmailUnsubscribeLinkIncluded,
  onUploadFilesForNewEmail: uploadFileForNewEmail.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCreationModal);

import React from "react";

import { bem } from "@react-md/utils";
import cn from "classnames";

const block = bem("mmc-inline-label");

export interface InlineLabeledFieldProps {
  children: React.ReactNode;
  className?: string;
  extra?: React.ReactNode;
  label?: React.ReactNode;
  labelClassName?: string;
  labelPosition?: "side" | "top";
  required?: boolean;
  sideLabelSpan?: number;
}

const InlineLabeled: React.FC<InlineLabeledFieldProps> = ({
  children,
  className,
  extra,
  label,
  labelClassName,
  labelPosition = "top",
  required,
}) => {
  return (
    <div className={cn(block({ [labelPosition]: true }), className)}>
      <div className={cn(block("label", { required }), labelClassName)}>
        <span>{label}</span>
        {extra && <span>{extra}</span>}
      </div>
      <div className={cn(block("content"))}>{children}</div>
    </div>
  );
};

export default InlineLabeled;

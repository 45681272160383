import React, { useCallback, useEffect, useMemo } from "react";
import { connect } from "react-redux";

import Select, { RefSelectProps } from "antd/es/select";
import cn from "classnames";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";
import Color from "@mapmycustomers/shared/enum/Color";
import FilterOption from "@mapmycustomers/shared/enum/fieldModel/FilterOption";
import { Route } from "@mapmycustomers/shared/types/entity";
import { SimpleCondition } from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";
import defaultFilterOption from "@mapmycustomers/shared/util/input/defaultFilterOption";

import EntityBadge from "@app/component/EntityBadge";
import { fetchCompanyRoutes, fetchPeopleRoutes } from "@app/store/routes/actions";

import styles from "./RoutesFilter.module.scss";

interface Props {
  className?: string;
  focus?: boolean;
  onChange?: (value: SimpleCondition) => void;
  onFetchCompanyRoutes?: (payload: { query?: string; selectedRouteIds?: Route["id"][] }) => void;
  onFetchPeopleRoutes?: (payload: { query?: string; selectedRouteIds?: Route["id"][] }) => void;
  options: Partial<Record<FilterOption, unknown>> | undefined;
  value: SimpleCondition;
}

const RoutesFilter: React.FC<Props> = ({
  className,
  focus,
  onChange,
  onFetchCompanyRoutes,
  onFetchPeopleRoutes,
  options,
  value,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (!options?.entityType || options?.entityType === EntityType.PERSON) {
      onFetchPeopleRoutes?.({
        selectedRouteIds: Array.isArray(value.value) ? value.value : undefined,
      });
    }
    if (!options?.entityType || options?.entityType === EntityType.COMPANY) {
      onFetchCompanyRoutes?.({
        selectedRouteIds: Array.isArray(value.value) ? value.value : undefined,
      });
    }
  }, [onFetchPeopleRoutes, onFetchCompanyRoutes, options?.entityType, value.value]);

  const setRef = useCallback(
    (ref: null | RefSelectProps) => {
      if (focus && ref) {
        ref.focus();
      }
    },
    [focus]
  );

  const handleSearch = useDebouncedCallback(
    [
      (query: string) => {
        (options?.entityType === EntityType.COMPANY ? onFetchCompanyRoutes : onFetchPeopleRoutes)?.(
          { query, selectedRouteIds: Array.isArray(value.value) ? value.value : undefined }
        );
      },
      100,
    ],
    [onFetchCompanyRoutes, onFetchPeopleRoutes, options?.entityType, value.value]
  );

  const handleBlur = useCallback(() => {
    (options?.entityType === EntityType.COMPANY ? onFetchCompanyRoutes : onFetchPeopleRoutes)?.({
      selectedRouteIds: Array.isArray(value.value) ? value.value : undefined,
    });
  }, [onFetchCompanyRoutes, onFetchPeopleRoutes, options?.entityType, value.value]);

  const selectOptions = useMemo(
    () =>
      ((options?.availableRoutes as Route[]) ?? []).map((route) => ({
        label: <EntityBadge entity={{ color: Color.GREY, name: route.name }} />,
        text: route.name,
        value: route.id,
      })),
    [options?.availableRoutes]
  );

  return (
    <Select<Array<Route["id"]>>
      className={cn(styles.container, className)}
      filterOption={defaultFilterOption}
      mode="multiple"
      onBlur={handleBlur}
      onChange={(routeIds: Route["id"][]) => onChange?.({ ...value, value: routeIds })}
      onSearch={handleSearch}
      options={selectOptions}
      placeholder={intl.formatMessage({
        id: "filters.route.select.placeholder",
        defaultMessage: "Click or type to select routes",
        description: "Placeholder displayed in a RouteFilter's select field",
      })}
      ref={setRef}
      value={Array.isArray(value.value) ? value.value : []}
    />
  );
};

const mapDispatchToProps = {
  onFetchCompanyRoutes: fetchCompanyRoutes.request,
  onFetchPeopleRoutes: fetchPeopleRoutes.request,
};

export default connect(null, mapDispatchToProps)(RoutesFilter);

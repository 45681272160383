import { ActionType, createAsyncAction } from "typesafe-actions";

import { Territory } from "@mapmycustomers/shared/types/entity";

import ToolbarState from "./ToolbarState";

export const fetchTerritories = createAsyncAction(
  "map/toolbar/fetchTerritories/request",
  "map/toolbar/fetchTerritories/success",
  "map/toolbar/fetchTerritories/failure"
)<
  Partial<ToolbarState["filters"]>,
  {
    territories: Territory[];
    territoriesTotal: number;
  },
  unknown
>();

export type ToolbarActions = ActionType<typeof fetchTerritories>;

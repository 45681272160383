import { createReducer } from "typesafe-actions";

import { Guide, GuideUserStatus } from "@mapmycustomers/shared/types/entity";

import {
  Actions,
  bulkAddGuideAssignees,
  initialize,
  initializeMemberView,
  refreshGuidesStatus,
  updateGuideAssignees,
  updateUserGuides,
} from "./actions";

export interface OnboardingState {
  guides: Guide[];
  guidesStatus: GuideUserStatus[];
  loading?: boolean;
}

const initialState: OnboardingState = {
  guides: [],
  guidesStatus: [],
  // loading is true by default to avoid flashing a "no rows" overlay when the page
  // is just opened but loading is not yet started
  loading: true,
};

const onboarding = createReducer<OnboardingState, Actions>(initialState)
  .handleAction([initialize.request, initializeMemberView.request], (state) => ({
    ...state,
    loading: true,
  }))
  .handleAction([initialize.success, initializeMemberView.success], (state, { payload }) => ({
    ...state,
    guides: payload.guides,
    guidesStatus: payload.guidesStatus,
    loading: false,
  }))
  .handleAction([initialize.failure, initializeMemberView.failure], (state) => ({
    ...state,
    loading: false,
  }))
  .handleAction(
    [
      updateGuideAssignees.success,
      bulkAddGuideAssignees.success,
      updateUserGuides.success,
      refreshGuidesStatus.success,
    ],
    (state, { payload }) => ({
      ...state,
      guidesStatus: payload.guidesStatus,
    })
  );

export * from "./selectors";
export type OnboardingSceneActions = Actions;
export default onboarding;

import React, { useMemo } from "react";

import { faInfoCircle, faUpRightFromSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";

import { Activity } from "@mapmycustomers/shared/types/entity";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { Button } from "@mapmycustomers/ui";

import Field from "@app/component/FieldGrid/components/Field";
import { displayNameComparator } from "@app/util/comparator";
import activityFieldModel from "@app/util/fieldModel/ActivityFieldModel";

import Section from "../Section";

import styles from "./DetailsSection.module.scss";

const MIN_FIELDS_COUNT = 5;

interface Props {
  activity: Activity;
}

export const DetailsSection: React.FC<Props> = ({ activity }) => {
  const intl = useIntl();
  const [showAll, show] = useBoolean();

  const customFields = useMemo(
    () =>
      activity.customFields
        ?.map(({ esKey }) => activityFieldModel.getByName(esKey))
        .filter(isDefined)
        .sort(displayNameComparator) ?? [],
    [activity.customFields]
  );

  if (!customFields.length && !activity.crmLink) {
    return null;
  }

  return (
    <Section
      icon={faInfoCircle}
      title={intl.formatMessage({
        id: "component.activityAnnotation.detailsSection.title",
        defaultMessage: "Details",
        description: "Details section title",
      })}
    >
      <div className={styles.container}>
        {customFields
          .filter((field) => field.isReadable)
          .slice(0, MIN_FIELDS_COUNT)
          .map((field) => (
            <Field entity={activity} field={field} key={field.name} />
          ))}
        {showAll &&
          customFields
            .filter((field) => field.isReadable)
            .slice(MIN_FIELDS_COUNT)
            .map((field) => <Field entity={activity} field={field} key={field.name} />)}
        {customFields.length >= MIN_FIELDS_COUNT && !showAll
          ? null
          : activity.crmLink && (
              <>
                <div className={styles.name}>
                  {intl.formatMessage({
                    id: "activity.field.crmLink",
                    defaultMessage: "CRM Link",
                    description: "CRM link field of activity",
                  })}
                </div>
                <Button
                  className={styles.link}
                  href={activity.crmLink}
                  rel="noopener noreferrer"
                  rightIcon={<FontAwesomeIcon icon={faUpRightFromSquare} />}
                  size="small"
                  target="_blank"
                  type="link"
                >
                  {intl.formatMessage({
                    id: "activity.field.open",
                    defaultMessage: "Open",
                    description: "Open CRM link field of activity button",
                  })}
                </Button>
              </>
            )}
      </div>
      {!showAll && customFields.length >= MIN_FIELDS_COUNT && (
        <Button className={styles.showAll} onClick={show} size="small" type="link">
          {intl.formatMessage({
            id: "component.activityAnnotation.detailsSection.showAll",
            defaultMessage: "Show All",
            description: "Show All button",
          })}
        </Button>
      )}
    </Section>
  );
};

export default DetailsSection;

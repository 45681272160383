import React, { ReactNode } from "react";

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { Link } from "react-router-dom";

import configService from "@app/config/ConfigService";
import authService from "@app/store/auth/AuthService";
import { getLegacyAppUrl } from "@app/util/appUrl";

import styles from "./MenuItemTitle.module.scss";

interface Props {
  active?: boolean;
  children?: ReactNode;
  hasSubmenu?: boolean;
  icon?: IconProp;
  legacyPath?: string;
  onClick?: () => void;
  path?: string;
  tooltip?: ReactNode;
}

const MenuItemTitle: React.FC<Props> = ({
  active,
  children,
  hasSubmenu,
  icon,
  legacyPath,
  onClick,
  path,
  tooltip,
}) => {
  const baseOldAppUrl = configService.getBaseOldAppUrl();
  const token = authService.getToken();

  return (
    <Tooltip title={tooltip}>
      <span className={cn(styles.container, { [styles.active]: active })} onClick={onClick}>
        {legacyPath ? (
          <a className={styles.link} href={getLegacyAppUrl(baseOldAppUrl, token, legacyPath)}>
            {icon ? <FontAwesomeIcon className={styles.icon} icon={icon} /> : null}
            {children && <span>{children}</span>}{" "}
          </a>
        ) : null}
        {path ? (
          <Link className={styles.link} to={path}>
            {icon ? <FontAwesomeIcon className={styles.icon} icon={icon} /> : null}
            {children && <span>{children}</span>}{" "}
          </Link>
        ) : null}
        {!path && !legacyPath ? (
          <span>
            {icon ? <FontAwesomeIcon className={styles.icon} icon={icon} /> : null}
            {children && <span>{children}</span>}
          </span>
        ) : null}
        {hasSubmenu ? (
          <span className={styles.chevron}>
            <FontAwesomeIcon icon={faChevronDown} />
          </span>
        ) : null}
      </span>
    </Tooltip>
  );
};

export default MenuItemTitle;

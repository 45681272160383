import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import EmailPreview from "@mapmycustomers/shared/types/email/EmailPreview";
import { EntityType } from "@mapmycustomers/shared/types/entity";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import { LoadingSpinner, Modal } from "@mapmycustomers/ui";

import { getNewEmailPreview, isNewEmailPreviewLoading } from "@app/store/email";
import { fetchNewEmailPreview } from "@app/store/email/actions";
import { RootState } from "@app/store/rootReducer";
import createEmailAssociatedEntity from "@app/util/email/createEmailAssociatedEntity";

import messages from "../messages";
import Preview from "../Preview";
import RecipientSelector from "../RecipientSelector";

import styles from "./PreviewModal.module.scss";

interface Props {
  body: string;
  emailPreview?: EmailPreview;
  loading: boolean;
  onFetchNewEmailPreview: typeof fetchNewEmailPreview.request;
  onHide: () => void;
  onSelectRecipientId?: (id?: EntitiesSupportedByEmailFeature["id"]) => void;
  recipients: EntitiesSupportedByEmailFeature[];
  selectedRecipientId?: EntitiesSupportedByEmailFeature["id"];
  subject: string;
}

const PreviewModal: React.FC<Props> = ({
  body,
  emailPreview,
  loading,
  onFetchNewEmailPreview,
  onHide,
  onSelectRecipientId,
  recipients,
  selectedRecipientId,
  subject,
}) => {
  const intl = useIntl();

  const selectedRecipient = useMemo(
    () => recipients.find((recipient) => recipient.id === selectedRecipientId),
    [recipients, selectedRecipientId]
  );

  useEffect(() => {
    if (selectedRecipientId) {
      onFetchNewEmailPreview({
        associatedEntities: [createEmailAssociatedEntity({ entityId: selectedRecipientId })],
        body,
        entityType: recipients[0]?.entity ?? EntityType.COMPANY,
        isHtml: true,
        logActivity: false,
        subject,
        tracking: true,
      });
    }
  }, [body, onFetchNewEmailPreview, recipients, selectedRecipientId, subject]);

  return (
    <Modal
      className={styles.modal}
      closable
      footer={null}
      onCancel={onHide}
      open
      title={intl.formatMessage(messages.previewAs)}
      width="48rem"
    >
      <div className={styles.preview}>
        {emailPreview && selectedRecipient ? (
          <Preview preview={emailPreview} recipient={selectedRecipient} />
        ) : (
          <div className={styles.noPreview}>
            {loading && <LoadingSpinner />}
            <span className={styles.noPreviewTitle}>
              {intl.formatMessage(messages.previewWillShowHere)}
            </span>
            <span className={styles.noPreviewDescription}>
              {intl.formatMessage(messages.chooseRecipient)}
            </span>
          </div>
        )}
      </div>
      <div className={styles.selectorContainer}>
        <div className={styles.previewAs}>{intl.formatMessage(messages.previewAs)}</div>
        <RecipientSelector
          disabled={loading}
          onSelectRecipientId={onSelectRecipientId}
          recipients={recipients}
          selectedRecipientId={selectedRecipientId}
        />
        <div className={styles.previewAs}>{intl.formatMessage(messages.previewAsDescription)}</div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  emailPreview: getNewEmailPreview(state),
  loading: isNewEmailPreviewLoading(state),
});

const mapDispatchToProps = {
  onFetchNewEmailPreview: fetchNewEmailPreview.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewModal);

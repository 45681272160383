import { compact } from "lodash-es";
import { createReducer } from "typesafe-actions";

import { Activity } from "@mapmycustomers/shared/types/entity";

import { CreateEditActivityModalActions } from "@app/component/createEditEntity/Activity/store";
import { fileCreatedAtDescComparator } from "@app/component/preview/util/comparators";
import { Actions as AppActions } from "@app/store/app/actions";
import { UiSyncActions } from "@app/store/uiSync";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import FileListItem from "@app/types/FileListItem";
import applyActivityAssociationsChanges from "@app/util/activity/applyActivityAssociationsChanges";

import {
  Actions,
  deleteActivityFile,
  destroyAnnotation,
  initializeAnnotation,
  loadAnnotationData,
  postponeActivity,
  toggleComplete,
  uploadActivityFiles,
} from "./actions";
import AnnotationData from "./AnnotationData";

export interface ActivityAnnotationState {
  activities: Activity[];
  activitiesHasNoAccess: boolean;
  activitiesLoading: boolean;
  annotationData?: AnnotationData;
  annotationDataHasNoAccess: boolean;
  annotationDataLoading: boolean;
  filesAdded: FileListItem[];
  filesUploading: boolean;
  postponeLoading: boolean;
  toggleCompleteLoading: boolean;
}

const initialState: ActivityAnnotationState = {
  activities: [],
  activitiesHasNoAccess: false,
  activitiesLoading: false,
  annotationDataHasNoAccess: false,
  annotationDataLoading: false,
  filesAdded: [],
  filesUploading: false,
  postponeLoading: false,
  toggleCompleteLoading: false,
};

const activityAnnotation = createReducer<
  ActivityAnnotationState,
  Actions | AppActions | CreateEditActivityModalActions | UiSyncActions
>(initialState)
  .handleAction(initializeAnnotation.request, (state) => ({
    ...state,
    activities: [],
    activitiesHasNoAccess: false,
    activitiesLoading: true,
    annotationData: undefined,
  }))
  .handleAction(initializeAnnotation.success, (state, { payload }) => ({
    ...state,
    activities: payload,
    activitiesLoading: false,
  }))
  .handleAction(initializeAnnotation.failure, (state, { payload }) => ({
    ...state,
    activitiesHasNoAccess: payload,
    activitiesLoading: false,
  }))
  .handleAction(destroyAnnotation, () => ({
    ...initialState,
  }))
  .handleAction(loadAnnotationData.request, (state) => ({
    ...state,
    annotationData: undefined,
    annotationDataHasNoAccess: false,
    annotationDataLoading: true,
  }))
  .handleAction(loadAnnotationData.success, (state, { payload }) => ({
    ...state,
    annotationData: payload,
    annotationDataLoading: false,
  }))
  .handleAction(loadAnnotationData.failure, (state, { payload }) => ({
    ...state,
    annotationDataHasNoAccess: payload,
    annotationDataLoading: false,
  }))
  .handleAction(toggleComplete.request, (state) => ({
    ...state,
    toggleCompleteLoading: true,
  }))
  .handleAction(toggleComplete.success, (state, { payload }) => ({
    ...state,
    annotationData: state.annotationData
      ? {
          ...state.annotationData,
          activity: payload,
        }
      : undefined,
    toggleCompleteLoading: false,
  }))
  .handleAction(toggleComplete.failure, (state) => ({
    ...state,
    toggleCompleteLoading: false,
  }))
  .handleAction(postponeActivity.request, (state) => ({
    ...state,
    postponeLoading: true,
  }))
  .handleAction(postponeActivity.success, (state, { payload }) => ({
    ...state,
    annotationData: state.annotationData
      ? {
          ...state.annotationData,
          activity: payload,
        }
      : undefined,
    postponeLoading: false,
  }))
  .handleAction(postponeActivity.failure, (state) => ({
    ...state,
    postponeLoading: false,
  }))
  .handleAction(notifyAboutChanges, (state, { payload }) => {
    const activity = state.annotationData?.activity;
    if (!activity || (!payload.updated && !payload.deletedIds)) {
      return state;
    }
    const [updatedActivity] = applyActivityAssociationsChanges([activity], payload);
    return { ...state, annotationData: { ...state.annotationData!, activity: updatedActivity } };
  })
  .handleAction(uploadActivityFiles.request, (state) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadActivityFiles.success, (state, { payload }) => {
    const filesUploaded = compact(payload.map(({ uploadedFile }) => uploadedFile));
    return {
      ...state,
      annotationData: state.annotationData
        ? {
            ...state.annotationData,
            files: [...filesUploaded, ...state.annotationData.files].sort(
              fileCreatedAtDescComparator
            ),
          }
        : undefined,
      filesAdded: [...state.filesAdded, ...payload],
      filesUploading: false,
    };
  })
  .handleAction(uploadActivityFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(deleteActivityFile.success, (state, { payload }) => ({
    ...state,
    annotationData: state.annotationData
      ? {
          ...state.annotationData,
          files: state.annotationData.files.filter(({ id }) => id !== payload.id),
        }
      : undefined,
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== payload.id),
  }));

export * from "./selectors";
export type ActivityAnnotationActions = Actions;
export default activityAnnotation;

import { PinnedFieldsVisibility } from "@mapmycustomers/shared";
import {
  EntityType,
  EntityTypeSupportingDataView,
  FieldDescription,
  MapPersistedEntityColumns,
} from "@mapmycustomers/shared/types/entity";
import Team from "@mapmycustomers/shared/types/Team";

import { displayOrderComparator } from "@app/util/comparator";

import EntityColumnsConfiguration, {
  InternalFieldDescription,
} from "../EntityColumnsConfiguration";

const convertInternalFieldDescriptionToFieldDescription = (
  fieldDescription: InternalFieldDescription
): FieldDescription => ({
  displayOrder: fieldDescription.displayOrder,
  fieldName: fieldDescription.field.name,
});

const getMapPersistedEntityColumns = (
  configuration: Partial<EntityColumnsConfiguration>,
  pinnedFieldVisibility: PinnedFieldsVisibility,
  teamId?: Team["id"]
): MapPersistedEntityColumns["fields"] => {
  const filterAndSortFields = (entityType: EntityTypeSupportingDataView) =>
    (configuration[entityType] ?? [])
      .filter(
        ({ teamIds, visibility }) =>
          visibility === pinnedFieldVisibility && (!teamId || (teamIds?.includes(teamId) ?? false))
      )
      .map(convertInternalFieldDescriptionToFieldDescription)
      .sort(displayOrderComparator);

  return {
    actions:
      configuration.actions?.visibility === pinnedFieldVisibility &&
      (!teamId || (configuration.actions?.teamIds?.includes(teamId) ?? false)),
    engagement:
      configuration.engagement?.visibility === pinnedFieldVisibility &&
      (!teamId || (configuration.engagement?.teamIds?.includes(teamId) ?? false)),
    [EntityType.COMPANY]: filterAndSortFields(EntityType.COMPANY),
    [EntityType.DEAL]: filterAndSortFields(EntityType.DEAL),
    [EntityType.PERSON]: filterAndSortFields(EntityType.PERSON),
  };
};

export default getMapPersistedEntityColumns;

import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const openStageDealAmountState = (state: RootState) =>
  state.scene.dashboard.openStageDealAmountState;

export const getOpenStageDealAmountDrillDownViewState = createSelector(
  openStageDealAmountState,
  ({ viewState }) => viewState
);

export const getOpenStageDealAmountDrillDownTotalFilteredRecords = createSelector(
  openStageDealAmountState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getOpenStageDealAmountDrillDownTotalRecords = createSelector(
  openStageDealAmountState,
  ({ totalRecords }) => totalRecords
);

export const getOpenStageDealAmountDrillDownConfiguration = createSelector(
  openStageDealAmountState,
  ({ configuration }) => configuration
);

export const isOpenStageDealAmountDrillDownLoading = createSelector(
  openStageDealAmountState,
  ({ loading }) => loading
);

import { call, select, takeLatest } from "redux-saga/effects";

import EmailSubscriptionStatus from "@mapmycustomers/shared/types/email/EmailSubscriptionStatus";
import { EntityType, MapEntity } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId, isEmailServiceSupported } from "@app/store/iam";
import { getMapViewSettings } from "@app/store/map";
import type AggregatedListResponse from "@app/types/viewModel/AggregatedListResponse";
import type ListResponseAggregation from "@app/types/viewModel/ListResponseAggregation";

import { fetchAnnotationData, subscribeEntityEmail, unsubscribeEntityEmail } from "./actions";

function* getFetchPayload(entityType: MapEntity["entity"], entityId: MapEntity["id"]) {
  const mapViewState: MapViewState = yield select(getMapViewSettings);

  return {
    $filters: {
      cadence: true,
      entities: {
        [entityType]: {
          $and: [{ id: entityId }],
          includeGroups: true,
          includeTerritories: true,
        },
      },
      includeAccessStatus: true,
      includeCustomFields: true,
      includeUsersWithAccess: true,
      pinLegends: {
        [entityType]: {
          color: mapViewState.colorKey![entityType],
          shape: mapViewState.shapeKey![entityType],
        },
      },
    },
    $limit: 1,
  };
}

export function* onFetchAnnotationPreview({
  payload: { callback, entityId, entityType, failureCallback },
}: ReturnType<typeof fetchAnnotationData>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const emailServiceSupported: boolean = yield select(isEmailServiceSupported);

    const pinsResponse: AggregatedListResponse<MapEntity, ListResponseAggregation<MapEntity>[]> =
      yield callApi("fetchPins", orgId, yield call(getFetchPayload, entityType, entityId));

    const entity = pinsResponse.data[0];
    const emailSubscriptionStatus: EmailSubscriptionStatus | undefined =
      emailServiceSupported &&
      (entity.entity === EntityType.COMPANY || entity.entity === EntityType.PERSON) &&
      entity.email
        ? yield callApi("fetchEntityEmailSubscriptionStatus", orgId, entity.entity, entityId)
        : null;

    yield call(callback, {
      emailSubscribed: emailSubscriptionStatus?.subscribed,
      entity,
    });
  } catch {
    yield call(failureCallback);
  }
}

export function* onSubscribeEmail({
  payload: { callback, entityId, entityType },
}: ReturnType<typeof subscribeEntityEmail>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("subscribeEntityEmail", orgId, entityType, entityId);
    yield call(callback);
  } catch (error) {
    handleError({ error });
  }
}

export function* onUnsubscribeEmail({
  payload: { callback, entityId, entityType },
}: ReturnType<typeof unsubscribeEntityEmail>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("unsubscribeEntityEmail", orgId, entityType, entityId);
    yield call(callback);
  } catch (error) {
    handleError({ error });
  }
}

export function* entityAnnotationSagas() {
  yield takeLatest(fetchAnnotationData, onFetchAnnotationPreview);
  yield takeLatest(subscribeEntityEmail, onSubscribeEmail);
  yield takeLatest(unsubscribeEntityEmail, onUnsubscribeEmail);
}

import { defineMessages } from "react-intl";

import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";

import DateField from "@app/util/fieldModel/impl/DateField";
import DateTimeField from "@app/util/fieldModel/impl/DateTimeField";
import FieldModel from "@app/util/fieldModel/impl/FieldModel";
import FrequencyStatusField from "@app/util/fieldModel/impl/FrequencyStatusField";
import MapLocationField from "@app/util/fieldModel/impl/MapLocationField";
import NameField from "@app/util/fieldModel/impl/NameField";
import NoContactField from "@app/util/fieldModel/impl/NoContactField";
import TeamField from "@app/util/fieldModel/impl/TeamField";
import TerritoryField from "@app/util/fieldModel/impl/TerritoryField";
import UserField from "@app/util/fieldModel/impl/UserField";
import ViewAsField from "@app/util/fieldModel/impl/ViewAsField";

import FrequencyIntervalField from "./impl/FrequencyIntervalField";
import SourceField from "./impl/SourceField";

export enum UniversalFieldName {
  FREQUENCY_STATUS = "cadenceDaysOut",
  FREQUENCY_INTERVAL = "cadenceInterval",
  CREATED_AT = "createdAt",
  CREATOR = "creator",
  GEOPOINT = "geoPoint",
  LAST_ACTIVITY_DATE = "lastCompletedActivity.completedAt",
  LAST_ACTIVITY_TYPE = "lastCompletedActivity.activityType",
  LAST_ACTIVITY_NAME = "lastCompletedActivity.name",
  NAME = "name",
  NEXT_ACTIVITY = "upcomingActivity.startAt",
  NO_CONTACT_IN = "noContactDaysOut",
  SOURCE = "sourceCreated",
  TEAM = "team",
  TERRITORIES = "territories",
  UPDATED_AT = "updatedAt",
  USER = "user",
  VIEW_AS = "viewAs",
}

const displayNames = defineMessages<UniversalFieldName>({
  [UniversalFieldName.CREATED_AT]: {
    id: "universal.field.createdAt",
    defaultMessage: "Date Created",
    description: "Created At field in universal field model",
  },
  [UniversalFieldName.CREATOR]: {
    id: "universal.field.creator",
    defaultMessage: "Creator",
    description: "Creator field in universal field model",
  },
  [UniversalFieldName.FREQUENCY_INTERVAL]: {
    id: "universal.field.frequencyInterval",
    defaultMessage: "Frequency Interval",
    description: "Frequency Interval field in universal field model",
  },
  [UniversalFieldName.FREQUENCY_STATUS]: {
    id: "universal.field.frequencyStatus",
    defaultMessage: "Frequency Status",
    description: "Frequency Status field in universal field model",
  },
  [UniversalFieldName.GEOPOINT]: {
    id: "universal.field.geopoint",
    defaultMessage: "Location",
    description: "Location field in universal field model",
  },
  [UniversalFieldName.LAST_ACTIVITY_DATE]: {
    id: "universal.field.lastActivity.completedAt",
    defaultMessage: "Date of Last Done Activity",
    description: "Date of last done activity field in universal field model",
  },
  [UniversalFieldName.LAST_ACTIVITY_NAME]: {
    id: "universal.field.lastActivity.name",
    defaultMessage: "Name of Last Done Activity",
    description: "Name of Last Done Activity field in universal field model",
  },
  [UniversalFieldName.LAST_ACTIVITY_TYPE]: {
    id: "universal.field.lastActivity.activityType",
    defaultMessage: "Type of Last Done Activity",
    description: "Type of Last Done Activity field in universal field model",
  },
  [UniversalFieldName.NAME]: {
    id: "universal.field.name",
    defaultMessage: "Name",
    description: "Name field in universal field model",
  },
  [UniversalFieldName.NEXT_ACTIVITY]: {
    id: "universal.field.nextActivity",
    defaultMessage: "Date of Next Activity",
    description: "Next Activity field in universal field model",
  },
  [UniversalFieldName.NO_CONTACT_IN]: {
    id: "universal.field.noContactIn",
    defaultMessage: "No Contact In",
    description: "No Contact In field in universal field model",
  },
  [UniversalFieldName.SOURCE]: {
    id: "universal.field.sourceCreated",
    defaultMessage: "Source",
    description: "Source field in universal field model",
  },
  [UniversalFieldName.TEAM]: {
    id: "universal.field.team",
    defaultMessage: "Team",
    description: "Team field in universal field model",
  },
  [UniversalFieldName.TERRITORIES]: {
    id: "universal.field.territories",
    defaultMessage: "Territories",
    description: "Territories field in universal field model",
  },
  [UniversalFieldName.UPDATED_AT]: {
    id: "universal.field.updatedAt",
    defaultMessage: "Date Last Modified",
    description: "Modified At field in universal field model",
  },
  [UniversalFieldName.USER]: {
    id: "universal.field.user",
    defaultMessage: "Owner",
    description: "Owner field in universal field model",
  },
  [UniversalFieldName.VIEW_AS]: {
    id: "universal.field.viewAs",
    defaultMessage: "Visible to",
    description: "View as field in universal field model, purely for filtering",
  },
});

// All fields in this model are filter-only fields
const universalFieldsFieldModel = new FieldModel([
  new FrequencyStatusField({
    displayName: displayNames[UniversalFieldName.FREQUENCY_STATUS],
    name: UniversalFieldName.FREQUENCY_STATUS,
  }),
  new FrequencyIntervalField({
    displayName: displayNames[UniversalFieldName.FREQUENCY_INTERVAL],
    features: [
      FieldFeature.NON_INTEGRATION,
      FieldFeature.NON_IMPORT,
      FieldFeature.NON_ADDABLE_FORM_FIELD,
      FieldFeature.ENGAGEMENT,
      FieldFeature.BULK_EDIT,
      FieldFeature.FREQUENCY_INTERVAL,
      FieldFeature.FILTERABLE,
      FieldFeature.NUMERIC,
      FieldFeature.FILTERABLE_ON_MAP,
    ],
    name: UniversalFieldName.FREQUENCY_INTERVAL,
  }),
  new DateTimeField({
    displayName: displayNames[UniversalFieldName.CREATED_AT],
    features: [FieldFeature.FILTERABLE, FieldFeature.FILTERABLE_ON_MAP],
    name: UniversalFieldName.CREATED_AT,
  }),
  new DateTimeField({
    displayName: displayNames[UniversalFieldName.UPDATED_AT],
    features: [FieldFeature.FILTERABLE, FieldFeature.FILTERABLE_ON_MAP],
    name: UniversalFieldName.UPDATED_AT,
  }),
  new DateField({
    displayName: displayNames[UniversalFieldName.LAST_ACTIVITY_DATE],
    features: [FieldFeature.FILTERABLE, FieldFeature.FILTERABLE_ON_MAP, FieldFeature.ENGAGEMENT],
    name: UniversalFieldName.LAST_ACTIVITY_DATE,
  }),
  new MapLocationField({
    displayName: displayNames[UniversalFieldName.GEOPOINT],
    name: UniversalFieldName.GEOPOINT,
  }),
  new DateField({
    displayName: displayNames[UniversalFieldName.NEXT_ACTIVITY],
    features: [FieldFeature.FILTERABLE, FieldFeature.FILTERABLE_ON_MAP, FieldFeature.ENGAGEMENT],
    name: UniversalFieldName.NEXT_ACTIVITY,
  }),
  new NoContactField({
    displayName: displayNames[UniversalFieldName.NO_CONTACT_IN],
    name: UniversalFieldName.NO_CONTACT_IN,
  }),
  new UserField({
    displayName: displayNames[UniversalFieldName.USER],
    features: [
      FieldFeature.FILTERABLE_ON_MAP,
      FieldFeature.FILTERABLE,
      FieldFeature.OWNER_FIELD,
      FieldFeature.SUPPORTS_SET_FILTER,
    ],
    importProperties: {
      required: false,
    },
    name: UniversalFieldName.USER,
    platformFilterName: "userId",
  }),
  new UserField({
    displayName: displayNames[UniversalFieldName.CREATOR],
    features: [
      FieldFeature.FILTERABLE,
      FieldFeature.FILTERABLE_ON_MAP,
      FieldFeature.SUPPORTS_SET_FILTER,
    ],
    name: UniversalFieldName.CREATOR,
    platformFilterName: "createdBy",
    platformName: "createdBy",
  }),
  new TeamField({
    displayName: displayNames[UniversalFieldName.TEAM],
    name: UniversalFieldName.TEAM,
    platformFilterName: "teamId",
  }),
  new SourceField({
    displayName: displayNames[UniversalFieldName.SOURCE],
    name: UniversalFieldName.SOURCE,
  }),
  // filter-only fields:
  new TerritoryField({
    displayName: displayNames[UniversalFieldName.TERRITORIES],
    features: [
      FieldFeature.FILTERABLE_ON_MAP,
      FieldFeature.NON_IMPORT,
      FieldFeature.NON_INTEGRATION,
      FieldFeature.SUPPORTS_SET_FILTER,
      FieldFeature.TERRITORY_FIELD,
    ],
    name: UniversalFieldName.TERRITORIES,
  }),
  new ViewAsField({
    displayName: displayNames[UniversalFieldName.VIEW_AS],
    name: UniversalFieldName.VIEW_AS,
  }),
  // name field in universal field model, excluded from any kind of filters, integrations or lists
  new NameField({
    displayName: displayNames[UniversalFieldName.NAME],
    features: [
      FieldFeature.NON_EXPORT_VIEW,
      FieldFeature.NON_MAP_VIEW,
      FieldFeature.NON_LIST_VIEW,
      FieldFeature.NON_INTEGRATION,
      FieldFeature.NON_IMPORT,
    ],
    name: UniversalFieldName.NAME,
  }),
]);

export default universalFieldsFieldModel;

import React, { useCallback } from "react";

import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared/types/entity";
import { EntityTypeIcon } from "@mapmycustomers/ui";

import Chip from "@app/component/Chip";
import Path from "@app/enum/Path";
import ViewMode from "@app/scene/deal/enum/ViewMode";

import FrequencyEntity from "../type/FrequencyEntity";

import styles from "./EntityBlock.module.scss";

// import Link from "antd/es/typography/Link";

interface Props {
  entity: FrequencyEntity;
}

const EntityBlock: React.FC<Props> = ({ entity }) => {
  const intl = useIntl();

  const handleClick = useCallback(() => {
    let url = "";

    switch (entity.entity) {
      case EntityType.COMPANY:
        url = `${Path.COMPANY}/${entity.id}`;
        break;
      case EntityType.PERSON:
        url = `${Path.PEOPLE}/${entity.id}`;
        break;
      case EntityType.DEAL:
        url = `${Path.DEAL}/${ViewMode.LIST}/${entity.id}}`;
        break;
      case EntityType.COMPANY_GROUP:
      case EntityType.PEOPLE_GROUP:
      case EntityType.DEAL_GROUP:
        url = `${Path.MAP}/groups/${entity.entity}/${entity.id}`;
        break;
    }
    window.open(`${window.location.origin}${url}`, "_blank");
  }, [entity]);

  return (
    <Tooltip
      title={intl.formatMessage({
        id: "frequency.entityBlock.tooltip",
        defaultMessage: "Open in New Tab",
        description: "frequency component - entity block - tooltip",
      })}
    >
      <Chip
        className={styles.entityName}
        icon={
          <div className={styles.iconContainer}>
            <div className={cn(styles.iconWrapper, styles[entity.entity])}>
              <EntityTypeIcon className={styles.entityIcon} entityType={entity.entity} />
            </div>
          </div>
        }
        onClick={handleClick}
      >
        {entity.name}
      </Chip>
    </Tooltip>
  );
};

export default EntityBlock;

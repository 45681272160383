import { ITooltipParams, ValueFormatterParams, ValueGetterParams } from "@ag-grid-community/core";
import get from "lodash-es/get";
import { defineMessage } from "react-intl";

import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import MonetaryValue from "@mapmycustomers/shared/types/customField/MonetaryValue";
import { EntitySupportingCustomFields, Group } from "@mapmycustomers/shared/types/entity";
import Team from "@mapmycustomers/shared/types/Team";
import User, { UserRef } from "@mapmycustomers/shared/types/User";

import i18nService from "@app/config/I18nService";
import localSettings from "@app/config/LocalSettings";
import reduxStore from "@app/store";
import { getUsers } from "@app/store/members";
import { getCurrencies } from "@app/store/referenceData";
import { formatDate } from "@app/util/formatters";
import parseDistance from "@app/util/units/parseDistance";

import formatCountryName from "../../countries/formatCountryName";
import isValidDate from "../../isValidDate";

export const valueGetterUsingFunction =
  (fn: (entity: unknown) => unknown) => (params: ValueGetterParams) =>
    params.data ? fn(params.data) : undefined;

export const valueFormatterUsingFunction =
  (fn: (entity: unknown, value: unknown) => string) => (params: ValueFormatterParams) =>
    params.data ? fn(params.data, params.value) : "";

export const defaultTooltipValueGetter = (fieldName: string) => (params: ITooltipParams) => {
  if (!params.data) {
    return "";
  }
  if (params.valueFormatted !== undefined) {
    return params.valueFormatted;
  }
  if (params.value !== undefined) {
    return params.value;
  }
  return params.data[fieldName] ?? "";
};

// TODO: move these date-time formatters somewhere away
export const dateTimeFormatter = (entity: unknown, date: unknown) =>
  isValidDate(date) ? formatDate(date, "PPp") : "";

export const dateFormatter = (entity: unknown, date: unknown) =>
  isValidDate(date) ? formatDate(date, "PP") : "";

export const timeFormatter = (entity: unknown, date: unknown) =>
  isValidDate(date) ? formatDate(date, "p") : "";

export const booleanFormatter = (entity: unknown, value: unknown) => (value ? "Yes" : "No");

export const listFormatter =
  (path: string, separator: string = ", ") =>
  (entity: unknown, values: unknown) =>
    Array.isArray(values) ? values.map((value) => get(value, path, "")).join(separator) : "";

export const groupsCadenceFormatter = (entity: unknown, values: unknown) =>
  Array.isArray(values)
    ? (values as Group[])
        .map(({ cadenceInterval, name }) => `${name} - ${cadenceInterval ?? "No cadence"}`)
        .join(", ")
    : "";

export const locationFormatter = (entity: unknown, coordinates: unknown) =>
  Array.isArray(coordinates) ? `${coordinates[1]}, ${coordinates[0]}` : "";

export const moneyFormatter = (entity: unknown, fieldValue: unknown): string => {
  const value = fieldValue as number;
  const currencyCode = localSettings.getCurrencyCode();
  if (!currencyCode || !Number.isFinite(value)) {
    return "";
  }

  return (
    i18nService.getIntl()?.formatNumber(value, {
      currency: currencyCode,
      minimumFractionDigits: 0,
      style: "currency",
    }) ?? ""
  );
};

export const currencyFormatter = (entity: unknown, fieldValue: unknown): string => {
  const { currencyId, value } = fieldValue as MonetaryValue;
  const currency = getCurrencies(reduxStore.store.getState()).find(({ id }) => id === currencyId);
  if (!currency) {
    return "";
  }
  if (!Number.isFinite(value)) {
    return currency.code;
  }

  return (
    i18nService.getIntl()?.formatNumber(value, {
      currency: currency.code,
      minimumFractionDigits: 0,
      style: "currency",
    }) ?? ""
  );
};

export const monetaryAmountGetter =
  (fn: (entity: EntitySupportingCustomFields) => MonetaryValue) => (params: ValueGetterParams) =>
    fn(params.data).value;

const distanceMessage = defineMessage({
  id: "formatters.distanceFormatter",
  defaultMessage:
    "{unit, select, km {{value, number, ::.#}km} meter {{value, number, ::.}{value, plural, one { meter} other {meters}}} miles {{value, number, ::.#} {value, plural, one {mile} other {miles}}} ft {{value, number, ::.}ft} other {{value, number, ::.}}}",
  description: "Distance formatter's format",
});

export const meterDistanceFormatter = (entity: unknown, value: unknown) => {
  if (!value && value !== 0) {
    return "";
  }

  const { distance, unit } = parseDistance(value as number);

  return i18nService.formatMessage(distanceMessage, `${distance.toFixed(2)}${unit}`, {
    unit,
    value: distance,
  });
};

export const distanceFormatter = (entity: unknown, distance: unknown) => {
  if (!distance && distance !== 0) {
    return "";
  }
  return meterDistanceFormatter(entity, (distance as number) * 1000);
};

export const getUserTeams = (userId?: UserRef["id"]): Team[] => {
  if (!userId) {
    return [];
  }
  const foundUser: undefined | User = getUsers(reduxStore.store.getState()).find(
    ({ id }) => id === userId
  );
  if (!foundUser) {
    return [];
  }
  return foundUser.teams;
};

export const countryFormatter = (fieldValue: unknown): string => {
  const countryCode = fieldValue as CountryCode | null;
  if (!countryCode) {
    return "";
  }
  const intl = i18nService.getIntl();
  if (!intl) {
    return countryCode;
  }
  return formatCountryName(intl, countryCode);
};

export const teamsFormatter = (entity: unknown, value: unknown): string =>
  (value as Team[]).map(({ name }) => name).join(", ");

export const percentFormatter = (entity: unknown, value: unknown): string => {
  if (value === undefined) {
    return "";
  }
  const intl = i18nService.getIntl();
  if (intl) {
    return intl.formatNumber((value as number) / 100, { style: "percent" });
  }
  return String(value as number);
};

export const numberFormatter = (entity: unknown, value: unknown): string => {
  if (value === undefined) {
    return "";
  }
  const intl = i18nService.getIntl();
  if (intl) {
    return intl.formatNumber(value as number);
  }
  return String(value as number);
};

import React from "react";

import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/pro-light-svg-icons/faExclamationCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notification } from "antd";
import { Col } from "antd/es/grid";
import Row from "antd/es/row";
import cn from "classnames";
import { push } from "connected-react-router";
import { format } from "date-fns-tz";
import { call, put, select, takeEvery, takeLatest, takeLeading } from "redux-saga/effects";

import EmailLogStatus from "@mapmycustomers/shared/enum/EmailLogStatus";
import { CustomFieldError } from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import Email from "@mapmycustomers/shared/types/email/Email";
import EmailAssociatedEntity from "@mapmycustomers/shared/types/email/EmailAssociatedEntity";
import EmailLog from "@mapmycustomers/shared/types/email/EmailLog";
import EmailPreview from "@mapmycustomers/shared/types/email/EmailPreview";
import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";
import EmailTemplate from "@mapmycustomers/shared/types/email/EmailTemplate";
import { EntityType, type Person } from "@mapmycustomers/shared/types/entity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import { EntitiesSupportedByEmailFeature } from "@mapmycustomers/shared/types/map/types";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";

import { hideMapEntityView } from "@app/scene/map/store/mapMode/actions";
import { connectToMailNylas } from "@app/scene/settings/util/connectToNylas";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId, isEmailServiceSupported } from "@app/store/iam";
import { EMAIL_WARNING_MODAL_CLASS_NAME } from "@app/util/email/const";
import createEmailAssociatedEntity from "@app/util/email/createEmailAssociatedEntity";
import getValidationErrors from "@app/util/errorHandling/getValidationErrors";

import styles from "../../component/view/SelectBar/components/EmailButton/EmailButton.module.scss";
import i18nService from "../../config/I18nService";
import NylasStatus from "../../enum/NylasStatus";
import Path from "../../enum/Path";
import SettingPath from "../../enum/settings/SettingPath";
import layout from "../../styles/layout";
import NylasInfo from "../../types/nylas/NylasInfo";
import { downloadFileByUrl } from "../../util/file";
import { callApi } from "../api/callApi";
import { getEmailNylasInfo } from "../nylas";

import {
  cancelQueuedEmail,
  checkEmailSupporting,
  clearEmailQueue,
  createNewEmailTemplate,
  deleteEmailTemplate,
  downloadAttachedFile,
  fetchEmailInfo,
  fetchEmailQueueLogs,
  fetchEmailTemplates,
  fetchNewEmailPreview,
  fetchSelectedEntities,
  fetchTemplatePreview,
  loadImageToEmailBody,
  sendEmail,
  setEmailAssociatedEntities,
  showEmailCreationModal,
  updateEmailTemplate,
  uploadFileForNewEmail,
} from "./actions";
import messages from "./messages";

function* onFetchEmailInfo({ payload }: ReturnType<typeof fetchEmailInfo.request>) {
  try {
    const orgId: Organization["id"] | undefined = yield select(getOrganizationId);
    if (orgId) {
      const emailQuota: EmailQuota = yield callApi("getEmailQuota", orgId);
      if (payload?.callback) {
        yield call(payload.callback, emailQuota);
      }
      yield put(fetchEmailInfo.success(emailQuota));
    }
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchEmailInfo.failure(error));
  }
}

function* onFetchEmailTemplates() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<EmailTemplate> = yield callApi("fetchEmailTemplates", orgId);
    yield put(fetchEmailTemplates.success(response.data));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchEmailTemplates.failure(error));
  }
}

function* onFetchNewEmailPreview({ payload }: ReturnType<typeof fetchNewEmailPreview.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const preview: EmailPreview = yield callApi("previewEmail", orgId, payload);
    yield put(fetchNewEmailPreview.success(preview));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchNewEmailPreview.failure(error));
  }
}

function* onFetchTemplatePreview({ payload }: ReturnType<typeof fetchTemplatePreview.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const preview: EmailPreview = yield callApi("previewEmail", orgId, payload);
    yield put(fetchTemplatePreview.success(preview));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchTemplatePreview.failure(error));
  }
}

function* onFetchSelectedEntities({
  payload: { callback, entityIds, entityType },
}: ReturnType<typeof fetchSelectedEntities.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const response: ListResponse<EntitiesSupportedByEmailFeature> = yield callApi(
      entityType === EntityType.COMPANY ? "fetchCompanies" : "fetchPeople",
      orgId,
      {
        $filters: {
          $and: [
            {
              id: { $in: entityIds },
            },
          ],
        },
      }
    );
    yield call(callback, response.data);

    yield put(fetchSelectedEntities.success(response.data));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchSelectedEntities.failure(error));
  }
}

export function* onLoadImageToEmailBody({
  payload: { callback, file },
}: ReturnType<typeof loadImageToEmailBody>) {
  try {
    const FILE_SIZE_LIMIT = 25 * 1024 * 1024;

    if (file.size > FILE_SIZE_LIMIT) {
      const intl = i18nService.getIntl();
      notification.error({
        message: (
          <div>
            <div className={styles.errorTitle}>
              {intl?.formatMessage(messages.fileSizeExceeded)}
            </div>
            <div className={styles.errorDescription}>
              {intl?.formatMessage(messages.fileSizeExceededDescription)}
            </div>
          </div>
        ),
      });
    } else {
      const orgId: Organization["id"] = yield select(getOrganizationId);
      const rawFile: RawFile = yield callApi(
        "createFile",
        orgId,
        file,
        true,
        undefined,
        undefined,
        {
          headers: {
            "x-mmc-email-attachment": true,
          },
        }
      );

      if (rawFile.publicURI) {
        yield call(callback, rawFile.publicURI);
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onCreateNewEmailTemplate({
  payload: { callback, template },
}: ReturnType<typeof createNewEmailTemplate.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const createdTemplate: EmailTemplate = yield callApi("createEmailTemplate", orgId, template);
    if (callback) {
      yield call(callback, createdTemplate);
    }
    yield put(fetchEmailTemplates.request());
    yield put(createNewEmailTemplate.success(createdTemplate));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(createNewEmailTemplate.failure(error));
  }
}

function* onUpdateEmailTemplate({
  payload: { callback, template },
}: ReturnType<typeof updateEmailTemplate.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const updatedTemplate: EmailTemplate = yield callApi("updateEmailTemplate", orgId, template);
    if (callback) {
      yield call(callback, updatedTemplate);
    }
    yield put(fetchEmailTemplates.request());
    yield put(updateEmailTemplate.success(updatedTemplate));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(updateEmailTemplate.failure(error));
  }
}

export function* onDownloadAttachedFile({
  payload: { attachedFile, preview },
}: ReturnType<typeof downloadAttachedFile>) {
  try {
    if (attachedFile.file) {
      const orgId: Organization["id"] = yield select(getOrganizationId);
      const fileBlob: Blob = yield callApi(
        "fetchFile",
        orgId,
        attachedFile.file.id,
        true,
        false,
        undefined,
        undefined,
        {
          responseType: "blob",
        }
      );
      const fileUrl = window.URL.createObjectURL(fileBlob);
      if (preview) {
        window.open(fileUrl, "_blank");
      } else {
        yield call(downloadFileByUrl, fileUrl, attachedFile.file.name);
      }
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onCheckEmailSupporting({
  payload: { modal, successfulCallback },
}: ReturnType<typeof checkEmailSupporting>) {
  try {
    let success = true;
    const nylasInfo: NylasInfo | undefined = yield select(getEmailNylasInfo);
    const emailServiceSupported: boolean = yield select(isEmailServiceSupported);

    const intl = i18nService.getIntl();
    if (!emailServiceSupported) {
      // we need to hide entity pane if this redirection was called from the map
      yield put(hideMapEntityView());
      yield put(push(`${Path.SETTINGS}/${SettingPath.PERSONAL_EMAIL_PREFERENCES}`));
      success = false;
    } else {
      const isSyncFailed =
        nylasInfo &&
        [null, NylasStatus.INVALID, NylasStatus.STOPPED, NylasStatus.SYNC_ERROR].includes(
          nylasInfo.status
        );

      if (!nylasInfo || isSyncFailed) {
        modal.show({
          cancelText: intl?.formatMessage(messages.syncModalCancel),
          centered: true,
          className: cn(styles.confirmationModal, EMAIL_WARNING_MODAL_CLASS_NAME),
          content: (
            <div className={styles.syncModalContent}>
              {isSyncFailed
                ? intl?.formatMessage(messages.syncModalFailedConnectionText)
                : intl?.formatMessage(messages.syncModalNoConnectionText, {
                    b: (text: string) => <b>{text}</b>,
                    br: <br />,
                    icon: <FontAwesomeIcon icon={faInfoCircle} />,
                  })}
            </div>
          ),
          icon: null,
          okCancel: true,
          okText: isSyncFailed
            ? intl?.formatMessage(messages.syncModalFailedConnectionOk)
            : intl?.formatMessage(messages.syncModalNoConnectionOk),
          onOk: connectToMailNylas,
          title: (
            <Row align="middle" className={styles.syncModalTitle} gutter={layout.spacerM}>
              <Col>
                <FontAwesomeIcon
                  className={isSyncFailed ? styles.warningIcon : styles.infoIcon}
                  icon={faExclamationCircle}
                />
              </Col>
              <Col>
                {isSyncFailed
                  ? intl?.formatMessage(messages.syncModalFailedConnectionTitle)
                  : intl?.formatMessage(messages.syncModalNoConnectionTitle)}
              </Col>
            </Row>
          ),
          type: "info",
        });
        success = false;
      }
    }

    if (success) {
      yield call(successfulCallback);
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onUploadFileForNewEmail({
  payload,
}: ReturnType<typeof uploadFileForNewEmail.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const uploadedFile: RawFile = yield callApi(
      "createFile",
      orgId,
      payload.preparedFile,
      false,
      undefined,
      undefined,
      {
        headers: {
          "x-mmc-email-attachment": true,
        },
      }
    );
    yield put(uploadFileForNewEmail.success({ ...payload, file: uploadedFile }));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(uploadFileForNewEmail.failure(payload));
  }
}

function* onDeleteEmailTemplate({
  payload: { callback, templateId },
}: ReturnType<typeof deleteEmailTemplate.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("deleteEmailTemplate", orgId, templateId);
    callback?.();
    yield put(fetchEmailTemplates.request());
    yield put(deleteEmailTemplate.success());
  } catch (error) {
    yield put(handleError({ error }));
    yield put(deleteEmailTemplate.failure(error));
  }
}

function* onSendEmail({
  payload: { callback, email, failureCallback },
}: ReturnType<typeof sendEmail.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const createdEmail: Email = yield callApi("sendEmail", orgId, email);
    yield put(sendEmail.success(createdEmail));
    if (callback) {
      yield call(callback);
    }
  } catch (error) {
    const erroredCustomFields = getValidationErrors(error);

    if (erroredCustomFields.length && failureCallback) {
      // Need to convert ValidationError[] type to CustomFieldError[]
      // Might need to update getValidationErrors to return correct error types
      yield call(failureCallback, erroredCustomFields as unknown as CustomFieldError[]);
      yield put(sendEmail.failure(error));
      return;
    }
    yield put(handleError({ error }));
    yield put(sendEmail.failure(error));
  }
}

function* onShowSendEmailModal({
  payload: { deals, recipients, recipientType },
}: ReturnType<typeof showEmailCreationModal>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const findDealByRecipient = (recipient: EntitiesSupportedByEmailFeature) =>
      deals?.find((deal) => {
        switch (recipient.entity) {
          case EntityType.COMPANY:
            return deal.account?.id === recipient.id;
          case EntityType.PERSON:
            return deal.contact?.id === recipient.id;
        }
      });

    let emailAssociatedEntities: EmailAssociatedEntity[];

    if (!recipients.length) {
      emailAssociatedEntities = [];
    } else if (recipients.length === 1) {
      const recipient = recipients[0];
      emailAssociatedEntities = [
        createEmailAssociatedEntity({
          dealId: findDealByRecipient(recipient)?.id,
          entityId: recipient.id,
        }),
      ];
    } else {
      emailAssociatedEntities = recipients.map((recipient) =>
        createEmailAssociatedEntity({
          dealId: findDealByRecipient(recipient)?.id,
          entityId: recipient.id,
        })
      );

      if (deals?.length) {
        for (const emailAssociatedEntity of emailAssociatedEntities) {
          const deal = deals.find((deal) => deal.id === emailAssociatedEntity.dealId);
          if (deal) {
            switch (recipientType) {
              case EntityType.COMPANY:
                emailAssociatedEntity.contactId = deal.contact?.id ?? null;
                break;
              case EntityType.PERSON:
                emailAssociatedEntity.accountId = deal.account?.id ?? null;
                break;
            }
          }
        }
      } else if (recipientType === EntityType.PERSON) {
        const personIds = recipients.map((recipient) => recipient.id);
        const persons: ListResponse<Person> = yield callApi("fetchPeople", orgId, {
          $filters: {
            $and: [
              {
                id: { $in: personIds },
              },
            ],
            includeAccessStatus: true,
          },
        });
        for (const emailAssociatedEntity of emailAssociatedEntities) {
          const person = persons.data.find(
            (person) => person.id === emailAssociatedEntity.entityId
          );
          if (person?.accounts?.length === 1) {
            emailAssociatedEntity.accountId = person.accounts[0].id;
          }
        }
      }
    }

    yield put(setEmailAssociatedEntities(emailAssociatedEntities));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

function* onFetchEmailQueueLogs() {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    const emailLogsResponse: ListResponse<EmailLog> = yield callApi("fetchEmailLogs", orgId, {
      $filters: {
        $and: [
          {
            $or: [
              {
                status: {
                  $in: [EmailLogStatus.SUBMITTED, EmailLogStatus.SENDING],
                },
              },
              {
                $and: [
                  {
                    status: {
                      $in: [EmailLogStatus.SENT, EmailLogStatus.ERRORED],
                    },
                  },
                  {
                    createdAt: {
                      $dateOnly: true,
                      $eq: format(new Date(), "yyyy-MM-dd"),
                    },
                  },
                ],
              },
            ],
          },
          {
            folder: {
              $eq: "sent",
            },
          },
        ],
      },
      $limit: 10000, // fetch all available data
      $order: "-date",
    });

    yield put(fetchEmailQueueLogs.success(emailLogsResponse.data));
  } catch (error) {
    yield put(handleError({ error }));
    yield put(fetchEmailQueueLogs.failure());
  }
}

function* onClearEmailQueue({ payload }: ReturnType<typeof clearEmailQueue>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("clearEmailQueue", orgId);
    yield put(fetchEmailQueueLogs.request());
    yield put(fetchEmailInfo.request());
    yield call(payload.onFinish);
  } catch (error) {
    yield call(payload.onFinish);
    yield put(handleError({ error }));
  }
}

function* onCancelQueuedEmail({ payload }: ReturnType<typeof cancelQueuedEmail>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("cancelQueuedEmail", orgId, [payload.emailLogId]);
    yield put(fetchEmailInfo.request());
    yield put(fetchEmailQueueLogs.request());
    yield call(payload.onFinish);
  } catch (error) {
    yield call(payload.onFinish);
    yield put(handleError({ error }));
  }
}

export function* emailSaga() {
  yield takeLeading(createNewEmailTemplate.request, onCreateNewEmailTemplate);
  yield takeLeading(deleteEmailTemplate.request, onDeleteEmailTemplate);
  yield takeLeading(fetchEmailInfo.request, onFetchEmailInfo);
  yield takeLeading(fetchEmailTemplates.request, onFetchEmailTemplates);
  yield takeLeading(loadImageToEmailBody, onLoadImageToEmailBody);
  yield takeLeading(sendEmail.request, onSendEmail);
  yield takeLeading(updateEmailTemplate.request, onUpdateEmailTemplate);
  yield takeEvery(uploadFileForNewEmail.request, onUploadFileForNewEmail);
  yield takeLeading(fetchNewEmailPreview.request, onFetchNewEmailPreview);
  yield takeLeading(fetchTemplatePreview.request, onFetchTemplatePreview);
  yield takeLeading(fetchSelectedEntities.request, onFetchSelectedEntities);
  yield takeLeading(downloadAttachedFile, onDownloadAttachedFile);
  yield takeEvery(checkEmailSupporting, onCheckEmailSupporting);
  yield takeEvery(showEmailCreationModal, onShowSendEmailModal);
  yield takeLatest(fetchEmailQueueLogs.request, onFetchEmailQueueLogs);
  yield takeLeading(clearEmailQueue, onClearEmailQueue);
  yield takeEvery(cancelQueuedEmail, onCancelQueuedEmail);
}

import { defineMessages } from "react-intl";

const messages = defineMessages({
  activityTypeAutoEmailName: {
    id: "activity.lockedFormFields.activityTypeAutoEmailName",
    defaultMessage: "Auto Email",
    description: "Auto Email activity type name for locked activity type",
  },
  activityTypeLockTooltip: {
    id: "activity.lockedFormFields.activityTypeLockTooltip",
    defaultMessage: "Activity type cannot be changed",
    description: "Tooltip for locked activity type",
  },
  assigneeLockTooltip: {
    id: "activity.lockedFormFields.assigneeLockTooltip",
    defaultMessage: "Assignee cannot be changed",
    description: "Tooltip for locked assignee",
  },
  filesLabel: {
    id: "activity.lockedFormFields.filesLabel",
    defaultMessage: "Files",
    description: "Files label in activity creation modal",
  },
  fileUploadRestrictedLabel: {
    id: "activity.lockedFormFields.fileUploadRestrictedLabel",
    defaultMessage: "Files that were added to the email will be attached to all activities",
    description: "File upload restricted label in activity creation modal",
  },
});

export default messages;

import React from "react";
import { connect } from "react-redux";

import { isEmailCreationModalVisible } from "@app/store/email";
import { hideEmailCreationModal } from "@app/store/email/actions";
import { RootState } from "@app/store/rootReducer";
import { useExtendedAnalytics } from "@app/util/analytic/AnalyticsService";
import AnalyticsContext from "@app/util/contexts/AnalyticsContext";

import EmailCreationModal from "./Modal";

interface Props {
  modalVisible: boolean;
  onHideEmailCreationModal: typeof hideEmailCreationModal;
}

const EmailCreation: React.FC<Props> = ({ modalVisible, onHideEmailCreationModal }) => {
  const emailCreationAnalytics = useExtendedAnalytics(["Bulk Email Modal"]);
  return (
    <AnalyticsContext.Provider value={emailCreationAnalytics}>
      {modalVisible && <EmailCreationModal onHide={onHideEmailCreationModal} />}
    </AnalyticsContext.Provider>
  );
};

const mapStateToProps = (state: RootState) => ({
  modalVisible: isEmailCreationModalVisible(state),
});

const mapDispatchToProps = {
  onHideEmailCreationModal: hideEmailCreationModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCreation);

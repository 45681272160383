import { createReducer } from "typesafe-actions";

import { ToolbarActions } from "@app/scene/map/store/toolbar/actions";
import ToolbarState from "@app/scene/map/store/toolbar/ToolbarState";

import { fetchTerritories } from "./actions";

export const toolbarInitialState: ToolbarState = {
  filters: {
    countryRegions: [],
    creatorIds: [],
    query: "",
    sharedWithIds: [],
  },
  territories: [],
  territoriesLoading: false,
  territoriesTotal: 0,
};

const toolbar = createReducer<ToolbarState, ToolbarActions>(toolbarInitialState)
  .handleAction(fetchTerritories.request, (state, { payload }) => ({
    ...state,
    filters: {
      ...state.filters,
      ...payload,
    },
    territoriesLoading: true,
  }))
  .handleAction(fetchTerritories.success, (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction(fetchTerritories.failure, (state) => ({
    ...state,
    territoriesLoading: false,
  }));

export default toolbar;

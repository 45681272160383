import React, { FC, useCallback, useState } from "react";

import { faQuestionCircle } from "@fortawesome/pro-regular-svg-icons/faQuestionCircle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Radio from "antd/es/radio";
import Space from "antd/es/space";
import { useIntl } from "react-intl";

import { Modal } from "@mapmycustomers/ui";

import RadioGroup from "@app/component/input/RadioGroup";

import styles from "./CloseConfirmationModal.module.scss";

export interface CloseConfirmationModalProps {
  onHide: (closeMode: "cancel" | "close" | "dismiss") => void;
}

const CloseConfirmationModal: FC<CloseConfirmationModalProps> = ({ onHide }) => {
  const intl = useIntl();
  const [closeMode, setCloseMode] = useState<"close" | "dismiss">("close");

  const handleOk = useCallback(() => onHide(closeMode), [onHide, closeMode]);
  const handleCancel = useCallback(() => onHide("cancel"), [onHide]);

  return (
    <Modal
      centered
      className={styles.container}
      okText={intl.formatMessage({
        id: "frigade.closeConfirmation.confirm",
        defaultMessage: "Confirm",
        description:
          "Ok button caption in a Close Confirmation modal shown when user closes a Checklist flow",
      })}
      onCancel={handleCancel}
      onOk={handleOk}
      open
      title={intl.formatMessage(
        {
          id: "frigade.closeConfirmation.title",
          defaultMessage: "{icon} Dismiss for now or forever?",
          description: "Title of a Close Confirmation modal",
        },
        {
          icon: <FontAwesomeIcon className={styles.icon} icon={faQuestionCircle} />,
        }
      )}
      width={400}
      // to appear above frigade's checklist modal
      zIndex={2000}
    >
      <RadioGroup className={styles.radioGroup} onChange={setCloseMode} value={closeMode}>
        <Space direction="vertical">
          <Radio key="close" value="close">
            {intl.formatMessage({
              id: "frigade.closeConfirmation.options.dismissForNow",
              defaultMessage: "Dismiss for now",
              description: "Dismiss for now option in a Close Confirmation modal",
            })}
          </Radio>
          <Radio key="dismiss" value="dismiss">
            {intl.formatMessage({
              id: "frigade.closeConfirmation.options.dismissForever",
              defaultMessage: "Dismiss forever",
              description: "Dismiss forever option in a Close Confirmation modal",
            })}
          </Radio>
        </Space>
      </RadioGroup>
    </Modal>
  );
};

export default CloseConfirmationModal;

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBusinessTime } from "@fortawesome/free-solid-svg-icons/faBusinessTime";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons/faCalendarCheck";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import { faRoute } from "@fortawesome/free-solid-svg-icons/faRoute";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faAlarmExclamation } from "@fortawesome/pro-solid-svg-icons/faAlarmExclamation";
import { faBook } from "@fortawesome/pro-solid-svg-icons/faBook";

import MmcNotificationAction from "@app/enum/MmcNotificationAction";

const getActionIcon = (action: MmcNotificationAction): IconProp | undefined => {
  switch (action) {
    case MmcNotificationAction.ACTIVITY_STARTING:
    case MmcNotificationAction.INCOMPLETE_ACTIVITY:
    case MmcNotificationAction.MARK_ACTIVITY_AS_DONE:
      return faCalendarCheck;
    case MmcNotificationAction.COMPANY_GROUP_SHARED:
    case MmcNotificationAction.DEAL_GROUP_SHARED:
    case MmcNotificationAction.PEOPLE_GROUP_SHARED:
      return faLayerGroup;
    case MmcNotificationAction.COMPANY_ROUTE_SHARED:
    case MmcNotificationAction.PEOPLE_ROUTE_SHARED:
      return faRoute;
    case MmcNotificationAction.DEALS_MARKED_LOST:
      return faTimes;
    case MmcNotificationAction.DEALS_MARKED_WON:
      return faCheck;
    case MmcNotificationAction.DEALS_ROTTING:
      return faBusinessTime;
    case MmcNotificationAction.GUIDE_ASSIGNED:
    case MmcNotificationAction.GUIDE_REMINDER:
      return faBook;
    case MmcNotificationAction.OUT_OF_CADENCE:
      return faAlarmExclamation;
    case MmcNotificationAction.OVERDUE_ACTIVITIES:
      return faExclamation;
  }
};

export default getActionIcon;

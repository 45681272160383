import { createReducer } from "typesafe-actions";

import ActivityVisibility from "@mapmycustomers/shared/enum/activity/ActivityVisibility";
import ActivityType from "@mapmycustomers/shared/types/entity/activities/ActivityType";
import Activity from "@mapmycustomers/shared/types/entity/Activity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import Team from "@mapmycustomers/shared/types/Team";
import { UserRef } from "@mapmycustomers/shared/types/User";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import { OnlyRequiredFields } from "@mapmycustomers/shared/util/ts";

import localSettings from "@app/config/LocalSettings";
import { Actions as AppActions, initializeApp } from "@app/store/app/actions";
import FileListItem from "@app/types/FileListItem";
import activityFieldModel from "@app/util/fieldModel/ActivityFieldModel";

import {
  Actions,
  applyListViewSettings,
  bulkDeleteActivities,
  bulkEdit,
  bulkPostpone,
  bulkToggleComplete,
  changeActivityAssignee,
  changeActivityType,
  changeActivityVisibility,
  changeActivityVisibilityTeamsIds,
  deleteActivity,
  deselectAll,
  fetchChildActivities,
  fetchFilePreview,
  fetchList,
  openDuplicationModal,
  removeActivityFile,
  resetSelectedActivities,
  subscribeOnViewStateUpdating,
  toggleActivitySelection,
  toggleComplete,
  toggleSelectAll,
  unsubscribeOnViewStateUpdating,
  updateActivity,
  updateNote,
  uploadActivityFiles,
} from "./actions";

export interface ActivityListViewState extends ViewState {
  activityAssignee?: UserRef;
  activityType?: ActivityType;
  activityVisibility?: ActivityVisibility;
  activityVisibilityTeamIds?: Team["id"][];
  bulkDeleteLoading: boolean;
  bulkEditLoading: boolean;
  bulkPostponeLoading: boolean;
  bulkToggleCompleteLoading: boolean;
  childActivities: Record<Activity["id"], Activity[]>;
  deleteLoading: Activity["id"][];
  error: undefined | unknown;
  filePreview?: Blob;
  filePreviewId?: RawFile["id"];
  filePreviewLoading: boolean;
  filesAdded: FileListItem[];
  filesUploading: boolean;
  list: Activity[];
  loading: boolean;
  onViewStateChanged?: (state: Partial<ViewState>) => void;
  openDuplicationModalLoading: Activity["id"][];
  selected: Activity[];
  toggleCompleteLoading: Activity["id"][];
  totalFilteredRecords: number;
  totalRecords: number;
  updateActivityLoading: Activity["id"][];
  updateNoteLoading: Activity["id"][];
}

const initialState: ActivityListViewState = {
  ...activityFieldModel.getDefaultListViewState(),
  activityAssignee: undefined,
  activityType: undefined,
  activityVisibility: undefined,
  bulkDeleteLoading: false,
  bulkEditLoading: false,
  bulkPostponeLoading: false,
  bulkToggleCompleteLoading: false,
  childActivities: {},
  deleteLoading: [],
  error: undefined,
  filePreviewLoading: false,
  filesAdded: [],
  filesUploading: false,
  list: [],
  loading: true,
  openDuplicationModalLoading: [],
  selected: [],
  toggleCompleteLoading: [],
  totalFilteredRecords: 0,
  totalRecords: 0,
  updateActivityLoading: [],
  updateNoteLoading: [],
};

const listUpdaterFactory = (updatedActivities: OnlyRequiredFields<Activity, "id">[]) => {
  const map = new Map<Activity["id"], OnlyRequiredFields<Activity, "id">>(
    updatedActivities.map((activity) => [activity.id, activity])
  );
  return (activity: Activity) =>
    map.has(activity.id) ? { ...activity, ...map.get(activity.id) } : activity;
};

const activity = createReducer<ActivityListViewState, Actions | AppActions>(initialState)
  .handleAction(initializeApp.success, (state) => {
    const viewState = localSettings.getViewSettings("activity/listView", activityFieldModel);
    // replace "startDate" with "startAt" field. We used to use startDate before, but it's not correct anymore.
    // However, for users which still have startDate in their localStorage we need to convert one field
    // to another.
    // Feel free to remove this hack after a couple of successive releases. This fix will be released on Feb-04,
    // hence feel free to remove any time after April-2022.
    if (viewState && viewState.sort.length && viewState.sort[0].field.name === "startDate") {
      viewState.sort[0].field = activityFieldModel.getByName("startAt")!;
    }
    return {
      ...state,
      ...viewState,
    };
  })
  .handleAction(applyListViewSettings, (state, { payload }) => ({
    ...state,
    filter: payload.filter ?? state.filter,
    range: payload.range ?? state.range,
    // only update selectedSavedFilterId when it is explicitly present in a payload (even when it is `undefined`)
    selectedSavedFilterId:
      "selectedSavedFilterId" in payload
        ? payload.selectedSavedFilterId
        : state.selectedSavedFilterId,
    sort: payload.sort ?? state.sort,
    // only update viewAs when it is explicitly present in a payload (even when it is `undefined`)
    viewAs: "viewAs" in payload ? payload.viewAs : state.viewAs,
  }))
  .handleAction(toggleActivitySelection, (state, { payload }) => {
    const hasActivity = state.selected.some((a) => a.id === payload.id);
    const childActivityIds = new Set((state.childActivities[payload.id] ?? []).map(({ id }) => id));
    const alreadySelectedIds = new Set((state.selected ?? []).map(({ id }) => id));
    return {
      ...state,
      selected: hasActivity
        ? state.selected.filter(({ id }) => id !== payload.id && !childActivityIds.has(id))
        : [
            ...state.selected,
            payload,
            ...(state.childActivities[payload.id] ?? []).filter(
              ({ id }) => !alreadySelectedIds.has(id)
            ),
          ],
    };
  })
  .handleAction(toggleSelectAll, (state) => {
    const areAllSelected =
      Object.values(state.childActivities).reduce(
        (sum, items) => sum + items.length,
        state.list.length
      ) === state.selected.length;
    return {
      ...state,
      selected: areAllSelected
        ? []
        : [...state.list, ...Object.values(state.childActivities).flat()],
    };
  })
  .handleAction(deselectAll, (state) => ({
    ...state,
    selected: [],
  }))
  .handleAction(resetSelectedActivities, (state) => ({
    ...state,
    selected: [],
  }))
  .handleAction(fetchList.request, (state) => ({
    ...state,
    error: undefined,
    loading: true,
  }))
  .handleAction(fetchList.success, (state, action) => ({
    ...state,
    list: action.payload.list,
    loading: false,
    selected: [],
    totalFilteredRecords: action.payload.totalFilteredRecords,
    totalRecords: action.payload.totalRecords,
  }))
  .handleAction(uploadActivityFiles.request, (state) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadActivityFiles.success, (state, action) => ({
    ...state,
    filesAdded: [...state.filesAdded, ...action.payload],
    filesUploading: false,
  }))
  .handleAction(uploadActivityFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(removeActivityFile.request, (state) => ({ ...state }))
  .handleAction(removeActivityFile.success, (state, action) => ({
    ...state,
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== action.payload.file),
  }))

  .handleAction(removeActivityFile.failure, (state) => ({
    ...state,
  }))
  .handleAction(fetchList.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false,
  }))
  .handleAction(updateActivity.request, (state, { payload }) => ({
    ...state,
    updateActivityLoading: state.updateActivityLoading.concat(payload.id),
  }))
  .handleAction(updateActivity.success, (state, { payload }) => {
    return {
      ...state,
      list: state.list.map((item) => (item.id === payload.id ? { ...item, ...payload } : item)),
      updateActivityLoading: state.updateActivityLoading.filter((id) => id !== payload.id),
    };
  })
  .handleAction(updateActivity.failure, (state, action) => ({
    ...state,
    updateActivityLoading: state.updateActivityLoading.filter((id) => id !== action.payload),
  }))
  .handleAction(bulkPostpone.request, (state) => ({
    ...state,
    bulkPostponeLoading: true,
  }))
  .handleAction(bulkPostpone.success, (state, action) => {
    const listUpdater = listUpdaterFactory(action.payload);
    return {
      ...state,
      bulkPostponeLoading: false,
      list: state.list.map(listUpdater),
      selected: state.selected.map(listUpdater),
    };
  })
  .handleAction(bulkPostpone.failure, (state) => ({
    ...state,
    bulkPostponeLoading: false,
  }))
  .handleAction(toggleComplete.request, (state, action) => ({
    ...state,
    toggleCompleteLoading: state.toggleCompleteLoading.concat(action.payload.id),
  }))
  .handleAction(toggleComplete.success, (state, { payload }) => ({
    ...state,
    list: state.list.map((item) => (item.id === payload.id ? { ...item, ...payload } : item)),
    toggleCompleteLoading: state.toggleCompleteLoading.filter((id) => id !== payload.id),
  }))
  .handleAction(toggleComplete.failure, (state, action) => ({
    ...state,
    toggleCompleteLoading: state.toggleCompleteLoading.filter(
      (loadingId) => loadingId !== action.payload
    ),
  }))
  .handleAction(bulkToggleComplete.request, (state) => ({
    ...state,
    bulkToggleCompleteLoading: true,
  }))
  .handleAction(bulkToggleComplete.success, (state, action) => {
    const listUpdater = listUpdaterFactory(action.payload);
    return {
      ...state,
      bulkToggleCompleteLoading: false,
      list: state.list.map(listUpdater),
      selected: state.selected.map(listUpdater),
    };
  })
  .handleAction(bulkToggleComplete.failure, (state) => ({
    ...state,
    bulkToggleCompleteLoading: false,
  }))
  .handleAction(openDuplicationModal.request, (state, action) => ({
    ...state,
    openDuplicationModalLoading: state.openDuplicationModalLoading.concat(action.payload),
  }))
  .handleAction(openDuplicationModal.success, (state, action) => ({
    ...state,
    openDuplicationModalLoading: state.openDuplicationModalLoading.filter(
      (loadingId) => loadingId !== action.payload
    ),
  }))
  .handleAction(openDuplicationModal.failure, (state, action) => ({
    ...state,
    openDuplicationModalLoading: state.openDuplicationModalLoading.filter(
      (loadingId) => loadingId !== action.payload
    ),
  }))
  .handleAction(deleteActivity.request, (state, action) => ({
    ...state,
    deleteLoading: state.deleteLoading.concat(action.payload.id),
  }))
  .handleAction(deleteActivity.success, (state, action) => ({
    ...state,
    deleteLoading: state.deleteLoading.filter((loadingId) => loadingId !== action.payload),
  }))
  .handleAction(deleteActivity.failure, (state, action) => ({
    ...state,
    deleteLoading: state.deleteLoading.filter((loadingId) => loadingId !== action.payload),
  }))
  .handleAction(bulkDeleteActivities.request, (state) => ({
    ...state,
    bulkDeleteLoading: true,
  }))
  .handleAction(bulkDeleteActivities.success, (state) => ({
    ...state,
    bulkDeleteLoading: false,
  }))
  .handleAction(bulkDeleteActivities.failure, (state) => ({
    ...state,
    bulkDeleteLoading: false,
  }))
  .handleAction(bulkEdit.request, (state) => ({
    ...state,
    bulkEditLoading: true,
  }))
  .handleAction(bulkEdit.success, (state, action) => {
    const listUpdater = listUpdaterFactory(action.payload);
    return {
      ...state,
      activityAssignee: undefined,
      activityType: undefined,
      activityVisibility: undefined,
      activityVisibilityTeamIds: undefined,
      bulkEditLoading: false,
      list: state.list.map(listUpdater),
      selected: state.selected.map(listUpdater),
    };
  })
  .handleAction(bulkEdit.failure, (state) => ({
    ...state,
    bulkEditLoading: false,
  }))
  .handleAction(updateNote.request, (state, action) => ({
    ...state,
    updateNoteLoading: state.updateNoteLoading.concat(action.payload.activity.id),
  }))
  .handleAction(updateNote.success, (state, action) => ({
    ...state,
    list: state.list.map((item) => {
      if (item.id === action.payload.id) {
        return {
          ...item,
          note: action.payload.note,
        };
      }

      return item;
    }),
    updateNoteLoading: state.updateNoteLoading.filter(
      (loadingId) => loadingId !== action.payload.id
    ),
  }))
  .handleAction(updateNote.failure, (state, action) => ({
    ...state,
    updateNoteLoading: state.updateNoteLoading.filter((loadingId) => loadingId !== action.payload),
  }))
  .handleAction(changeActivityAssignee, (state, action) => ({
    ...state,
    activityAssignee: action.payload,
  }))
  .handleAction(fetchFilePreview.request, (state, action) => ({
    ...state,
    filePreviewId: action.payload.file,
    filePreviewLoading: true,
  }))
  .handleAction(fetchFilePreview.success, (state, action) => ({
    ...state,
    filePreview: action.payload,
    filePreviewLoading: false,
  }))
  .handleAction(fetchFilePreview.failure, (state) => ({
    ...state,
    filePreviewLoading: false,
  }))
  .handleAction(changeActivityVisibilityTeamsIds, (state, action) => ({
    ...state,
    activityVisibilityTeamIds: action.payload,
  }))
  .handleAction(changeActivityType, (state, action) => ({
    ...state,
    activityType: action.payload,
  }))
  .handleAction(changeActivityVisibility, (state, action) => ({
    ...state,
    activityVisibility: action.payload,
  }))
  .handleAction(subscribeOnViewStateUpdating, (state, { payload: { callback } }) => ({
    ...state,
    onViewStateChanged: callback,
  }))
  .handleAction(unsubscribeOnViewStateUpdating, (state) => ({
    ...state,
    onViewStateChanged: undefined,
  }))
  .handleAction(fetchChildActivities.success, (state, { payload }) => {
    if (!payload) {
      return state;
    }
    return {
      ...state,
      childActivities: { ...state.childActivities, [payload.parentActivityId]: payload.activities },
      selected: payload.preselect ? [...state.selected, ...payload.activities] : state.selected,
    };
  });

export type ActivityActions = Actions;
export default activity;

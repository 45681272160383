import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const activityAnnotationState = (state: RootState) => state.component.activityAnnotation;

export const isAnnotationDataLoading = createSelector(
  activityAnnotationState,
  ({ annotationDataLoading }) => annotationDataLoading
);

export const isActivitiesLoading = createSelector(
  activityAnnotationState,
  ({ activitiesLoading }) => activitiesLoading
);

export const isAnnotationDataHasNoAccess = createSelector(
  activityAnnotationState,
  ({ annotationDataHasNoAccess }) => annotationDataHasNoAccess
);

export const isActivitiesHasNoAccess = createSelector(
  activityAnnotationState,
  ({ activitiesHasNoAccess }) => activitiesHasNoAccess
);

export const getActivities = createSelector(
  activityAnnotationState,
  ({ activities }) => activities
);

export const isMultipleActivities = createSelector(
  activityAnnotationState,
  ({ activities }) => activities.length > 1
);

export const getAnnotationData = createSelector(
  activityAnnotationState,
  ({ annotationData }) => annotationData
);

export const isToggleCompleteLoading = createSelector(
  activityAnnotationState,
  ({ toggleCompleteLoading }) => toggleCompleteLoading
);

export const isPostponeLoading = createSelector(
  activityAnnotationState,
  ({ postponeLoading }) => postponeLoading
);

export const areFilesUploading = createSelector(
  activityAnnotationState,
  ({ filesUploading }) => filesUploading
);

export const getActivity = createSelector(
  activityAnnotationState,
  ({ annotationData }) => annotationData?.activity
);

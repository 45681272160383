import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import { StateType } from "typesafe-actions";

import component, { ComponentActions } from "../component/componentReducer";
import scene, { SceneActions } from "../scene/sceneReducer";

import activity, { ActivityActions } from "./activity";
import api, { ApiActions } from "./api";
import app, { AppActions } from "./app";
import auth, { AuthActions } from "./auth";
import { CompanyActions } from "./company";
import cumul, { CumulActions } from "./cumul";
import customFields, { CustomFieldsActions } from "./customFields";
import deal, { DealActions } from "./deal";
import dealLossReasons, { DealLossReasonsActions } from "./dealLossReasons";
import email, { EmailActions } from "./email";
import entityView, { EntityViewActions } from "./entityView";
import { ErrorActions } from "./errors";
import files, { FilesActions } from "./files";
import frequency, { FrequencyActions } from "./frequency";
import globalSearch, { GlobalSearchActions } from "./globalSearch";
import groups, { GroupsActions } from "./groups";
import iam, { IamActions } from "./iam";
import integrations, { IntegrationActions } from "./integrations";
import layout, { LayoutActions } from "./layout";
import locale, { LocaleActions } from "./locale";
import map, { MapActions } from "./map";
import members, { MembersActions } from "./members";
import notification, { NotificationsActions } from "./notification";
import nylas, { NylasActions } from "./nylas";
import { OnboardingActions } from "./onboarding";
import { PersonActions } from "./person";
import pinLegends, { PinLegendActions } from "./pinLegends";
import recordPreview, { RecordPreviewActions } from "./recordPreview";
import referenceData, { ReferenceDataActions } from "./referenceData";
import routes, { RoutesActions } from "./routes";
import savedFilters, { SavedFiltersActions } from "./savedFilters";
import schema, { SchemaActions } from "./schema";
import territories, { TerritoriesActions } from "./territories";
import theme, { ThemeActions } from "./theme";
import uiSync, { UiSyncActions } from "./uiSync";
import userColor, { UserColorActions } from "./userColor";

const createRootReducer = (history: History) =>
  combineReducers({
    activity,
    api,
    app,
    auth,
    component,
    cumul,
    customFields,
    deal,
    dealLossReasons,
    email,
    entityView,
    files,
    frequency,
    globalSearch,
    groups,
    iam,
    integrations,
    layout,
    locale,
    map,
    members,
    notification,
    nylas,
    pinLegends,
    recordPreview,
    referenceData,
    router: connectRouter(history),
    routes,
    savedFilters,
    scene,
    schema,
    territories,
    theme,
    uiSync,
    userColor,
  });

export type Actions =
  | ActivityActions
  | ApiActions
  | AppActions
  | AuthActions
  | CompanyActions
  | ComponentActions
  | CumulActions
  | CustomFieldsActions
  | DealActions
  | DealLossReasonsActions
  | EmailActions
  | EntityViewActions
  | ErrorActions
  | FilesActions
  | FrequencyActions
  | GlobalSearchActions
  | GroupsActions
  | IamActions
  | IntegrationActions
  | LayoutActions
  | LocaleActions
  | MapActions
  | MembersActions
  | NotificationsActions
  | NylasActions
  | OnboardingActions
  | PersonActions
  | PinLegendActions
  | RecordPreviewActions
  | ReferenceDataActions
  | RoutesActions
  | SavedFiltersActions
  | SceneActions
  | SchemaActions
  | TerritoriesActions
  | ThemeActions
  | UiSyncActions
  | UserColorActions;

declare module "typesafe-actions" {
  interface Types {
    RootAction: Actions;
  }
}

export type ActionsTypes = Actions["type"];

export type RootState = StateType<ReturnType<typeof createRootReducer>>;

export default createRootReducer;

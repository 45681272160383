import React, { FC, ReactNode, useCallback } from "react";

import Button from "antd/es/button";
import Tooltip from "antd/es/tooltip";
import { defineMessages, useIntl } from "react-intl";

import { EntityTypeSupportingDataMerging } from "@mapmycustomers/shared/types/entity";

import styles from "./Footer.module.scss";

const messages = defineMessages({
  cancelButton: {
    id: "createModal.footer.cancel",
    defaultMessage: "Cancel",
    description: "Cancel button title on Create modal",
  },
  createAnywayButton: {
    id: "createModal.footer.okAnyway",
    defaultMessage:
      "Create {entityType, select, accounts {Company} contacts {Person} other {record}} anyway",
    description: "Create Anyway button title on Create modal",
  },
  createButton: {
    id: "createModal.footer.ok",
    defaultMessage:
      "Create {entityType, select, accounts {Company} contacts {Person} other {record}}",
    description: "Create button title on Create modal",
  },
  goBackButton: {
    id: "createModal.footer.back",
    defaultMessage: "Go Back",
    description: "Go Back button title on Create modal",
  },
  previewModeTooltip: {
    id: "createModal.footer.previewMode.tooltip",
    defaultMessage:
      "Please exit Preview Mode to create {entityType, select, accounts {company} contacts {person} other {record}}",
    description: "Create modal - OK button tooltip in preview mode",
  },
});

export interface FooterProps {
  creating?: boolean;
  entityType: EntityTypeSupportingDataMerging;
  hasDuplicates?: boolean;
  isPreviewMode?: boolean;
  okDisabled?: boolean;
  okText?: ReactNode;
  onCancel?: () => void;
  onCreate?: (checkDuplicates: boolean) => void;
  onGoBack?: () => void;
}

const Footer: FC<FooterProps> = ({
  creating,
  entityType,
  hasDuplicates,
  isPreviewMode,
  okDisabled,
  okText,
  onCancel,
  onCreate,
  onGoBack,
}) => {
  const intl = useIntl();

  const handleCreateClick = useCallback(() => {
    onCreate?.(!hasDuplicates);
  }, [onCreate, hasDuplicates]);

  return (
    <div className={styles.container}>
      {hasDuplicates && (
        <Button onClick={onGoBack} type="default">
          {intl.formatMessage(messages.goBackButton)}
        </Button>
      )}
      <div className={styles.rightButtons}>
        {hasDuplicates && (
          <Button onClick={onCancel} type="default">
            {intl.formatMessage(messages.cancelButton)}
          </Button>
        )}
        <Tooltip
          placement="topRight"
          title={
            isPreviewMode ? intl.formatMessage(messages.previewModeTooltip, { entityType }) : null
          }
          trigger={isPreviewMode ? ["hover"] : []}
        >
          <Button
            disabled={okDisabled}
            loading={creating}
            onClick={handleCreateClick}
            type="primary"
          >
            {hasDuplicates
              ? intl.formatMessage(messages.createAnywayButton, { entityType })
              : okText ?? intl.formatMessage(messages.createButton, { entityType })}
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default Footer;

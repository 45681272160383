import { getLocation, replace, RouterLocation } from "connected-react-router";
import { all, call, put, race, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { Action, isActionOf } from "typesafe-actions";

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import {
  EntityTypesSupportedByMapsPage,
  EntityTypesSupportingPlatformSavedFilters,
  EntityTypesSupportingSavedFilters,
  MapFilterEntityType,
} from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import User from "@mapmycustomers/shared/types/User";
import FilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import MapFilterModel from "@mapmycustomers/shared/types/viewModel/internalModel/MapFilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import PlatformFilterModel from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import SavedFilter, {
  MapSavedFilter,
  PlatformSavedFilter,
  RegularSavedFilter,
} from "@mapmycustomers/shared/types/viewModel/SavedFilter";
import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import localSettings from "@app/config/LocalSettings";
import { callApi } from "@app/store/api/callApi";
import { getCurrentUserId, getOrganization, getUserMetaData } from "@app/store/iam";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import getFilterIdFromUrl from "@app/util/getFilterIdFromUrl";
import parseDatedFields from "@app/util/parsers/parseDatedFields";
import { convertFromPlatformFilterModel } from "@app/util/viewModel/convertFromPlatformFilterModel";
import convertMapFilterToPlatformFilterModel from "@app/util/viewModel/convertMapFilterToPlatformFilterModel";
import convertMapPlatformFilterToMapFilterModel from "@app/util/viewModel/convertMapPlatformFilterToMapFilterModel";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import { handleError } from "../errors/actions";
import { addFavoriteFilter, removeFavoriteFilter } from "../iam/actions";

import {
  createSavedFilter,
  deleteSavedFilter,
  fetchAllSavedFilters,
  fetchSavedFilters,
  initializeSavedFiltersForView,
  selectSavedFilter,
  updateSavedFilter,
} from "./actions";
import { getSavedFilters } from "./selectors";

const successForEntityTypeActionMatcher =
  (entityType: EntityTypesSupportingSavedFilters) =>
  (action: Action): boolean =>
    isActionOf(fetchSavedFilters.success, action) && action.payload.entityType === entityType;

const convertRegularPlatformSavedFilterToSavedFilter =
  (type: Exclude<EntityTypesSupportingPlatformSavedFilters, EntityType.PIN>) =>
  (
    savedFilter: PlatformSavedFilter<
      Exclude<EntityTypesSupportingPlatformSavedFilters, EntityType.PIN>
    >
  ): RegularSavedFilter =>
    parseDatedFields({
      ...savedFilter,
      filters: convertFromPlatformFilterModel(savedFilter.filters, getFieldModelByEntityType(type)),
      viewAs: savedFilter.filters.viewAs,
    });

const convertMapPlatformSavedFilterToMapSavedFilter = (
  savedFilter: PlatformSavedFilter<EntityType.PIN>
): MapSavedFilter =>
  parseDatedFields({
    ...savedFilter,
    filters: convertMapPlatformFilterToMapFilterModel(savedFilter.filters),
    viewAs: savedFilter.filters.viewAs,
  });

// does the same thing as convertMapFilterToPlatformFilterModel, but doesn't need
// visibleEntities param and doesn't include entities which don't have a filter
const convertMapFilterToSavedFilterPlatformFilterModel = (
  filter: MapFilterModel,
  viewAs: undefined | User["id"]
): PlatformFilterModel =>
  convertMapFilterToPlatformFilterModel(
    filter,
    // include all except "universal" and empty filters
    (Object.keys(filter) as MapFilterEntityType[]).filter(
      (e) => e !== "universal" && filter[e] && Object.keys(filter[e]!).length
    ) as EntityTypesSupportedByMapsPage[],
    viewAs
  );

type SavedFilterConverter<T extends EntityTypesSupportingSavedFilters> = T extends Exclude<
  EntityTypesSupportingPlatformSavedFilters,
  EntityType.PIN
>
  ? (savedFilter: PlatformSavedFilter) => RegularSavedFilter
  : (savedFilter: PlatformSavedFilter) => MapSavedFilter;

const convertPlatformSavedFilterToSavedFilter = (
  type: EntityTypesSupportingSavedFilters
): SavedFilterConverter<typeof type> =>
  // it's ok to have these "as" as we know that only expected saved filters will get into these functions
  type === EntityType.ACTIVITY
    ? (convertRegularPlatformSavedFilterToSavedFilter(type) as (
        savedFilter: PlatformSavedFilter
      ) => RegularSavedFilter)
    : (convertMapPlatformSavedFilterToMapSavedFilter as (
        savedFilter: PlatformSavedFilter
      ) => MapSavedFilter);

export function* onFetchSavedFilters({
  payload: { entityType },
}: ReturnType<typeof fetchSavedFilters.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const currentUserId: User["id"] = yield select(getCurrentUserId);

    const response: ListResponse<PlatformSavedFilter> = yield callApi(
      "fetchSavedFilters",
      organization.id,
      entityType
    );
    const rowMapper = convertPlatformSavedFilterToSavedFilter(entityType);
    const filters = response.data.map<SavedFilter>(rowMapper);

    const savedFilters = filters
      .filter((filter) => filter.valid || filter.user?.id === currentUserId)
      .sort(nameComparator);

    yield put(fetchSavedFilters.success({ entityType, savedFilters }));
  } catch (error) {
    yield put(fetchSavedFilters.failure(entityType));
    yield put(handleError({ error }));
  }
}

export function* onFetchAllSavedFilters() {
  try {
    yield put(fetchSavedFilters.request({ entityType: EntityType.ACTIVITY, initialization: true }));
    yield put(fetchSavedFilters.request({ entityType: EntityType.PIN, initialization: true }));

    const { failure } = yield race({
      failure: take([fetchSavedFilters.failure]),
      success: all([
        take(successForEntityTypeActionMatcher(EntityType.ACTIVITY)),
        take(successForEntityTypeActionMatcher(EntityType.PIN)),
      ]),
    });

    if (failure) {
      yield put(fetchAllSavedFilters.failure());
    } else {
      yield put(fetchAllSavedFilters.success());
    }
  } catch (error) {
    yield put(fetchAllSavedFilters.failure());
    yield put(handleError({ error }));
  }
}

export function* onCreateSavedFilter({ payload }: ReturnType<typeof createSavedFilter.request>) {
  const org: Organization = yield select(getOrganization);
  const { callback, columns, favorite, filter } = payload;
  try {
    const realSavedFilterType = filter.type === EntityType.ACTIVITY ? filter.type : EntityType.PIN;
    const createdFilter: PlatformSavedFilter = yield callApi("createSavedFilter", org.id, {
      ...filter,
      filters:
        filter.type !== EntityType.ACTIVITY
          ? convertMapFilterToSavedFilterPlatformFilterModel(
              filter.filters as MapFilterModel,
              filter.viewAs
            )
          : convertToPlatformFilterModel(
              filter.filters as FilterModel,
              columns,
              getFieldModelByEntityType(filter.type),
              true,
              filter.viewAs
            ),
      type: realSavedFilterType,
    });
    const savedFilter: SavedFilter =
      convertPlatformSavedFilterToSavedFilter(realSavedFilterType)(createdFilter);
    if (favorite) {
      yield put(addFavoriteFilter.request(savedFilter.id));
    }

    yield put(fetchSavedFilters.request({ entityType: realSavedFilterType }));
    callback?.(savedFilter);
    yield put(selectSavedFilter({ entityType: filter.type, savedFilter }));
    yield put(createSavedFilter.success(savedFilter));
  } catch (error) {
    yield put(createSavedFilter.failure());
    yield put(handleError({ error }));
  }
}

export function* onUpdateSavedFilter({ payload }: ReturnType<typeof updateSavedFilter.request>) {
  const org: Organization = yield select(getOrganization);
  const { callback, columns, favorite, filter, preventUpdateSavedFilter } = payload;
  try {
    let saveFilter = filter;
    if (!preventUpdateSavedFilter) {
      const updatedFilter: PlatformSavedFilter<
        Exclude<EntityTypesSupportingSavedFilters, EntityType.PIN>
      > = yield callApi("updateSavedFilter", org.id, {
        id: filter.id,
        filters:
          filter.type === EntityType.PIN
            ? convertMapFilterToSavedFilterPlatformFilterModel(
                filter.filters as MapFilterModel,
                filter.viewAs
              )
            : convertToPlatformFilterModel(
                filter.filters as FilterModel,
                columns,
                getFieldModelByEntityType(filter.type),
                true,
                filter.viewAs
              ),
        name: filter.name,
        teamId: filter?.teamId,
        type: filter.type,
        user: filter?.user,
        visibility: filter.visibility,
      });
      saveFilter = convertPlatformSavedFilterToSavedFilter(filter.type)(updatedFilter);
    }

    if (favorite) {
      yield put(addFavoriteFilter.request(filter.id));
    }
    // favorite can be undefined
    if (favorite === false) {
      yield put(removeFavoriteFilter.request(filter.id));
    }

    yield put(fetchSavedFilters.request({ entityType: filter.type }));
    callback?.(saveFilter);
    yield put(updateSavedFilter.success(saveFilter));
  } catch (error) {
    yield put(updateSavedFilter.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteSavedFilter({
  payload: { id, type },
}: ReturnType<typeof deleteSavedFilter.request>) {
  const org: Organization = yield select(getOrganization);
  try {
    yield callApi("deleteSavedFilter", org.id, id);
    yield put(removeFavoriteFilter.request(id));
    yield put(deleteSavedFilter.success());
    yield put(fetchSavedFilters.request({ entityType: type }));
  } catch (error) {
    yield put(deleteSavedFilter.failure());
    yield put(handleError({ error }));
  }
}

export function* onSelectSavedFilter({
  payload: { savedFilter, skipUrlUpdate },
}: ReturnType<typeof selectSavedFilter>) {
  if (skipUrlUpdate) {
    return;
  }
  const { pathname, query }: RouterLocation<any> = yield select(getLocation);
  const urlParams = new URLSearchParams();
  Object.keys(query).forEach((key) => urlParams.set(key, query[key]));
  if (savedFilter) {
    urlParams.set("filter", savedFilter.id.toString());
  } else {
    urlParams.delete("filter");
  }
  yield put(replace(`${pathname}?${urlParams.toString()}`));
}

export function* onInitializeSavedFiltersForView({
  payload: { entityType, viewKey },
}: ReturnType<typeof initializeSavedFiltersForView>) {
  const savedFiltersGetter: (entityType: EntityType) => SavedFilter[] = yield select(
    getSavedFilters
  );
  const savedFilters = savedFiltersGetter(entityType);

  const urlFilterId = getFilterIdFromUrl();
  const savedFilterFromUrl = savedFilters.find(({ id, valid }) => id === urlFilterId && valid);
  if (savedFilterFromUrl) {
    yield put(selectSavedFilter({ entityType, savedFilter: savedFilterFromUrl }));
    return;
  }

  let selectedSavedFilterId: SavedFilter["id"] | undefined;
  if (entityType === EntityType.PIN) {
    const metaData: User["metaData"] = yield select(getUserMetaData);
    selectedSavedFilterId = metaData.mapViewSettings?.selectedSavedFilterId;
  } else {
    const viewSettings: ViewState = yield call(
      localSettings.getViewSettings,
      viewKey,
      getFieldModelByEntityType(entityType)
    );
    selectedSavedFilterId = viewSettings.selectedSavedFilterId;
  }
  if (selectedSavedFilterId) {
    const savedFilter = savedFilters.find(({ id, valid }) => id === selectedSavedFilterId && valid);
    if (savedFilter) {
      yield put(selectSavedFilter({ entityType, savedFilter }));
    } else {
      // In case if filter isn't accessible anymore (has field access restrictions or deleted),
      // then clean up stored references to that filter
      yield call(clearSelectedSavedFilter, entityType, viewKey);
    }
  }
}

export function* clearSelectedSavedFilter(
  entityType: EntityTypesSupportingSavedFilters,
  viewKey: string
) {
  if (entityType !== EntityType.PIN) {
    const viewSettings: ViewState = yield call(
      localSettings.getViewSettings,
      viewKey,
      getFieldModelByEntityType(entityType)
    );

    localSettings.setViewSettings(
      {
        ...viewSettings,
        filter: {},
        selectedSavedFilterId: undefined,
      },
      viewKey
    );
  }
  yield put(selectSavedFilter({ entityType, savedFilter: undefined }));
}

export function* savedFiltersSaga() {
  yield takeLatest(createSavedFilter.request, onCreateSavedFilter);
  yield takeLatest(updateSavedFilter.request, onUpdateSavedFilter);
  yield takeLatest(deleteSavedFilter.request, onDeleteSavedFilter);
  yield takeEvery(fetchSavedFilters.request, onFetchSavedFilters);
  yield takeLatest(fetchAllSavedFilters.request, onFetchAllSavedFilters);
  yield takeLatest(selectSavedFilter, onSelectSavedFilter);
  yield takeLatest(initializeSavedFiltersForView, onInitializeSavedFiltersForView);
}

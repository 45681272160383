import { createReducer } from "typesafe-actions";

import EmailUnsubscribeInfo from "@mapmycustomers/shared/types/email/EmailUnsubscribeInfo";

import { Actions, loadEmailUnsubscribeInfo, unsubscribeEmail } from "./actions";

export interface EmailUnsubscribeState {
  unsubscribeCode?: string;
  unsubscribeInfo?: EmailUnsubscribeInfo;
  unsubscribing: boolean;
}

const initialState: EmailUnsubscribeState = {
  unsubscribing: false,
};

const emailUnsubscribe = createReducer<EmailUnsubscribeState, Actions>(initialState)
  .handleAction(loadEmailUnsubscribeInfo.request, (_, { payload: { code } }) => ({
    ...initialState,
    unsubscribeCode: code,
  }))
  .handleAction(loadEmailUnsubscribeInfo.success, (state, { payload }) => ({
    ...state,
    unsubscribeInfo: payload,
  }))
  .handleAction(unsubscribeEmail.request, (state) => ({
    ...state,
    unsubscribing: true,
  }))
  .handleAction(unsubscribeEmail.success, (state) => ({
    ...state,
    unsubscribeInfo: {
      ...state.unsubscribeInfo!,
      subscribed: false,
    },
    unsubscribing: false,
  }))
  .handleAction(unsubscribeEmail.failure, (state) => ({
    ...state,
    unsubscribing: false,
  }));

export type EmailUnsubscribeActions = Actions;

export default emailUnsubscribe;

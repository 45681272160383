import { defineMessages } from "react-intl";

const messages = defineMessages({
  no: {
    id: "associations.personAssociationRow.info.replacePrimary.no",
    defaultMessage: "No",
    description: "No button",
  },
  pinnedFields: {
    id: "associations.personAssociationRow.pinnedFields.title",
    defaultMessage: "Pinned Fields",
    description: "Pinned Fields popover title",
  },
  primary: {
    id: "associations.personAssociationRow.info.primary",
    defaultMessage: "Primary",
    description: "Primary Record Tag",
  },
  replacePrimary: {
    id: "associations.personAssociationRow.info.replacePrimary.title",
    defaultMessage:
      "“{primaryEntityName}” will be <b>replaced</b> as the Primary Association. Continue?",
    description: "Replace primary entity confirm modal title",
  },
  replacePrimaryCompanyTooltip: {
    id: "associations.personAssociationRow.info.replacePrimaryCompanyTooltip.tooltip",
    defaultMessage: "Remove as primary company",
    description: "Remove as primary company tooltip",
  },
  replacePrimaryPersonTooltip: {
    id: "associations.personAssociationRow.info.replacePrimaryPersonTooltip.tooltip",
    defaultMessage: "Remove as primary person",
    description: "Remove as primary person tooltip",
  },
  setPrimary: {
    id: "associations.personAssociationRow.info.setPrimary",
    defaultMessage: "Set Primary",
    description: "Set Primary button",
  },
  setPrimaryCompanyTooltip: {
    id: "associations.personAssociationRow.info.setPrimaryCompanyTooltip.tooltip",
    defaultMessage: "Set as primary company",
    description: "Set as primary company tooltip",
  },
  setPrimaryPersonTooltip: {
    id: "associations.personAssociationRow.info.setPrimaryCompanyTooltip.tooltip",
    defaultMessage: "Set as primary person",
    description: "Set as primary person tooltip",
  },
  unsetPrimary: {
    id: "associations.personAssociationRow.info.unsetPrimary",
    defaultMessage: "Unset Primary",
    description: "Unset Primary button",
  },
  yes: {
    id: "associations.personAssociationRow.info.replacePrimary.yes",
    defaultMessage: "Yes",
    description: "Yes button",
  },
});

export default messages;

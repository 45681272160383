import DateRangeType from "@mapmycustomers/shared/enum/DateRangeType";
import LeaderboardMetricFieldName from "@mapmycustomers/shared/enum/fieldModel/LeaderboardMetricFieldName";
import { StandardRange } from "@mapmycustomers/shared/types/range/DateRange";

import type { LocalSettings } from "@app/config/LocalSettings";
import localSettings from "@app/config/LocalSettings";
import TimelineEventType from "@app/scene/dashboard/enum/TimelineEventType";
import leaderboardFieldModel from "@app/util/fieldModel/LeaderboardFieldModel";
import { parseApiDate } from "@app/util/parsers";
import standardRangeToDateRange from "@app/util/range/standardRangeToDateRange";

import StackRankViewState from "../store/stackRank/StackRankViewState";

class DashboardSettings {
  settings: LocalSettings;

  private readonly VISIBLE_EVENT_TYPES = "__DASHBOARD_VISIBLE_EVENT_TYPES__";

  //  stack rank view state
  private readonly STACK_RANK_VIEW_STATE = "__STACK_RANK_VIEW_STATE__";

  constructor(settings: LocalSettings) {
    this.settings = settings;
  }

  getVisibleEventTypes = (): TimelineEventType[] => {
    const json = this.settings.getValue(this.VISIBLE_EVENT_TYPES);
    const allTypes = Object.values(TimelineEventType);
    try {
      let types = json ? JSON.parse(json as string) : allTypes;
      types = Array.isArray(types) ? types.filter((type) => allTypes.includes(type)) : types;
      return !Array.isArray(types) || !types.length ? allTypes : types;
    } catch {
      return allTypes;
    }
  };

  setVisibleEventTypes = (eventTypes: TimelineEventType[]) => {
    this.settings.setValue(this.VISIBLE_EVENT_TYPES, JSON.stringify(eventTypes));
  };

  getStackRankViewState = (): StackRankViewState | undefined => {
    const json = localStorage.getItem(this.STACK_RANK_VIEW_STATE);
    if (!json) {
      return undefined;
    }

    try {
      const viewState: Omit<StackRankViewState, "rankingMetric"> & {
        rankingMetric: LeaderboardMetricFieldName;
      } = JSON.parse(json);
      const rankingMetric = leaderboardFieldModel.getByName(viewState.rankingMetric)!;

      if (viewState.dateRangeType === DateRangeType.CUSTOM) {
        if (typeof viewState.dateRange.startDate === "string") {
          // both these start and end fields can be strings after parsing json
          // converting them back in Dates here
          viewState.dateRange.startDate = parseApiDate(viewState.dateRange.startDate);
        }
        if (typeof viewState.dateRange.endDate === "string") {
          viewState.dateRange.endDate = parseApiDate(viewState.dateRange.endDate);
        }
      } else {
        viewState.dateRange =
          standardRangeToDateRange(viewState.dateRangeType as StandardRange) ??
          standardRangeToDateRange(`${DateRangeType.WEEK}0`)!;
      }
      return { ...viewState, rankingMetric };
    } catch {
      return undefined;
    }
  };

  setStackRankViewState = (viewState: StackRankViewState) => {
    localStorage.setItem(
      this.STACK_RANK_VIEW_STATE,
      JSON.stringify({ ...viewState, rankingMetric: viewState.rankingMetric.name })
    );
  };
}

const dashboardSettings = new DashboardSettings(localSettings);

export default dashboardSettings;

import { isEqual, uniqBy } from "lodash-es";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { PinnedFieldsVisibility } from "@mapmycustomers/shared";
import FieldFeature from "@mapmycustomers/shared/enum/fieldModel/FieldFeature";
import SchemaFieldCategory from "@mapmycustomers/shared/enum/SchemaFieldCategory";
import Visibility from "@mapmycustomers/shared/enum/Visibility";
import {
  EntityType,
  EntityTypesSupportingFieldCustomization,
} from "@mapmycustomers/shared/types/entity";
import { FieldDescription } from "@mapmycustomers/shared/types/entity";
import { MapPersistedEntityColumns } from "@mapmycustomers/shared/types/map/types";
import Organization, { OrganizationMetaData } from "@mapmycustomers/shared/types/Organization";
import SchemaField from "@mapmycustomers/shared/types/schema/SchemaField";
import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";

import { handleError } from "@app/store/errors/actions";
import {
  getCurrentUser,
  getOrganization,
  getOrganizationId,
  getOrganizationMetaData,
  isCurrentUserOwner,
} from "@app/store/iam";
import { updateMetadata, updateOrganizationMetadata } from "@app/store/iam/actions";
import { getTeams } from "@app/store/members";
import { fetchTeams, updateTeam } from "@app/store/members/actions";
import { getEntitySchemaFields } from "@app/store/schema";
import { displayOrderComparator } from "@app/util/comparator";
import getFieldModelByEntityType from "@app/util/fieldModel/getByEntityType";
import { PersonFieldName } from "@app/util/fieldModel/PersonFieldModel";
import { MAP_ENTITY_TYPES } from "@app/util/map/consts";

import { callApi } from "../api/callApi";
import { isOwner } from "../iam/util";

import { setRecordsPreviewConfiguration, updateRecordsPreviewConfiguration } from "./actions";
import EntityColumnsConfiguration from "./EntityColumnsConfiguration";
import { getRecordPreviewConfiguration } from "./selectors";
import convertToFieldDescription from "./util/convertToFieldDescription";
import getMapPersistedEntityColumns from "./util/getMapPersistedEntityColumns";

const getOldConfiguration = (columns: MapPersistedEntityColumns["fields"]) => ({
  [EntityType.COMPANY]: columns[EntityType.COMPANY].map(({ fieldName }) => fieldName),
  [EntityType.DEAL]: columns[EntityType.DEAL].map(({ fieldName }) => fieldName),
  [EntityType.PERSON]: columns[EntityType.PERSON].map(({ fieldName }) => fieldName),
});

const isFieldDisabled = (user: User, visibility: PinnedFieldsVisibility) => {
  return !isOwner(user) && visibility !== Visibility.PRIVATE;
};

export function* onUpdateRecordsPreviewConfiguration({
  payload,
}: ReturnType<typeof updateRecordsPreviewConfiguration.request>) {
  try {
    const owner: boolean = yield select(isCurrentUserOwner);
    const orgId: Organization["id"] = yield select(getOrganizationId);

    const configuration: EntityColumnsConfiguration = yield select(getRecordPreviewConfiguration);

    if (owner) {
      const organization: Organization = yield select(getOrganization);

      const existingOrgConfiguration = getMapPersistedEntityColumns(
        configuration,
        Visibility.SHARED_WITH_ORGANIZATION
      );
      const updatedOrgConfiguration = getMapPersistedEntityColumns(
        payload.configuration,
        Visibility.SHARED_WITH_ORGANIZATION
      );

      if (!isEqual(existingOrgConfiguration, updatedOrgConfiguration)) {
        const metaData = {
          ...organization.metaData,
          mapConfiguration: {
            ...organization.metaData?.mapConfiguration,
            ...getOldConfiguration(updatedOrgConfiguration),
            fields: updatedOrgConfiguration,
          },
        };

        const organizationUpdatePayload: Pick<Organization, "id" | "metaData"> = {
          id: organization.id,
          metaData,
        };
        const updatedOrganization: Organization = yield callApi(
          "updateOrganization",
          organizationUpdatePayload
        );

        yield put(updateOrganizationMetadata.success(updatedOrganization));
      }
    }

    if (owner) {
      const teams: Team[] = yield select(getTeams);
      const updatedTeams: Team[] = [];

      for (const team of teams) {
        const existingConfiguration: MapPersistedEntityColumns["fields"] =
          getMapPersistedEntityColumns(configuration, Visibility.SHARED_WITH_TEAM, team.id);

        const updatedConfiguration: MapPersistedEntityColumns["fields"] =
          getMapPersistedEntityColumns(payload.configuration, Visibility.SHARED_WITH_TEAM, team.id);

        if (!isEqual(existingConfiguration, updatedConfiguration)) {
          updatedTeams.push({
            ...team,
            metaData: {
              ...team.metaData,
              mapConfiguration: {
                ...team.metaData?.mapConfiguration,
                ...getOldConfiguration(updatedConfiguration),
                fields: updatedConfiguration,
              },
            },
          });
        }
      }

      for (const team of updatedTeams) {
        const response: Team = yield callApi("updateTeam", orgId, team);
        // Platform doesn't return this information in PUT response, so we need to copy it manually,
        // otherwise just visible team becomes invisible to user
        response.viaTeamAccess = team.viaTeamAccess;
        yield put(updateTeam.success(response));
      }

      if (updatedTeams.length) {
        yield put(fetchTeams.request());
      }
    }

    const existingUserMetaData = getMapPersistedEntityColumns(configuration, Visibility.PRIVATE);
    const updatedUserMetaData = getMapPersistedEntityColumns(
      payload.configuration,
      Visibility.PRIVATE
    );

    if (!isEqual(existingUserMetaData, updatedUserMetaData)) {
      const user: User = yield select(getCurrentUser);

      const mapConfiguration: Partial<MapPersistedEntityColumns> = {
        ...user.metaData.mapConfiguration,
        ...getOldConfiguration(updatedUserMetaData),
        // New Config
        fields: updatedUserMetaData,
      };
      const updatedUser: User = yield callApi("updateMe", {
        id: user.id,
        metaData: {
          mapConfiguration: mapConfiguration,
        },
      });
      yield put(updateMetadata.success(updatedUser.metaData));
    }

    // Update list configuration to reflect changes on display
    yield call(buildRecordsPreviewConfiguration);

    if (payload.successCallback) {
      yield call(payload.successCallback);
    }
    yield put(updateRecordsPreviewConfiguration.success());
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* buildRecordsPreviewConfiguration() {
  try {
    const orgMetadata: OrganizationMetaData = yield select(getOrganizationMetaData);
    const orgMetadataMapConfiguration = orgMetadata?.mapConfiguration ?? {};

    const currentUser: User = yield select(getCurrentUser);
    const userMetadataMapConfiguration = currentUser?.metaData?.mapConfiguration ?? {};

    const teams: Team[] = yield select(getTeams);

    const teamsMetadataMapConfiguration = teams.map(
      ({ metaData }) => metaData?.mapConfiguration ?? {}
    );

    const fieldsGetter: (entityType: EntityTypesSupportingFieldCustomization) => SchemaField[] =
      yield select(getEntitySchemaFields);

    const pinnedFields = MAP_ENTITY_TYPES.reduce<MapPersistedEntityColumns>(
      (result, entityType) => ({
        ...result,
        fields: {
          ...result.fields,
          [entityType]: getFieldModelByEntityType(entityType)
            .sortedFields.filter((modelField) => {
              if (entityType === EntityType.PERSON && modelField.name === PersonFieldName.NAME) {
                return false;
              }

              if (modelField.hasFeature(FieldFeature.MAP_PINNED_FIELD)) {
                return !(fieldsGetter?.(entityType) ?? []).find(
                  (schemaField) =>
                    schemaField.category === SchemaFieldCategory.SYSTEM_REQUIRED &&
                    modelField.platformName === schemaField.field
                );
              }

              return false;
            })
            .map((field, index) => ({
              displayOrder: index,
              fieldName: field.name,
            })),
        },
      }),
      {
        [EntityType.COMPANY]: [],
        [EntityType.DEAL]: [],
        [EntityType.PERSON]: [],
        fields: {
          actions: false,
          engagement: false,
          [EntityType.COMPANY]: [],
          [EntityType.DEAL]: [],
          [EntityType.PERSON]: [],
        },
      }
    );

    const quickActionsVisibility =
      orgMetadataMapConfiguration?.fields?.actions ??
      orgMetadataMapConfiguration?.actions?.[EntityType.COMPANY]
        ? Visibility.SHARED_WITH_ORGANIZATION
        : teamsMetadataMapConfiguration.some(
            (config) => config.fields?.actions ?? config.actions?.[EntityType.COMPANY]
          )
        ? Visibility.SHARED_WITH_TEAM
        : userMetadataMapConfiguration?.fields?.actions ??
          userMetadataMapConfiguration?.actions?.[EntityType.COMPANY]
        ? Visibility.PRIVATE
        : undefined;

    const quickActionsTeamIds =
      quickActionsVisibility === Visibility.SHARED_WITH_TEAM
        ? teams
            .filter(
              ({ metaData }) =>
                metaData?.mapConfiguration?.fields?.actions ??
                metaData?.mapConfiguration?.actions?.[EntityType.COMPANY]
            )
            .map(({ id }) => id)
        : undefined;

    const engagementVisibility =
      orgMetadataMapConfiguration?.fields?.engagement ??
      orgMetadataMapConfiguration?.engagement?.[EntityType.COMPANY]
        ? Visibility.SHARED_WITH_ORGANIZATION
        : teamsMetadataMapConfiguration.some(
            (config) => config.fields?.engagement ?? config.engagement?.[EntityType.COMPANY]
          )
        ? Visibility.SHARED_WITH_TEAM
        : userMetadataMapConfiguration?.fields?.engagement ??
          userMetadataMapConfiguration?.engagement?.[EntityType.COMPANY]
        ? Visibility.PRIVATE
        : undefined;

    const engagementTeamIds =
      engagementVisibility === Visibility.SHARED_WITH_TEAM
        ? teams
            .filter(
              ({ metaData }) =>
                metaData?.mapConfiguration?.fields?.engagement ??
                metaData?.mapConfiguration?.engagement?.[EntityType.COMPANY]
            )
            .map(({ id }) => id)
        : undefined;

    // Reading org level configuration
    const orgConfiguration: Omit<EntityColumnsConfiguration, "actions" | "engagement"> = {
      [EntityType.COMPANY]: [],
      [EntityType.DEAL]: [],
      [EntityType.PERSON]: [],
      ...MAP_ENTITY_TYPES.reduce((result, entityType) => {
        const fields = (
          orgMetadataMapConfiguration.fields?.[entityType] ??
          orgMetadataMapConfiguration?.[entityType] ??
          []
        ).map(convertToFieldDescription(0));
        return {
          ...result,
          [entityType]: (fields.length
            ? uniqBy([...pinnedFields.fields[entityType], ...fields], "fieldName")
            : pinnedFields.fields[entityType]
          )
            .filter(({ fieldName }) => !!getFieldModelByEntityType(entityType).getByName(fieldName))
            .map(({ displayOrder, fieldName }) => ({
              disabled: isFieldDisabled(currentUser, Visibility.SHARED_WITH_ORGANIZATION),
              displayOrder,
              field: getFieldModelByEntityType(entityType).getByName(fieldName),
              visibility: Visibility.SHARED_WITH_ORGANIZATION,
            })),
        };
      }, {}),
    };

    const teamConfiguration: Omit<EntityColumnsConfiguration, "actions" | "engagement"> = {
      [EntityType.COMPANY]: [],
      [EntityType.DEAL]: [],
      [EntityType.PERSON]: [],
      ...MAP_ENTITY_TYPES.reduce((result, entityType) => {
        const teamFields: FieldDescription[] = teams.reduce((result, { metaData }) => {
          const fields = (
            metaData?.mapConfiguration?.fields?.[entityType] ??
            metaData?.mapConfiguration?.[entityType] ??
            []
          ).map(
            convertToFieldDescription(
              (orgConfiguration[entityType].at(-1)?.displayOrder ?? 0) + result.length
            )
          );
          return uniqBy([...result, ...fields], "fieldName");
        }, [] as FieldDescription[]);
        return {
          ...result,
          [entityType]: teamFields
            .filter(({ fieldName }) => {
              const field = getFieldModelByEntityType(entityType).getByName(fieldName);

              if (field) {
                return !orgConfiguration[entityType]?.map(({ field }) => field).includes(field);
              }

              return false;
            })
            .map(({ displayOrder, fieldName }) => {
              const teamIds = teams
                .filter(({ metaData }) =>
                  (
                    metaData?.mapConfiguration?.fields?.[entityType] ??
                    metaData?.mapConfiguration?.[entityType] ??
                    []
                  )
                    .map(convertToFieldDescription(0))
                    .some(({ fieldName: name }) => name === fieldName)
                )
                .map(({ id }) => id);
              return {
                disabled: isFieldDisabled(currentUser, Visibility.SHARED_WITH_TEAM),
                displayOrder,
                field: getFieldModelByEntityType(entityType).getByName(fieldName),
                teamIds,
                visibility: Visibility.SHARED_WITH_TEAM,
              };
            }),
        };
      }, {}),
    };

    // Reading user level configuration
    const userConfiguration: Omit<EntityColumnsConfiguration, "actions" | "engagement"> = {
      [EntityType.COMPANY]: [],
      [EntityType.DEAL]: [],
      [EntityType.PERSON]: [],
      ...MAP_ENTITY_TYPES.reduce((result, entityType) => {
        const fields = (
          userMetadataMapConfiguration.fields?.[entityType] ??
          userMetadataMapConfiguration?.[entityType] ??
          []
        ).map(convertToFieldDescription(teamConfiguration[entityType].at(-1)?.displayOrder ?? 0));
        return {
          ...result,
          [entityType]: uniqBy(fields, "fieldName")
            .filter(({ fieldName }) => {
              const field = getFieldModelByEntityType(entityType).getByName(fieldName);

              if (field) {
                return !(
                  orgConfiguration[entityType]?.map(({ field }) => field).includes(field) ||
                  teamConfiguration[entityType]?.map(({ field }) => field).includes(field)
                );
              }

              return false;
            })
            .map(({ displayOrder, fieldName }) => ({
              disabled: false,
              displayOrder,
              field: getFieldModelByEntityType(entityType).getByName(fieldName),
              visibility: Visibility.PRIVATE,
            }))
            .sort(displayOrderComparator),
        };
      }, {}),
    };

    const config: EntityColumnsConfiguration = {
      actions: {
        teamIds: quickActionsTeamIds,
        visibility: quickActionsVisibility,
      },
      engagement: {
        teamIds: engagementTeamIds,
        visibility: engagementVisibility,
      },
      [EntityType.COMPANY]: [
        ...orgConfiguration[EntityType.COMPANY],
        ...teamConfiguration[EntityType.COMPANY],
        ...(isOwner(currentUser) ? userConfiguration[EntityType.COMPANY] : []),
      ]
        .sort(displayOrderComparator)
        .concat(!isOwner(currentUser) ? userConfiguration[EntityType.COMPANY] : []), // Private fields always below fields set by owner
      [EntityType.DEAL]: [
        ...orgConfiguration[EntityType.DEAL],
        ...teamConfiguration[EntityType.DEAL],
        ...(isOwner(currentUser) ? userConfiguration[EntityType.DEAL] : []),
      ]
        .sort(displayOrderComparator)
        .concat(!isOwner(currentUser) ? userConfiguration[EntityType.DEAL] : []),
      [EntityType.PERSON]: [
        ...orgConfiguration[EntityType.PERSON],
        ...teamConfiguration[EntityType.PERSON],
        ...(isOwner(currentUser) ? userConfiguration[EntityType.PERSON] : []),
      ]
        .sort(displayOrderComparator)
        .concat(!isOwner(currentUser) ? userConfiguration[EntityType.PERSON] : []),
    };

    yield put(setRecordsPreviewConfiguration(config));
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* recordPreviewSaga() {
  yield takeEvery(updateRecordsPreviewConfiguration.request, onUpdateRecordsPreviewConfiguration);
}

import React, { useCallback } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import { EntityType, Person } from "@mapmycustomers/shared/types/entity";
import { primaryEntityComparator } from "@mapmycustomers/shared/util/comparator";

import Chip from "@app/component/Chip";
import EntityChip from "@app/component/EntityChip";
import { RegistryProps } from "@app/component/FieldGrid/utils/ComponentRegistry";
import ListWithOverflowItem from "@app/component/ListWithOverflowItem";
import { showEntityView } from "@app/store/entityView/actions";

import styles from "./CompaniesField.module.scss";

interface Props extends RegistryProps {
  onShowEntityView: typeof showEntityView;
}

const CompaniesField: React.FC<Props> = ({ entity, field, onShowEntityView }) => {
  const intl = useIntl();
  const companies = field.getValueFor(entity) as Person["accounts"];

  const handleOpenCompany = useCallback(
    ({ entityId, entityType }: { entityId: number; entityType: EntityType }) => {
      onShowEntityView({ entityId, entityType });
    },
    [onShowEntityView]
  );

  const companiesOverflowRenderer = useCallback(
    (count: number) => {
      return (
        <span>
          <Chip type="light">
            {intl.formatMessage(
              {
                id: "fieldGrid.field.companies.overflowRender",
                defaultMessage: "+ {count} more",
                description: "overflow Render for frequency interval",
              },
              { count }
            )}
          </Chip>
        </span>
      );
    },
    [intl]
  );
  const primaryCompanyId = (entity as Person).primaryAccountId ?? undefined;
  return companies ? (
    <ListWithOverflowItem className={styles.container} overflowRender={companiesOverflowRenderer}>
      {companies.sort(primaryEntityComparator(primaryCompanyId)).map((company) => (
        <EntityChip
          entityId={company.id}
          entityType={EntityType.COMPANY}
          key={company.id}
          name={company.name ?? ""}
          onClick={handleOpenCompany}
          primary={company.id === primaryCompanyId}
          tooltip={intl.formatMessage(
            {
              id: "fieldGrid.field.companies.tooltip",
              defaultMessage: "Open {primary, select, true {primary } other{}}company",
              description: "Open company tooltip",
            },
            { primary: company.id === (entity as Person).primaryAccountId }
          )}
        />
      ))}
    </ListWithOverflowItem>
  ) : null;
};

const mapStateToProps = {
  onShowEntityView: showEntityView,
};

export default connect(null, mapStateToProps)(CompaniesField);

import React, { FC, ReactNode, useMemo } from "react";

import type { IntlShape } from "react-intl";

import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import FieldCategory from "@mapmycustomers/shared/enum/fieldModel/FieldCategory";
import { UserRef } from "@mapmycustomers/shared/types/User";

import { MessageKey } from "./messages";
import IConfigProviderContext, {
  FormatNumberOptions,
  PrimitiveType,
} from "./types/IConfigProviderContext";
import ConfigProviderContext from "./utils/ConfigProviderContext";
import defaultMessageFormatter from "./utils/defaultMessageFormatter";
import getDecimalSeparator from "./utils/getDecimalSeparator";

export interface ConfigProviderProps
  extends Pick<
    IConfigProviderContext,
    | "complementFileUrl"
    | "currencies"
    | "dateFnsLocale"
    | "defaultCurrencyCode"
    | "formatCountryName"
    | "formatMessage"
    | "getFieldCategoryDisplayName"
    | "getUserColorClassName"
    | "useFindMyLocation"
  > {
  children: ReactNode;
  intl: IntlShape;
}

const ConfigProvider: FC<ConfigProviderProps> = ({
  children,
  complementFileUrl,
  currencies,
  dateFnsLocale,
  defaultCurrencyCode,
  formatCountryName,
  formatMessage,
  getFieldCategoryDisplayName,
  getUserColorClassName,
  intl,
  useFindMyLocation,
}) => {
  const value: IConfigProviderContext = useMemo(
    () => ({
      complementFileUrl(url?: null | string): string | undefined {
        return complementFileUrl(url);
      },
      currencies,
      dateFnsLocale,
      defaultCurrencyCode,
      formatCountryName(countryCode: CountryCode): string {
        return formatCountryName(countryCode);
      },
      formatMessage(messageId: MessageKey, values?: Record<string, PrimitiveType>) {
        const message = formatMessage(messageId, values);
        if (!message) {
          return defaultMessageFormatter(messageId, values);
        }
        return message;
      },
      formatNumber(
        value: Parameters<Intl.NumberFormat["format"]>[0],
        opts?: FormatNumberOptions
      ): string {
        return intl.formatNumber(value, opts);
      },
      getDecimalSeparator(): string {
        return getDecimalSeparator(intl.locale);
      },
      getFieldCategoryDisplayName(fieldCategory: FieldCategory): string {
        return getFieldCategoryDisplayName(fieldCategory);
      },
      getUserColorClassName(user: UserRef): string | undefined {
        return getUserColorClassName(user);
      },
      useFindMyLocation,
    }),
    [
      complementFileUrl,
      currencies,
      dateFnsLocale,
      defaultCurrencyCode,
      formatCountryName,
      formatMessage,
      getFieldCategoryDisplayName,
      getUserColorClassName,
      intl,
      useFindMyLocation,
    ]
  );

  return <ConfigProviderContext.Provider value={value}>{children}</ConfigProviderContext.Provider>;
};

export default ConfigProvider;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import useFormInstance from "antd/es/form/hooks/useFormInstance";
import { LabeledValue } from "antd/es/select";
import { useIntl } from "react-intl";

import { EntityType } from "@mapmycustomers/shared";
import { Company, Deal, Person } from "@mapmycustomers/shared/types/entity";
import IField from "@mapmycustomers/shared/types/fieldModel/IField";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { EntityTypeShapedIcon, FormItem, SelectField } from "@mapmycustomers/ui";

import DealAssociation from "@app/component/associations/DealAssociation";
import ChangeAssociationsPayload from "@app/store/associations/ChangeAssociationsPayload";
import { fetchDeal } from "@app/store/deal/actions";
import showRelateEntitiesAlert from "@app/util/form/showRelateEntitiesAlert";

import styles from "./CommonRelationshipField.module.scss";
import RelateEntitiesRequestPayload from "./RelateEntitiesRequestPayload";

interface Props {
  allowAdd?: boolean;
  allowClear?: boolean;
  associatedCompany?: Company;
  associatedDeal?: Deal;
  associatedPerson?: Person;
  disabled?: boolean;
  field: IField;
  onChangeAssociatedEntities: (payload: Omit<ChangeAssociationsPayload, "entityType">) => void;
  onFetchDeal: typeof fetchDeal;
  relateEntities?: (payload: RelateEntitiesRequestPayload) => void;
  required?: boolean;
  suggestedDeals?: Deal[];
}

const DealRelationshipField: React.FC<Props> = ({
  allowAdd,
  allowClear,
  associatedCompany,
  associatedDeal,
  associatedPerson,
  disabled,
  field,
  onChangeAssociatedEntities,
  onFetchDeal,
  relateEntities,
  required,
  suggestedDeals,
}) => {
  const form = useFormInstance();
  const intl = useIntl();

  const [modalVisible, showModal, hideModal] = useBoolean();
  const [selectedDeal, setSelectedDeal] = useState(associatedDeal);

  const handleSelect = useCallback(
    (dealIds: Deal["id"][]) => {
      const dealId = dealIds[0];
      onFetchDeal({
        id: dealId,
        callback: (deal: Deal) => {
          setSelectedDeal(deal);
          form.setFieldValue("dealId", dealId);
          onChangeAssociatedEntities({
            assignee: form.getFieldValue("assignee"),
            company: associatedCompany,
            deal,
            person: associatedPerson,
          });

          showRelateEntitiesAlert(intl, associatedCompany, associatedPerson, deal, relateEntities);
        },
        options: { includeUsersWithAccess: true },
      });
    },
    [
      associatedCompany,
      associatedPerson,
      form,
      intl,
      onChangeAssociatedEntities,
      onFetchDeal,
      relateEntities,
    ]
  );

  const handleRemove = useCallback(() => {
    onChangeAssociatedEntities({
      assignee: form.getFieldValue("assignee"),
      company: associatedCompany,
      deal: undefined,
      person: associatedPerson,
    });
    form.setFieldValue("dealId", undefined);
  }, [associatedCompany, associatedPerson, form, onChangeAssociatedEntities]);

  const options = useMemo<LabeledValue[]>(
    () =>
      selectedDeal
        ? [{ key: String(selectedDeal.id), label: selectedDeal.name, value: selectedDeal.id }]
        : [],
    [selectedDeal]
  );

  useEffect(() => {
    setSelectedDeal(associatedDeal);
    form.setFieldValue("dealId", associatedDeal?.id);
  }, [associatedDeal, form]);

  return (
    <>
      <FormItem label={field.displayName} name="dealId" required={required} rules={[{ required }]}>
        <SelectField<Deal["id"][]>
          allowClear={allowClear}
          label={field.displayName}
          locked={disabled}
          onClear={handleRemove}
          onClick={disabled ? undefined : showModal}
          open={false}
          options={options}
          prefixIcon={
            <EntityTypeShapedIcon className={styles.entityIcon} entityType={EntityType.DEAL} />
          }
          required={required}
          showArrow={false}
        />
      </FormItem>

      {modalVisible && (
        <DealAssociation
          allowAdd={allowAdd}
          associatedDeals={associatedDeal ? [associatedDeal] : undefined}
          fixedCompany={associatedCompany}
          fixedPerson={associatedPerson}
          multiselect={false}
          onHide={hideModal}
          onSelect={handleSelect}
          suggestedDeals={suggestedDeals}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  onFetchDeal: fetchDeal,
};

export default connect(null, mapDispatchToProps)(DealRelationshipField);

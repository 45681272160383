import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { Route } from "@mapmycustomers/shared/types/entity";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { nameComparator } from "@mapmycustomers/shared/util/comparator";

import { callApi } from "@app/store/api/callApi";
import { getOrganization } from "@app/store/iam";

import { fetchCompanyRoutes, fetchPeopleRoutes } from "./actions";

const mergeResponses = (response: ListResponse<Route>, extraResponse?: ListResponse<Route>) => {
  const result = response.data;
  if (extraResponse) {
    const alreadyFetchedRouteIds = new Set(response.data.map(({ id }) => id));
    extraResponse.data.forEach((route) => {
      if (!alreadyFetchedRouteIds.has(route.id)) {
        result.push(route);
      }
    });
  }
  return result.sort(nameComparator);
};

export function* onFetchCompanyRoutes({ payload }: ReturnType<typeof fetchCompanyRoutes.request>) {
  try {
    const organization: Organization = yield select(getOrganization);

    const [routesResponse, extraRoutesResponse]: [
      ListResponse<Route>,
      ListResponse<Route> | undefined
    ] = yield all([
      callApi("fetchCompanyRoutes", organization.id, {
        $filters: { name: payload.query ? { $in: payload.query } : undefined },
        $limit: 100,
        $order: "name",
      }),
      payload.selectedRouteIds
        ? callApi("fetchCompanyRoutes", organization.id, {
            $filters: { $and: [{ id: { $in: payload.selectedRouteIds } }] },
            $limit: payload.selectedRouteIds.length,
          })
        : undefined,
    ]);

    // now let's merge two responses avoiding duplicates
    const result: Route[] = yield call(mergeResponses, routesResponse, extraRoutesResponse);
    yield put(fetchCompanyRoutes.success(result));
  } catch {
    yield put(fetchCompanyRoutes.failure());
  }
}

export function* onFetchPeopleRoutes({ payload }: ReturnType<typeof fetchPeopleRoutes.request>) {
  try {
    const organization: Organization = yield select(getOrganization);

    const [routesResponse, extraRoutesResponse]: [
      ListResponse<Route>,
      ListResponse<Route> | undefined
    ] = yield all([
      callApi("fetchPeopleRoutes", organization.id, {
        $filters: { name: payload.query ? { $in: payload.query } : undefined },
        $limit: 100,
        $order: "name",
      }),
      payload.selectedRouteIds
        ? callApi("fetchPeopleRoutes", organization.id, {
            $filters: { $and: [{ id: { $in: payload.selectedRouteIds } }] },
            $limit: payload.selectedRouteIds.length,
          })
        : undefined,
    ]);

    // now let's merge two responses avoiding duplicates
    const result: Route[] = yield call(mergeResponses, routesResponse, extraRoutesResponse);
    yield put(fetchPeopleRoutes.success(result));
  } catch {
    yield put(fetchPeopleRoutes.failure());
  }
}

export function* routesSaga() {
  yield takeLatest(fetchCompanyRoutes.request, onFetchCompanyRoutes);
  yield takeLatest(fetchPeopleRoutes.request, onFetchPeopleRoutes);
}

import React, { useMemo } from "react";
import { connect } from "react-redux";

import { ColumnType } from "antd/es/table";
import { useIntl } from "react-intl";

import EmailLog from "@mapmycustomers/shared/types/email/EmailLog";

import Table from "@app/component/Table";
import { getEmailQueueLogs, isEmailQueueLogsLoading } from "@app/store/email";
import { RootState } from "@app/store/rootReducer";

import CancelEmailAction from "./CancelEmailAction";
import EmailQueueActions from "./EmailQueueActions";
import EmailQueueEmpty from "./EmailQueueEmpty";
import styles from "./EmailQueueTable.module.scss";
import EmailStatusTag from "./EmailStatusTag";
import messages from "./messages";

const getEmailLogName = (emailLog: EmailLog) => emailLog.recipientAddress[0].name;

const stringSorter = (a: null | string | undefined, b: null | string | undefined) =>
  (a ?? "").localeCompare(b ?? "");

interface EmailQueueTableProps {
  emailLogs: EmailLog[];
  emailLogsLoading: boolean;
}

const EmailQueueTable: React.FC<EmailQueueTableProps> = ({ emailLogs, emailLogsLoading }) => {
  const intl = useIntl();

  const columns: ColumnType<EmailLog>[] = useMemo(() => {
    return [
      {
        key: "name",
        render: (_, emailLog) => getEmailLogName(emailLog),
        sorter: (a, b) => stringSorter(getEmailLogName(a), getEmailLogName(b)),
        title: intl.formatMessage(messages.nameColumnTitle),
      },
      {
        key: "email",
        render: (_, emailLog) => emailLog.recipientAddress[0].email,
        sorter: (a, b) => stringSorter(a.recipientAddress[0].email, b.recipientAddress[0].email),
        title: intl.formatMessage(messages.emailColumnTitle),
      },
      {
        key: "subject",
        render: (_, emailLog) => emailLog.subject,
        sorter: (a, b) => stringSorter(a.subject, b.subject),
        title: intl.formatMessage(messages.subjectColumnTitle),
      },
      {
        key: "status",
        render: (_, emailLog) => <EmailStatusTag emailLog={emailLog} />,
        sorter: (a, b) => stringSorter(a.status, b.status),
        title: intl.formatMessage(messages.statusColumnTitle),
      },
      {
        key: "action",
        render: (_, emailLog) => <CancelEmailAction emailLog={emailLog} />,
        title: (
          <span className={styles.actionColumnTitle}>
            <span>{intl.formatMessage(messages.actionColumnTitle)}</span>
            <EmailQueueActions />
          </span>
        ),
      },
    ];
  }, [intl]);

  if (!emailLogs.length && !emailLogsLoading) {
    return <EmailQueueEmpty className={styles.content} />;
  }

  return (
    <Table<EmailLog>
      className={styles.content}
      columns={columns}
      dataSource={emailLogs}
      loading={emailLogsLoading}
      noHover
      pagination={false}
      rowKey="id"
      scrollable
      showBorder
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  emailLogs: getEmailQueueLogs(state),
  emailLogsLoading: isEmailQueueLogsLoading(state),
});

export default connect(mapStateToProps)(EmailQueueTable);

import { ActionType, createAsyncAction } from "typesafe-actions";

import EmailUnsubscribeInfo from "@mapmycustomers/shared/types/email/EmailUnsubscribeInfo";

export const loadEmailUnsubscribeInfo = createAsyncAction(
  "emailUnsubscribe/loadUnsubscribeInfo/request",
  "emailUnsubscribe/loadUnsubscribeInfo/success",
  "emailUnsubscribe/loadUnsubscribeInfo/failure"
)<
  {
    code: string;
  },
  EmailUnsubscribeInfo,
  void
>();

export const unsubscribeEmail = createAsyncAction(
  "emailUnsubscribe/unsubscribe/request",
  "emailUnsubscribe/unsubscribe/success",
  "emailUnsubscribe/unsubscribe/failure"
)<void, void, void>();

export type Actions = ActionType<typeof loadEmailUnsubscribeInfo | typeof unsubscribeEmail>;

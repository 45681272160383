import { ColDef } from "@ag-grid-community/core";
import memoize from "fast-memoize";
import { createSelector } from "reselect";

import ViewState from "@mapmycustomers/shared/types/viewModel/ViewState";

import { RootState } from "@app/store/rootReducer";
import CHECKBOX_COLUMN_DEF from "@app/util/fieldModel/checkBoxColumnDef";

const companyState = (state: RootState) => state.scene.company;

export const getGridColumnDefs = createSelector(companyState, ({ columns }) =>
  memoize((forClientSideGrid?: boolean) => {
    // get field's gridProperties && also add width/pinned/hide props from the column model
    const columnDefs = columns
      .filter(({ field }) => field.isReadable || field.isSystemRequired)
      .map<ColDef>(({ field, pinned, visible, width }) => ({
        ...(field.gridProperties(forClientSideGrid) ?? {}),
        hide: !visible,
        pinned,
        width,
      }));

    // always make a checkbox column to appear on the left side of a grid
    columnDefs.unshift(CHECKBOX_COLUMN_DEF);

    return columnDefs;
  })
);

export const getListViewState = createSelector(
  companyState,
  ({ columns, filter, range, selectedSavedFilterId, sort, viewAs }): ViewState => ({
    columns,
    filter,
    range,
    selectedSavedFilterId,
    sort,
    viewAs,
  })
);

export const isLoading = createSelector(companyState, ({ loading }) => loading);

export const getTotalFilteredRecordsCount = createSelector(
  companyState,
  ({ totalFilteredRecords }) => totalFilteredRecords
);

export const getTotalRecordsCount = createSelector(
  companyState,
  ({ totalRecords }) => totalRecords
);

export const getOfferedFilters = createSelector(companyState, ({ offerFilters }) => offerFilters);

import notification from "antd/es/notification";
import { differenceInCalendarDays, startOfDay, startOfMonth, startOfToday } from "date-fns/esm";
import { partition } from "lodash-es";
import { defineMessage } from "react-intl";
import { Action } from "redux";
import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { isActionOf } from "typesafe-actions";

import FilterOperator from "@mapmycustomers/shared/enum/FilterOperator";
import {
  Activity,
  Company,
  Deal,
  EntityType,
  EntityTypesSupportedByMapsPage,
  MapEntity,
  Person,
} from "@mapmycustomers/shared/types/entity";
import { OutOfCadenceEntity } from "@mapmycustomers/shared/types/entity/activities/OutOfCadenceEntity";
import { ActivityOrSuggestion } from "@mapmycustomers/shared/types/entity/ActivitySuggestion";
import PinLegend from "@mapmycustomers/shared/types/map/PinLegend";
import Organization from "@mapmycustomers/shared/types/Organization";
import FilterModel, {
  SimpleCondition,
} from "@mapmycustomers/shared/types/viewModel/internalModel/FilterModel";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import MapViewState from "@mapmycustomers/shared/types/viewModel/MapViewState";
import PlatformFilterModel, {
  Condition,
  PinLegendsFilter,
} from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformFilterModel";
import PlatformListRequest from "@mapmycustomers/shared/types/viewModel/platformModel/PlatformListRequest";
import SavedFilter from "@mapmycustomers/shared/types/viewModel/SavedFilter";

import getSuccessNotificationNode from "@app/component/createEditEntity/util/getSuccessNotificationNode";
import CalendarViewState from "@app/component/view/CalendarView/types/CalendarViewState";
import i18nService from "@app/config/I18nService";
import localSettings from "@app/config/LocalSettings";
import CalendarViewMode from "@app/enum/CalendarViewMode";
import MapEntityWithActivity from "@app/scene/activity/component/ActivityCalendarPage/type/MapEntityWithActivity";
import getActivityDateRange from "@app/scene/activity/utils/getActivityDateRange";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { exportEntities } from "@app/store/exportEntities/actions";
import { getOrganization, getOrganizationId } from "@app/store/iam";
import { getMapViewSettings } from "@app/store/map";
import { getAllColorLegends, getAllShapeLegends } from "@app/store/pinLegends";
import { getSelectedFilter } from "@app/store/savedFilters";
import { initializeSavedFiltersForView } from "@app/store/savedFilters/actions";
import { onSetUserColors } from "@app/store/userColor/sagas";
import { isActivity } from "@app/util/activity/assert";
import getParentActivitiesFilter from "@app/util/activity/getParentActivitiesFilter";
import { getLocalTimeZoneFormattedOffset, getWeekStart } from "@app/util/dates";
import activityFieldModel, { ActivityFieldName } from "@app/util/fieldModel/ActivityFieldModel";
import isValidDate from "@app/util/isValidDate";
import { activityLayoutModel } from "@app/util/layout/impl";
import { MAP_ENTITY_TYPES } from "@app/util/map/consts";
import getColorShapeForEntity from "@app/util/map/markerStyles/getColorShapeForEntity";
import { parseApiDate, parseApiDateWithTz } from "@app/util/parsers";
import { isSimpleCondition } from "@app/util/viewModel/assert";
import { convertToPlatformSortModel } from "@app/util/viewModel/convertSort";
import { convertToPlatformFilterModel } from "@app/util/viewModel/convertToPlatformFilterModel";

import {
  applyCalendarViewSettings,
  exportCalendarActivities,
  fetchCalendarActivities,
  fetchNearbyEntities,
  fetchOutOfCadenceEntities,
  findActivities,
  goToActivity,
  initializeCalendarView,
  moveResizeActivity,
  postponeActivity,
} from "./actions";
import { getCalendarViewState, getCalendarViewTotalFilteredRecordsCount } from "./selectors";

export const CALENDAR_VIEW_STATE = "activity/calendarView";

const postponeSuccessMessage = defineMessage({
  id: "activities.calendarView.postpone.success",
  defaultMessage: "Activity postponed successfully",
  description: "Activity postpone success message",
});

export function* onInitializeCalendarView() {
  const savedFilterGetter: (entityType: EntityType) => SavedFilter | undefined = yield select(
    getSelectedFilter
  );
  const savedFilter = savedFilterGetter(EntityType.ACTIVITY);
  if (!savedFilter) {
    yield put(
      initializeSavedFiltersForView({
        entityType: EntityType.ACTIVITY,
        viewKey: CALENDAR_VIEW_STATE,
      })
    );
  }
  yield put(initializeCalendarView.success());

  // now fetch initial portion of deals. Not passing any viewState, because we don't need any changes there
  yield put(fetchCalendarActivities.request({ viewState: {} }));
}

const getRangeCondition = (startAt: Date, viewMode: CalendarViewMode): Condition => {
  const [start, end] = getActivityDateRange(startAt, viewMode);
  return { $gte: start, $lte: end };
};

const getCalendarRequestFilters = (
  viewState: CalendarViewState,
  ignoreFilters: boolean = false
): PlatformFilterModel => {
  // Add special filters for the date range.
  // By default, we only keep startAt in filter model, but in fact we should send more details to
  // the backend to get appropriate result. First, we need to generate range for the startAt
  // field. Second, we're not only interested in activities which start in the generated timeframe, but
  // also in ones which end there. Thus, we need to add a filter for the endAt field too.
  // And combine both these filters under the $or conjunction.
  // We also have a special processing for quickFilter filter.

  const { quickFilter, startAt, ...filters } = ignoreFilters
    ? ({} as FilterModel)
    : viewState.filter;
  const platformFilterModel = convertToPlatformFilterModel(
    filters,
    viewState.columns,
    activityFieldModel,
    true,
    viewState.viewAs
  );

  if (!ignoreFilters && startAt && isSimpleCondition(startAt)) {
    const startDate = parseApiDate(startAt.value);
    const rangeCondition = getRangeCondition(startDate, viewState.viewMode);
    platformFilterModel.$or = [{ startAt: rangeCondition }, { endAt: rangeCondition }];
  }

  if (!ignoreFilters && quickFilter && isSimpleCondition(quickFilter)) {
    const { value } = quickFilter;
    platformFilterModel.$and?.push({
      $or: [
        { name: { $in: value } },
        { "account.name": { $in: value } },
        { "contact.name": { $in: value } },
        { "deal.name": { $in: value } },
      ],
    });
  }

  platformFilterModel.includeNotes = true;

  const hasRelationshipFilters = (Object.keys(viewState.filter) as ActivityFieldName[]).some(
    (name) =>
      [ActivityFieldName.COMPANY, ActivityFieldName.DEAL, ActivityFieldName.PERSON].includes(name)
  );
  // We only fetch parent activities for the list view when there are no relationship filters
  if (!hasRelationshipFilters) {
    platformFilterModel.$and?.push(getParentActivitiesFilter());
  }

  return platformFilterModel;
};

export function* onExportCalendarActivities({
  payload: { viewState },
}: ReturnType<typeof exportCalendarActivities>) {
  const total: number = yield select(getCalendarViewTotalFilteredRecordsCount);
  yield put(
    exportEntities.request({
      entityType: EntityType.ACTIVITY,
      platformRequest: {
        $filters: getCalendarRequestFilters(viewState),
        $order: convertToPlatformSortModel(viewState.sort),
      },
      total,
      viewState: { columns: viewState.columns },
    })
  );
}

export function* onFetchCalendarActivities({
  payload,
}: ReturnType<typeof fetchCalendarActivities.request>) {
  try {
    if (!payload.fetchOnlyWithoutFilters) {
      yield put(applyCalendarViewSettings(payload.viewState));
    }
    const organizationId: Organization["id"] = yield select(getOrganizationId);

    const viewState: CalendarViewState = yield select(getCalendarViewState);
    const selectedUserIds = (viewState.filter?.assignee as SimpleCondition)?.value ?? [];

    if (!payload.fetchOnlyWithoutFilters && !payload.updateOnly) {
      localSettings.setViewSettings(viewState, CALENDAR_VIEW_STATE);
    }

    if (payload.updateOnly) {
      return;
    }

    // do not fetch activities with empty assignee filter
    if (!selectedUserIds.length) {
      const response: ListResponse<ActivityOrSuggestion> = yield callApi(
        "fetchActivitiesAndSuggestions",
        organizationId,
        { $limit: 0 }
      );
      yield put(
        fetchCalendarActivities.success({
          activities: [],
          mapEntities: [],
          suggestions: [],
          totalFilteredRecords: 0,
          totalRecords: response.accessible,
        })
      );
      return;
    }

    const $offset =
      payload.fetchOnlyWithoutFilters && payload.viewState.range
        ? payload.viewState.range.startRow
        : viewState.range.startRow;
    const $limit =
      payload.fetchOnlyWithoutFilters && payload.viewState.range
        ? payload.viewState.range.endRow - payload.viewState.range.startRow
        : viewState.range.endRow - viewState.range.startRow;

    const requestPayload = {
      $filters: getCalendarRequestFilters(viewState, payload.fetchOnlyWithoutFilters),
      $limit,
      $offset,
      $order: convertToPlatformSortModel(viewState.sort),
    };
    const response: ListResponse<ActivityOrSuggestion> = yield callApi(
      "fetchActivitiesAndSuggestions",
      organizationId,
      requestPayload
    );

    const [activities, suggestions] = partition(response.data, isActivity);

    const getKeyByMapEntity = (entity: MapEntity) => `${entity.entity}-${entity.id}`;
    const companyIds: Company["id"][] = [];
    const peopleIds: Person["id"][] = [];
    const dealIds: Deal["id"][] = [];
    const mapEntityToActivity = new Map<string, Activity>();
    activities.forEach((activity) => {
      const entity = activity.account?.geoPoint
        ? activity.account
        : activity.contact?.geoPoint
        ? activity.contact
        : activity.deal?.account?.geoPoint || activity.deal?.contact?.geoPoint
        ? activity.deal
        : undefined;
      if (entity) {
        mapEntityToActivity.set(getKeyByMapEntity(entity), activity);
        (entity.entity === EntityType.COMPANY
          ? companyIds
          : entity.entity === EntityType.PERSON
          ? peopleIds
          : dealIds
        ).push(entity.id);
      }
    });
    const mapViewState: MapViewState | undefined = yield select(getMapViewSettings);
    const colorPinLegends: PinLegend[] = yield select(getAllColorLegends);
    const shapePinLegends: PinLegend[] = yield select(getAllShapeLegends);
    const entitiesFilter: Partial<Record<EntityTypesSupportedByMapsPage, PlatformFilterModel>> = {};
    if (companyIds.length) {
      entitiesFilter.accounts = { $and: [{ id: { $in: companyIds } }] };
    }
    if (peopleIds.length) {
      entitiesFilter.contacts = { $and: [{ id: { $in: peopleIds } }] };
    }
    if (dealIds.length) {
      entitiesFilter.deals = { $and: [{ id: { $in: dealIds } }] };
    }
    const mapEntitiesRequestPayload: Partial<PlatformListRequest> = {
      $filters: {
        entities: entitiesFilter,
        pinLegends: MAP_ENTITY_TYPES.reduce(
          (result, entityType) => ({
            ...result,
            [entityType]: getColorShapeForEntity(
              mapViewState,
              colorPinLegends,
              shapePinLegends,
              entityType
            ),
          }),
          { $offset: getLocalTimeZoneFormattedOffset() } as PinLegendsFilter
        ),
      },
    };

    let pinsResponse: ListResponse<MapEntity> = {
      accessible: 0,
      count: 0,
      data: [],
      limit: 0,
      offset: 0,
      total: 0,
    };
    if (companyIds.length || peopleIds.length || dealIds.length) {
      pinsResponse = yield callApi("fetchPins", organizationId, mapEntitiesRequestPayload);
    }

    payload.dataCallback?.({ ...response, data: activities });
    yield put(
      fetchCalendarActivities.success({
        activities,
        mapEntities: pinsResponse.data
          .map((entity) => ({
            activity: mapEntityToActivity.get(getKeyByMapEntity(entity)),
            entity: entity,
          }))
          .filter((mapEntity): mapEntity is MapEntityWithActivity => !!mapEntity.activity),
        suggestions,
        totalFilteredRecords: activities.length,
        totalRecords: response.accessible,
      })
    );
  } catch (error) {
    payload.failCallback?.();
    yield put(fetchCalendarActivities.failure());
    yield put(handleError({ error }));
  }
}

export function* onPostponeActivity({ payload }: ReturnType<typeof postponeActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload).id;

    const updatedActivity: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      payload
    );
    yield put(postponeActivity.success(updatedActivity));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(postponeSuccessMessage, "Activity postponed successfully")
      ),
    });
  } catch (error) {
    yield put(postponeActivity.failure());
    yield put(handleError({ error }));
  }
}

export function* onMoveResizeActivity({ payload }: ReturnType<typeof moveResizeActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(payload.activity).id;

    const updatedActivity: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      payload.activity
    );
    yield put(moveResizeActivity.success(updatedActivity));
    yield put(fetchCalendarActivities.request({ viewState: {} })); // just reload, don't change view
  } catch (error) {
    if (payload.failCallback) {
      yield call(payload.failCallback, payload.activity);
    }
    yield put(moveResizeActivity.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchNearbyEntities({
  payload,
}: ReturnType<typeof fetchNearbyEntities.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const request: Partial<PlatformListRequest> = {
      $filters: {
        area: { point: payload.geoPoint.coordinates, radius: payload.distance, uom: "meter" },
        includeAccessStatus: true,
      },
      $limit: 1000,
    };
    const result: [ListResponse<Company>, ListResponse<Person>] = yield all([
      callApi("fetchCompanies", organization.id, request),
      callApi("fetchPeople", organization.id, request),
    ]);
    const mapEntities = [
      ...result[0].data.map<MapEntity>((entity) => ({ ...entity, entity: EntityType.COMPANY })),
      ...result[1].data.map<MapEntity>((entity) => ({ ...entity, entity: EntityType.PERSON })),
    ];
    yield put(fetchNearbyEntities.success(mapEntities));
  } catch (error) {
    yield put(fetchNearbyEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchOutOfCadenceEntities({
  payload,
}: ReturnType<typeof fetchOutOfCadenceEntities.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const startAt = parseApiDateWithTz(payload.startAt.substring(0, 10));
    const differenceInDaysWithToday = differenceInCalendarDays(startOfToday(), startAt); // negative value
    const request: Partial<PlatformListRequest> = {
      $filters: {
        $and: [{ cadenceDaysOut: differenceInDaysWithToday }],
        includeAccessStatus: true,
        includeGroups: true,
      },
      $limit: 3000,
    };
    const result: [ListResponse<Company>, ListResponse<Person>, ListResponse<Deal>] = yield all([
      callApi("fetchCompanies", organization.id, request),
      callApi("fetchPeople", organization.id, request),
      callApi("fetchDeals", organization.id, request),
    ]);
    const entities = [
      ...result[0].data.map<OutOfCadenceEntity>((entity) => ({
        ...entity,
        entity: EntityType.COMPANY,
      })),
      ...result[1].data.map<OutOfCadenceEntity>((entity) => ({
        ...entity,
        entity: EntityType.PERSON,
      })),
      ...result[2].data.map<OutOfCadenceEntity>((entity) => ({
        ...entity,
        entity: EntityType.DEAL,
      })),
    ];
    yield put(fetchOutOfCadenceEntities.success(entities));
  } catch (error) {
    yield put(fetchOutOfCadenceEntities.failure());
    yield put(handleError({ error }));
  }
}

export function* onFindActivities({ payload }: ReturnType<typeof findActivities.request>) {
  try {
    const { query } = payload;
    const requestPayload = {
      $filters: {
        $or: [
          { name: { $in: query } },
          { "account.name": { $in: query } },
          { "contact.name": { $in: query } },
          { "deal.name": { $in: query } },
        ],
        includeAccessStatus: true,
      },
      $limit: 1000,
      $order: "name",
    };

    const organization: Organization = yield select(getOrganization);
    const response: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organization.id,
      requestPayload
    );

    yield put(findActivities.success(response.data));
  } catch (error) {
    yield put(findActivities.failure());
    yield put(handleError({ error }));
  }
}

const getRangeStart = (date: Date, viewMode: CalendarViewMode): Date => {
  switch (viewMode) {
    case CalendarViewMode.DAY:
      return startOfDay(date);
    case CalendarViewMode.MONTH:
      return startOfMonth(date);
    case CalendarViewMode.WEEK:
      return getWeekStart(date);
  }
};

export function* onGoToActivity({ payload }: ReturnType<typeof goToActivity>) {
  try {
    const { activity, callback } = payload;
    const startAt = activity.startAt ? parseApiDate(activity.startAt) : undefined;
    if (!isValidDate(startAt)) {
      return;
    }

    const viewState: CalendarViewState = yield select(getCalendarViewState);

    const rangeStart = getRangeStart(startAt, viewState.viewMode);
    if (!viewState.filter.startAt || !isSimpleCondition(viewState.filter.startAt)) {
      return;
    }

    const filter = {
      ...viewState.filter,
      startAt: { operator: FilterOperator.ON, value: rangeStart.toISOString() },
    };

    yield put(
      fetchCalendarActivities.request({
        dataCallback: () => callback?.(),
        viewState: { filter },
      })
    );
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* onApplyCalendarViewSettings({
  payload,
}: ReturnType<typeof applyCalendarViewSettings>) {
  yield call(onSetUserColors, payload);
}

export function* calendarSaga() {
  yield takeEvery(initializeCalendarView.request, onInitializeCalendarView);
  yield takeLatest(
    (action: Action) =>
      isActionOf(fetchCalendarActivities.request)(action) && !action.payload.updateOnly,
    onFetchCalendarActivities
  );
  yield takeEvery(
    (action: Action) =>
      isActionOf(fetchCalendarActivities.request)(action) && !!action.payload.updateOnly,
    onFetchCalendarActivities
  );
  yield takeEvery(postponeActivity.request, onPostponeActivity);
  yield takeEvery(moveResizeActivity.request, onMoveResizeActivity);
  yield takeEvery(fetchNearbyEntities.request, onFetchNearbyEntities);
  yield takeEvery(fetchOutOfCadenceEntities.request, onFetchOutOfCadenceEntities);
  yield takeLatest(findActivities.request, onFindActivities);
  yield takeLatest(goToActivity, onGoToActivity);
  yield takeEvery(applyCalendarViewSettings, onApplyCalendarViewSettings);
  yield takeEvery(exportCalendarActivities, onExportCalendarActivities);
}

import React from "react";
import { connect } from "react-redux";

import { faArrowPointer } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faEnvelopeOpen } from "@fortawesome/pro-solid-svg-icons";
import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons/faEyeSlash";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tooltip from "antd/es/tooltip";
import cn from "classnames";
import { useIntl } from "react-intl";

import EmailLog from "@mapmycustomers/shared/types/email/EmailLog";

import { getUserSettingValue } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";

import styles from "./EmailMetrics.module.scss";
import messages from "./messages";

interface EmailMetricsProps {
  emailLog: EmailLog | null | undefined;
  size?: "default" | "small";
  transparent?: boolean;
  visible?: boolean;
}

const EmailMetrics: React.FC<EmailMetricsProps> = ({ emailLog, size, transparent, visible }) => {
  const intl = useIntl();
  const linksOpened = emailLog?.linksOpened?.length ?? 0;

  if (!emailLog || !visible) {
    return null;
  }
  return (
    <div
      className={cn(styles.container, {
        [styles.smallSize]: size === "small",
        [styles.transparent]: transparent,
      })}
    >
      {emailLog.opened ? (
        <Tooltip title={intl.formatMessage(messages.openedTooltip)}>
          <div className={cn(styles.chip, styles.active)}>
            <FontAwesomeIcon className={cn(styles.icon, styles.activeIcon)} icon={faEnvelopeOpen} />
            <FontAwesomeIcon className={styles.icon} icon={faCheck} />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={intl.formatMessage(messages.notOpenedTooltip)}>
          <div className={styles.chip}>
            <FontAwesomeIcon className={styles.icon} icon={faEyeSlash} />
            <FontAwesomeIcon className={styles.icon} icon={faTimes} />
          </div>
        </Tooltip>
      )}
      <Tooltip
        title={
          linksOpened > 0
            ? intl.formatMessage(messages.linksClickedTooltip)
            : intl.formatMessage(messages.linksNotClickedTooltip)
        }
      >
        <div className={cn(styles.chip, { [styles.active]: linksOpened })}>
          <FontAwesomeIcon
            className={cn(styles.icon, {
              [styles.activeIcon]: linksOpened,
            })}
            icon={faArrowPointer}
          />
          <span>
            {linksOpened > 0 ? (
              <FontAwesomeIcon className={styles.icon} icon={faCheck} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={faTimes} />
            )}
          </span>
        </div>
      </Tooltip>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  visible: getUserSettingValue(state)("bulkEmail").trackEmails,
});

export default connect(mapStateToProps)(EmailMetrics);

import React from "react";

import Tag from "antd/es/tag";
import Tooltip from "antd/es/tooltip";
import { useIntl } from "react-intl";

import EmailLogStatus from "@mapmycustomers/shared/enum/EmailLogStatus";
import EmailLog from "@mapmycustomers/shared/types/email/EmailLog";

import emailSendingSrc from "@app/assets/emailQueue/email-sending.svg";
import { formatRawDate } from "@app/util/formatters";

import styles from "./EmailStatus.module.scss";
import messages from "./messages";
import statusMessages from "./statusMessages";

interface EmailStatusTag {
  emailLog: EmailLog;
}

const EmailLogStatusTag: React.FC<EmailStatusTag> = ({ emailLog }) => {
  const intl = useIntl();

  if (emailLog.status === EmailLogStatus.CANCELLED) {
    return null;
  }

  const statusLabel = intl.formatMessage(statusMessages[emailLog.status]);

  switch (emailLog.status) {
    case EmailLogStatus.SENT: {
      return (
        <Tooltip
          title={intl.formatMessage(messages.sentTodayTooltip, {
            time: formatRawDate(emailLog.date, "p"),
          })}
        >
          <Tag color="green">{statusLabel}</Tag>
        </Tooltip>
      );
    }
    case EmailLogStatus.SENDING:
      return (
        <div className={styles.sendingContainer}>
          <Tooltip title={intl.formatMessage(messages.sendingTooltip)}>
            <Tag color="geekblue">{statusLabel}</Tag>
          </Tooltip>
          <img alt="logo" src={emailSendingSrc} />
        </div>
      );
    case EmailLogStatus.SUBMITTED:
      return (
        <Tooltip title={statusLabel}>
          <Tag color="default">{statusLabel}</Tag>
        </Tooltip>
      );
    case EmailLogStatus.ERRORED:
      return (
        <Tooltip title={intl.formatMessage(messages.notSentTooltip)}>
          <Tag color="red">{statusLabel}</Tag>
        </Tooltip>
      );
  }
};

export default EmailLogStatusTag;

import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";

import { useIntl } from "react-intl";

import { Modal } from "@mapmycustomers/ui";

import EmailQueueProgress from "@app/component/EmailQueueModal/EmailQueueProgress";
import EmailQueueSubtitle from "@app/component/EmailQueueModal/EmailQueueSubtitle";
import EmailQueueTable from "@app/component/EmailQueueModal/EmailQueueTable";
import { isEmailQueueVisible } from "@app/store/email";
import { fetchEmailInfo, fetchEmailQueueLogs, hideEmailQueue } from "@app/store/email/actions";
import { RootState } from "@app/store/rootReducer";

import messages from "./messages";

interface EmailQueueModalProps {
  onClose: typeof hideEmailQueue;
  onFetchEmailInfo: typeof fetchEmailInfo.request;
  onFetchEmailLogs: typeof fetchEmailQueueLogs.request;
  visible: boolean;
}

const EmailQueueModal: React.FC<EmailQueueModalProps> = ({
  onClose,
  onFetchEmailInfo,
  onFetchEmailLogs,
  visible,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (visible) {
      onFetchEmailInfo();
      onFetchEmailLogs();
    }
  }, [visible, onFetchEmailLogs, onFetchEmailInfo]);

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <Modal
      destroyOnClose
      footer={<EmailQueueProgress />}
      onCancel={handleCancel}
      open={visible}
      scrollable
      title={
        <div>
          <div>{intl.formatMessage(messages.title)}</div>
          <EmailQueueSubtitle />
        </div>
      }
      width="65rem"
    >
      <EmailQueueTable />
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  visible: isEmailQueueVisible(state),
});

const mapDispatchToProps = {
  onClose: hideEmailQueue,
  onFetchEmailInfo: fetchEmailInfo.request,
  onFetchEmailLogs: fetchEmailQueueLogs.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailQueueModal);

import React, { FC, ReactNode } from "react";

import { bem } from "@react-md/utils";
import { Button as AntDButton } from "antd";
import { ButtonProps as AntdButtonProps } from "antd/es/button";
import cn from "classnames";

const block = bem("mmc-button");

export type ButtonType = "control" | "default" | "filled" | "link" | "pill" | "primary" | "text";

const mmcButtonTypeToAntdType: Record<ButtonType, AntdButtonProps["type"]> = {
  control: "link",
  default: "default",
  filled: "dashed",
  link: "link",
  pill: "text",
  primary: "primary",
  text: "text",
};

export interface ButtonProps extends Omit<AntdButtonProps, "label" | "shape" | "type"> {
  children?: ReactNode;
  className?: string;
  iconOnly?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  type?: ButtonType;
}

const Button: FC<ButtonProps> = ({
  children,
  className,
  danger,
  iconOnly,
  leftIcon,
  rightIcon,
  size = "middle",
  type = "default",
  ...antDButtonProps
}) => {
  return (
    <AntDButton
      className={cn(
        block({ danger, ["icon-only"]: iconOnly, [size]: true, [type]: true }),
        className
      )}
      {...antDButtonProps}
      danger={danger}
      shape={type === "pill" ? "round" : "default"}
      size={size}
      type={mmcButtonTypeToAntdType[type]}
    >
      {leftIcon && <span className={block("icon")}>{leftIcon}</span>}
      {children}
      {rightIcon && <span className={block("icon")}>{rightIcon}</span>}
    </AntDButton>
  );
};

export default Button;

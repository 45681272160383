import React, { ReactNode, useCallback } from "react";

import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Popconfirm from "antd/es/popconfirm";
import cn from "classnames";
import { useIntl } from "react-intl";

import { stopEvents } from "@mapmycustomers/shared/util/browser";
import { Modal, ModalProps } from "@mapmycustomers/ui";

import { extendNavbarAnalyticWithMenuKey } from "@app/util/analytic/navbarAnalytics";

import styles from "./EntityModal.module.scss";

const getEntityKeyName: Record<string, string> = {
  Activity: "addActivity",
  Company: "addCompany",
  Deal: "addDeal",
  Person: "addPerson",
};

const getEntityEventName: Record<string, string> = {
  Activity: "Create Activity",
  Company: "Create Company",
  Deal: "Create Deal",
  Person: "Create Person",
};

interface Props extends ModalProps {
  confirmationText?: ReactNode;
  entityTypeName?: string;
  showConfirm?: boolean;
}

const EntityModal: React.FC<Props> = ({
  children,
  className,
  confirmationText,
  entityTypeName,
  showConfirm,
  ...props
}) => {
  const intl = useIntl();
  const confirmationMessage =
    confirmationText ??
    intl.formatMessage(
      {
        id: "createEditEntity.baseModal.close.popconfirm.title",
        defaultMessage: "Are you sure you want to {br}discard this record without {br}saving?",
        description: "Confirmation text when closing Base Modal",
      },
      {
        br: <br />,
      }
    );

  const handlePopupCancelAction = useCallback(
    (e) => {
      stopEvents(e);
      if (entityTypeName) {
        extendNavbarAnalyticWithMenuKey(getEntityKeyName[entityTypeName]).clicked([
          getEntityEventName[entityTypeName],
          "Discard Pop Up",
          "Cancel",
        ]);
      }
    },
    [entityTypeName]
  );

  const handlePopupDiscardAction = useCallback(
    (e) => {
      if (e) {
        props.onCancel?.(e);
      }
      if (entityTypeName) {
        extendNavbarAnalyticWithMenuKey(getEntityKeyName[entityTypeName]).clicked([
          getEntityEventName[entityTypeName],
          "Discard Pop Up",
          "Discard",
        ]);
      }
    },
    [entityTypeName, props]
  );

  return (
    <Modal
      cancelButtonProps={{ hidden: true }}
      centered
      className={cn(styles.container, className)}
      closeIcon={
        <p onClick={showConfirm ? stopEvents : undefined}>
          <Popconfirm
            cancelText="Cancel"
            disabled={!showConfirm}
            okText="Discard"
            onCancel={handlePopupCancelAction}
            onConfirm={handlePopupDiscardAction}
            placement="rightTop"
            title={confirmationMessage}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Popconfirm>
        </p>
      }
      maskClosable={false}
      open
      scrollable
      width="clamp(100vw, 100vw, 750px)"
      {...props}
    >
      {children}
    </Modal>
  );
};

export default EntityModal;

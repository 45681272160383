import { OptionalFields } from "@mapmycustomers/shared";
import EmailAssociatedEntity from "@mapmycustomers/shared/types/email/EmailAssociatedEntity";

const createEmailAssociatedEntity = ({
  accountId,
  contactId,
  dealId,
  entityId,
}: OptionalFields<
  EmailAssociatedEntity,
  "accountId" | "contactId" | "dealId"
>): EmailAssociatedEntity => {
  return {
    accountId: accountId ?? null,
    contactId: contactId ?? null,
    dealId: dealId ?? null,
    entityId,
  };
};

export default createEmailAssociatedEntity;

import React, { useCallback, useMemo, useState } from "react";
import { connect } from "react-redux";

import { faSearch, faUsers } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Alert from "antd/es/alert";
import Button from "antd/es/button";
import cn from "classnames";
import { useIntl } from "react-intl";

import Team from "@mapmycustomers/shared/types/Team";
import User from "@mapmycustomers/shared/types/User";
import { stopEvents } from "@mapmycustomers/shared/util/browser";
import { Modal, TextField } from "@mapmycustomers/ui";

import Chip from "@app/component/Chip";
import {
  SUPPORTED_PREVIEW_CHART_TYPES,
  UserPreviewCardType,
} from "@app/component/UserPreviewCard/util/const";
import { getCurrentUser } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";
import Dashboard from "@app/types/dashboard/Dashboard";
import { UserPreviewCard } from "@app/types/dashboard/DashboardCard";
import Scope from "@app/types/dashboard/Scope";
import { formatDate } from "@app/util/formatters";
import { parseApiDate } from "@app/util/parsers";

import { updateCards } from "../../store/actions";
import getChartTitle from "../../util/getChartTitle";
import DataCard from "../DataCard";

import styles from "./ChartSelectionModal.module.scss";
import messages from "./messages";

interface Props {
  cardIds: { [key: number]: string | undefined };
  currentUser: User;
  dashboard?: Dashboard;
  index: number;
  onHide: () => void;
  onUpdateCards: typeof updateCards.request;
  open: boolean;
  scope: Scope;
  team?: Team;
  user: User;
}

const ChartSelectionModal: React.FC<Props> = ({
  cardIds,
  currentUser,
  dashboard,
  index,
  onHide,
  onUpdateCards,
  open,
  scope,
  team,
  user,
}) => {
  const intl = useIntl();
  const [selectedCard, setSelectedCard] = useState<UserPreviewCard>();
  const [query, setQuery] = useState<string>("");

  const handleSave = useCallback(() => {
    if (selectedCard) {
      onUpdateCards({
        cardIds: { [index]: selectedCard.id },
        scope: { teamId: scope.teamId, userId: scope.userId },
      });
      onHide();
    }
  }, [index, onHide, onUpdateCards, scope.teamId, scope.userId, selectedCard]);

  const filteredCards = useMemo(
    () =>
      (
        (dashboard?.settings.cards.filter(
          ({ id, type }) =>
            SUPPORTED_PREVIEW_CHART_TYPES.includes(type as UserPreviewCardType) &&
            !Object.values(cardIds).includes(id)
        ) as undefined | UserPreviewCard[]) ?? []
      )
        .map((card) => ({
          ...card,
          title: getChartTitle(intl, card),
        }))
        .filter((card) => !query || card.title.toLowerCase().includes(query.toLowerCase())),
    [cardIds, dashboard?.settings.cards, intl, query]
  );

  return (
    <Modal
      className={styles.modalContainer}
      closable
      footer={
        <Button disabled={!selectedCard} onClick={handleSave} type="primary">
          {intl.formatMessage(messages.addMetric)}
        </Button>
      }
      onCancel={onHide}
      open={open}
      title={
        <div className={styles.title}>
          {intl.formatMessage(messages.title, {
            scope: team && (
              <Chip className={styles.chip} icon={<FontAwesomeIcon icon={faUsers} />} key={team.id}>
                {team.name}
              </Chip>
            ),
          })}
        </div>
      }
      zIndex={1051} // Higher than user preview
    >
      <div className={styles.container} onClick={stopEvents}>
        <div className={styles.preview}>
          <span>{intl.formatMessage(messages.preview)}</span>
          {!selectedCard ? (
            <div className={styles.previewDescription}>
              {intl.formatMessage(messages.previewDescription)}
            </div>
          ) : (
            <DataCard allowRemove={false} card={selectedCard} scope={scope} />
          )}
        </div>
        <div className={styles.charts}>
          <TextField
            className={styles.search}
            onChange={setQuery}
            placeholder={intl.formatMessage(messages.placeholder)}
            size="large"
            suffix={<FontAwesomeIcon icon={faSearch} />}
            value={query}
          />
          <Alert
            className={styles.alert}
            message={intl.formatMessage(
              currentUser.id === scope.userId && !scope.teamId
                ? messages.selfPreviewAlert
                : messages.infoText,
              {
                noTeam: !scope.teamId,
                teamName: team?.name,
                userName: user.fullName ?? user.username,
              }
            )}
            showIcon
            type="info"
          />
          {filteredCards.length > 0 ? (
            <div className={styles.chartsList}>
              {filteredCards?.map((card) => (
                <div
                  className={cn(styles.chart, {
                    [styles.selectedCard]: selectedCard?.id === card.id,
                  })}
                  key={card.id}
                  onClick={() => setSelectedCard(card)}
                >
                  <span className={styles.chartTitle}>{card.title}</span>
                  <span className={styles.subtext}>
                    {intl.formatMessage(
                      card.updatedAt ? messages.lastModified : messages.defaultChart,
                      {
                        lastModified: card.updatedAt
                          ? formatDate(parseApiDate(card.updatedAt), "PPPp")
                          : undefined,
                      }
                    )}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.noCharts}>
              <span className={styles.noChartTitle}>
                {intl.formatMessage(messages.noChartsTitle)}
              </span>
              <span>{intl.formatMessage(messages.noChartsDescription)}</span>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => ({
  currentUser: getCurrentUser(state)!,
});

const mapDispatchToProps = {
  onUpdateCards: updateCards.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartSelectionModal);

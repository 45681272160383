import { useMemo } from "react";

import { useIntl } from "react-intl";

import messages from "../../component/email/creation/messages";

interface UseEmailBodyArgs {
  emailBody: string;
  unsubscribeLinkIncluded?: boolean;
}

const useEmailBodyWithAddons = ({
  emailBody,
  unsubscribeLinkIncluded,
}: UseEmailBodyArgs): string => {
  const intl = useIntl();

  return useMemo(() => {
    let result = emailBody;

    if (unsubscribeLinkIncluded) {
      result += `
        <div>
          <div>--</div>
          <a href="{emailUnsubscribeLink}">${intl.formatMessage(messages.unsubscribeLink)}</a>
        </div>
      `;
    }

    return result;
  }, [intl, emailBody, unsubscribeLinkIncluded]);
};

export default useEmailBodyWithAddons;

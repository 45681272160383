import { ActionType, createAction, createAsyncAction } from "typesafe-actions";

import { CustomFieldError } from "@mapmycustomers/shared/types/customField/CustomFieldValuesUpsertResponse";
import Email, { EmailCreationPayload } from "@mapmycustomers/shared/types/email/Email";
import EmailAssociatedEntity from "@mapmycustomers/shared/types/email/EmailAssociatedEntity";
import EmailLog from "@mapmycustomers/shared/types/email/EmailLog";
import EmailPreview from "@mapmycustomers/shared/types/email/EmailPreview";
import EmailQuota from "@mapmycustomers/shared/types/email/EmailQuota";
import EmailTemplate, {
  EmailTemplateCreateRequest,
} from "@mapmycustomers/shared/types/email/EmailTemplate";
import { Deal } from "@mapmycustomers/shared/types/entity";
import {
  EntitiesSupportedByEmailFeature,
  EntityTypesSupportedByEmailFeature,
} from "@mapmycustomers/shared/types/map/types";
import { AttachedFile, ModalFunctions } from "@mapmycustomers/ui";

export const fetchEmailInfo = createAsyncAction(
  "@email/fetchEmailInfo/request",
  "@email/fetchEmailInfo/success",
  "@email/fetchEmailInfo/failure"
)<{ callback?: (quota: EmailQuota) => void } | void, EmailQuota, unknown>();

export const fetchEmailTemplates = createAsyncAction(
  "@email/fetchEmailTemplates/request",
  "@email/fetchEmailTemplates/success",
  "@email/fetchEmailTemplates/failure"
)<void, EmailTemplate[], unknown>();

export const fetchSelectedEntities = createAsyncAction(
  "@email/fetchSelectedEntities/request",
  "@email/fetchSelectedEntities/success",
  "@email/fetchSelectedEntities/failure"
)<
  {
    callback: (entities: EntitiesSupportedByEmailFeature[]) => void;
    entityIds: EntitiesSupportedByEmailFeature["id"][];
    entityType: EntityTypesSupportedByEmailFeature;
  },
  EntitiesSupportedByEmailFeature[],
  unknown
>();

export const createNewEmailTemplate = createAsyncAction(
  "@email/createNewEmailTemplate/request",
  "@email/createNewEmailTemplate/success",
  "@email/createNewEmailTemplate/failure"
)<
  { callback?: (template: EmailTemplate) => void; template: EmailTemplateCreateRequest },
  EmailTemplate,
  unknown
>();

export const updateEmailTemplate = createAsyncAction(
  "@email/updateEmailTemplate/request",
  "@email/updateEmailTemplate/success",
  "@email/updateEmailTemplate/failure"
)<
  { callback?: (template: EmailTemplate) => void; template: EmailTemplate },
  EmailTemplate,
  unknown
>();

export const deleteEmailTemplate = createAsyncAction(
  "@email/deleteEmailTemplate/request",
  "@email/deleteEmailTemplate/success",
  "@email/deleteEmailTemplate/failure"
)<{ callback?: () => void; templateId: EmailTemplate["id"] }, void, unknown>();

export const sendEmail = createAsyncAction(
  "@email/sendEmail/request",
  "@email/sendEmail/success",
  "@email/sendEmail/failure"
)<
  {
    callback?: () => void;
    email: EmailCreationPayload;
    failureCallback?: (customFieldErrors: CustomFieldError[]) => void;
  },
  Email,
  unknown
>();

export const showEmailCreationModal = createAction("@email/showEmailCreationModal")<{
  deals?: Deal[];
  recipients: EntitiesSupportedByEmailFeature[];
  recipientType: EntityTypesSupportedByEmailFeature;
}>();

export const hideEmailCreationModal = createAction("@email/hideEmailCreationModal")<void>();

export const setEmailAssociatedEntities = createAction("@email/setAssociatedEntities")<
  EmailAssociatedEntity[]
>();

export const uploadFileForNewEmail = createAsyncAction(
  "@email/uploadFileForNewEmail/request",
  "@email/uploadFileForNewEmail/success",
  "@email/uploadFileForNewEmail/failure"
)<AttachedFile, AttachedFile, AttachedFile>();

export const deleteUploadedFile = createAction("@email/deleteUploadedFile")<AttachedFile["id"]>();

export const loadImageToEmailBody = createAction("@email/loadImageToEmailBody")<{
  callback: (url: string) => void;
  file: Blob;
}>();

export const fetchNewEmailPreview = createAsyncAction(
  "@email/fetchNewEmailPreview/request",
  "@email/fetchNewEmailPreview/success",
  "@email/fetchNewEmailPreview/failure"
)<EmailCreationPayload, EmailPreview, unknown>();

export const fetchTemplatePreview = createAsyncAction(
  "@email/fetchTemplatePreview/request",
  "@email/fetchTemplatePreview/success",
  "@email/fetchTemplatePreview/failure"
)<EmailCreationPayload, EmailPreview, unknown>();

export const fetchEmailQueueLogs = createAsyncAction(
  "@email/fetchEmailQueueLogs/request",
  "@email/fetchEmailQueueLogs/success",
  "@email/fetchEmailQueueLogs/failure"
)<void, EmailLog[], void>();

export const showEmailQueue = createAction("@email/showEmailQueue")<void>();

export const hideEmailQueue = createAction("@email/hideEmailQueue")<void>();

export const clearEmailQueue = createAction("@email/clearEmailQueue")<{
  onFinish: () => void;
}>();

export const cancelQueuedEmail = createAction("@email/cancelQueuedEmail")<{
  emailLogId: EmailLog["id"];
  onFinish: () => void;
}>();

export const showEmailFeatures = createAction("@email/showEmailFeatures")<void>();

export const hideEmailFeatures = createAction("@email/hideEmailFeatures")<void>();

export const setNewEmailUnsubscribeLinkIncluded = createAction(
  "@email/setNewEmailUnsubscribeLinkIncluded"
)<boolean>();

export const checkEmailSupporting = createAction("@email/checkEmailSupporting")<{
  modal: Omit<ModalFunctions, "warn">;
  successfulCallback: () => void;
}>();

export const downloadAttachedFile = createAction("@email/downloadAttachedFile")<{
  attachedFile: AttachedFile;
  preview?: boolean;
}>();

export type Actions = ActionType<
  | typeof cancelQueuedEmail
  | typeof checkEmailSupporting
  | typeof clearEmailQueue
  | typeof createNewEmailTemplate
  | typeof deleteEmailTemplate
  | typeof deleteUploadedFile
  | typeof downloadAttachedFile
  | typeof fetchEmailInfo
  | typeof fetchEmailQueueLogs
  | typeof fetchEmailTemplates
  | typeof fetchNewEmailPreview
  | typeof fetchSelectedEntities
  | typeof fetchTemplatePreview
  | typeof hideEmailCreationModal
  | typeof hideEmailFeatures
  | typeof hideEmailQueue
  | typeof loadImageToEmailBody
  | typeof sendEmail
  | typeof setEmailAssociatedEntities
  | typeof setNewEmailUnsubscribeLinkIncluded
  | typeof showEmailCreationModal
  | typeof showEmailFeatures
  | typeof showEmailQueue
  | typeof updateEmailTemplate
  | typeof uploadFileForNewEmail
>;

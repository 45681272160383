import notification from "antd/es/notification";
import orderBy from "lodash-es/orderBy";
import { defineMessage } from "react-intl";
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import * as uuid from "uuid";

import {
  AnyEntityId,
  Company,
  Deal,
  EntityType,
  Person,
} from "@mapmycustomers/shared/types/entity";
import Activity from "@mapmycustomers/shared/types/entity/Activity";
import { RawFile } from "@mapmycustomers/shared/types/File";
import Organization from "@mapmycustomers/shared/types/Organization";
import ListResponse from "@mapmycustomers/shared/types/viewModel/ListResponse";
import { isApiError, isDeal } from "@mapmycustomers/shared/util/assert";

import getSuccessNotificationNode from "@app/component/createEditEntity/util/getSuccessNotificationNode";
import i18nService from "@app/config/I18nService";
import { notifyActivityRelatedEntities } from "@app/store/activity/actions";
import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganization, getOrganizationId, shouldIncludeSharedActivities } from "@app/store/iam";
import { notifyAboutChanges } from "@app/store/uiSync/actions";
import FileListItem from "@app/types/FileListItem";
import { allSettled, SettleResult } from "@app/util/effects";
import { activityLayoutModel } from "@app/util/layout/impl";

import {
  deleteActivityFile,
  fetchThumbnail,
  initializeAnnotation,
  loadAnnotationData,
  postponeActivity,
  toggleComplete,
  uploadActivityFiles,
} from "./actions";
import AnnotationData from "./AnnotationData";
import { getActivities, getActivity, getAnnotationData } from "./selectors";

const toggleCompleteSuccessMessage = defineMessage({
  id: "component.activityAnnotation.saga.toggleComplete.success",
  defaultMessage: 'Activity{completed, select, true {} other { is no longer}} marked as "Done"',
  description: "Activity toggle complete success message",
});

const postponeSuccessMessage = defineMessage({
  id: "component.activityAnnotation.saga.postpone.success",
  defaultMessage: "Activity postponed successfully",
  description: "Activity postpone success message",
});

export function* onInitializeAnnotation({
  payload: activityId,
}: ReturnType<typeof initializeAnnotation.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const includeSharedActivities: boolean = yield select(shouldIncludeSharedActivities);

    const activitiesResponse: ListResponse<Activity> = yield callApi(
      "fetchActivities",
      organizationId,
      {
        $filters: {
          $or: [{ id: { $eq: activityId } }, { parentId: { $eq: activityId } }],
          includeAccessStatus: true,
          includeAccountSharedActivities: includeSharedActivities,
          includeContactSharedActivities: includeSharedActivities,
          includeCustomFields: true,
          includeDealSharedActivities: includeSharedActivities,
          includeEmails: true,
        },
      }
    );

    const activities = orderBy(
      activitiesResponse.data,
      (activity) => activity.emailLog?.recipientAddress[0].name
    );

    yield put(initializeAnnotation.success(activities));

    if (activities.length === 1) {
      yield put(loadAnnotationData.request(activities[0].id));
    }
  } catch (error) {
    const hasNoAccess = isApiError(error) && error.status === 404;
    yield put(initializeAnnotation.failure(hasNoAccess));
    if (!hasNoAccess) {
      yield put(handleError({ error }));
    }
  }
}

export function* onLoadAnnotationData({
  payload: activityId,
}: ReturnType<typeof loadAnnotationData.request>) {
  try {
    const organizationId: Organization["id"] = yield select(getOrganizationId);
    const activities: Activity[] = yield select(getActivities);

    const activity = activities.find((activity) => activity.id === activityId)!;

    const filesResponse: ListResponse<RawFile> | undefined = yield activity.hasAccess
      ? callApi("fetchEntityFiles", organizationId, EntityType.ACTIVITY, activity.id)
      : undefined;

    let addressRecord: Company | Deal | Person | undefined;
    try {
      let addressRecordId: AnyEntityId | undefined = undefined;
      let addressRecordType: EntityType | undefined = undefined;
      if (activity.account) {
        addressRecordId = activity.account.id;
        addressRecordType = EntityType.COMPANY;
      } else if (activity.contact) {
        addressRecordId = activity.contact.id;
        addressRecordType = EntityType.PERSON;
      } else if (activity.deal) {
        addressRecordId = activity.deal.id;
        addressRecordType = EntityType.DEAL;
      }
      addressRecord =
        addressRecordType === EntityType.COMPANY
          ? yield callApi("fetchCompany", organizationId, addressRecordId!)
          : addressRecordType === EntityType.PERSON
          ? yield callApi("fetchPerson", organizationId, addressRecordId!)
          : addressRecordType === EntityType.DEAL
          ? yield callApi("fetchDeal", organizationId, addressRecordId!)
          : undefined;

      // If it was deal, let's do another round of fetching because deal doesn't have its own address.
      // So we need to fetch either deal's company or person
      if (isDeal(addressRecord, addressRecordType)) {
        if (addressRecord.account) {
          addressRecord = yield callApi("fetchCompany", organizationId, addressRecord.account.id);
        } else if (addressRecord.contact) {
          addressRecord = yield callApi("fetchPerson", organizationId, addressRecord.contact.id);
        } else {
          addressRecord = undefined;
        }
      }
    } catch (error) {
      const hasNoAccess = isApiError(error) && error.status === 404;
      if (!hasNoAccess) {
        yield put(handleError({ error }));
      }
    }

    yield put(
      loadAnnotationData.success({
        activity,
        // it's either a Company or a Person or undefined at this moment
        addressRecord: addressRecord as Company | Person | undefined,
        files: filesResponse?.data ?? [],
      })
    );
  } catch (error) {
    const hasNoAccess = isApiError(error) && error.status === 404;
    yield put(loadAnnotationData.failure(hasNoAccess));
    if (!hasNoAccess) {
      yield put(handleError({ error }));
    }
  }
}

export function* onToggleComplete({
  payload: { activity, callback },
}: ReturnType<typeof toggleComplete.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(activity).id;

    const updatedActivity: Activity = yield callApi("updateActivity", organization.id, layoutId, {
      ...activity,
      completed: !activity.completed,
    });

    callback?.(updatedActivity);
    yield put(toggleComplete.success(updatedActivity));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(
          toggleCompleteSuccessMessage,
          `Activity marked as "${updatedActivity.completed ? "Done" : "Not yet done"}"`,
          { completed: updatedActivity.completed }
        )
      ),
    });
    notifyAboutChanges({ entityType: EntityType.ACTIVITY, updated: [updatedActivity] });
    yield put(notifyActivityRelatedEntities(updatedActivity));
  } catch (error) {
    yield put(toggleComplete.failure());
    yield put(handleError({ error }));
  }
}

export function* onPostponeActivity({
  payload: { activity, callback },
}: ReturnType<typeof postponeActivity.request>) {
  try {
    const organization: Organization = yield select(getOrganization);
    const layoutId = activityLayoutModel.getLayoutFor(activity).id;

    const updatedActivity: Activity = yield callApi(
      "updateActivity",
      organization.id,
      layoutId,
      activity
    );
    callback?.(updatedActivity);
    yield put(postponeActivity.success(updatedActivity));

    notification.success({
      message: getSuccessNotificationNode(
        i18nService.getIntl(),
        i18nService.formatMessage(postponeSuccessMessage, "Activity postponed successfully")
      ),
    });
    notifyAboutChanges({ entityType: EntityType.ACTIVITY, updated: [updatedActivity] });
    yield put(notifyActivityRelatedEntities(updatedActivity));
  } catch (error) {
    yield put(postponeActivity.failure());
    yield put(handleError({ error }));
  }
}

export function* onUploadActivityFiles({
  payload,
}: ReturnType<typeof uploadActivityFiles.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const annotationData: AnnotationData = yield select(getAnnotationData);
    const activity = annotationData.activity;
    const fileGroupId = payload.fileGroupId ?? uuid.v4();
    const responses: SettleResult<RawFile>[] = yield allSettled(
      payload.files.map((file) =>
        callApi("createFile", org.id, file, false, EntityType.ACTIVITY, activity.id, {
          headers: {
            "x-mmc-file-group-id": fileGroupId,
          },
        })
      )
    );
    const fileList: FileListItem[] = responses.map((response, index) => ({
      file: payload.files[index],
      uploading: false,
      ...(response.error
        ? { errored: true, errorMessage: String(response.result) }
        : { errored: false, uploadedFile: response.result }),
    }));
    if (payload.callback) {
      yield call(payload.callback, fileList);
    }
    yield put(uploadActivityFiles.success(fileList));
  } catch (error) {
    if (payload.callback) {
      yield call(
        payload.callback,
        payload.files.map((file) => ({ errored: true, file, uploading: false }))
      );
    }
    yield put(uploadActivityFiles.failure());
    yield put(handleError({ error }));
  }
}

export function* onDeleteActivityFile({ payload }: ReturnType<typeof deleteActivityFile.request>) {
  try {
    const org: Organization = yield select(getOrganization);
    const annotationData: AnnotationData = yield select(getAnnotationData);
    const activity = annotationData.activity;
    yield callApi("deleteEntityFile", org.id, EntityType.ACTIVITY, activity.id, payload.id);

    yield put(deleteActivityFile.success(payload));
  } catch (error) {
    yield put(deleteActivityFile.failure());
    yield put(handleError({ error }));
  }
}

export function* onFetchThumbnail({ payload }: ReturnType<typeof fetchThumbnail>) {
  try {
    const org: Organization = yield select(getOrganization);
    const activity: Activity | undefined = yield select(getActivity);
    if (activity) {
      const fileData: Blob = yield callApi(
        "fetchFile",
        org.id,
        payload.fileId,
        false,
        true,
        EntityType.ACTIVITY,
        activity.id,
        { responseType: "blob" }
      );
      yield call(payload.callback, fileData);
    }
  } catch (error) {
    yield put(handleError({ error }));
  }
}

export function* activityAnnotationSaga() {
  yield takeLatest(initializeAnnotation.request, onInitializeAnnotation);
  yield takeLatest(loadAnnotationData.request, onLoadAnnotationData);
  yield takeLatest(toggleComplete.request, onToggleComplete);
  yield takeLatest(postponeActivity.request, onPostponeActivity);
  yield takeEvery(uploadActivityFiles.request, onUploadActivityFiles);
  yield takeEvery(deleteActivityFile.request, onDeleteActivityFile);
  yield takeEvery(fetchThumbnail, onFetchThumbnail);
}

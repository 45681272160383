import React from "react";

import EntityType from "@mapmycustomers/shared/enum/EntityType";
import { SelectField, SelectFieldProps } from "@mapmycustomers/ui";
import { EntityTypeShapedIcon } from "@mapmycustomers/ui";

import styles from "./LockedEntityType.module.scss";

type LockedEntityTypeProps = Pick<
  SelectFieldProps,
  "caption" | "label" | "placeholder" | "required"
> & {
  entityType: EntityType;
};

const LockedEntityType: React.FC<LockedEntityTypeProps> = ({
  entityType,
  required,
  ...selectProps
}) => {
  return (
    <SelectField
      {...selectProps}
      disabled={true}
      locked={true}
      prefixIcon={<EntityTypeShapedIcon className={styles.entityIcon} entityType={entityType} />}
      required={required}
    />
  );
};

export default LockedEntityType;

import { defineMessages } from "react-intl";

const messages = defineMessages({
  fileSizeExceeded: {
    id: "view.selectBar.button.email.fileSizeExceeded",
    defaultMessage: "File size exceeded",
    description: "Email button on a selected entities bar - File size exceeded",
  },
  fileSizeExceededDescription: {
    id: "view.selectBar.button.email.fileSizeExceededDescription",
    defaultMessage: "The file size exceeds the limit of 25 MB",
    description: "Email button on a selected entities bar - File size exceeded description",
  },
  syncModalCancel: {
    id: "view.selectBar.button.email.sycModalCancel",
    defaultMessage: "Maybe Later",
    description: "Email button on a selected entities bar - Sync modal cancel",
  },
  syncModalFailedConnectionOk: {
    id: "view.selectBar.button.email.syncModalFailedConnectionOk",
    defaultMessage: "Reconnect my email",
    description: "Email button on a selected entities bar - Sync modal with failed connection Ok",
  },
  syncModalFailedConnectionText: {
    id: "view.selectBar.button.email.syncModalFailedConnectionText",
    defaultMessage:
      "Let’s get you synced again. Reconnecting only takes a few seconds - you’ll be back on track in no time! And don’t worry, all your settings and preferences will be saved.",
    description:
      "Email button on a selected entities bar - Sync modal with failed connection title",
  },
  syncModalFailedConnectionTitle: {
    id: "view.selectBar.button.email.syncModalFailedConnectionTitle",
    defaultMessage: "Uh oh! Your email isn’t connected",
    description:
      "Email button on a selected entities bar - Sync modal with failed connection title",
  },
  syncModalNoConnectionOk: {
    id: "view.selectBar.button.email.sycModalNoConnectionOk",
    defaultMessage: "Sync my email",
    description: "Email button on a selected entities bar - Sync modal with no connection Ok",
  },
  syncModalNoConnectionText: {
    id: "view.selectBar.button.email.syncModalNoConnectionText",
    defaultMessage:
      "When you sync your email, MMC will: {br} 1. Auto-log the activity for you{br} 2. Save the content of the email to the activity{br} 3. Track opens and clicks{br}{br} {icon} <b>Pro Tip:</b> Use Lasso to send emails in bulk",
    description: "Email button on a selected entities bar - Sync modal with no connection title",
  },
  syncModalNoConnectionTitle: {
    id: "view.selectBar.button.email.sycModalNoConnectionTitle",
    defaultMessage: "Set up email in Map My Customers ",
    description: "Email button on a selected entities bar - Sync modal with no connection title",
  },
});

export default messages;

import { ActionType, createAsyncAction } from "typesafe-actions";

import { Route } from "@mapmycustomers/shared/types/entity";

export const fetchCompanyRoutes = createAsyncAction(
  "@routes/fetchCompanyRoutes/request",
  "@routes/fetchCompanyRoutes/success",
  "@routes/fetchCompanyRoutes/failure"
)<
  {
    query?: string;
    // these are routes which must be included into result, e.g. because user selected them
    selectedRouteIds?: Route["id"][];
  },
  Route[],
  void
>();

export const fetchPeopleRoutes = createAsyncAction(
  "@routes/fetchPeopleRoutes/request",
  "@routes/fetchPeopleRoutes/success",
  "@routes/fetchPeopleRoutes/failure"
)<
  {
    query?: string;
    // these are routes which must be included into result, e.g. because user selected them
    selectedRouteIds?: Route["id"][];
  },
  Route[],
  void
>();

export type Actions = ActionType<typeof fetchCompanyRoutes | typeof fetchPeopleRoutes>;

import React from "react";

import { Route, RouteProps } from "react-router-dom";

import { LoadingSpinner } from "@mapmycustomers/ui";

type PublicRouteProps = RouteProps;

const PublicRoute: React.FC<PublicRouteProps> = ({ component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        let result = null;
        if (component) {
          result = React.createElement(component, props);
        }
        return <React.Suspense fallback={<LoadingSpinner global />}>{result}</React.Suspense>;
      }}
    />
  );
};

export default PublicRoute;

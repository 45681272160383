import CountryCode from "@mapmycustomers/shared/enum/CountryCode";
import AreaType from "@mapmycustomers/shared/types/territory/AreaType";
import { isDefined } from "@mapmycustomers/shared/util/assert";
import stringHash from "@mapmycustomers/shared/util/hash/stringHash";
import { parseAreaType } from "@mapmycustomers/shared/util/territory/areaTypes";

import configService from "@app/config/ConfigService";
import Environment from "@app/enum/Environment";

// use .json.gz extension when using S3 storage, and we use it on staging/prod
const suffix = configService.getCurrentEnvironment() === Environment.DEVELOPMENT ? "" : ".gz";

const getIdPath = (id: string) => {
  const hash = Math.abs(stringHash(id)).toString(16);
  // split hash in chunks of size 4
  return hash
    .split(/(.{1,4})/g)
    .filter((x) => !!x)
    .join("/");
};

interface BoundaryUrlGetter {
  // Getting country states for given territory details
  (type: "countryStates"): string;
  // lookup file for country
  (type: "lookup", countryCode: CountryCode): string;
  // meta file for country
  (type: "meta", countryCode: CountryCode): string;
  // meta file for areaType
  (type: "meta", areaType: AreaType): string;
  // individual record (or a simplified record when zoom level given)
  (type: "id", areaType: AreaType, id: string, zoomLevel?: number): string;
  // list of records for given zoom level
  (type: "zoom", areaType: AreaType, zoomLevel: number): string;
  // tile information
  (type: "tiles", areaType: AreaType): string;
  // regions information
  (type: "regions", areaType: AreaType): string;
}

const getBoundaryUrl: BoundaryUrlGetter = (
  type: "countryStates" | "id" | "lookup" | "meta" | "regions" | "tiles" | "zoom",
  areaTypeOrCountryCode?: AreaType | CountryCode,
  idOrTileOrZoomLevel?: number | string,
  idZoomLevel?: number
) => {
  const boundariesUrl = configService.getBaseBoundariesUrl();
  const serviceUrl = configService.getBoundaryServiceUrl();

  if (type === "countryStates") {
    return `${serviceUrl}/country_states`;
  }
  if (!areaTypeOrCountryCode) {
    throw new Error(
      `Invalid argument: AreaType or CountryCode required for requested type: ${type}`
    );
  }

  const parsedAreaType = parseAreaType(areaTypeOrCountryCode as AreaType);
  if (!parsedAreaType) {
    const countryCode = areaTypeOrCountryCode;
    switch (type) {
      case "lookup":
        return `${boundariesUrl}/${countryCode}/${countryCode}.lookup.json${suffix}`;
      case "meta":
        return `${boundariesUrl}/${countryCode}/${countryCode}.meta.json${suffix}`;
      default: {
        throw new Error("Invalid argument: you can only use country code with type lookup");
      }
    }
  }

  const { countryCode, level } = parsedAreaType;
  const areaType = areaTypeOrCountryCode;

  switch (type) {
    case "id": {
      const id = idOrTileOrZoomLevel as string;
      const hashPath = getIdPath(id);

      if (isDefined(idZoomLevel)) {
        return `${boundariesUrl}/${countryCode}/${level}/${idZoomLevel}/id/${hashPath}/${areaType}.id_${id}.json${suffix}`;
      } else {
        return `${boundariesUrl}/${countryCode}/${level}/id/${hashPath}/${areaType}.id_${id}.json${suffix}`;
      }
    }
    case "meta":
      return `${boundariesUrl}/${countryCode}/${level}/${areaType}.meta.json${suffix}`;
    case "regions": {
      return `${serviceUrl}/regions`;
    }
    case "tiles": {
      return `${serviceUrl}/tiles`;
    }
    case "zoom": {
      const zoom = idOrTileOrZoomLevel as number;
      return `${boundariesUrl}/${countryCode}/${level}/${zoom}/${areaType}.${zoom}.json${suffix}`;
    }
    default:
      throw new Error(
        `Received invalid combination of arguments: ${areaTypeOrCountryCode}, ${type}, ${idOrTileOrZoomLevel}, ${idZoomLevel}`
      );
  }
};

export default getBoundaryUrl;

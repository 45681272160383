import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";

import Space from "antd/es/space";
import { ColumnType } from "antd/es/table";
import cn from "classnames";
import { defineMessages, useIntl } from "react-intl";

import { Activity } from "@mapmycustomers/shared/types/entity";
import useDebouncedCallback from "@mapmycustomers/shared/util/hook/useDebouncedCallback";
import { EntityTypeShapedIcon } from "@mapmycustomers/ui";
import { TextField } from "@mapmycustomers/ui";

import ActivityAssignee from "@app/component/activity/ActivityAssignee";
import EmailMetrics from "@app/component/EmailMetrics";
import EmailsMetrics from "@app/component/EmailsMetrics";
import Table from "@app/component/Table";
import EntityViewPayload from "@app/store/entityView/EntityViewPayload";
import { getUserSettingValue } from "@app/store/iam";
import { RootState } from "@app/store/rootReducer";

import styles from "./ActivitiesView.module.scss";
import ActivityView from "./ActivityView";
import EntityLink from "./EntityLink";
import { loadAnnotationData } from "./store/actions";
import { getActivities } from "./store/selectors";

const messages = defineMessages({
  metricsTitle: {
    id: "component.activityAnnotation.activities.metricsTitle",
    defaultMessage: "Metrics",
    description: "Email activities metrics title",
  },
  recipientsTitle: {
    id: "component.activityAnnotation.activities.recipientsTitle",
    defaultMessage: "Recipients ({count})",
    description: "Email activities recipients title",
  },
  searchPlaceholder: {
    id: "component.activityAnnotation.activities.searchPlaceholder",
    defaultMessage: "Search recipients",
    description: "Email activities search placeholder",
  },
});

interface ActivitiesViewProps {
  activities: Activity[];
  onChange?: (activity: Activity) => void;
  onEdit: (payload: EntityViewPayload) => void;
  onHide(): void;
  onLoadAnnotationData: typeof loadAnnotationData.request;
  trackEmails: boolean;
}

const ActivitiesView: React.FC<ActivitiesViewProps> = ({
  activities,
  onChange,
  onEdit,
  onHide,
  onLoadAnnotationData,
  trackEmails,
}) => {
  const intl = useIntl();

  const [searchText, setSearchText] = useState("");
  const [filteredActivities, setFilteredActivities] = useState(activities);
  const [selectedActivityId, setSelectedActivityId] = useState<Activity["id"] | undefined>();

  const emailLogs = useMemo(() => activities.map((activity) => activity.emailLog!), [activities]);

  const columns: ColumnType<Activity>[] = useMemo(() => {
    const columns: ColumnType<Activity>[] = [];

    columns.push({
      key: "recipients",
      render: (_, activity) => (
        <EntityLink
          entity={{
            id: activity.emailLog!.entityId,
            name: activity.emailLog!.recipientAddress[0].name,
          }}
          entityType={activity.emailLog!.entityType}
          onClick={onHide}
          showIcon={false}
        />
      ),
      title: (
        <Space>
          <EntityTypeShapedIcon
            className={styles.entityTypeIconContainer}
            entityType={activities[0].emailLog!.entityType}
            iconClassName={styles.entityTypeIcon}
          />
          <span>{intl.formatMessage(messages.recipientsTitle, { count: activities.length })}</span>
        </Space>
      ),
    });

    if (trackEmails) {
      columns.push({
        key: "metrics",
        render: (_, activity) => (
          <EmailMetrics emailLog={activity.emailLog} size="small" transparent />
        ),
        title: intl.formatMessage(messages.metricsTitle),
      });
    }

    return columns;
  }, [intl, activities, trackEmails, onHide]);

  useEffect(() => {
    if (activities.length) {
      onLoadAnnotationData(activities[0].id);
    }
  }, [activities, onLoadAnnotationData]);

  const handleSearch = useDebouncedCallback(
    [
      (searchText: string) => {
        const searchedTextLowered = searchText.toLowerCase();
        const filteredActivities = activities.filter((activity) =>
          activity.emailLog!.recipientAddress[0].name.toLowerCase().includes(searchedTextLowered)
        );
        setFilteredActivities(filteredActivities);
      },
    ],
    [setFilteredActivities]
  );

  const handleActivitySelect = useCallback(
    (activity: Activity) => {
      setSelectedActivityId(activity.id);
      onLoadAnnotationData(activity.id);
    },
    [setSelectedActivityId, onLoadAnnotationData]
  );

  const rowEventHandlers = useCallback(
    (activity: Activity) => ({
      onClick: () => {
        handleActivitySelect(activity);
      },
    }),
    [handleActivitySelect]
  );

  const getRowClassName = useCallback(
    (activity: Activity) => {
      return cn(styles.row, activity.id === selectedActivityId ? styles.selectedRow : undefined);
    },
    [selectedActivityId]
  );

  useEffect(() => {
    handleSearch(searchText);
  }, [handleSearch, searchText]);

  return (
    <div className={styles.container}>
      <Space>
        <EmailsMetrics emailLogs={emailLogs} />
        <ActivityAssignee activity={activities[0]} />
      </Space>
      <div className={styles.panelsContainer}>
        <div className={styles.activitiesPanel}>
          <TextField
            onChange={setSearchText}
            placeholder={intl.formatMessage(messages.searchPlaceholder)}
            value={searchText}
          />
          <Table<Activity>
            className={styles.table}
            columns={columns}
            dataSource={filteredActivities}
            onRow={rowEventHandlers}
            pagination={false}
            rowClassName={getRowClassName}
            rowKey="id"
            scrollable
            showBorder
            size="small"
          />
        </div>
        <div className={styles.activityPanel}>
          <ActivityView className={styles.activityView} onChange={onChange} onEdit={onEdit} />
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: RootState) => ({
  activities: getActivities(state),
  trackEmails: getUserSettingValue(state)("bulkEmail").trackEmails,
});

const mapDispatchToProps = {
  onLoadAnnotationData: loadAnnotationData.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivitiesView);

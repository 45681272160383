import React, { memo, useEffect } from "react";

import Button from "antd/es/button";
import Popconfirm from "antd/es/popconfirm";
import Space from "antd/es/space";
import Tooltip from "antd/es/tooltip";
import { defineMessages, useIntl } from "react-intl";

import { RawFile } from "@mapmycustomers/shared/types/File";
import { LoadingSpinner, Modal } from "@mapmycustomers/ui";

import canPreview from "./canPreview";
import styles from "./PreviewFile.module.scss";

const PdfFilePreview = React.lazy(() => import("./PdfFilePreview"));

const messages = defineMessages({
  cancelText: {
    id: "component.filePreview.cancelText",
    defaultMessage: "Cancel",
    description: "Cancel text",
  },
  closeText: {
    id: "component.filePreview.closeText",
    defaultMessage: "Close",
    description: "Close text",
  },
  deleteFile: {
    id: "component.filePreview.deleteFile",
    defaultMessage: "Delete file",
    description: "Delete file",
  },
  deleteFileTitle: {
    id: "component.filePreview.deleteFile.title",
    defaultMessage: "Delete file?",
    description: "Delete file title",
  },
  downloadFile: {
    id: "component.filePreview.downloadFile",
    defaultMessage: "Download file",
    description: "Download file",
  },
  noPreview: {
    id: "component.filePreview.noPreview",
    defaultMessage: "No preview available for files of type {contentType}",
    description: "No preview available",
  },
  okText: {
    id: "component.filePreview.okText",
    defaultMessage: "Yes",
    description: "Ok text",
  },
});

interface Props {
  deleteTooltip?: string;
  disabled?: boolean;
  file: RawFile;
  loading?: boolean;
  onCancel: () => void;
  onDelete?: (file: RawFile) => void;
  onDownload?: (file: RawFile) => void;
  onFetchFilePreview?: (fileId: RawFile["id"]) => void;
  preview: Blob | undefined;
  previewId: RawFile["id"] | undefined;
}

const PreviewFile: React.FC<Props> = ({
  deleteTooltip,
  disabled,
  file,
  loading,
  onCancel,
  onDelete,
  onDownload,
  onFetchFilePreview,
  preview,
  previewId,
}) => {
  const intl = useIntl();

  useEffect(() => {
    if (!canPreview(file)) {
      return;
    }
    onFetchFilePreview?.(file.id);
  }, [file, onFetchFilePreview]);

  return (
    <Modal
      cancelText={intl.formatMessage(messages.closeText)}
      footer={
        <Space>
          {(!disabled || deleteTooltip) && (
            <Popconfirm
              cancelText={intl.formatMessage(messages.cancelText)}
              disabled={disabled}
              getPopupContainer={(trigger: HTMLElement) => trigger.parentElement!}
              key="deleteFile"
              okText={intl.formatMessage(messages.okText)}
              onConfirm={() => {
                onDelete?.(file);
              }}
              title={intl.formatMessage(messages.deleteFileTitle)}
            >
              <Tooltip title={deleteTooltip ?? null}>
                <Button danger disabled={disabled} key="delete" type="text">
                  {intl.formatMessage(messages.deleteFile)}
                </Button>
              </Tooltip>
            </Popconfirm>
          )}
          <Button
            key="download"
            onClick={() => {
              onDownload?.(file);
            }}
            type="primary"
          >
            {intl.formatMessage(messages.downloadFile)}
          </Button>
        </Space>
      }
      onCancel={onCancel}
      open
      title={file.name}
      wrapClassName={styles.previewModal}
    >
      <div className={styles.preview}>
        {loading ? (
          <LoadingSpinner />
        ) : preview && previewId === file.id ? (
          (() => {
            switch (file.contentType) {
              case "image/png":
              case "image/jpg":
              case "image/jpeg":
              case "image/gif":
                return (
                  <img
                    alt={file.name}
                    className={styles.previewImage}
                    src={URL.createObjectURL(preview)}
                  />
                );
              case "application/pdf":
                return (
                  <React.Suspense fallback={<LoadingSpinner />}>
                    <PdfFilePreview file={preview} />
                  </React.Suspense>
                );
              case "video/mp4":
                return (
                  <video controls height="267" width="494">
                    <source src={URL.createObjectURL(preview)} type="video/mp4" />
                  </video>
                );
              default:
                return null;
            }
          })()
        ) : (
          intl.formatMessage(messages.noPreview, { contentType: file.contentType })
        )}
      </div>
    </Modal>
  );
};

export default memo(PreviewFile);

import React from "react";

import Col from "antd/es/col";
import Row from "antd/es/row";
import { useIntl } from "react-intl";

import { Activity } from "@mapmycustomers/shared/types/entity";
import { Collapse, CollapsePanel } from "@mapmycustomers/ui";

import EmailBody from "@app/component/email/parts/EmailBody";
import EmailRecipients from "@app/component/email/parts/EmailRecipients";
import EmailSubject from "@app/component/email/parts/EmailSubject";
import EmailMetrics from "@app/component/EmailMetrics";

import styles from "./EmailSection.module.scss";

interface Props {
  activity: Activity;
}

const EmailSection: React.FC<Props> = ({ activity }) => {
  const intl = useIntl();

  if (!activity.emailLog) {
    return null;
  }

  return (
    <Collapse accordion className={styles.collapse} expandIconPosition="end" ghost>
      <CollapsePanel
        className={styles.collapsePanel}
        header={
          <Row className={styles.collapseHeader} justify="space-between">
            <Col className={styles.title}>
              {intl.formatMessage({
                id: "component.activityAnnotation.emailSection.title",
                defaultMessage: "Email",
                description: "Email section title",
              })}
            </Col>
            <Col>
              <EmailMetrics emailLog={activity.emailLog} />
            </Col>
          </Row>
        }
        key="email"
      >
        <EmailRecipients recipients={activity.emailLog.recipientAddress} />
        <EmailSubject subject={activity.emailLog.subject} />
        <EmailBody body={activity.emailLog.body} />
      </CollapsePanel>
    </Collapse>
  );
};

export default EmailSection;

import { createReducer } from "typesafe-actions";

import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import { DuplicateEntity } from "@mapmycustomers/shared/types/entity/Duplicate";

import FileListItem from "@app/types/FileListItem";

import {
  Actions,
  clearAllUploadedCompanyFiles,
  clearDuplicates,
  createCompany,
  hideGlobalCreateCompanyModal,
  initialize,
  removeCompanyFile,
  showGlobalCreateCompanyModal,
  uploadCompanyFiles,
} from "./actions";

export interface CreateCompanyModalState {
  availableCompanies: CompanyRef[];
  availableCompaniesLoading: boolean;
  createLoading: boolean;
  duplicates: DuplicateEntity[];
  fileGroupId: string;
  filesAdded: FileListItem[];
  filesUploading: boolean;
  initializeLoading: boolean;
  isGlobalAddCompany?: boolean;
  visible: boolean;
}

const initialState: CreateCompanyModalState = {
  availableCompanies: [],
  availableCompaniesLoading: false,
  createLoading: false,
  duplicates: [],
  fileGroupId: "",
  filesAdded: [],
  filesUploading: false,
  initializeLoading: false,
  visible: false,
};

const createCompanyModal = createReducer<CreateCompanyModalState, Actions>(initialState)
  .handleAction(showGlobalCreateCompanyModal, (state, action) => ({
    ...state,
    isGlobalAddCompany: action.payload?.showPostCreatePrompt,
    visible: true,
  }))
  .handleAction(hideGlobalCreateCompanyModal, (state) => ({
    ...state,
    isGlobalAddCompany: false,
    visible: false,
  }))
  .handleAction(initialize.request, (state) => ({
    ...state,
    duplicates: [],
    filesAdded: [],
    initializeLoading: true,
    parentCompanyDetails: undefined,
  }))
  .handleAction(initialize.success, (state, action) => ({
    ...state,
    ...action.payload,
    initializeLoading: false,
  }))
  .handleAction(initialize.failure, (state) => ({
    ...state,
    initializeLoading: false,
  }))
  .handleAction(createCompany.request, (state) => ({
    ...state,
    createLoading: true,
  }))
  .handleAction(createCompany.success, (state) => ({
    ...state,
    createLoading: false,
    duplicates: [],
  }))
  .handleAction(createCompany.failure, (state, { payload }) => ({
    ...state,
    createLoading: false,
    duplicates: payload.duplicates ?? [],
  }))
  .handleAction(uploadCompanyFiles.request, (state) => ({
    ...state,
    filesUploading: true,
  }))
  .handleAction(uploadCompanyFiles.success, (state, action) => ({
    ...state,
    filesAdded: [...state.filesAdded, ...action.payload],
    filesUploading: false,
  }))
  .handleAction(uploadCompanyFiles.failure, (state) => ({
    ...state,
    filesUploading: false,
  }))
  .handleAction(removeCompanyFile.request, (state) => ({
    ...state,
  }))
  .handleAction(removeCompanyFile.success, (state, action) => ({
    ...state,
    //Removing the deleted file from store
    filesAdded: state.filesAdded.filter((file) => file.uploadedFile?.id !== action.payload),
  }))
  .handleAction(removeCompanyFile.failure, (state) => ({
    ...state,
  }))
  .handleAction(clearAllUploadedCompanyFiles, (state) => ({
    ...state,
    filesAdded: [],
    filesUploading: false,
  }))
  .handleAction(clearDuplicates, (state) => ({
    ...state,
    duplicates: [],
  }));

export * from "./selectors";
export type CreateCompanyModalActions = Actions;
export default createCompanyModal;

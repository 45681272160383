@use "../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .separator {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: colors.$mmc-soft-slate;
      }

      .unsubscribeLink {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        text-decoration-line: underline;
        color: colors.$mmc-soft-slate;
        margin-bottom: 0.75rem;
      }
    }
  }
}

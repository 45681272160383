import { GuideUserStatus } from "@mapmycustomers/shared/types/entity";
import User from "@mapmycustomers/shared/types/User";

const getUsersStatusMap = (guidesStatus: GuideUserStatus[]) =>
  guidesStatus.reduce((result, guideStatus) => {
    if (!result[guideStatus.user.id]) {
      result[guideStatus.user.id] = [];
    }
    result[guideStatus.user.id].push(guideStatus);
    return result;
  }, {} as Record<User["id"], GuideUserStatus[]>);

export default getUsersStatusMap;

import { put, select, takeEvery } from "redux-saga/effects";

import Organization from "@mapmycustomers/shared/types/Organization";

import { callApi } from "@app/store/api/callApi";
import { handleError } from "@app/store/errors/actions";
import { getOrganizationId } from "@app/store/iam";

import { updateGuideStatus } from "./actions";

function* onUpdateGuideStatus({ payload }: ReturnType<typeof updateGuideStatus.request>) {
  try {
    const orgId: Organization["id"] = yield select(getOrganizationId);
    yield callApi("updateGuideProgress", orgId, payload);
    yield put(updateGuideStatus.success());
  } catch (error) {
    yield put(handleError({ error }));
    yield put(updateGuideStatus.failure());
  }
}

export function* onboardingSaga() {
  yield takeEvery(updateGuideStatus.request, onUpdateGuideStatus);
}

import React, { useCallback } from "react";

import { defineMessages, useIntl } from "react-intl";

import { useModal } from "@mapmycustomers/ui";

import styles from "../CreateEditActivityModal.module.scss";

const messages = defineMessages({
  cancel: {
    id: "editActivityModal.recurIntervalChangedModal.cancel",
    defaultMessage: "Not yet",
    description: "Recurrent interval Modal cancel button text on Create/Edit Activity modal",
  },
  content: {
    id: "editActivityModal.recurIntervalChangedModal.content",
    defaultMessage:
      "You’ve made changes to the frequency this activity repeats. By saving, all future repeating activities will be replaced. Notes or files attached to future activities will be lost. ",
    description: "Recurrent interval Modal Content on Create/Edit Activity modal",
  },
  ok: {
    id: "editActivityModal.recurIntervalChangedModal.ok",
    defaultMessage: "Yes, update repeating activities",
    description: "Recurrent interval Modal Ok button text on Create/Edit Activity modal",
  },
  title: {
    id: "editActivityModal.recurIntervalChangedModal.title",
    defaultMessage: "Edit repeating activity",
    description: "Recurrent interval Modal Title on Create/Edit Activity modal",
  },
});

export const useModalConfirm = (onSave: () => void): [() => void, React.ReactElement] => {
  const intl = useIntl();

  const [modal, contextHolder] = useModal();

  const confirm = useCallback(
    () =>
      modal.show({
        cancelButtonProps: {
          type: "text",
        },
        cancelText: intl.formatMessage(messages.cancel),
        className: styles.confirmationModal,
        content: intl.formatMessage(messages.content),
        okText: intl.formatMessage(messages.ok),
        onOk: onSave,
        title: intl.formatMessage(messages.title),
        type: "confirm",
      }),
    [onSave, intl, modal]
  );

  return [confirm, contextHolder];
};

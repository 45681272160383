import { createSelector } from "reselect";

import { RootState } from "@app/store/rootReducer";

const getEmailUnsubscribeState = (state: RootState) => state.scene.emailUnsubscribe;

export const isEmailUnsubscribing = createSelector(
  getEmailUnsubscribeState,
  ({ unsubscribing }) => unsubscribing
);

export const getEmailUnsubscribeInfo = createSelector(
  getEmailUnsubscribeState,
  ({ unsubscribeInfo }) => unsubscribeInfo
);

export const getEmailUnsubscribeCode = createSelector(
  getEmailUnsubscribeState,
  ({ unsubscribeCode }) => unsubscribeCode
);

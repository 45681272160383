import React, { ReactNode, useCallback, useState } from "react";
import { connect } from "react-redux";

import Form from "antd/es/form";
import { useWatch } from "antd/es/form/Form";
import useFormInstance from "antd/es/form/hooks/useFormInstance";

import {
  Company,
  EntityType,
  EntityTypesSupportingCompanyAssociation,
} from "@mapmycustomers/shared/types/entity";
import { CompanyRef } from "@mapmycustomers/shared/types/entity/Company";
import useBoolean from "@mapmycustomers/shared/util/hook/useBoolean";
import { EntityTypeShapedIcon, FormItem, LoadingSpinner, SelectField } from "@mapmycustomers/ui";

import CompanyAssociation from "@app/component/associations/CompanyAssociation";
import { fetchCompany } from "@app/store/company/actions";

import styles from "./BaseCompanyFieldCommon.module.scss";
import NotifyInput from "./NotifyInput";

export interface BaseCompanyFieldProps {
  allowAdd?: boolean;
  disabled?: boolean;
  entityType: EntityTypesSupportingCompanyAssociation;
  idFieldName: string;
  label?: ReactNode;
  modalTitle?: ReactNode;
  nameFieldName: string;
  onChange?: (company?: Company) => void;
  required?: boolean;
}

interface Props extends BaseCompanyFieldProps {
  onFetchCompany: typeof fetchCompany;
}

const ParentCompanyField: React.FC<Props> = ({
  allowAdd,
  disabled,
  entityType,
  idFieldName,
  label,
  modalTitle,
  nameFieldName,
  onChange,
  onFetchCompany,
  required,
}) => {
  const form = useFormInstance();
  const parentCompanyId: CompanyRef["id"] = useWatch("parentCompanyId", form);
  const [modalVisible, showModal, hideModal] = useBoolean();
  const [loading, startLoading, stopLoading] = useBoolean();
  const [assignedCompanies, setAssignedCompanies] = useState<Company[]>([]);

  const handleChange = useCallback(() => {
    // this handler is only called when user clears the text field,
    // hence we don't listen to a value here, but instead are just
    // resetting both values in the form
    form.setFieldsValue({ [idFieldName]: undefined, [nameFieldName]: undefined });
    setCompanyId(undefined);
  }, [idFieldName, form, nameFieldName]);

  // Read more about why we need this state in NotifyInput file.
  const [companyId, setCompanyId] = useState<Company["id"] | null | undefined>(null);
  const handleReset = useCallback(() => setCompanyId(null), []);

  const handleClick = useCallback(() => {
    if (parentCompanyId) {
      startLoading();
      onFetchCompany({
        id: parentCompanyId,
        callback: (company) => {
          setAssignedCompanies([company]);
          stopLoading();
          showModal();
        },
        failureCallback: stopLoading,
        options: {
          includeAccessStatus: true,
        },
      });
    } else {
      setAssignedCompanies([]);
      showModal();
    }
  }, [parentCompanyId, showModal, startLoading, stopLoading, onFetchCompany]);

  const handleParentCompanyHide = useCallback(
    (selectedCompaniesIds: Company["id"][]) => {
      // it should never be more than 1
      if (selectedCompaniesIds.length === 1) {
        startLoading();
        onFetchCompany({
          id: selectedCompaniesIds[0],
          callback: (company: Company) => {
            stopLoading();
            form.setFieldsValue({ [idFieldName]: company.id, [nameFieldName]: company.name });
            setCompanyId(company.id);
            onChange?.(company);
          },
          failureCallback: stopLoading,
          options: {
            includeAccessStatus: true,
          },
        });
      } else {
        form.setFieldsValue({ [idFieldName]: undefined, [nameFieldName]: undefined });
        setCompanyId(undefined);
        onChange?.();
      }
    },
    [idFieldName, form, nameFieldName, onChange, onFetchCompany, startLoading, stopLoading]
  );

  return (
    <>
      <FormItem
        dependencies={[idFieldName]}
        label={label}
        name={nameFieldName}
        required={required}
        rules={[{ required }]}
      >
        <SelectField
          allowClear
          label={label}
          locked={disabled}
          onChange={handleChange}
          onClick={handleClick}
          open={false}
          prefixIcon={
            <EntityTypeShapedIcon className={styles.entityIcon} entityType={EntityType.COMPANY} />
          }
          required={required}
          showArrow={false}
          suffixIcon={loading ? <LoadingSpinner mini /> : undefined}
        />
      </FormItem>

      <Form.Item hidden name={idFieldName}>
        <NotifyInput companyId={companyId} onReset={handleReset} />
      </Form.Item>

      {modalVisible && (
        <CompanyAssociation
          allowAdd={allowAdd}
          assignedCompanies={assignedCompanies}
          entityType={entityType}
          multiselect={false}
          onHide={hideModal}
          onSelect={handleParentCompanyHide}
          title={modalTitle}
        />
      )}
    </>
  );
};

const mapDispatchToProps = {
  onFetchCompany: fetchCompany,
};

export default connect(null, mapDispatchToProps)(ParentCompanyField);
